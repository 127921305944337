import { put, call, takeLatest } from "redux-saga/effects";
import { viewTrackerActionSuccess ,viewTrackerActionError } from "../action/viewTrackerAction";
import { VIEW_TRACKER_REQUEST } from "../actionType/type";
import { VIEW_TRACKER } from "../../service/apiUrl";
import { postApiCall } from "../../service/PostApiCall";

function* viewTrackerSaga(action){
    try {
        const response = yield call(postApiCall,action?.params,action?.params?.access_token,VIEW_TRACKER)
        yield put(viewTrackerActionSuccess( response));
        // action.onSuccess(response)
    } catch (error) {
        yield put(viewTrackerActionError(error));
    }
}

export function* watchViewTrackerSaga(){
    yield takeLatest(VIEW_TRACKER_REQUEST,viewTrackerSaga)
}