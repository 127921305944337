import { apiWithHeader } from "./api";
import { BASE_URL } from "./environment";
import { API_STATUS } from "./apiUrl";
import Utils from "../utility/utility";

export const postApiCall = async (params, access_token, url, isFormData=false) => {
  const CONTENT_TYPE = "application/json";
  const response = await apiWithHeader(BASE_URL, CONTENT_TYPE, access_token,isFormData)
    .post(url, params)
    .catch(function (error) {
      // Utils.toast("error", error?.response?.data?.message);
      return Promise.reject(error.response);
    });

  if (response?.data?.status === API_STATUS.SUCCESS) {
    if (params.showSuccessToast === true) {
    
      Utils.toast("success", response?.data?.message);
    }
    return response?.data?.data;
  } else {
    return Promise.reject(response);
  }
};

export const postApiCallImage = async (formData, url) => {
  const CONTENT_TYPE = "multipart/form-data";
  return apiWithHeader(BASE_URL, CONTENT_TYPE)
    .post(url, formData)
    .catch(function (error) {
      return error.response;
    });
};
