import AsyncStorage from "@react-native-async-storage/async-storage";
import { combineReducers } from "redux";
import { signinReducers } from "./signinReducers";
import { manageUserReducers } from "./manageUserReducers";
import { manageTrackerReducers } from "./manageTrackerReducers";
import * as ActionType from "../actionType/type"
import { viewTrackerReducers } from "./viewTrackerReducers";
import { dashboardReducers } from "./dashboardReducers";
import { manageAnnouncementReducer } from "./manageAnnouncementReducer";
import { manageLeaveReducer } from "./manageLeaveReducer";
import { manageProjectReducer } from "./manageProjectReducer";
import { managePayslipsReducer } from "./managePayslipsReducer";
import { timelogReducer } from "./timelogReducer";
import { saturdayListReducer } from "./saturdayListReducer";
import { manageTodoReducers } from "./manageTodoReducers";
import { inventoryReducers } from "./inventoryReducers";
import { manageClickupReducers } from "./manageClickupReducer";
import { viewClickupReducers } from "./viewClickupReducers";

const appReducer = combineReducers({
    signinReducers: signinReducers,
    manageUserReducers: manageUserReducers, 
    manageTrackerReducers : manageTrackerReducers,
    viewTrackerReducers : viewTrackerReducers,
    dashboardReducers: dashboardReducers,
    manageAnnouncementReducer : manageAnnouncementReducer,
    manageLeaveReducer : manageLeaveReducer,
    manageProjectReducer : manageProjectReducer,
    managePayslipsReducer : managePayslipsReducer,
    timelogReducer : timelogReducer,
    saturdayListReducer : saturdayListReducer,
    manageTodoReducers :manageTodoReducers,
    inventoryReducers : inventoryReducers,
    manageClickupReducers: manageClickupReducers,
    viewClickupReducers: viewClickupReducers,
})

export const RootReducer = (state, action) => {
    if (action.type === ActionType.SIGNOUT_REQUEST) {
        AsyncStorage.removeItem("persist:root");
        state = undefined;
    }
    return appReducer(state, action);
}
