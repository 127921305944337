import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { useSelector } from "react-redux";
import Utils from "../utility/utility";
import { STRING } from "../constants/string";
import { postApiCall } from "../service/PostApiCall";
import TimePicker from "react-bootstrap-time-picker";
import { useNavigate } from "react-router-dom";
import {
  LEAVES_HELPER,
  RECENTAPPROVED,
  LEAVECREATE,
  DEPARTMENTSUSERS,
} from "../service/apiUrl";

const AddLeave = (props) => {
  const [departmentval, setdepartmentval] = useState();
  const [leavetable, setleavetable] = useState([]);
  const [employee, setEmployee] = useState(false);
  const [departmentEmployees, setdepartmentEmployees] = useState(false);
  const [type, settype] = useState();
  const [leaveStatus, setLeaveStatus] = useState({ value: "", label: "" });
  const [comment, setComment] = useState("");
  const [fromDate, setfromDate] = useState(new Date());
  const fromDates = Utils.momentDate(fromDate, STRING.SLASH_DATE_FORMAT);
  const [toDate, settoDate] = useState(new Date());
  const toDates = Utils.momentDate(toDate, STRING.SLASH_DATE_FORMAT);
  const [reason, setReason] = useState();
  const [contact, setcontact] = useState();
  const [startTime, setStartTime] = useState(32400);
  const [employes, setEmployes] = useState();
  const startTimeformat = Utils.secondtoTime(startTime);
  const [endtime, setEndTime] = useState(32400);
  const endTimeformat = Utils.secondtoTime(endtime);
  const [half, setHalf] = useState();
  const [helperData, setHelperData] = useState([]);

  const navigate = useNavigate();

  const signdata = useSelector((state) => state.signinReducers);
  const user_id = signdata?.data?.id;
  const access_token = signdata?.data?.access_token;



  const getDepartmentUsers = (department) => {
    const departementParams = {
      department: department,
    };
    postApiCall(departementParams, access_token, DEPARTMENTSUSERS).then(
      (result) => {
        setdepartmentEmployees(result?.users);
      }
    );
  };

  useEffect(() => {
    postApiCall([], access_token, LEAVES_HELPER)
      .then((result) => {
        setHelperData(result);
      })
      .catch((error) => {
        console.error("LEAVES_HELPER", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const AddLeave = () => {
    let message = "";
    if (Utils.isEmpty(departmentval)) {
      message = STRING.DEPARTMENT;
    } else if (Utils.isEmpty(type)) {
      message = STRING.TYPE;
    } else if (startTime > endtime && type == 3) {
      message = STRING.TIMEGREATHER;
    } else if (Utils.isEmpty(reason)) {
      message = STRING.REASON;
    } else if (Utils.isEmpty(contact)) {
      message = STRING.CONTACT;
    }

    if (message) {
      Utils.toast("error", message);
      return false;
    } else {
      const addLeaveParams = {
        department: departmentval,
        type: type,
        from: fromDates,
        to: toDates,
        half_day: String(half ?? ""),
        time_from: startTimeformat,
        time_to: endTimeformat,
        reason: reason,
        contact: contact,
        created_by: user_id,
        uid: employes,
        status: parseInt(leaveStatus?.value),
        comment:comment,
        showSuccessToast: true

      };

      postApiCall(addLeaveParams, access_token, LEAVECREATE, navigate).then(
        (response) => {
          Utils.toast("success", "Leave created successfully!!!");
          navigate("/manage-leave");
        }
      );
    }
  };

  const getRecentLeaves = (employee_id) => {
    const params = {
      uid: employee_id,
    };
    postApiCall(params, access_token, RECENTAPPROVED).then((result) => {
      setleavetable(result?.approved_leaves);
    });
  };

  return (
    <>
      <div className={props?.data?.transtion == true ? "contents" : "content"}>
        <div className="content-top-spacer"></div>
        <div className="color-body">
          <div className="row">
            <div className="col-md-6 manage">
              <div className="card-input mt-5 mb-2">
                <div className="col-md-12">
                  <div className="mt-3">
                    <h5 className="border-bottom pb-2">Apply for Leave</h5>
                  </div>
                  <form>
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Department</label>
                          <Select
                            options={helperData?.departments}
                            // value={{label : departments?.label , value:departments?.value}}
                            onChange={(departments) => {
                              setdepartmentval(departments.value);
                              getDepartmentUsers(departments.value);
                              setEmployee(true);
                            }}
                            placeholder="Please Select"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Leave Type</label>
                          <Select
                            options={helperData?.leave_type}
                            // value={{label : departments?.label , value:departments?.value}}
                            onChange={(leavetype) => {
                              settype(leavetype.value);
                              if (leavetype.value == "1") {
                                setHalf("");
                                setStartTime("");
                                setEndTime("");
                              }
                              if (leavetype.value == "2") {
                                setStartTime("");
                                setEndTime("");
                              }
                              if (leavetype.value == "3") {
                                setHalf("");
                              }
                            }}
                            placeholder="Please Select"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        {employee == true ? (
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Employee</label>
                            <Select
                              options={departmentEmployees}
                              // value={{label : departments?.label , value:departments?.value}}
                              onChange={(employe) => {
                                setEmployes(employe.value);
                                getRecentLeaves(employe.value);
                              }}
                              placeholder="Please Select"
                            />
                          </div>
                        ) : null}
                      </div>
                      {type == 1 || type == 2 || type == 3 ? (
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              From Date
                            </label>
                            <DatePicker
                              selected={fromDate}
                              onChange={(date) => {
                                setfromDate(date);
                              }}
                              className="width-dateset w-100"
                            />
                          </div>
                        </div>
                      ) : null}
                      {type == 1 ? (
                        <>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                End Date
                              </label>
                              <DatePicker
                                selected={toDate}
                                onChange={(date) => settoDate(date)}
                                className="width-dateset w-100"
                              />
                            </div>
                          </div>
                        </>
                      ) : null}
                      {type == 2 ? (
                        <>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">Half</label>
                              <Select
                                options={helperData?.half_leave_type}
                                // value={{label : departments?.label , value:departments?.value}}
                                onChange={(element) => {
                                  setHalf(element.value);
                                  settoDate(fromDate);
                                }}
                                placeholder="Please Select"
                              />
                            </div>
                          </div>
                        </>
                      ) : null}
                      {type == 3 ? (
                        <>
                          <div className="col-md-3">
                            <label htmlFor="exampleInputEmail1">
                              Start Time
                            </label>
                            <TimePicker
                              start="09:00"
                              end="20:00"
                              onChange={(start) => {
                                setStartTime(start);
                              }}
                              value={startTime}
                            />
                          </div>
                          <div className="col-md-3">
                            <label htmlFor="exampleInputEmail1">End Time</label>
                            <TimePicker
                              start="09:00"
                              end="20:00"
                              step={30}
                              onChange={(end) => {
                                setEndTime(end);
                              }}
                              value={endtime}
                            />
                          </div>
                        </>
                      ) : null}

                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Reason</label>
                          <textarea
                            className="form-control"
                            id="exampleFormControlTextarea1"
                            onChange={(reason) => {
                              setReason(reason.target.value);
                            }}
                            rows="4"
                          ></textarea>
                        </div>
                        <div className="mt-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Contact During Leave
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              onChange={(Contact) => {
                                setcontact(Contact.target.value);
                              }}
                              // placeholder="Enter Project Name"
                            />
                          </div>
                        </div>

                      {signdata?.data?.role == 1 ?
                      <>
                      <div className="mt-2 form-group">
                          <label htmlFor="exampleInputEmail1">
                            Leave Status
                          </label>
                          <Select
                              options={helperData?.leave_status}
                              value={leaveStatus}
                              onChange={(event) => {
                                setLeaveStatus({
                                  value: event?.value,
                                  label: event?.label
                                });
                              }}

                              


                              placeholder="Please Select"
                            />
                        </div>

                        <div className="mt-2 form-group">
                          <label htmlFor="exampleInputEmail1">Comment</label>
                          <textarea
                            className="form-control"
                            onChange={(reason) => {
                              setComment(reason.target.value);
                            }}
                            rows="4"
                            value={comment}
                          ></textarea>
                        </div>
                        </> :
                        ""
                      }




                    </div>


                    </div>
                    <div className="col-md-12">
                      <div className="row mb-5 mt-4">
                        <div className="">
                          <button
                            type="button"
                            className="create-button"
                            onClick={AddLeave}
                          >
                            Apply
                          </button>
                        </div>
                        <div className="ml-2">
                          <button
                            type="button"
                            className="create-button1 btn-info"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-6 manage">
              <div className="card-input mt-5 mb-2">
                <div className="col-md-12">
                  <div className="mt-3">
                    <h5>
                      My Recent Approved Leaves
                    </h5>
                  </div>
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col" className="text-center">
                          Date
                        </th>
                        <th scope="col" className="text-center">
                          Type
                        </th>
                        <th scope="col" className="text-center">
                          Duration
                        </th>
                        <th scope="col" className="text-center">
                          Reason
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {leavetable?.map((datae, idx) => {
                        return (
                          <tr key={idx}>
                            <td className="text-center">{Utils.momentDate(datae?.from,'MMMM DD, YYYY')}</td>
                            <td className="text-center">{datae?.type}</td>
                            <td className="text-center">{datae?.duration}</td>
                            <td className="text-center" title={datae?.reason}>{Utils.stringReducer(datae?.reason)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div className="border-bottom mt-3"></div>
                  {leavetable == "" ? (
                    <div className="text-center">
                      <div className="no-approved">
                        <p className="mb-0">No Recent Approved Leaves</p>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddLeave;
