/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
// import DatePicker from "react-datepicker";
import { useSelector, useDispatch } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
// import {} from "../assets";
import { Chart } from "react-google-charts";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import { USER_LEAVE_CANCEL, USER_MYLEAVE } from "../../service/apiUrl";
import { postApiCall } from "../../service/PostApiCall";
import moment from "moment";

const customStylesDelete = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
  },
};

const MyLeaves = (props) => {
  const [chart, setChart] = useState();
  const [deletModalIsOpen, setDeletModalIsOpen] = useState(false);
  const [deleteLeaveId, setDeleteLeaveId] = useState("");
  const [update, setUpdate] = useState(false);
  const [myLeaves, setMyLeaves] = useState({});
  const signdata = useSelector((state) => state.signinReducers);
  //   const user_id = signdata?.data?.id;
  const access_token = signdata?.data?.access_token;
  const closeModal = () => {
    setDeletModalIsOpen(false);
    setDeleteLeaveId("");
  };
  useEffect(() => {
    getLeaves();
  }, []);

  const getLeaves = () => {
    const parms = {};
    postApiCall(parms, access_token, USER_MYLEAVE)
      .then((response) => {
        setMyLeaves(response);
      })
      .catch((error) => {
        console.error("MY_LEAVE", error);
      });
  };

  const cancelLeave = () => {
    if (deleteLeaveId) {
      const parms = {
        id: deleteLeaveId,
      };
      postApiCall(parms, access_token, USER_LEAVE_CANCEL)
        .then(() => {
          getLeaves();
          closeModal();
        })
        .catch((error) => {
          console.error("Cancel_leave", error);
        });
    }
  };

  return (
    <>
      <div className={props?.data?.transtion == true ? "contents" : "content"}>
        <div className="content-top-spacer"></div>
        <div className="color-body">
          <div className="col-md-12">
            <div className="card-input mt-3">
              <div className="col-md-12 mb-3">
                <div className="mt-4">
                  <div className="col-md-12"></div>
                  <div className="">
                    <div className="mt-3">
                      <table className="table table-sm">
                        <thead>
                          <tr>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">#</span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">Type</span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">Duration</span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">Status</span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">
                                Created Date
                              </span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">
                                Updated By
                              </span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">Manage</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* <tr>
                            <td></td>
                            <td>
                              <input
                                type="text"
                                className="w-75 listing-serach mt-2 mb-2"
                              />
                            </td>
                          </tr> */}
                          {myLeaves?.leaves?.map((data, idx) => {
                            const createdAt = new Date(data.created_at * 1000);
                            createdAt.setHours(0, 0, 0, 0);
                            const isToday =
                              createdAt.getTime() ===
                              new Date().setHours(0, 0, 0, 0);
                            const created_date = moment
                              .unix(data.created_at)
                              .format("MMMM D, YYYY");
                            return (
                              <tr key={idx}>
                                <td>
                                  <span className="my-1 d-flex mr-4">
                                    {idx + 1}
                                  </span>
                                </td>
                                <td>
                                  <span className="my-1 d-flex">
                                    {data?.type}
                                  </span>
                                </td>
                                <td>
                                  <span className="my-1 d-flex">
                                    {data?.duration}
                                  </span>
                                </td>
                                <td>
                                  <span className="my-1 d-flex">
                                    {data?.status}
                                  </span>
                                </td>
                                <td>
                                  <span className="my-1 d-flex">
                                    {created_date}
                                  </span>
                                </td>
                                <td>
                                  <span className="my-1 d-flex">
                                    {data?.updated_by?.name}
                                  </span>
                                </td>
                                <td>
                                  {/* {data?.status == "Pending" ? (
                                    <div className="row ">
                                      {console.log("asdsa---> ",new Date(data.created_at * 1000)).getTime()}
                                      {isVisible === true ? (
                                        <div
                                          className="btn btn-success "
                                          style={{ fontSize: "12px" }}
                                          onClick={() => {
                                            setDeletModalIsOpen(true);
                                            setDeleteLeaveId(data?.id);
                                          }}
                                        >
                                          Cancel Leave
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  )} */}
                                  {data?.status === "Pending" && isToday && (
                                    <div className="row">
                                      <div
                                        className="btn btn-success"
                                        style={{ fontSize: "12px" }}
                                        onClick={() => {
                                          setDeletModalIsOpen(true);
                                          setDeleteLeaveId(data?.id);
                                        }}
                                      >
                                        Cancel Leave
                                      </div>
                                    </div>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>

                      <Modal
                        isOpen={deletModalIsOpen}
                        ariaHideApp={false}
                        onRequestClose={closeModal}
                        style={customStylesDelete}
                        contentLabel="Example Modal"
                      >
                        <div className="row">
                          <div className="col-md-6">
                            <h5 className="mb-0">Cancel Leave</h5>
                          </div>
                          <div className="col-md-6 text-right mb-3">
                            <i
                              className="fa fa-times curser-point"
                              aria-hidden="true"
                              onClick={closeModal}
                            ></i>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <p className="text-center">
                              Are you sure you want to Cancel this ?
                            </p>
                          </div>
                          <div className="col-md-12 mt-2">
                            <div className="row justify-content-center">
                              <button
                                type="button"
                                className="reset-button"
                                onClick={cancelLeave}
                              >
                                Cancel Leave
                              </button>
                              {/* <button
                                type="button"
                                className="button-default ml-2"
                                onClick={closeModal}
                                >
                                Cancel
                                </button> */}
                            </div>
                          </div>
                        </div>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyLeaves;
