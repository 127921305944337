/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import Utils from "../utility/utility";
import { STRING } from "../constants/string";
import { useNavigate, useParams } from "react-router-dom";
import LoadingBox from "../components/LoadingBox";
import { postApiCall } from "../service/PostApiCall";
import { CREATE_CLICKUP_ACCOUNT, CLICKUP_ACCOUNT_UPDATE, PROJECT_HELPER } from "../service/apiUrl";
import { viewClickupActionRequest } from "../redux/action/viewClickupAction";
import { MultiSelect } from "react-multi-select-component";


const AddClickupAccount = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [developerAssigned, setDeveloperAssigned] = useState([]);
  const [helperData, setHelperData] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getid = useParams();
  const signdata = useSelector((state) => state.signinReducers);
  const viewClickup = useSelector((state) => state.viewClickupReducers);

  console.log('viewClickup',viewClickup);

  const access_token = signdata?.data?.access_token;
  const user_id = signdata?.data?.id;

  useEffect(() => {
    const parms = {
      access_token: access_token,
      id: getid?.clickup_id ?? "",
    };

    if (getid && getid.clickup_id) {
      dispatch(viewClickupActionRequest(parms));
    }
  }, [getid]);

  useEffect(() => {
    postApiCall([], signdata?.data?.access_token, PROJECT_HELPER)
      .then((result) => {
        setHelperData(result);
      })
      .catch((error) => {
        console.error("PROJECT_HELPER", error);
      });
  }, []);

  useEffect(() => {
    if(getid){
      setEmail(viewClickup?.data?.email ?? '');
      setPassword(viewClickup?.data?.password ?? '');
      setDeveloperAssigned(viewClickup?.data?.assigned_developer ? viewClickup?.data?.assigned_developer : []);

    }
  }, [viewClickup]);
  
  const validation = () => {
    let message = "";
    if (Utils.isEmpty(email)) {
      message = STRING.USERNAME;
    } 
    else if (!Utils.validateEmail(email)) {
      message = STRING.ENTER_VALID_EMAIL;
    } 
    else if (Utils.isEmpty(password)) {
      message = STRING.ENTER_PASSWORD;
    } 
    // else if (password.length < 6) {
    //   message = STRING.ENTER_SIX_DIGIT_PASSWORD;
    // } 
    return message;
  };
  const clickAdd = () => {
    var message = validation();
    if (message) {
      Utils.toast("error", message);
      return false;
    } else {
      const objectdata = {
        email: email,
        password: password,
        assigned_developer: developerAssigned,
        created_by: user_id,
      };

      postApiCall(objectdata, access_token, CREATE_CLICKUP_ACCOUNT).then(
        (response) => {
          Utils.toast("success", "Click account added!");
          navigate("/manage-clickup");
        }
        
      ).catch(error => {
        console.log('error',error);
        Utils.toast("error", "Some error occured!");
      });
      return true;
    }
  };

  const clickUpdate = () => {
    var message = validation();
    if (message) {
      Utils.toast("error", message);
      return false;
    } else {
      const updatedata = {
        // name: name,
        // platform: platformValue,
        email: email,
        password: password,
        assigned_developer: developerAssigned,
        created_by: user_id,
        id: getid?.clickup_id,
      };
      postApiCall(updatedata, access_token, CLICKUP_ACCOUNT_UPDATE, navigate).then(
        (response) => {
          Utils.toast("success", "Clickup account  updated successfully!");
          navigate("/manage-clickup");
        }
      );
      return true;
    }
  };

  return (
    <>
      <div className={props?.data?.transtion === true ? "contents" : "content"}>
        <div className="content-top-spacer"></div>
        <div className="color-body">
          <div className="col-md-6 manage">
            {viewClickup?.isLoading === true ? (
              <LoadingBox />
            ) : (
              <div className="card-input mt-5 mb-2">
                <div className="col-md-12">
                  <div className="mt-3">
                    <h5>Create Tracker</h5>
                  </div>
                  <form>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Email</label>
                          <input
                            type="text"
                            className="form-control"
                            value={email}
                            onChange={(username) => {
                              setEmail(username.target.value);
                            }}
                            // placeholder="Enter Project Name"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Password</label>
                          <input
                            type="text"
                            className="form-control"
                            value={password}
                            onChange={(password) => {
                              setPassword(password.target.value);
                            }}
                            // placeholder="Enter Project Name"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                          <label>Developer Assigned To</label>
                          <MultiSelect
                            options={helperData?.developers ?? []}
                            value={developerAssigned ?? []}
                            onChange={(developers) => {
                              setDeveloperAssigned(developers);
                            }}
                            labelledBy="Select"
                          />
                        </div>
                    </div>
                    <div className="col-md-12">
                      <div className="row mb-5 mt-3">
                        <div className="">
                          {getid && getid?.clickup_id ? (
                            <button
                              type="button"
                              className="create-button"
                              onClick={clickUpdate}
                            >
                              Update
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="create-button"
                              onClick={clickAdd}
                            >
                              Submit
                            </button>
                          )}
                        </div>
                        <div className="ml-2">
                          <button
                            type="button"
                            className="create-button1 btn-info"
                            onClick={()=>navigate("/manage-clickup")}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddClickupAccount;
