
import { MANAGE_CLICKUP_REQUEST, MANAGE_CLICKUP_REQUEST_SUCCESS, MANAGE_CLICKUP_REQUEST_ERROR, FORM_REFRESH, } from "../actionType/type";

export const manageClickupActionRequest = (params) => {
    return {
        type :  MANAGE_CLICKUP_REQUEST,
        params : params 
    }
}

export const manageClickupActionSuccess = (result) => {
    return {
        type: MANAGE_CLICKUP_REQUEST_SUCCESS,
        payload: result,
        error: null,
    };
}

export const manageClickupActionError = (error) => {
    return {
        type: MANAGE_CLICKUP_REQUEST_ERROR,
        error:error,
        payload:null
    }
}

export const actionRefreshForm = () => {
    return {
        type: FORM_REFRESH,
        error:null,
        payload:null,
    }
}
