import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { USER_VIEW, USER_UPDATE } from "../service/apiUrl";
import Utils from "../utility/utility";
import { STRING } from "../constants/string";
import AddUser from "./AddUser";
import "react-tabs/style/react-tabs.css";
import DatePicker from "react-datepicker";
import { postApiCall } from "../service/PostApiCall";
import { faClosedCaptioning, faWindowClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomModal from "../components/CustomModal";
import { saveAs } from "file-saver";
import {useNavigate,useLocation} from "react-router-dom"


const UserEdit = (props) => {
  const { employee_id } = useParams();
  let navigate = useNavigate()
  let location = useLocation();
  const [userData, setUserData] = useState({});
  const [update, setUpdate] = useState(false);
  const [fatherName, setFatherName] = useState("");
  const [motherName, setMotherName] = useState("");
  const [dob, setDob] = useState(null);
  const [bloodGroup, setBloodGroup] = useState("");
  const [pastExperience, setPastExperience] = useState(["", "", "", ""]);
  const [contactEmail, setContactEmail] = useState("");
  const [contactMobileNumber, setContactMobileNumber] = useState("");
  const [homeNumber, setHomeNumber] = useState("");
  const [emergencyNumber, setEmergencyNumber] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [personalAddress, setPersonalAddress] = useState("");
  const [pgAddress, setPgAddress] = useState("");
  const [bankName, setBankName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [salary, setSalary] = useState("");
  const [deducationDuration, setDeducationDuration] = useState("");
  const [deducationPercentage, setDeducationPercentage] = useState("");
  const [incrementCycle, setIncrementCycle] = useState("");
  const [bondPeriod, setBondPeriod] = useState("");
  const [bondEndingDate, setBondEndingDate] = useState("");
  const [noticePeriod, setNoticePeriod] = useState("");
  const [deducationReleasePeriod, setDeducationReleasePeriod] = useState("");
  const [joiningDate, setJoiningDate] = useState("");
  const [training, setTraining] = useState("");
  const [resgnationDate, setResignationDate] = useState("");
  const [relievingDate, setRelievingDate] = useState("");
  const [deductionRelease, setDeductionRelease] = useState("");
  const [pendingSalary, setPendingSalary] = useState("");
  const [salaryReleaseDate, setSalaryReleaseDate] = useState("");
  const [releivingReason, setReleivingReason] = useState("");
  const [additionalComments, setAdditionalComments] = useState("");

  const [aadharNumber, setAadharNumber] = useState("");
  const [panNumber, setPanNumber] = useState("");
  const [aadharFront, setAadharFront] = useState({ image: '', object: {} });
  const [aadharBack, setAadharBack] = useState({ image: '', object: {} });
  const [panFront, setPanFront] = useState({ image: '', object: {} });
  const [panBack, setPanBack] = useState({ image: '', object: {} });

  const [viewImage, setViewImage] = useState({ show: false, src: "" });

  const imageDownloadRef = useRef(null)

  const employeeId = useParams();
  const signdata = useSelector((state) => state.signinReducers);
  const user_id = signdata?.data?.id;
  const access_token = signdata?.data?.access_token;

  const hrDetailObjectData = {
    uid: employee_id,
    tab: "hr",
    father_name: fatherName,
    mother_name: motherName,
    dob: Utils.momentDate(dob,'Y-MM-DD'),
    blood_group: bloodGroup,
    past_experience: JSON.stringify(pastExperience),
    email: contactEmail,
    mobile_number: contactMobileNumber,
    home_number: homeNumber,
    emergency_number: emergencyNumber,
    city: city,
    state: state,
    pincode: pinCode,
    personal_address: personalAddress,
    pg_address: pgAddress,
    showSuccessToast: true
  };

  const officalInfoObjectData = {
    uid: employee_id,
    tab: "official",
    bank_name: bankName,
    bank_acc_number: accountNumber,
    ifsc_code: ifsc,
    salary: salary,
    deduction_duration: deducationDuration,
    deduction_percentage: deducationPercentage,
    increment_cycle: incrementCycle,
    bond_period: bondPeriod,
    bond_ending_date: bondEndingDate,
    notice_period: noticePeriod,
    deduction_release_period: deducationReleasePeriod,
    date_of_joining: joiningDate,
    training: training,
    date_of_resignation: resgnationDate,
    date_of_relieving: relievingDate,
    deduction_release: deductionRelease,
    pending_salary: pendingSalary,
    salary_release_date: salaryReleaseDate,
    relieving_reason: releivingReason,
    additional_comments: additionalComments,
    showSuccessToast: true
  };

  const submitHrDetail = () => {
    let message = "";
    // if (Utils.isEmpty(fatherName)) {
    //   message = STRING.FATHER_NAME;
    // } else if (Utils.isEmpty(motherName)) {
    //   message = STRING.MOTHER_NAME;
    // } else if (Utils.isEmpty(dob)) {
    //   message = STRING.DOB;
    // } else if (Utils.isEmpty(bloodGroup)) {
    //   message = STRING.BLOOD_GROUP;
    // } else if (Utils.isEmpty(contactEmail)) {
    //   message = STRING.ENTER_EMAIL;
    // } else if (!Utils.validateEmail(contactEmail)) {
    //   message = STRING.ENTER_VALID_EMAIL;
    // } else if (Utils.isEmpty(contactMobileNumber)) {
    //   message = STRING.MOBILE;
    // } else if (Utils.isEmpty(emergencyNumber)) {
    //   message = STRING.EMERGENCY_NUMBER;
    // } else if (Utils.isEmpty(city)) {
    //   message = STRING.CITY;
    // } else if (Utils.isEmpty(state)) {
    //   message = STRING.STATE;
    // } else if (Utils.isEmpty(pinCode)) {
    //   message = STRING.PIN_CODE;
    // } else if (Utils.isEmpty(personalAddress)) {
    //   message = STRING.PERSONAL_ADDRESS;
    // }
    // if (message) {
    //   Utils.toast("error", message);
    //   return false;
    // } else {
      if (!Utils.isEmpty(employee_id)) {
        postApiCall(hrDetailObjectData, access_token, USER_UPDATE).then(
          (response) => {
            setUpdate(false);
          }
        );
      }
      return true;
    // }
  };

  const submitOfficalInfo = () => {
    let message = "";
    // if (Utils.isEmpty(bankName)) {
    //   message = STRING.BANK;
    // } else if (Utils.isEmpty(accountNumber)) {
    //   message = STRING.ACCOUNT;
    // } else if (Utils.isEmpty(ifsc)) {
    //   message = STRING.IFSC;
    // } else if (Utils.isEmpty(salary)) {
    //   message = STRING.SALARY;
    // } else if (Utils.isEmpty(deducationPercentage)) {
    //   message = STRING.DEDUCTION_PERCENTAGE;
    // } else if (Utils.isEmpty(incrementCycle)) {
    //   message = STRING.INCREMENT_CYCLE;
    // } else if (Utils.isEmpty(bondPeriod)) {
    //   message = STRING.BOND_PERIOD;
    // } else if (Utils.isEmpty(bondEndingDate)) {
    //   message = STRING.BOND_ENDING_DATE;
    // } else if (Utils.isEmpty(noticePeriod)) {
    //   message = STRING.NOTICE_PERIOD;
    // } else if (Utils.isEmpty(joiningDate)) {
    //   message = STRING.JOINING_DATE;
    // } else if (Utils.isEmpty(training)) {
    //   message = STRING.TRAINING;
    // } else if (Utils.isEmpty(deductionRelease)) {
    //   message = STRING.DEDUCTION_RELEASE;
    // } else if (Utils.isEmpty(pendingSalary)) {
    //   message = STRING.PENDING_SALARY;
    // }

    // if (message) {
    //   Utils.toast("error", message);
    //   return false;
    // } else {
      if (!Utils.isEmpty(employee_id)) {
        postApiCall(officalInfoObjectData, access_token, USER_UPDATE).then(
          (response) => {
            setUpdate(false);
          }
        );
      }
      return true;
    // }
  };

  const submitDocuments = () => {
    let message = "";
    if (Utils.isEmpty(aadharNumber)) {
      message = STRING.ENTER_AADHAR;
    } else if (Utils.isEmpty(panNumber)) {
      message = STRING.ENTER_PAN;
    }

    if (message) {
      Utils.toast("error", message);
      return false;
    } else {
      const documentsObjectData = {
        uid: employee_id,
        tab: "documents",
        aadharNumber: aadharNumber,
        aadharFront: aadharFront.object,
        aadharBack: aadharBack.object,
        panNumber: panNumber,
        panFront: panFront.object,
        panBack: panBack.object,

      };
      if (!Utils.isEmpty(employee_id)) {
        postApiCall(documentsObjectData, access_token, USER_UPDATE, true).then(
          (response) => {
            setUpdate(false);
          }
        );
      }
      return true;
    }
  };

  const updatePastExperience = (index) => (e) => {
    let newArr = [...pastExperience];
    newArr[index] = e.target.value;
    setPastExperience(newArr);
  };

  const imageHide = () => {
    setViewImage({ show: false, src: "" })
  }


  const handleSalaryChange = (e) => {
    const re = /^[0-9\b]+$/;
    if (e === '' || re.test(e)) {
      setPendingSalary(e)
    }
  }

  const imageDownload = async (imagePath, imageName = "image") => {

    const nameSplit = imageName.split("/");
    const duplicateName = nameSplit.pop();
    // saveAs(imagePath, duplicateName); 

    imageDownloadRef.current.href = imagePath;
    imageDownloadRef.current.download = duplicateName;
    imageDownloadRef.current.click()

  }

  const imageRemove = (setStateFunOfImage) => {
    setStateFunOfImage({ image: '', object: "remove" });
  }

  const DocImage = ({src,name,setStateFun}) => {
    return <>
      <div className="round-icon-block icon-normal" style={{ right: "52px", top: "-8px" }} onClick={(e) => { e.preventDefault(); setViewImage({ show: true, src: src }) }}>
        <i className="fa fa-eye"></i>
      </div>
      <div className="round-icon-block icon-normal" style={{ right: "20px", top: "-8px" }} onClick={(e) => { e.preventDefault(); imageDownload(src, name) }}>
        <i className="fa fa-download"></i>
      </div>
      <div className="round-icon-block" style={{ right: "-8px", top: "-8px" }} onClick={(e)=>{ e.preventDefault(); imageRemove(setStateFun)}}>
        <i className="fa fa-close"></i>
      </div>
    </>
  }



  useEffect(() => {
    if (employeeId) {
      const parms = {
        access_token: access_token,
        uid: employeeId,
      };
      postApiCall(parms, access_token, USER_VIEW)
        .then((result) => {
          setUpdate(true);
          setUserData(result);
          setFatherName(result?.hrDetails?.father_name);
          setMotherName(result?.hrDetails?.mother_name);
          // setDob(result?.hrDetails?.dob);
          setDob(Utils.getDate(result?.hrDetails?.dob));
          setBloodGroup(result?.hrDetails?.blood_group);
          setPastExperience(
            result?.hrDetails?.past_experience &&
              result?.hrDetails?.past_experience != ""
              ? JSON.parse(result?.hrDetails?.past_experience)
              : []
          );
          setContactEmail(result?.hrDetails?.email);
          setContactMobileNumber(result?.hrDetails?.mobile_number);
          setHomeNumber(result?.hrDetails?.home_number);
          setEmergencyNumber(result?.hrDetails?.emergency_number);
          setCity(result?.hrDetails?.city);
          setState(result?.hrDetails?.state);
          setPinCode(result?.hrDetails?.pincode);
          setPersonalAddress(result?.hrDetails?.personal_address);
          setPgAddress(result?.hrDetails?.pg_address);

          setBankName(result?.officialInfo?.bank_acc_number);
          setAccountNumber(result?.officialInfo?.bank_acc_number);
          setIfsc(result?.officialInfo?.ifsc_code);
          setSalary(result?.officialInfo?.salary);
          setDeducationDuration(result?.officialInfo?.deduction_duration);
          setDeducationPercentage(result?.officialInfo?.deduction_percentage);
          setIncrementCycle(result?.officialInfo?.increment_cycle);
          setBondPeriod(result?.officialInfo?.bond_period);
          setBondEndingDate(result?.officialInfo?.bond_ending_date);
          setNoticePeriod(result?.officialInfo?.notice_period);
          setDeducationReleasePeriod(
            result?.officialInfo?.deduction_release_period
          );
          setJoiningDate(result?.officialInfo?.date_of_joining && new Date(result?.officialInfo?.date_of_joining) != "Invalid Date" ? new Date(result?.officialInfo?.date_of_joining) : "");
          setTraining(result?.officialInfo?.training);
          setResignationDate(result?.officialInfo?.date_of_resignation && new Date(result?.officialInfo?.date_of_resignation) != "Invalid Date" ? new Date(result?.officialInfo?.date_of_resignation) : "");
          setRelievingDate(result?.officialInfo?.date_of_relieving && new Date(result?.officialInfo?.date_of_relieving) != "Invalid Date" ? new Date(result?.officialInfo?.date_of_relieving) : "");
          setDeductionRelease(result?.officialInfo?.deduction_release);
          setPendingSalary(result?.officialInfo?.pending_salary);
          setSalaryReleaseDate(result?.officialInfo?.salary_release_date);
          setReleivingReason(result?.officialInfo?.relieving_reason);
          setAdditionalComments(result?.officialInfo?.additional_comments);

          setAadharNumber(result?.documents?.aadharNumber ?? "")
          setPanNumber(result?.documents?.panNumber ?? "")
          setAadharFront({ object: {}, image: result?.documents?.aadharFront ?? "" })
          setAadharBack({ object: {}, image: result?.documents?.aadharBack ?? "" })
          setPanFront({ object: {}, image: result?.documents?.panFront ?? "" })
          setPanBack({ object: {}, image: result?.documents?.panBack ?? "" })
        })
        .catch((error) => {
        });
    }
  }, [employeeId, update]);
  return (
    <>
      <a ref={imageDownloadRef} />
      <div className={props?.data?.transtion == true ? "contents" : "content"}>
        <div className="content-top-spacer"></div>
        <div className="color-body edit-user manage">
          <div className="col-md-12">
            <div className="mt-3">
              <div className="col-md-12 mt-3">
                <Tabs className="noafter">
                  <TabList>
                    <Tab>User Information</Tab>
                    <Tab>Hr Information</Tab>
                    <Tab>Official Information</Tab>
                    <Tab>Documentations</Tab>
                  </TabList>
                  <TabPanel>
                    <AddUser
                      employeeData={userData}
                      topbar_space={false}
                      transtion={true}
                      update={update}
                    />
                  </TabPanel>
                  <TabPanel>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="card-input mt-4 mb-2">
                          <div className="col-md-12">
                            <div className="mt-3">
                              <h5 className="border-bottom pb-2">
                                Personal Details
                              </h5>
                            </div>
                            <form>
                              <div className="row mt-3">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                      Father Name
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={fatherName}
                                      onChange={(e) => {
                                        setFatherName(e.target.value);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                      Mother Name
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={motherName}
                                      onChange={(e) => {
                                        setMotherName(e.target.value);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                      Date of Birth
                                    </label>
                                    {/* <input
                                      type="text"
                                      className="form-control"
                                      value={dob}
                                      placeholder="DD/MM/YYYY"
                                      onChange={(e) => {
                                        setDob(e.target.value);
                                      }}
                                    /> */}
                                    <DatePicker
                                      selected={dob}
                                      onChange={(date) => setDob(date)}
                                      className="width-dateset w-100"
                                      // onKeyDown={(e) => {
                                      //   e.preventDefault();
                                      // }}
                                      />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                      Blood Group
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={bloodGroup}
                                      onChange={(e) => {
                                        setBloodGroup(e.target.value);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="card-input mt-4 mb-2">
                          <div className="col-md-12">
                            <div className="mt-3">
                              <h5 className="border-bottom pb-2">
                                Past Experience
                              </h5>
                            </div>
                            <form>
                              <div className="row mt-3">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                      Company One
                                    </label>
                                    <textarea
                                      className="form-control rounded-0"
                                      id="exampleFormControlTextarea1"
                                      rows="3"
                                      value={pastExperience[0]}
                                      onChange={updatePastExperience(0)}
                                    ></textarea>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                      Company Two
                                    </label>
                                    <textarea
                                      className="form-control rounded-0"
                                      id="exampleFormControlTextarea1"
                                      rows="3"
                                      value={pastExperience[1]}
                                      onChange={updatePastExperience(1)}
                                    ></textarea>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                      Company Three
                                    </label>
                                    <textarea
                                      className="form-control rounded-0"
                                      id="exampleFormControlTextarea1"
                                      rows="3"
                                      value={pastExperience[2]}
                                      onChange={updatePastExperience(2)}
                                    ></textarea>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                      Company Four
                                    </label>
                                    <textarea
                                      className="form-control rounded-0"
                                      id="exampleFormControlTextarea1"
                                      rows="3"
                                      value={pastExperience[3]}
                                      onChange={updatePastExperience(3)}
                                    ></textarea>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="card-input mt-4 mb-2">
                          <div className="col-md-12">
                            <div className="mt-3">
                              <h5 className="border-bottom pb-2">
                                Contact Details
                              </h5>
                            </div>
                            <form>
                              <div className="row mt-3">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                      Email
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={contactEmail}
                                      onChange={(e) => {
                                        setContactEmail(e.target.value);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                      Mobile Number
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={contactMobileNumber}
                                      onChange={(e) => {
                                        setContactMobileNumber(e.target.value);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                      Home Number
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={homeNumber}
                                      onChange={(e) => {
                                        setHomeNumber(e.target.value);
                                      }}
                                    // placeholder="Enter Project Owner"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                      Emergency Number
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={emergencyNumber}
                                      onChange={(e) => {
                                        setEmergencyNumber(e.target.value);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                      City
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={city}
                                      onChange={(e) => {
                                        setCity(e.target.value);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                      State
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={state}
                                      onChange={(e) => {
                                        setState(e.target.value);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                      Pin Code
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={pinCode}
                                      onChange={(e) => {
                                        setPinCode(e.target.value);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                      Personal Address
                                    </label>
                                    <textarea
                                      className="form-control rounded-0"
                                      id="exampleFormControlTextarea1"
                                      rows="3"
                                      value={personalAddress}
                                      onChange={(e) => {
                                        setPersonalAddress(e.target.value);
                                      }}
                                    ></textarea>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                      Pg Address
                                    </label>
                                    <textarea
                                      className="form-control rounded-0"
                                      id="exampleFormControlTextarea1"
                                      rows="3"
                                      value={pgAddress}
                                      onChange={(e) => {
                                        setPgAddress(e.target.value);
                                      }}
                                    ></textarea>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="col-md-12">
                          <div className="row mb-5 mt-3">
                            <div className="">
                              <button
                                type="button"
                                className="create-button"
                                onClick={submitHrDetail}
                              >
                                Create
                              </button>
                            </div>
                            <div className="ml-2">
                              <button
                                type="button"
                                className="create-button1 btn-info"
                                onClick={()=> navigate('/manage-user')}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="card-input mt-4 mb-2">
                          <div className="col-md-12">
                            <div className="mt-3">
                              <h5 className="border-bottom pb-2">
                                Salary Information
                              </h5>
                            </div>
                            <form>
                              <div className="row mt-3">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                      Bank Name
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={bankName}
                                      onChange={(e) => {
                                        setBankName(e.target.value);
                                      }}
                                    // placeholder="Enter Project Owner"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                      Bank Account Number
                                    </label>
                                    <input
                                      type="text"
                                      pattern="[0-9]*"
                                      className="form-control"
                                      value={accountNumber}
                                      onChange={(e) =>
                                        setAccountNumber((v) => (e.target.validity.valid ? e.target.value : v))
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                      IFSC Code
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={ifsc}
                                      onChange={(e) => {
                                        setIfsc(e.target.value);
                                      }}
                                    // placeholder="Enter Project Owner"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                      Salary (Rs)
                                    </label>
                                    <input
                                      type="text"
                                      pattern="[0-9]*"
                                      className="form-control"
                                      value={salary}
                                      onChange={(e) => {
                                        setSalary((v) => (e.target.validity.valid ? e.target.value : v))
                                      }}

                                    // placeholder="Enter Project Owner"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                      Deducation Duration
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={deducationDuration}
                                      onChange={(e) => {
                                        setDeducationDuration(e.target.value);
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                      Deducation(%)
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={deducationPercentage}
                                      onChange={(e) => {
                                        setDeducationPercentage(e.target.value);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="card-input mt-4 mb-2">
                          <div className="col-md-12">
                            <div className="mt-3">
                              <h5 className="border-bottom pb-2">
                                Bond Information
                              </h5>
                            </div>
                            <form>
                              <div className="row mt-3">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                      Increment Cycle
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={incrementCycle}
                                      onChange={(e) => {
                                        setIncrementCycle(e.target.value);
                                      }}
                                    // placeholder="Enter Project Owner"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                      Bond Period
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={bondPeriod}
                                      onChange={(e) => {
                                        setBondPeriod(e.target.value);
                                      }}
                                    // placeholder="Enter Project Owner"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                      Bond Ending Date
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={bondEndingDate}
                                      onChange={(e) => {
                                        setBondEndingDate(e.target.value);
                                      }}
                                    // placeholder="Enter Project Owner"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                      Notice Period
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={noticePeriod}
                                      onChange={(e) => {
                                        setNoticePeriod(e.target.value);
                                      }}
                                    // placeholder="Enter Project Owner"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12 mb-5">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                      Deducation Release Period
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={deducationReleasePeriod}
                                      onChange={(e) => {
                                        setDeducationReleasePeriod(
                                          e.target.value
                                        );
                                      }}
                                    // placeholder="Enter Project Owner"
                                    />
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="col-md-12 ml-3 mt-5">
                          <div className="row  mt-1">
                            <div className="">
                              <button
                                type="button"
                                className="create-button"
                                onClick={submitOfficalInfo}
                              >
                                Create
                              </button>
                            </div>
                            <div className="ml-2">
                              <button
                                type="button"
                                className="create-button1 btn-info"
                                onClick={()=> navigate('/manage-user')}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="card-input mt-4 mb-2">
                          <div className="col-md-12">
                            <div className="mt-3">
                              <h5 className="border-bottom pb-2">
                                Official Information
                              </h5>
                            </div>
                            <form>
                              <div className="row mt-3">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                      Date of Joining
                                    </label>
                                    <DatePicker
                                      selected={joiningDate}
                                      onChange={(date) => setJoiningDate(date)}
                                      className="width-dateset w-100"
                                      onKeyDown={(e) => {
                                        e.preventDefault();
                                      }}

                                    />
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <label htmlFor="exampleInputEmail1">
                                    Traning
                                  </label>
                                  <div className="row">
                                    <div className="form-check ml-3">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="flexRadioDefault"
                                        id="flexRadioDefaultw"
                                        value={1}
                                        checked={training == 1}
                                        onChange={(e) =>
                                          setTraining(e.target.value)
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="flexRadioDefaultw"
                                      >
                                        yes
                                      </label>
                                    </div>
                                    <div className="form-check ml-3 mb-4">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="flexRadioDefault"
                                        id="flexRadioDefaultw"
                                        value={0}
                                        checked={training == 0}
                                        onChange={(e) =>
                                          setTraining(e.target.value)
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="flexRadioDefaultw"
                                      >
                                        No
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="card-input mt-4 mb-2">
                          <div className="col-md-12">
                            <div className="mt-3">
                              <h5 className="border-bottom pb-2">
                                Relieving Information
                              </h5>
                            </div>
                            <form>
                              <div className="row mt-3">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                      Date of Resignation
                                    </label>
                                    <DatePicker

                                      selected={resgnationDate}
                                      onChange={(date) =>
                                        setResignationDate(date)
                                      }
                                      onKeyDown={(e) => {
                                        e.preventDefault();
                                      }
                                      }
                                      className="width-dateset w-100"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                      Date of Relieving
                                    </label>
                                    <DatePicker
                                      className="width-dateset w-100"
                                      selected={relievingDate}
                                      onChange={(date) =>
                                        setRelievingDate(date)
                                      }
                                      onKeyDown={(e) => {
                                        e.preventDefault();
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <label htmlFor="exampleInputEmail1">
                                    Deducation Release
                                  </label>
                                  <div className="row">
                                    <div className="form-check ml-3">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="flexRadioDefault"
                                        id="flexRadioDefaultw"
                                        value={1}
                                        checked={deductionRelease == 1}
                                        onChange={(e) =>
                                          setDeductionRelease(e.target.value)
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="flexRadioDefaultw"
                                      >
                                        yes
                                      </label>
                                    </div>
                                    <div className="form-check ml-3 mb-4">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="flexRadioDefault"
                                        id="flexRadioDefaultw"
                                        value={0}
                                        checked={deductionRelease == 0}
                                        onChange={(e) =>
                                          setDeductionRelease(e.target.value)
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="flexRadioDefaultw"
                                      >
                                        No
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                      Pending Salary
                                    </label>
                                    <input
                                      type="text"
                                      pattern="[0-9]*"
                                      className="form-control"
                                      value={pendingSalary}
                                      onChange={(e) =>
                                        setPendingSalary((v) => (e.target.validity.valid ? e.target.value : v))
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                      Date of Release of salary
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={salaryReleaseDate}
                                      onChange={(e) => {
                                        setSalaryReleaseDate(e.target.value);
                                      }}
                                    // placeholder="Enter Project Owner"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                      Reason of Relieving
                                    </label>
                                    <textarea
                                      className="form-control rounded-0"
                                      id="exampleFormControlTextarea1"
                                      rows="3"
                                      value={releivingReason}
                                      onChange={(e) => {
                                        setReleivingReason(e.target.value);
                                      }}
                                    ></textarea>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="card-input mt-4 mb-2">
                          <div className="col-md-12">
                            <div className="mt-3">
                              <h5 className="border-bottom pb-2">
                                Additional Information
                              </h5>
                            </div>
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                Additional Comments
                              </label>
                              <textarea
                                className="form-control rounded-0"
                                id="exampleFormControlTextarea1"
                                rows="3"
                                value={additionalComments}
                                onChange={(e) => {
                                  setAdditionalComments(e.target.value);
                                }}
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="card-input mt-4 mb-2">
                          <div className="col-md-12">
                            <div className="mt-3">
                              <h5 className="border-bottom pb-2">
                                Aadhar
                              </h5>
                            </div>
                            <form>
                              <div className="row mt-3">
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                      Number
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={aadharNumber}
                                      onChange={(e) => {
                                        setAadharNumber(e.target.value);
                                      }}
                                    // placeholder="Enter Project Owner"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                      Photo Front
                                    </label>
                                    {
                                      aadharFront?.image ?
                                        <div className="w-100">
                                          <label className="doc-block curser-point" htmlFor="aadharFront">
                                            <img src={aadharFront?.image} className="doc-image" />
                                            <DocImage src={aadharFront?.image} name="aadharFront" setStateFun={setAadharFront}/>
                                          </label>
                                        </div>
                                        : ""
                                    }
                                    <input
                                      type="file"
                                      id="aadharFront"
                                      className={`form-control ${aadharFront?.image ? "hide" : ""}`}
                                      accept="image/png, image/gif, image/jpeg"
                                      onChange={(e) =>
                                        setAadharFront({ object: e.target.files[0], image: URL.createObjectURL(e.target.files[0]) })
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>
                                      Photo Back
                                    </label>
                                    {
                                      aadharBack?.image ?
                                        <div className="w-100">
                                          <label className="doc-block curser-point" htmlFor="aadharBack">
                                            <img src={aadharBack?.image} className="doc-image" />
                                            <DocImage src={aadharBack?.image} name="aadharBack" setStateFun={setAadharBack}/>
                                          </label>
                                        </div>
                                        : ""
                                    }
                                    <input
                                      type="file"
                                      id="aadharBack"
                                      className={`form-control ${aadharBack?.image ? "hide" : ""}`}
                                      accept="image/png, image/gif, image/jpeg"
                                      onChange={(e) => {
                                        setAadharBack({ object: e.target.files[0], image: URL.createObjectURL(e.target.files[0]) })
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>

                        <div className="col-md-12 ml-3 mt-5">
                          <div className="row  mt-1">
                            <div className="">
                              <button
                                type="button"
                                className="create-button"
                                onClick={submitDocuments}
                              >
                                Update
                              </button>
                            </div>
                            <div className="ml-2">
                              <button
                                type="button"
                                className="create-button1 btn-info"
                                onClick={()=> navigate('/manage-user')}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="card-input mt-4 mb-2">
                          <div className="col-md-12">
                            <div className="mt-3">
                              <h5 className="border-bottom pb-2">
                                Pan Card
                              </h5>
                            </div>
                            <form>
                              <div className="row mt-3">
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                      Number
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={panNumber}
                                      onChange={(e) => {
                                        setPanNumber(e.target.value);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                      Photo Front
                                    </label>
                                    {
                                      panFront?.image ?
                                        <div className="w-100">
                                          <label className="doc-block curser-point" htmlFor="panFront">
                                            <img src={panFront?.image} className="doc-image" />
                                            <DocImage src={panFront?.image} name="panFront" setStateFun={setPanFront}/>
                                          </label>
                                        </div>
                                        : ""
                                    }
                                    <input
                                      type="file"
                                      id="panFront"
                                      className={`form-control ${panFront?.image ? "hide" : ""}`}
                                      accept="image/png, image/gif, image/jpeg"
                                      onChange={(e) =>
                                        setPanFront({ object: e.target.files[0], image: URL.createObjectURL(e.target.files[0]) })
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                      Photo Back
                                    </label>
                                    {
                                      panBack?.image ?
                                        <div className="w-100">
                                          <label className="doc-block curser-point" htmlFor="panBack">
                                            <img src={panBack?.image} className="doc-image" />
                                            <DocImage src={panBack?.image} name="panBack" setStateFun={setPanBack}/>
                                          </label>
                                        </div>
                                        : ""
                                    }
                                    <input
                                      type="file"
                                      id="panBack"
                                      className={`form-control ${panBack?.image ? "hide" : ""}`}
                                      accept="image/png, image/gif, image/jpeg"
                                      onChange={(e) => {
                                        setPanBack({ object: e.target.files[0], image: URL.createObjectURL(e.target.files[0]) })
                                      }}
                                    // placeholder="Enter Project Owner"
                                    />
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModal onHide={imageHide} isShow={viewImage?.show}>
        {viewImage?.src ? <img src={viewImage?.src} className="w-100 d-block mx-auto my-2" style={{maxWidth:"600px"}} /> : ''}
      </CustomModal>
    </>
  );
};

export default UserEdit;
