
import { LEAVE_REQUEST, LEAVE_REQUEST_SUCCESS, LEAVE_REQUEST_ERROR,FORM_REFRESH } from "../actionType/type";

export const manageLeaveActionRequest = (params,access_token) => {
    return {
        type :  LEAVE_REQUEST,
        params : params,
        access_token : access_token 
    }
}

export const manageLeaveActionSuccess = (result) => {

    return {
        type: LEAVE_REQUEST_SUCCESS,
        payload: result,
        error: null,
    };
}
export const manageLeaveActionError = (error) => {
    return {
        type: LEAVE_REQUEST_ERROR,
        error:error,
        payload:null
    }
}
export const actionRefreshForm = (msg) => {
    return {
        type: FORM_REFRESH,
        error:null,
        payload:null,
        message:msg
    }
}
