import {
  MANAGE_TRACKER_REQUEST,
  MANAGE_TRACKER_REQUEST_SUCCESS,
  MANAGE_TRACKER_REQUEST_ERROR,
  FORM_REFRESH,
} from '../actionType/type';

export const manageTrackerActionRequest = access_token => {
  return {
    type: MANAGE_TRACKER_REQUEST,
    params: access_token,
  };
};

export const manageTrackerActionSuccess = result => {
  return {
    type: MANAGE_TRACKER_REQUEST_SUCCESS,
    payload: result,
    error: null,
  };
};

export const manageTrackerActionError = error => {
  return {
    type: MANAGE_TRACKER_REQUEST_ERROR,
    error: error,
    payload: null,
  };
};
export const actionRefreshForm = msg => {
  return {
    type: FORM_REFRESH,
    error: null,
    payload: null,
  };
};
