export const STRING = {
  DASHBOARD: '/home',
  TIMELINE: '/timeline',
  ANALYSIS: '/analysis',
  MANAGE: '/manage-users',
  TODO: '/to-do',
  PAY: '/pay-slip',
  PROJECT: '/manage-project',
  TASK: '/task-list',
  TRACKER: '/manage-tracker',
  CLICKUP: '/manage-clickup',
  ANOUCEMENT: '/anouncements',
  GALLERY: '/gallery',
  LEAVE: '/leave',
  TIMELOG: '/timelog',
  INVENTORY: '/inventory',
  SUPPORT: '/manage-support',
  ENTER_EMAIL: 'Please enter your email.',
  ENTER_VALID_EMAIL: 'Please enter valid email.',
  ENTER_PASSWORD: 'Please enter your password.',
  ENTER_CONFIRM_PASSWORD: 'Please enter your confirm password.',
  ENTER_SIX_DIGIT_PASSWORD: 'Password must contain at least 6 characters',
  ENTER_EIGHT_DIGIT_PASSWORD: 'Password must contain at least 8 characters including Capital letter, small letter and a number.',
  INVALID_CREDENTIALS: 'Invalid credentials',
  USER_LOGIN_SUCCESS: 'Login Successful!',
  CONFIRM_PASSWORD: "Password Doesn't Match.",
  ADMIN_ROLE: 'Please select  role.',
  SHIFT: 'Please select  shift.',
  DEPARTMENT: 'Please select department.',
  ADMIN: 'Please select admin.',
  TECHNOLOGY: 'Please select technology.',
  STATUS: 'Password select status.',
  FIRSTNAME: 'Please enter your firstname.',
  PLATFORM: 'Please select platform.',
  NAME: 'Please enter your name.',
  USERNAME: 'Please enter your username.',
  PROFILE_LINK: 'Please enter your profile Link.',
  TITLE: 'Please enter your title.',
  DATE: 'Please select date.',
  DESCRIPTION: 'Please enter Description.',
  SLASH_DATE_FORMAT: 'MM/DD/YY',
  TYPE: 'Please select type.',
  TIMEGREATHER: 'Please select correct time.',
  YEAR: 'Please select year.',
  CONTACT: 'Please enter contact during leave.',
  REASON: 'Please enter your reason.',
  TOTALLEAVE: 'Please enter your Total Leaves .',
  TOTALELIGIBLE: 'Please enter your Eligible  .',
  FREELEAVES: 'Please enter your Free Leaves  .',
  PAIDLEAVE: 'Please enter your Paid Leaves  .',
  TOTALDAY: 'Please enter your Total Working Days.',
  TOTALWORK: 'Please enter your No. of Calculated Days.',
  EXTRAWORKING: 'Please enter your Extra Days Work.',
  PROJECTNAME: 'Please enter your projectname.',
  PROJECT_DESCRIPTION: 'Please enter your Project Description.',
  ESTIMATE_TIME: 'Please enter your Estimated Time.',
  PER_HOURS: 'Please enter your Per Hours.',
  BILLING_COMPANY_NAME: 'Please enter your Billing Company Name.',
  PROJECT_DATE_FORMAT: 'MMM DD, YYYY',
  PROJECT_TIME_FORMAT: 'hh:mm A',
  PAYSLIP_DATE_FORMAT: 'MMM',

  COPIED: 'Copy to clipboard!',
  COPIED_ERROR: 'Not able to copy!',
  CLOCK_FORMATED_DATE: 'YYYY-MM-DD',

  FATHER_NAME: 'Please enter Father Name',
  MOTHER_NAME: 'Please enter Mother Name',
  DOB: 'Please enter date of birth',
  BLOOD_GROUP: 'Please enter blood group',
  MOBILE: 'Please enter mobile number ',
  EMERGENCY_NUMBER: 'Please enter emergency number',
  CITY: 'Please enter city',
  STATE: 'Please enter state',
  PIN_CODE: 'Please enter pincode',
  PERSONAL_ADDRESS: 'Please enter personal address',

  BANK: 'Please enter Bank name',
  ACCOUNT: 'Please enter account number',
  IFSC: 'Please enter ifsc code',
  SALARY: 'Please enter salary',
  DEDUCTION_PERCENTAGE: 'Please enter salary deduction percentage',
  INCREMENT_CYCLE: 'Please enter increment cycle',
  BOND_PERIOD: 'Please enter bond period',
  BOND_ENDING_DATE: 'Please enter bond ending date',
  NOTICE_PERIOD: 'Please enter notice period',
  JOINING_DATE: 'Please enter joining date',
  TRAINING: 'Please select training',
  DEDUCTION_RELEASE: 'Please enter deduction release',
  PENDING_SALARY: 'Please enter pending salary',
  ENTER_PROJECT: 'Please select project',
  ENTER_BILLING_STATUS: 'Please select status',
  ENTER_BILLING_ID: 'Please select Billing id',
  ENTER_WORKING_HOURS: 'Please enter Working Hours',
  ENTER_TASK: 'Please enter Task',
  ENTER_TASK_DESCRIPTION: 'Please enter Task Description',
  ERROR_WORKING_HOURS: 'Working hours should be less than online hours.',

  DASHBOARD_DATE: 'DD-MMM-YYYY (dddd)',
  ENTER_AADHAR: 'Please enter Aadhar number',
  ENTER_PAN: 'Please enter Pan number',
  SERIALNO: 'Please enter Serial number',
  BRAND: 'Please enter Brand number',
  TYPE: 'Please enter Type number',
  ASSIGN: 'Please enter AssignNo number',
  INVENTORY_SUCCESSFUL: 'Inventory Create Successful!',
  INVENTORY_SUCCESSFUL_UPDATE: 'Inventory Update Successful!',
  INVENTORY_SUCCESSFUL_Delete: 'Inventory Delete Successful!',
};
