import React from "react";

export default function LoadingBox() {
  return (
    <div className="loading">
      <div className="spinner-border"></div>
    </div>
  );
}
export const LoadingBoxLarge = () => {
  return (
    <div className="loading large">
      <div className="spinner-border"></div>
    </div>
  );
};
