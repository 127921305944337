import { MANAGE_USER_REQUEST, MANAGE_USER_REQUEST_ERROR, MANAGE_USER_REQUEST_SUCCESS } from "../actionType/type";

const initialState = {
    data: null,
    isLoading: false,
};

export const manageUserReducers = (state=initialState,action) => {
    let object
    switch (action.type) {
        case MANAGE_USER_REQUEST:
            object = {
                ...state,
                isLoading: true,
              }
            break
        case MANAGE_USER_REQUEST_ERROR:
            object = {
                ...state,
                isLoading:false
            }
            break
        case MANAGE_USER_REQUEST_SUCCESS:
            object = {
                ...state,
                data:action.payload,
                isLoading:false
            }
            break
    
        default:
            object = state
            break
    }
    return object
}