import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import Select from "react-select";
import { default_pic } from "../../assets";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { dashboardActionRequest } from "../../redux/action/dashboardAction";
import Utils from "../../utility/utility";
import { STRING } from "../../constants/string";
import { useParams, useNavigate } from "react-router-dom";

const Admin = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [department, setDepartment] = useState({ value: "", label: "All" });
  const [update, setUpdate] = useState(false);

  const signdata = useSelector((state) => state.signinReducers);
  const access_token = signdata?.data?.access_token;
  const dashboardData = useSelector((state) => state.dashboardReducers);

  const options1 = {
    responsive: true,
    legend: {
      display: false,
    },
    type: "bar",
  };
  // const barData = {
  //   labels: [
  //     "January",
  //     "February",
  //     "March",
  //     "April",
  //     "May",
  //     "June",
  //     "July",
  //     "August",
  //     "September",
  //     "October",
  //     "November",
  //     "December",
  //   ],
  //   datasets: [
  //     {
  //       label: "My First dataset",
  //       backgroundColor: "rgba(255,99,132,0.2)",
  //       borderColor: "rgba(255,99,132,1)",
  //       borderWidth: 1,
  //       hoverBackgroundColor: "rgba(255,99,132,0.4)",
  //       hoverBorderColor: "rgba(255,99,132,1)",
  //       data: [65, 59, 80, 81, 56, 55, 40, 12, 10, 10, 10, 50],
  //     },
  //     {
  //       label: "My second dataset",
  //       backgroundColor: "rgba(155,231,91,0.2)",
  //       borderColor: "rgba(255,99,132,1)",
  //       borderWidth: 1,
  //       hoverBackgroundColor: "rgba(255,99,132,0.4)",
  //       hoverBorderColor: "rgba(255,99,132,1)",
  //       data: [45, 79, 50, 41, 16, 85, 20],
  //     },
  //   ],
  // };

  useEffect(() => {
    const parms = {
      department: department?.value,
      access_token: access_token,
    };
    dispatch(dashboardActionRequest(parms));
  }, [update, dashboardData.refreshPage]);

  // Chart.register(...registerables);
  return (
    <>
      <div className={props?.data?.transtion == true ? "contents" : "content"}>
        {/* <div className="content-top-spacer"></div> */}
        <div className="spacer"></div>
        <div className="color-body dashboard">

        <div className="row">
            <div className="col-lg-3 col-6">
              <div className="small-box bg-info">
                <div className="inner">
                  <div className="float-left">
                    <h3>{dashboardData?.data?.total_employees}</h3>
                    <p>Total Employee</p>
                  </div>
                  <div className="icon float-right">
                    <i className="fa fa-users"></i>
                  </div>
                </div>
                
              </div>
            </div>

            <div className="col-lg-3 col-6">
              <div className="small-box bg-success">
                <div className="inner">
                  <div className="float-left">
                    <h3>{dashboardData?.data?.today_working}</h3>
                    <p>Today Working</p>
                  </div>
                  <div className="icon float-right">
                    <i className="fa fa-laptop"></i>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-6">
              <div className="small-box bg-warning">
                <div className="inner">
                  <div className="float-left">
                  <h3>0</h3>
                  <p></p>
                  </div>
                  <div className="icon float-right">
                    {/* <i className="fa fa-users"></i> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-6">
              <div className="small-box bg-danger">
                <div className="inner">
                  <div className="float-left">
                  <h3><a target="_blank" href="https://docs.google.com/document/d/1LJw2CE6_t_WXf5kd76NeaAxH9dQiu91MvYo_uolHpYk/edit?usp=sharing" target="_blank" rel="noopener noreferrer">
        Open RuleBook
      </a></h3>
                  <p>RuleBook & Calendar</p>
                  </div>
                  <div className="icon float-right">
                    {/* <i className="fa fa-users"></i> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
         

          <div className="row mt-5">
            {dashboardData?.data?.announcements?.map((data, idx) => {
              return (<div className="col-md-12" key={idx}>
                <div className="alert alert-warning shadow" role="alert">
                  <h4 className="alert-heading">
                    <i className="fa fa-bullhorn" aria-hidden="true"></i>{" "}{data.title}</h4>
                  <div dangerouslySetInnerHTML={{ __html: data.description }} />
                </div>
              </div>);
            })}
            <div className="col-md-6">
              <div className="">
                <h3>Current Department is {department?.label}</h3>
              </div>
            </div>
            <div className="col-md-6">
              <div className="col-md-12">
                <div className="form-group float-right">
                  <div className="row">
                    <label className="mr-2 mt-2">Department</label>
                    <Select
                      options={
                        dashboardData?.data?.departments
                          ? [
                              { value: "", label: "All" },
                              ...dashboardData?.data?.departments,
                            ]
                          : []
                      }
                      placeholder=""
                      className="depart-width"
                      value={department}
                      onChange={(event) => {
                        setDepartment({
                          value: event?.value,
                          label: event?.label,
                        });
                        setUpdate(!update);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="graph-leave card-input">
            <Bar
              data={barData}
              width={null}
              height={null}
              options={options1}
              className=" p-3"
            />
          </div> */}
          <div className="row mt-4">
            <div className="col-md-6 manage">
              <div className="card-input  mb-2 block">
                <div className="col-md-12">
                  <div className="mt-3 col-md-12">
                    <div className="row">
                      <p>
                        <span className="mr-2 site-color font-weight">
                          <b>{dashboardData?.data?.leaves?.length ?? 0}</b>
                        </span>
                        <span>Employee on leave- {Utils.getCurrentDate()}</span>
                      </p>
                    </div>
                  </div>
                  <table className="table table-sm">
                    <thead>
                      <tr>
                        <th scope="col" className="site-color">
                          <span className="mt-2 mb-2 d-flex">Name</span>
                        </th>

                        <th scope="col" className="site-color">
                          <span className="mt-2 mb-2 d-flex">Type</span>
                        </th>
                        <th scope="col" className="site-color">
                          <span className="mt-2 mb-2 d-flex">Duration</span>
                        </th>
                        <th scope="col" className="site-color">
                          <span className="mt-2 mb-2 d-flex">Status</span>
                        </th>
                        <th scope="col" className="site-color">
                          <span className="mt-2 mb-2 d-flex">Action</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dashboardData?.data?.leaves?.length ? (
                        dashboardData?.data?.leaves?.map((data, index) => {
                          return (
                            <tr className="" key={index}>
                              <td>
                                <span>{data?.uid?.name}</span>
                              </td>
                              <td>
                                <span>{data?.type}</span>
                              </td>
                              <td>
                                <span>{data?.duration}</span>
                              </td>
                              <td>
                                <span>{data?.status}</span>
                              </td>
                              <td>
                                <button
                                  onClick={() =>
                                    navigate("/edit-leave/" + data?.id)
                                  }
                                  className="button-popup"
                                >
                                  <i
                                    className="fa fa-eye red"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={6}>
                            <div className="text-center">
                              <div className="no-approved mt-100">
                                <p className="mb-0">No Leaves Found</p>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="card-input mt-3 mb-2 block">
                <div className="col-md-12">
                  <div className="mt-3 col-md-12">
                    <div className="row">
                      <p>
                        <span className="mr-2 site-color font-weight">
                          <b>
                            {dashboardData?.data?.less_activity?.length ?? 0}
                          </b>
                        </span>
                        <span>
                          Employee less than 9 Hours-{" "}
                          {Utils.momentDate(
                            Utils.momentYesterdayDate(),
                            "DD-MMM-YYYY (dddd)"
                          )}{" "}
                        </span>
                      </p>
                    </div>
                  </div>

                  <table className="table table-sm">
                    <thead>
                      <tr>
                        <th scope="col" className="site-color">
                          <span className="mt-2 mb-2 d-flex">Name</span>
                        </th>
                        <th scope="col" className="site-color">
                          <span className="mt-2 mb-2 d-flex">Check-In</span>
                        </th>
                        <th scope="col" className="site-color">
                          <span className="mt-2 mb-2 d-flex">Check-Out</span>
                        </th>
                        <th scope="col" className="site-color">
                          <span className="mt-2 mb-2 d-flex">
                            Productive
                            <br /> Hours's
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dashboardData?.data?.less_activity?.length ? (
                        dashboardData?.data?.less_activity?.map(
                          (data, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <div className="col-md-12">
                                    <div className="row mt-3">
                                      {/* <div className="image-height">
                                      <img
                                        src={data?.profile_pic ?? default_pic}
                                        className="default-width"
                                        alt=""
                                      />
                                    </div> */}
                                      <div className="ml-2">
                                        <h6 className="mb-0 mt-2">
                                          {data?.first_name ?? ""}{" "}
                                          {data?.last_name ?? ""}
                                        </h6>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <span className="bg-green white fit-content px-2 py-1 rounded mt-3 d-flex">
                                    {data?.checkIn
                                      ? Utils.timestampDate(
                                          data?.checkIn,
                                          "h:mm A"
                                        )
                                      : ""}
                                  </span>
                                </td>
                                <td className="">
                                  <span className="bg-warning white fit-content px-2 py-1 rounded mt-3 d-flex">
                                    {data?.checkOut
                                      ? Utils.timestampDate(
                                          data?.checkOut,
                                          "h:mm A"
                                        )
                                      : ""}
                                  </span>
                                </td>
                                <td className="">
                                  <span className="bg-red white fit-content px-2 py-1 rounded mt-3 d-flex">
                                    {data?.product_hours
                                      ? Utils.getHoursFromSeconds(
                                          data?.product_hours
                                        )
                                      : ""}
                                  </span>
                                </td>
                              </tr>
                            );
                          }
                        )
                      ) : (
                        <tr>
                          <td colSpan={5}>
                            <div className="text-center">
                              <div className="no-approved mt-100">
                                <p className="mb-0">No Record Found</p>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card-input block">
                <div className="col-md-12 mb-3">
                  <div className="row col-md-12 mt-3">
                    <p>
                      <span className="mr-2 site-color font-weight">
                        <b>
                          {dashboardData?.data?.missingEnteries?.length ??
                            "NAN"}
                        </b>
                      </span>
                      <span>
                        EMS Entry Missing -{" "}
                        {Utils.momentDate(new Date(), STRING.DASHBOARD_DATE)}
                      </span>
                    </p>
                  </div>
                  <table className="table table-sm">
                    <thead>
                      <tr>
                        <th scope="col" className="site-color">
                          <span className="mt-2 mb-2 d-flex">Name</span>
                        </th>

                        <th scope="col" className="site-color">
                          <span className="mt-2 mb-2 d-flex">Position</span>
                        </th>
                        <th scope="col" className="site-color">
                          <span className="mt-2 mb-2 d-flex">Department</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dashboardData?.data?.missingEnteries?.length ? (
                        dashboardData?.data?.missingEnteries?.map(
                          (value, index) => {
                            return (
                              <tr key={index} className="td-height">
                                <td>
                                  <div className="col-md-12">
                                    <div className="row mt-3">
                                      <div className="image-height">
                                        <img
                                          src={value?.profile_pic}
                                          className="default-width"
                                          alt=""
                                        />
                                      </div>
                                      <div className="ml-2">
                                        <h6 className="mb-0 mt-2">
                                          {value?.first_name +
                                            " " +
                                            value?.last_name}
                                        </h6>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <span className="mt-4 d-flex">
                                    {value?.role?.label}
                                  </span>
                                </td>
                                <td>
                                  <span className="mt-4 d-flex">
                                    {value?.department?.label}
                                  </span>
                                </td>
                              </tr>
                            );
                          }
                        )
                      ) : (
                        <tr>
                          <td colSpan={3}>
                            <div className="text-center">
                              <div className="no-approved mt-100">
                                <p className="mb-0">No Record Found</p>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="card-input mt-3 block">
                <div className="col-md-12 mb-3">
                  <div className="row col-md-12 mt-3">
                    <p>
                      <span className="mr-2 site-color font-weight">
                        <b>{dashboardData?.data?.offlineUsers?.length ?? 0}</b>
                      </span>
                      <span>
                        Offline users-{" "}
                        {Utils.momentDate(new Date(), "DD-MMM-YYYY (dddd)")}{" "}
                      </span>
                    </p>
                  </div>
                  <table className="table table-sm">
                    <thead>
                      <tr>
                        <th scope="col" className="site-color">
                          <span className="mt-2 mb-2 d-flex">Name</span>
                        </th>

                        <th scope="col" className="site-color">
                          <span className="mt-2 mb-2 d-flex">
                            Offline Hours
                          </span>
                        </th>
                        <th scope="col" className="site-color">
                          <span className="mt-2 mb-2 d-flex">Project</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dashboardData?.data?.offlineUsers?.length ? (
                        dashboardData?.data?.offlineUsers.map((data, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <div className="col-md-12">
                                  <div className="row mt-3">
                                    <div className="image-height">
                                      <img
                                        src={
                                          data?.uid?.profile_pic ?? default_pic
                                        }
                                        className="default-width"
                                        alt=""
                                      />
                                    </div>
                                    <div className="ml-2">
                                      <h6 className="mb-0 mt-2">
                                        {data?.uid?.first_name ?? ""}{" "}
                                        {data?.uid?.last_name ?? ""}
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <span className="mt-4 d-flex">
                                  {data?.working_hrs ?? ""}
                                </span>
                              </td>
                              <td>
                                <span className="mt-4 d-flex">
                                  {data?.project ?? ""}
                                </span>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={3}>
                            <div className="text-center">
                              <div className="no-approved mt-100">
                                <p className="mb-0">No Record Found</p>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Admin;
