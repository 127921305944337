import axios from 'axios';

const accessToken = localStorage.getItem ('dropboxAccessToken');
const axiosInstance = axios.create ({
  baseURL: 'https://api.dropboxapi.com/2/',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
  },
});

axiosInstance.interceptors.request.use (
  config => {
    const token = localStorage.getItem ('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  err => {
    console.error ('Error in request interceptor:', err);
    return Promise.reject (err);
  }
);

export default axiosInstance;
