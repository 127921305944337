import { all } from "redux-saga/effects";
import { watchSigninSaga } from "./signinSaga";
import { watchManageUserSaga } from "./manageUserSaga";
import { watchManageTrackerSaga } from "./manageTrackerSaga";
import { watchViewTrackerSaga } from "./viewTrackerSaga";
import { watchdashboardSaga } from "./dashboardSaga";
import { watchAnnouncementSaga } from "./announcementSaga";
import { watchLeaveSaga } from "./leaveSaga";
import { watchManagePayslipSaga } from "./managePayslipSaga";
import { watchTimelogSaga } from "./timelogSaga";
import { watchManageProjectSaga } from "./manageProjectSaga";
import { watchSaturdayListSaga } from "./saturdayListSaga";
import { watchManageTodoSaga } from "./manageTodoSaga";
import { watchInventorySaga } from "./inventorySaga";
import { watchManageClickupSaga } from "./manageClickupSaga";
import { watchViewClickupSaga } from "./viewClickupSaga";
// import { watchSignoutSaga } from "./signoutSaga";

function* RootSaga() {
    yield all([
        watchSigninSaga(),
        watchManageUserSaga(),
        watchManageTrackerSaga(),
        watchViewTrackerSaga(),
        watchdashboardSaga(),
        watchAnnouncementSaga(),
        watchLeaveSaga(),
        watchManageProjectSaga(),
        watchManagePayslipSaga(),
        watchTimelogSaga(),
        watchSaturdayListSaga(),
        watchManageTodoSaga(),
        watchInventorySaga(),
        watchManageClickupSaga(),
        watchViewClickupSaga(),
    ])
}
export default RootSaga;