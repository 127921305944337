export const SIGNIN_REQUEST = 'SIGNIN_REQUEST';
export const SIGNIN_REQUEST_SUCCESS = 'SIGNIN_REQUEST_SUCCESS';
export const SIGNIN_REQUEST_ERROR = 'SIGNIN_REQUEST_ERROR';
export const SIGNOUT_REQUEST = 'SIGNOUT_REQUEST';

export const MANAGE_USER_REQUEST = 'MANAGE_USER_REQUEST';
export const MANAGE_USER_REQUEST_SUCCESS = 'MANAGE_USER_REQUEST_SUCCESS';
export const MANAGE_USER_REQUEST_ERROR = 'MANAGE_USER_REQUEST_ERROR';

export const MANAGE_TODO_REQUEST = 'MANAGE_TODO_REQUEST';
export const MANAGE_TODO_REQUEST_SUCCESS = 'MANAGE_TODO_REQUEST_SUCCESS';
export const MANAGE_TODO_REQUEST_ERROR = 'MANAGE_TODO_REQUEST_ERROR';

export const MANAGE_TRACKER_REQUEST = 'MANAGE_TRACKER_REQUEST';
export const MANAGE_TRACKER_REQUEST_SUCCESS = 'MANAGE_TRACKER_REQUEST_SUCCESS';
export const MANAGE_TRACKER_REQUEST_ERROR = 'MANAGE_TRACKER_REQUEST_ERROR';

export const MANAGE_CLICKUP_REQUEST = 'MANAGE_CLICKUP_REQUEST';
export const MANAGE_CLICKUP_REQUEST_SUCCESS = 'MANAGE_CLICKUP_REQUEST_SUCCESS';
export const MANAGE_CLICKUP_REQUEST_ERROR = 'MANAGE_CLICKUP_REQUEST_ERROR';

export const VIEW_TRACKER_REQUEST = ' VIEW_TRACKER_REQUEST';
export const VIEW_TRACKER_REQUEST_SUCCESS = ' VIEW_TRACKER_REQUEST_SUCCESS';
export const VIEW_TRACKER_REQUEST_ERROR = ' VIEW_TRACKER_REQUEST_ERROR';

export const VIEW_CLICKUP_REQUEST = 'VIEW_CLICKUP_REQUEST';
export const VIEW_CLICKUP_REQUEST_SUCCESS = 'VIEW_CLICKUP_REQUEST_SUCCESS';
export const VIEW_CLICKUP_REQUEST_ERROR = 'VIEW_CLICKUP_REQUEST_ERROR';

export const FORM_REFRESH = 'FORM_REFRESH';

export const DASHBOARD_REQUEST = 'DASHBOARD_REQUEST';
export const DASHBOARD_REQUEST_SUCCESS = 'DASHBOARD_REQUEST_SUCCESS';
export const DASHBOARD_REQUEST_ERROR = ' DASHBOARD_REQUEST_ERROR';

export const ANNOUNCEMENT_REQUEST = 'ANNOUNCEMENT_REQUEST';
export const ANNOUNCEMENT_REQUEST_SUCCESS =
  'ANNOUNCEMENT_ANNOUNCEMENT_REQUEST_SUCCESS';
export const ANNOUNCEMENT_REQUEST_ERROR = 'ANNOUNCEMENT_REQUEST_ERROR';

export const LEAVE_REQUEST = 'LEAVE_REQUEST';
export const LEAVE_REQUEST_SUCCESS = 'LEAVE_ANNOUNCEMENT_REQUEST_SUCCESS';
export const LEAVE_REQUEST_ERROR = 'LEAVE_REQUEST_ERROR';

export const PROJECT_REQUEST = 'PROJECT_REQUEST';
export const PROJECT_REQUEST_SUCCESS = 'PROJECT_REQUEST_SUCCESS';
export const PROJECT_REQUEST_ERROR = 'PROJECT_REQUEST_ERROR';
export const PROJECT_DELETE_REQUEST = 'PROJECT_DELETE_REQUEST';
export const PROJECT_DELETE_REQUEST_SUCCESS = 'PROJECT_DELETE_REQUEST_SUCCESS';
export const PROJECT_DELETE_REQUEST_ERROR = 'PROJECT_DELETE_REQUEST_ERROR';

export const MANAGE_PAYSLIPS_REQUEST = 'MANAGE_PAYSLIPS_REQUEST';
export const MANAGE_PAYSLIPS_REQUEST_SUCCESS =
  'MANAGE_PAYSLIPS_REQUEST_SUCCESS';
export const MANAGE_PAYSLIPS_REQUEST_ERROR = 'MANAGE_PAYSLIPS_REQUEST_ERROR';

export const TIMELOG_REQUEST = 'TIMELOG_REQUEST';
export const TIMELOG_REQUEST_SUCCESS = 'TIMELOG_REQUEST_SUCCESS';
export const TIMELOG_REQUEST_ERROR = 'TIMELOG_REQUEST_ERROR';

export const SATURDAY_LIST_REQUEST = 'SATURDAY_LIST_REQUEST';
export const SATURDAY_LIST_REQUEST_SUCCESS = 'SATURDAY_LIST_REQUEST_SUCCESS';
export const SATURDAY_LIST_REQUEST_ERROR = 'SATURDAY_LIST_REQUEST_ERROR';

export const INVENTORY_LIST_REQUEST = 'INVENTORY_LIST_REQUEST';
export const INVENTORY_LIST_REQUEST_SUCCESS = 'INVENTORY_LIST_REQUEST_SUCCESS';
export const INVENTORY_LIST_REQUEST_ERROR = 'INVENTORY_LIST_REQUEST_ERROR';

export const MANAGE_SUPPORT_REQUEST = 'MANAGE_SUPPORT_REQUEST';
export const MANAGE_SUPPORT_SUCCESS = 'MANAGE_SUPPORT_SUCCESS';
export const MANAGE_SUPPORT_ERROR = 'MANAGE_SUPPORT_ERROR';

export const NOTIFICATION_REQUEST = 'NOTIFICATION_REQUEST';
export const NOTIFICATION_REQUEST_SUCCESS = 'NOTIFICATION_REQUEST_SUCCESS';
export const NOTIFICATION_REQUEST_ERROR = 'NOTIFICATION_REQUEST_ERROR';
