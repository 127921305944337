export const API_STATUS = {
  SUCCESS: 200,
  CREATED: 201,
  ACCEPED: 202,
  ALREADY_REPORTED: 208,
  UNAUTHORIZED: 401,
  INVAILDCREDENTIALS: 1001,
  NOT_FOUND: 404,
  FORBIDDEN: 422,
  ACCESS_DENIED: 403,
};

/**
 * Api Response erros
 */
export const API_STANDRAD_ERRORS = {
  NETWORK_ERROR: 'Internet Error',
};

export const DASHBOARD = '/api/dashboard';
export const DEPARTMENTSUSERS = '/api/department-wise-users';

export const LOGIN_URL = '/api/login';
export const USER_CREATE = '/api/usercreate';
export const MANAGE_USERS = '/api/manage-users';
export const USERGET_DATA = '/api/add-user-get-data';
export const USER_VIEW = '/api/user-view';
export const USER_PROGRESS = '/api/user-progress';
export const USER_UPDATE = '/api/user-update';

export const MANAGE_TODO = '/api/manage-todo';
export const CREATE_TODO = '/api/to-do-create';
export const VIEW_TODO = '/api/to-do-view';
export const UPDATE_TODO = '/api/to-do-update';
export const DELETE_TODO = '/api/to-do-delete';
export const TODO_HELPER = '/api/to-do-helper';

export const CREATE_TRACKERS = '/api/trackercreate';
export const MANAGE_TRACKERS = '/api/manage-trackers';
export const VIEW_TRACKER = '/api/view-tracker';
export const TRACKER_UPDATE = '/api/tracker-update';
export const DELETE_TRACKER = '/api/delete-tracker';
export const TRACKER_HELPER = '/api/tracker-helper';

export const CREATE_CLICKUP_ACCOUNT = '/api/clickup-create';
export const MANAGE_CLICKUP_ACCOUNT = '/api/manage-clickup';
export const VIEW_CLICKUP_ACCOUNT = '/api/view-clickup';
export const CLICKUP_ACCOUNT_UPDATE = '/api/clickup-update';
export const DELETE_CLICKUP_ACCOUNT = '/api/delete-clickup';

export const MANAGE_ANNOUNCEMENTS = '/api/manage-announcements';
export const ANNOUNCEMENT_CREATE = '/api/announcementcreate';
export const VIEW_ANNOUNCEMENT = '/api/view-announcement';
export const ANNOUNCEMENTS_UPDATES = '/api/announcement-update';
export const DELETE_ANNOUNCEMENTS = '/api/delete-announcement';
export const ANOUNCEMENT_HELPER = '/api/announcement-helper';

export const LEAVECREATE = '/api/leave-create';
export const RECENTAPPROVED = '/api/recent-approved-leaves';
export const LEAVEMANAGEHELPER = '/api/manage-leave-helper';
export const LEAVEMANAGE = '/api/leave-manage';
export const LEAVES_HELPER = '/api/leave-helper';
export const LEAVE_VIEW = '/api/leave-view';
export const LEAVE_UPDATE = '/api/leave-update';
export const LEAVE_DELETE = '/api/leave-delete';

export const PROJECT_CREATE = '/api/projectcreate';
export const MANAGE_PROJECT = '/api/manage-projects';
export const VIEW_PROJECT = '/api/view-project';
export const DELETE_PROJECT = '/api/delete-project';
export const PROJECT_HELPER = '/api/project-helper';
export const PROJECT_UPDATE = '/api/project-update';
export const PROJECT_ADDTASK_HELPER = '/api/projec-addtaskt-helper';

export const PAYSLIP_CREATE = '/api/payslip-create';
export const PAY_HELPER = '/api/payslip-helper';
export const MANAGE_PAYSLIPS = '/api/payslip-manage';
export const VIEW_PAYSLIPS = '/api/payslip-view';
export const DELETE_PAYSLIPS = '/api/payslip-delete';
export const EDIT_PAYSLIPS = '/api/payslip-update';
export const PAYSLIPS_CALCS = '/api/payslip-calculation';

export const DAILY_TASK_LIST = '/api/task-listing';
export const DAILY_TASK_UPDATE = '/api/daily-task-update';
export const MANAGE_TASK_HELPER = '/api/manage-task-helper';
export const DELETE_TASK = '/api/delete-task';

export const MANAGE_TIMELOG_HELPER = '/api/manage-timelog-helper';
export const TIMELOG_LISTING = '/api/timelog-listing';
export const SATURDAY_LIST = '/api/saturday-working-list';

// user apis

export const DAILY_CLOCK_IN_OUT = '/user-api/daily-clock-in-out';
export const DAILY_CLOCK_IN_EDIT = '/user-api/daily-clock-in-edit';
export const DAILY_CLOCK_VIEW = '/user-api/daily-clock-view';
export const DAILY_CLOCK_OUT = '/user-api/daily-clock-out';

export const USER_TIMELOG_LISTING = '/user-api/timelog-listing';

export const USER_LEAVES_HELPER = '/user-api/leave-helper';
export const USER_LEAVECREATE = '/user-api/leave-create';
export const USER_MYLEAVE = '/user-api/my-leave';
export const USER_LEAVE_CANCEL = '/user-api/leave-cancel';
export const USER_RECENTAPPROVED = '/user-api/recent-approved-leaves';
export const INVENTORY_CREATED = '/api/inventory-create';
export const INVENTORY_MANAGE = '/api/manage-inventory';
export const VIEW_INVENTORY = '/api/view-inventory';
export const INVENTORY_UPDATE = '/api/inventory-update';
export const INVENTORY_DELETE = '/api/delete-inventory';

export const SATURDAY_WORKING_LIST = '/api/saturday-working-list-from-tracker';

// ADmin Listing And Support
export const ADMIN_LISTING = '/api/to-do-helper';
export const SUPPORT_CREATE = '/api/supports-create';
export const MANAGE_SUPPORT = '/api/manage-supports';
export const SUPPORT_UPDATE = '/api/supports-update';
export const SUPPORT_VIEW = '/api/view-supports';
export const DELETE_SUPPORT = '/api/delete-supports';

//User Support Listing
export const USER_HELPER = '/user-api/helper';
export const USER_SUPPORT_LISTING = '/user-api/manage-supports';
export const USER_SUPPORT_CREATE = '/user-api/supports-create';
export const USER_SUPPORT_UPDATE = '/user-api/supports-update';
export const USER_SUPPORT_VIEW = '/user-api/view-supports';
export const USER_DELETE_SUPPORT = '/api/user-delete-supports';

// notification
export const NOTIFICATION = '/api/notifications';
export const NOTIFICATION_READ = '/api/notification-read';
