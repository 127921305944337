import { SATURDAY_LIST_REQUEST, SATURDAY_LIST_REQUEST_ERROR, SATURDAY_LIST_REQUEST_SUCCESS } from "../actionType/type";

export const saturdayListRequest = (params,access_token,role=1) => {
    return {
        type :  SATURDAY_LIST_REQUEST,
        params : params,
        access_token : access_token,
        role : role
    }
}

export const saturdayListSuccess = (result) => {
    return {
        type: SATURDAY_LIST_REQUEST_SUCCESS,
        payload: result,
        error: null,
    };
}

export const saturdayListError = (error) => {
    return {
        type: SATURDAY_LIST_REQUEST_ERROR,
        error:error,
        payload:null
    }
}

