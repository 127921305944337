import { put, call, takeLatest } from "redux-saga/effects";
import { SATURDAY_LIST } from "../../service/apiUrl";
import { postApiCall } from "../../service/PostApiCall";
import { saturdayListError, saturdayListSuccess } from "../action/saturdayListAction";
import { SATURDAY_LIST_REQUEST } from "../actionType/type";

function* saturdayListSaga(action){
    try {
        const response = yield call(postApiCall,action.params,action.access_token,SATURDAY_LIST)
        yield put(saturdayListSuccess(response));
    } catch (error) {
        yield put(saturdayListError(error));
    }
}

export function* watchSaturdayListSaga(){
    yield takeLatest(SATURDAY_LIST_REQUEST,saturdayListSaga)
}
