import { put, call, takeLatest } from "redux-saga/effects";
import { VIEW_CLICKUP_REQUEST } from "../actionType/type";
import { VIEW_CLICKUP_ACCOUNT } from "../../service/apiUrl";
import { postApiCall } from "../../service/PostApiCall";
import { viewClickupActionError, viewClickupActionSuccess } from "../action/viewClickupAction";

function* viewClickupSaga(action){
    try {
        const response = yield call(postApiCall,action?.params,action?.params?.access_token,VIEW_CLICKUP_ACCOUNT)
        yield put(viewClickupActionSuccess( response));
        // action.onSuccess(response)
    } catch (error) {
        yield put(viewClickupActionError(error));
    }
}

export function* watchViewClickupSaga(){
    yield takeLatest(VIEW_CLICKUP_REQUEST,viewClickupSaga)
}