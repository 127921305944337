import React, { useEffect, useState } from 'react'
import Utils from '../utility/utility';

const TaskTracker = ({startTime,breakTime}) => {

    const [initiateTime, setInitiateTime] = useState(0);
    

    useEffect(()=>{
        let seconds = 0;
        let time = 0;
        startTime?.map((val)=>{
            if(val?.end_time){
                seconds += val.end_time - val.start_time;
            }else{
                time = val.start_time
            }
        })
        let totalBreak = 0

        let breakArray = breakTime?.break?.split(":");
        if(breakArray?.length == 2){
            totalBreak = parseInt(breakArray[0])*3600 + parseInt(breakArray[1]*60);
        }

        setInitiateTime(parseInt(time) - seconds + totalBreak);
    },[startTime,breakTime])

    const [timer, setTimer] = useState({
        hours:'00',
        minutes:'00',
        seconds:'00',
    });

    useEffect(() => {
        if (initiateTime)
          setInterval(() => {
            setTimer(Utils.momentTimeDiff(initiateTime))
          }, 1000);
      })
    

    return (
        <p className="text-big">
            {`${timer?.hours ?? '00'}:${timer?.minutes ?? '00'}:${timer?.seconds ?? '00'}`}
        </p>
    )
}

export default TaskTracker