import { put, call, takeLatest } from "redux-saga/effects";
import { MANAGE_TODO } from "../../service/apiUrl";
import { manageTodoActionSuccess , manageTodoActionError} from "../action/manageTodoAction";
import { MANAGE_TODO_REQUEST } from "../actionType/type";
import { postApiCall } from "../../service/PostApiCall";

function* manageTodoSaga(action){
    try {
        const response = yield call(postApiCall,action?.params,action?.params?.access_token,MANAGE_TODO)
        yield put(manageTodoActionSuccess(response));
    } catch (error) {
        yield put(manageTodoActionError(error));
    }
}

export function* watchManageTodoSaga(){
    yield takeLatest(MANAGE_TODO_REQUEST,manageTodoSaga)
}