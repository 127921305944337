import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { default_pic, default_pic_user } from "../assets";
import { saturdayListRequest } from "../redux/action/saturdayListAction";
import { postApiCall } from "../service/PostApiCall";
import { SATURDAY_WORKING_LIST } from "../service/apiUrl";
import { DateRangePicker } from "rsuite";
import isAfter from 'date-fns/isAfter';
import moment from "moment";
import DatePicker from "react-datepicker";


const SaturdayWorking = (props) => {

  const [saturdayData, setSaturdayData] = useState([]);
  const [fromDate, setFromDate] = useState(new Date());
  const [month, setMonth] = useState(new Date());
  /**
   * 1 => yes
   * 2 => no
   */
  const [isAttended, setIsAttended] = useState(1);
  const [update, setUpdate] = useState(false);
  // const [value, setValue] = useState([]);
  // const [calendarMonth, setCalendarMonth] = useState(new Date());

  // const handleMonthChange = (month) => {
  //   setCalendarMonth(month);
  // };

  // const handleDateChange = (nextValue) => {
  //   setValue(nextValue);
  // };

  const dispatch = useDispatch();

  const signdata = useSelector((state) => state.signinReducers);

  const { saturdayListReducer } = useSelector((state) => state);

  useEffect(()=>{
    console.log('saturdayListReducer',saturdayListReducer);
  },[saturdayListReducer])

  useEffect(() => {
    const params = {
      month: moment(month).format("MM-yyyy"),
      attended : isAttended
    }
    console.log('isAttended',isAttended);
    postApiCall(params, signdata?.data?.access_token, SATURDAY_WORKING_LIST)
    .then((result) => {
      console.log('result',result);
      setSaturdayData(result?.result ?? []);
    })
    .catch((error) => {
      console.error("saturday", error);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month,isAttended]);

  // useEffect(() => {
  //   const parms = {};
  //   dispatch(saturdayListRequest(parms,signdata?.data?.access_token));
  // }, []);
  const handleImageError = (event) => {
    // Handle image error here, for example, replace broken image with a placeholder
    event.target.src = default_pic;
  };
  return (
    <>
      <div className={props?.data?.transtion == true ? "contents" : "content"}>
        <div className="content-top-spacer"></div>
        <div className="color-body">
          <div className="col-md-12">
            <div className="card-input mt-3">
              <div className="col-md-12 mb-3">
                <div className="mt-3">
                  <div className="">
                    <div className="row m-3">
                      <DatePicker
                        className="width-date1 mt-2 mb-2 mr-2 ml-auto"
                        selected={month}
                        onChange={(date) => {
                          setMonth(date);
                        }}
                        dateFormat="MMMM/yyyy"
                        showMonthYearPicker
                        showFullMonthYearPicker
                      />
                      <button
                        style={{
                          height: "fit-content",
                          border: "2px solid white"
                        }}
                        className={`reset-button mr-2 mt-2 mb-2 ${isAttended === 1 ? "border-red" : "" }`}
                        onClick={()=>setIsAttended(1)}
                      >
                        Attended
                      </button>
                      <button
                        style={{
                          height: "fit-content",
                          border: "2px solid white"
                        }}
                         className={`reset-button mt-2 mb-2 ${isAttended === 2 ? "border-red" : "" }`}
                        onClick={()=>{
                          console.log("clicked"); setIsAttended(2)}}
                      >
                        Not Attended
                      </button>
                    </div>
                    <div className="m-3">
                      <table className="table table-sm">
                        <thead>
                          <tr>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">S.N</span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">Name</span>
                            </th>

                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">
                                Designation
                              </span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">
                                Saturday Working Date
                              </span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">Status</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {saturdayData?.map((data, idx) => {
                            return (
                              <tr key={data.id}>
                                <td>
                                  <span className="mt-4 d-flex mr-4">{idx+1}</span>
                                </td>
                                <td>
                                  <div className="col-md-12">
                                    <div className="row mt-3">
                                      <div className="image-height">
                                        <img
                                          src={data?.profile_pic ?? default_pic}
                                          className="default-width"
                                          alt=""
                                          onError={handleImageError}
                                        />
                                      </div>
                                      <div className="ml-2">
                                        <h6 className="mb-0">{data?.name}</h6>
                                        <p>{data?.email}</p>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <span className="mt-4 d-flex">
                                    {data?.departmentName}
                                  </span>
                                </td>
                                <td className="bg-timelog">
                                  <span className="mt-4 d-flex ">
                                    {data?.formated_date}
                                  </span>
                                </td>
                                <td>
                                  <span className="mt-4 d-flex">
                                    {data?.status}
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SaturdayWorking;
