import Select from "react-select";
import "rsuite/dist/rsuite.css";
import { DateRangePicker } from "rsuite";
import isAfter from 'date-fns/isAfter';
import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { } from "../assets";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { timelogActionRequest } from "../redux/action/timelogAction";
import Utils from "../utility/utility";
import LoadingBox from "../components/LoadingBox";
import { postApiCall } from "../service/PostApiCall";
import { MANAGE_TIMELOG_HELPER } from "../service/apiUrl";
import { STRING } from "../constants/string";
import Modal from "react-modal";
import ReactPaginate from "react-paginate";






const Timelog = (props) => {

  const [user, setUser] = useState({ value: "", label: "" });
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [helperData, setHelperData] = useState([]);
  const [update, setUpdate] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(30);

  const dispatch = useDispatch();

  const timelogList = useSelector(state => state.timelogReducer);
  const signdata = useSelector((state) => state.signinReducers);
  const access_token = signdata?.data?.access_token;

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "50%",
    },
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {



    postApiCall([], access_token, MANAGE_TIMELOG_HELPER)
      .then((result) => {
        setHelperData(result);
      })
      .catch((error) => {
        console.error("MANAGE_TIMELOG_HELPER", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  useEffect(() => {


    const params = {
      start_time: fromDate ? Utils.fromDateTimeStamp(fromDate) : "",
      end_time: toDate ? Utils.toDateTimeStamp(toDate) : "",
      orderby: "",
      order: "",
      user_id: user?.value,
      pageSize: pageSize,
      page: currentPage,

    }

    if (access_token) {
      dispatch(timelogActionRequest(params, access_token))
    } else {
      console.error('Timelog List error');
    }

  }, [access_token, dispatch, update]);

  const handlePageChange = (pageNumber) => {
    const page = pageNumber?.selected + 1;
    setCurrentPage(page);
    setUpdate(!update);
  };




  return (
    <>
      <div className={props?.data?.transtion == true ? "contents" : "content"}>
        <div className="content-top-spacer"></div>
        <div className="color-body">
          <div className="col-md-12">
            <div className="card-input mt-3">
              <div className="col-md-12 mb-3">
                <div className="mt-3">
                  <div className="row">
                    <h5 className="ml-4">Timelog</h5>
                    <div className="flex-row">
                      <div className="row mr-4 mt-1 mt-2">
                        <div className="dot bg-red mt-1 mr-1"></div>
                        <p>Not Completed</p>
                      </div>
                      <div className="row mr-4 mt-1 mt-2">
                        <div className="dot bg-green mt-1 mr-1"></div>
                        <p>Completed</p>
                      </div>
                      <div className="row mr-4 mt-1 mt-2">
                        <div className="dot bg-orange mt-1 mr-1"></div>
                        <p>Not Clocked Out</p>
                      </div>
                      <div className="row mr-4 mt-1 mt-2">
                        <div className="dot bg-warning mt-1 mr-1"></div>
                        <p>Saturday</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <div className="col-md-12"></div>
                  <div className="">
                    <div className="mt-3">
                      <table className="table table-sm table-v-align-m">
                        <thead>
                          <tr>
                            <th scope="col" className="site-color" style={{ width: "5%" }}>
                              <span className="mt-2 mb-2 d-flex">#</span>
                            </th>
                            <th scope="col" className="site-color" style={{ width: "20%" }}>
                              <span className="mt-2 mb-2 d-flex">Name</span>
                            </th>
                            <th scope="col" className="site-color" style={{ width: "25%" }}>
                              <span className="mt-2 mb-2 d-flex">Date</span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">Checkin</span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">Checkout</span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">Break</span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">
                                Total Hour
                              </span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">
                                Productive Hour
                              </span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">Comments</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="">
                            <td>
                            </td>
                            <td>
                              {" "}
                              <div className="w-75 min-h-38  mt-2 mb-2">
                                <Select
                                  options={helperData?.users}
                                  className={"mt-2 mb-2"}
                                  value={user ?? {}}
                                  isClearable={true}
                                  isSearchable={true}
                                  styles={{
                                    menu: (base) => ({
                                      ...base,
                                      width: "max-content",
                                      minWidth: "100%"
                                    })
                                  }}
                                  onChange={(event) => {
                                    setUser({
                                      value: event?.value,
                                      label: event?.label,
                                    });
                                    setUpdate(!update);
                                  }}
                                />

                              </div>
                            </td>
                            <td>
                              {" "}
                              <DateRangePicker
                                appearance="subtle"
                                showWeekNumbers
                                placeholder="Select Dates"
                                // style={{ width: "100%" }}
                                size="sm"
                                className="mt-2 mb-2 date-range-border"
                                disabledDate={date => isAfter(date, new Date())}
                                onChange={(date) => {
                                  setFromDate(date ? date[0] : new Date())
                                  setToDate(date ? date[1] : new Date())
                                  setUpdate(!update)

                                }
                                }
                              />

                            </td>
                          </tr>
                          {timelogList?.isLoading ?
                            <tr style={{ height: "100px" }}><td colSpan={`100%`}><LoadingBox /> </td></tr>

                            :
                            timelogList?.data?.timelog?.length ? (
                              timelogList?.data?.timelog?.map((timelog, idx) => {
                                return <tr key={idx} className={
                                  timelog?.isSaturday ? "bg-warning white h-40" :
                                  (timelog?.is_clockout == '1' ? 'bg-orange white h-40' :
                                    (Utils.momentHours(timelog?.productive_time) < 9 ? "bg-red white h-40" : "bg-green white h-40"))
                                }>
                                  <td>
                                    <span
                                        className="mt-4 d-flex mr-4"
                                      >
                                        {(currentPage - 1) * pageSize +
                                          (idx + 1)}
                                      </span>
                                  </td>
                                  <td>
                                    <span className="d-flex">{timelog?.first_name + ' ' + timelog?.last_name}</span>
                                  </td>
                                  <td>
                                    <span className="d-flex">{timelog?.formated_date ?? 'NA'}</span>
                                  </td>
                                  <td>
                                    <span className="d-flex">{timelog?.start_time ? Utils.momentTimeFromTimestamp(STRING.PROJECT_TIME_FORMAT, timelog?.start_time) : 'NA'}</span>
                                  </td>
                                  <td>
                                    <span className="d-flex">{timelog?.end_time ? Utils.momentTimeFromTimestamp(STRING.PROJECT_TIME_FORMAT, timelog?.end_time) : 'NA'}</span>
                                  </td>
                                  <td>
                                    <span className="d-flex">{timelog?.break ? timelog?.break : 'NA'}</span>


                                  </td>
                                  <td>
                                    <span className="d-flex">{timelog?.total_time ?? 'NA'}</span>

                                  </td>
                                  <td>
                                    <span className="d-flex">{timelog?.productive_time ?? 'NA'}</span>

                                  </td>
                                  <td>
                                    {timelog?.no_break_reason != "" ?
                                      <div className="text-center">
                                        <button
                                          onClick={() =>
                                            setModalData(timelog?.no_break_reason ?? "NA")
                                          }
                                          className="button-popup"
                                        >
                                          <i
                                            className="fa fa-eye white"
                                            onClick={openModal}
                                            aria-hidden="true"
                                          ></i>
                                        </button>
                                      </div>
                                      :
                                      ""
                                    }
                                  </td>
                                </tr>
                              })
                            ) : (
                              <tr >
                                <td colSpan="9">No record found</td>
                              </tr>
                            )}

                        </tbody>
                      </table>

                      {timelogList?.data?.timelog?.length > 0 &&
                      
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={timelogList?.data?.total_pages}
                        onPageChange={handlePageChange}
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                      />
                      }


                      <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                        ariaHideApp={false}
                        style={customStyles}
                        contentLabel="Example Modal"
                      >
                        <div className="col-md-12">
                          <div className="text-right">
                            <i
                              className="fa fa-times curser-point"
                              aria-hidden="true"
                              onClick={closeModal}
                            ></i>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <h5 className="mb-0">No Break Reason</h5>
                              <div className="border-bottom mt-2 mb-2"></div>
                            </div>
                            <div className="col-md-12">
                              <p> {modalData ?? "NA"} </p>
                            </div>
                          </div>
                        </div>
                      </Modal>


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Timelog;

