/* eslint-disable react-hooks/exhaustive-deps */
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import Utils from "../utility/utility";
import { STRING } from "../constants/string";
import { useNavigate, useParams } from "react-router-dom";
import { viewTrackerActionRequest } from "../redux/action/viewTrackerAction";
import LoadingBox from "../components/LoadingBox";
import { postApiCall } from "../service/PostApiCall";
import { CREATE_TRACKERS, TRACKER_UPDATE, TRACKER_HELPER } from "../service/apiUrl";

const AddTracker = (props) => {
  const [platform, setPlatform] = useState("");
  const [name, setName] = useState("");
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [profileLink, setProfileLink] = useState("");
  const [platformValue, setplatformValue] = useState("");
  const [nameformValue, setnameformValue] = useState("");
  const [helperData, setHelperData] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getid = useParams();
  const signdata = useSelector((state) => state.signinReducers);
  const viewTracker = useSelector((state) => state.viewTrackerReducers);

  const access_token = signdata?.data?.access_token;
  const user_id = signdata?.data?.id;

  useEffect(() => {
    postApiCall([], access_token, TRACKER_HELPER)
      .then((result) => {
        setHelperData(result);
      })
      .catch((error) => {
        console.error("TRACKER_HELPER", error);
      });
  }, []);

  useEffect(() => {
    const parms = {
      access_token: access_token,
      id: getid?.tracker_id ?? "",
    };

    if (getid && getid.tracker_id) {
      dispatch(viewTrackerActionRequest(parms));
    }
  }, [getid]);

  useEffect(() => {
    setplatformValue(viewTracker?.data?.platform?.id ?? '');
    setnameformValue(viewTracker?.data?.platform?.name ?? '');
    setName(viewTracker?.data?.name ?? '');
    setUserName(viewTracker?.data?.username ?? '');
    setPassword(viewTracker?.data?.password ?? '');
    setProfileLink(viewTracker?.data?.profile_link ?? '');
  }, [viewTracker]);
  
  const validation = () => {
    let message = "";
    if (Utils.isEmpty(nameformValue)) {
      message = STRING.PLATFORM;
    } else if (Utils.isEmpty(name)) {
      message = STRING.NAME;
    } else if (Utils.isEmpty(username)) {
      message = STRING.USERNAME;
    } 
    // else if (!Utils.validateEmail(username)) {
    //   message = STRING.ENTER_VALID_EMAIL;
    // } 
    else if (Utils.isEmpty(password)) {
      message = STRING.ENTER_PASSWORD;
    } else if (password.length < 6) {
      message = STRING.ENTER_SIX_DIGIT_PASSWORD;
    } else if (Utils.isEmpty(profileLink)) {
      message = STRING.PROFILE_LINK;
    }
    return message;
  };
  const trackeradd = () => {
    var message = validation();
    if (message) {
      Utils.toast("error", message);
      return false;
    } else {
      const objectdata = {
        name: name,
        platform: platformValue,
        username: username,
        password: password,
        profile_link: profileLink,
        created_by: user_id,
      };

      postApiCall(objectdata, access_token, CREATE_TRACKERS).then(
        (response) => {
          Utils.toast("success", "Tracker created successfully!");
          navigate("/manage-tracker");
        }
      );
      return true;
    }
  };

  const trackerupdate = () => {
    var message = validation();
    if (message) {
      Utils.toast("error", message);
      return false;
    } else {
      const updatedata = {
        name: name,
        platform: platformValue,
        username: username,
        password: password,
        profile_link: profileLink,
        created_by: user_id,
        id: getid?.tracker_id,
      };
      postApiCall(updatedata, access_token, TRACKER_UPDATE, navigate).then(
        (response) => {
          Utils.toast("success", "Tracker updated successfully!");
          navigate("/manage-tracker");
        }
      );
      return true;
    }
  };

  return (
    <>
      <div className={props?.data?.transtion == true ? "contents" : "content"}>
        <div className="content-top-spacer"></div>
        <div className="color-body">
          <div className="col-md-6 manage">
            {viewTracker?.isLoading == true ? (
              <LoadingBox />
            ) : (
              <div className="card-input mt-5 mb-2">
                <div className="col-md-12">
                  <div className="mt-3">
                    <h5>Create Tracker</h5>
                  </div>
                  <form>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label
                            htmlFor="exampleInputEmail1"
                            className={platform == "" ? "text-danger" : ""}
                          >
                            Platform
                          </label>
                          <Select
                            options={helperData?.sources}
                            value={{
                              label: nameformValue,
                              value: platformValue,
                            }}
                            placeholder="Please Select A Platform"
                            onChange={(platform) => {
                              setplatformValue(platform.value);
                              setnameformValue(platform.label);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Name</label>
                          <input
                            type="text"
                            className="form-control"
                            value={name}
                            onChange={(name) => {
                              setName(name.target.value);
                            }}
                            // placeholder="Enter Project Owner"
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Username</label>
                          <input
                            type="text"
                            className="form-control"
                            value={username}
                            onChange={(username) => {
                              setUserName(username.target.value);
                            }}
                            // placeholder="Enter Project Name"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Password</label>
                          <input
                            type="Password"
                            className="form-control"
                            value={password}
                            onChange={(password) => {
                              setPassword(password.target.value);
                            }}
                            // placeholder="Enter Project Name"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mt-2">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            Profile Link
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={profileLink}
                            onChange={(Profile) => {
                              setProfileLink(Profile.target.value);
                            }}
                            // placeholder="Enter Project Name"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="row mb-5 mt-3">
                        <div className="">
                          {getid && getid?.tracker_id ? (
                            <button
                              type="button"
                              className="create-button"
                              onClick={trackerupdate}
                            >
                              Update
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="create-button"
                              onClick={trackeradd}
                            >
                              Submit
                            </button>
                          )}
                        </div>
                        <div className="ml-2">
                          <button
                            type="button"
                            className="create-button1 btn-info"
                            onClick={()=>navigate("/manage-tracker")}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTracker;
