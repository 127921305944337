/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import Utils from "../utility/utility";
import { VIEW_PROJECT } from "../service/apiUrl";
import { postApiCall } from "../service/PostApiCall";
import { useParams } from "react-router-dom";

const ViewProject = (props) => {
  const [projectData, setProjectData] = useState([]);

  const { projectId } = useParams();

  const signdata = useSelector((state) => state.signinReducers);
  const user_id = signdata?.data?.id;
  const access_token = signdata?.data?.access_token;

  useEffect(() => {
    if (access_token && projectId) {
      let params = {
        id: projectId,
      };
      postApiCall(params, access_token, VIEW_PROJECT)
        .then((result) => {
          setProjectData(result);
         
        })
        .catch((error) => {
          console.error("view-project error", error);
        });
    }
  }, [projectId]);

  return (
    <>
      <div className={props?.data?.transtion == true ? "contents" : "content"}>
        <div className="content-top-spacer"></div>
        <div className="color-body manage">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6">
                <div className="card-input mt-4 mb-2">
                  <div className="col-md-12">
                    <div className="mt-3">
                      <h5>
                        <i
                          className="fa fa-building-o site-color curser-point"
                          aria-hidden="true"
                        ></i>{" "}
                        Projects Information
                      </h5>
                      <hr />
                    </div>
                    <div className="col-md-12 card-input-body list-items">
                      <div className="row mb-2 border-bottom">
                        <div className="col-md-6">Project Name</div>
                        <div className="col-md-6">{projectData?.name}</div>
                      </div>
                      <div className="row mb-2 border-bottom">
                        <div className="col-md-6">Project Description</div>
                        <div className="col-md-6">
                          {projectData?.description}
                        </div>
                      </div>
                      <div className="row mb-2 border-bottom">
                        <div className="col-md-6">Technology</div>
                        <div className="col-md-6">
                          {projectData?.technology?.label}
                        </div>
                      </div>
                      <div className="row mb-2 border-bottom">
                        <div className="col-md-6">Department</div>
                        <div className="col-md-6">
                          {projectData?.department?.label}
                        </div>
                      </div>
                      <div className="row mb-2 border-bottom">
                        <div className="col-md-6">Project Development Link</div>
                        <div className="col-md-6">
                          {projectData?.development_link != ""
                            ? (<a href={projectData?.development_link} target="_blank" rel="noreferrer">{projectData?.development_link}</a>)
                            : "NA"}
                        </div>
                      </div>
                      <div className="row mb-2 border-bottom">
                        <div className="col-md-6"> Project Live Link</div>
                        <div className="col-md-6">
                          {projectData?.live_link != ""
                            ? (<a href={projectData?.live_link} target="_blank" rel="noreferrer">{projectData?.live_link}</a>)
                            : "NA"}
                        </div>
                      </div>
                      <div className="row mb-2 border-bottom">
                        <div className="col-md-6">Project InHouse</div>
                        <div className="col-md-6">
                          {projectData?.in_house == "1" ? "Yes" : "NA"}
                        </div>
                      </div>
                      <div className="row mb-2 border-bottom">
                        <div className="col-md-6">Project Under NDA</div>
                        <div className="col-md-6">
                          {projectData?.under_nda == "1" ? "Yes" : "NA"}
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-md-6">Project Tags</div>
                        <div className="col-md-6">{projectData?.tags}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-input mt-4 mb-2">
                  <div className="col-md-12">
                    <div className="mt-3">
                      <h5>Tracking Information</h5>
                    </div>
                    <div className="mt-3 card-input-body">
                      <table className="table border">
                        <thead>
                          <tr>
                            <th>Tracker Name</th>
                            <th>Username</th>
                            <th>Password</th>
                          </tr>
                        </thead>
                        <tbody>
                          {projectData?.billing_resource?.map((data, id) => {
                            return (
                              <tr key={id}>
                                <td>{data?.name}</td>
                                <td>
                                  <span className="tracker-username">
                                    {data?.username}
                                  </span>
                                  <i
                                    className="fa fa-clipboard site-color curser-point ml-2"
                                    aria-hidden="true"
                                    onClick={() => { Utils.copyToClipboard(data?.username) }}
                                  ></i>
                                </td>
                                <td>
                                  <span className="tracker-password">
                                    {data?.password}
                                  </span>
                                  <i
                                    className="fa fa-clipboard site-color curser-point ml-2"
                                    aria-hidden="true"
                                    onClick={() => { Utils.copyToClipboard(data?.username) }}
                                  ></i>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="card-input mt-4 mb-2">
                  <div className="col-md-12">
                    <div className="mt-3">
                      <h5>
                        <i
                          className="fa fa-user site-color curser-point"
                          aria-hidden="true"
                        ></i>{" "}
                        Resource Management
                      </h5>
                      <hr />
                    </div>
                    <div className="mt-3 col-md-12 card-input-body list-items">
                      <div className="row mb-2 border-bottom">
                        <div className="col-md-6">Developers Assigned</div>
                        <div className="col-md-6">
                          <ul className="list-unstyled">
                            {projectData?.assigned_developer?.map((data, id) => {
                              return (
                                <li key={id}>{id + 1 + ". " + data.label}</li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                      <div className="row mb-2 border-bottom">
                        <div className="col-md-6">Managers Assigned</div>
                        <div className="col-md-6">
                          <ul className="list-unstyled">
                            {projectData?.assigned_manager?.map((data, id) => {
                              return (
                                <li key={id}>{id + 1 + ". " + data.label}</li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-md-6">Project Status</div>
                        <div className="col-md-6">
                          {projectData?.status?.label}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-input mt-4 mb-2">
                  <div className="col-md-12">
                    <div className="mt-3">
                      <h5>
                        <i
                          className="fa fa-usd site-color curser-point"
                          aria-hidden="true"
                        ></i>{" "}
                        Billing Information
                      </h5>
                      <hr />
                    </div>
                    <div className="mt-3 col-md-12 card-input-body list-items">
                      <div className="row mb-2 border-bottom">
                        <div className="col-md-6">Type(Billable,Fixed)</div>
                        <div className="col-md-6">
                          {projectData?.billing_type?.label}
                        </div>
                      </div>
                      <div className="row mb-2 border-bottom">
                        <div className="col-md-6">Is Offline</div>
                        <div className="col-md-6">
                          {projectData?.is_offline == "1" ? "Yes" : "No"}
                        </div>
                      </div>
                      <div className="row mb-2 border-bottom">
                        <div className="col-md-6">Billable Resources</div>
                        <div className="col-md-6">
                          {projectData?.billing_resource?.length}
                        </div>
                      </div>
                      <div className="row mb-2 border-bottom">
                        <div className="col-md-6">Total Estimated Hours</div>
                        <div className="col-md-6">
                          {projectData?.estimated_hour}
                        </div>
                      </div>
                      <div className="row mb-2 border-bottom">
                        <div className="col-md-6">Project Source</div>
                        <div className="col-md-6">
                          {projectData?.source?.label}
                        </div>
                      </div>
                      <div className="row mb-2 border-bottom">
                        <div className="col-md-6">Tracker Id</div>
                        <div className="col-md-6">
                          <ul className="list-unstyled">
                            {projectData?.billing_resource?.map((data, id) => {
                              return (
                                <li key={id}>{id + 1 + ". " + data.name}</li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-md-6">Company Name</div>
                        <div className="col-md-6">
                          {projectData?.billing_company}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card-input mt-4 mb-2">
                  <div className="col-md-12">
                    <div className="mt-3">
                      <h5>
                        <i
                          className="fa fa-clock-o site-color curser-point"
                          aria-hidden="true"
                        ></i>{" "}
                        Consumed Hours
                      </h5>
                    </div>
                    <div className="mt-3 card-input-body">
                      <table className="table border">
                        <thead>
                          <tr>
                            <th>Project Name</th>
                            <th>Total Hours</th>
                            <th>Total Online Hours Consumed</th>
                            <th>Total Offline Hours Consumed</th>
                            <th>Pending Online Hours</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{projectData?.name}</td>
                            <td>{projectData?.estimated_hour} Hours</td>
                            <td>{projectData?.projectHours?.online || 0}</td>
                            <td>{projectData?.projectHours?.offline || 0}</td>
                            <td>{projectData?.projectHours?.pending}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    {projectData?.projectHours?.userWiseUsage.length > 0 ? (
                      <>
                        <div className="mt-3">
                          <h5>
                            <i
                              className="fa fa-users site-color curser-point"
                              aria-hidden="true"
                            ></i>{" "}
                            Work Done By Users
                          </h5>
                        </div>
                        <div className="mt-3 card-input-body">
                          <table className="table border">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Project Name</th>
                                <th>Username</th>
                                <th>Billage or Fixed hours</th>
                                <th>Offline</th>
                              </tr>
                            </thead>
                            <tbody>
                              {projectData?.projectHours?.userWiseUsage?.map(
                                (data, id) => {
                                  return (
                                    <tr key={id}>
                                      <td>{id + 1}</td>
                                      <td>{projectData?.name}</td>
                                      <td>
                                        {data?.details?.first_name +
                                          " " +
                                          data?.details?.last_name}
                                      </td>
                                      <td>{data?.online || 0}</td>
                                      <td>{data?.offline || 0}</td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewProject;
