import React, { useEffect, useState } from "react";
import Select from "react-select";
import { postApiCall } from "../service/PostApiCall";
import DatePicker from "react-datepicker";
import { useSelector, useDispatch } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import { STRING } from "../constants/string";
import ReactPaginate from "react-paginate";
import {
  MANAGE_TASK_HELPER,
  DAILY_TASK_LIST,
  DAILY_TASK_UPDATE,
  DELETE_TASK,
} from "../service/apiUrl";
import Utils from "../utility/utility";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
  },
};
const customStyles1 = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
  },
};

const customStylesDelete = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
  },
};
const ManageTask = (props) => {
  const signdata = useSelector((state) => state.signinReducers);
  const access_token = signdata?.data?.access_token;

  const [modalIsOpen1, setIsOpen1] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [deleteIsOpen, setIsOpenDelete] = useState(false);
  const [update, setUpdate] = useState(false);
  const [tasklist, setTaskList] = useState([]);
  const [helperData, setHelperData] = useState([]);
  const [user, setUser] = useState({ value: "", label: "" });
  const [teamlead, setTeamlead] = useState({ value: "", label: "" });
  const [departments, setDepartments] = useState({ value: "", label: "" });
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const [taskId, setTaskId] = useState("");
  const [projectId, setProjectId] = useState("");
  const [billingId, setBillingId] = useState("");
  const [billingType, setBillingType] = useState({ value: "", label: "" });
  const [workingHours, setWorkingHours] = useState("");
  const [taskDescription, setTaskDescription] = useState("");

  const [modalData, setModalData] = useState([]);
  const [editModalData, setEditModalData] = useState({});

  const [currentPage, setCurrentPage] = useState(1);

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  function openModal1() {
    setIsOpen1(true);
  }
  function closeModal1() {
    setIsOpen1(false);
  }

  const deleteModalOpen = (data) => {
    setIsOpenDelete(true);
    setTaskId(data?.id);
  };
  function deleteModalClose() {
    setIsOpenDelete(false);
  }

  const objectdata = {
    start_time: Utils.fromDateTimeStamp(fromDate),
    end_time: Utils.toDateTimeStamp(toDate),
    pageSize: "10",
    orderby: "",
    order: "",
    page: currentPage,
    user_id: user?.value,
    team_lead: teamlead?.value,
    department: departments?.value,
  };
  const searchClick = () => {
    setUpdate(!update);
  };

  useEffect(() => {
    postApiCall([], signdata?.data?.access_token, MANAGE_TASK_HELPER)
      .then((result) => {
        setHelperData(result);
      })
      .catch((error) => {
        console.error("MANAGE_TASK_HELPER", error);
      });
  }, []);

  useEffect(() => {
    postApiCall(objectdata, signdata?.data?.access_token, DAILY_TASK_LIST)
      .then((result) => {
        setTaskList(result);
      })
      .catch((error) => {
        console.error("Daliy task api", error);
      });
  }, [update]);

  useEffect(() => {
    setTaskId(editModalData?.id ?? "");
    setProjectId(editModalData?.project_id?.id ?? "");
    setBillingId(editModalData?.billing_id?.id ?? "");
    setBillingType(editModalData?.billing_type ?? { value: "", label: "" });
    setWorkingHours(editModalData?.working_hrs ?? "");
    setTaskDescription(editModalData?.task_description ?? "");
  }, [modalIsOpen1, editModalData]);

  const updateObjectdata = {
    taskId: taskId,
    project_id: projectId,
    billing_type: billingType?.value,
    billing_id: billingId,
    working_hrs: workingHours,
    task_description: taskDescription,
    showSuccessToast: true,
  };

  const updateDailyTask = () => {
    let message = "";
    if (Utils.isEmpty(workingHours)) {
      message = STRING.ENTER_WORKING_HOURS;
    } else if (Utils.isEmpty(billingType.value)) {
      message = STRING.ENTER_BILLING_STATUS;
    } else if (Utils.isEmpty(taskDescription)) {
      message = STRING.ENTER_TASK_DESCRIPTION;
    }
    if (message) {
      Utils.toast("error", message);
      return false;
    } else {
      postApiCall(updateObjectdata, access_token, DAILY_TASK_UPDATE).then(
        (response) => {
          setIsOpen1(false);
          setIsOpen(false);
          setUpdate(!update);
        }
      );
    }
    return true;
  };

  const deleteSubmit = () => {
    console.log("Delete request", taskId);
    const deleteObject = {
      id: taskId,
      showSuccessToast: true,
    };

    postApiCall(deleteObject, access_token, DELETE_TASK).then((response) => {
      setIsOpenDelete(false);
      setIsOpen(false);
      setUpdate(!update);
    });
    return true;
  };

  function deleteModel() {
    return (
      <>
        <div className="">
          <div>
            <Modal
              isOpen={deleteIsOpen}
              preventScroll={true}
              onRequestClose={deleteModalClose}
              style={customStylesDelete}
              contentLabel="Delete TAsk"
              ariaHideApp={false}
            >
              <div className="">
                <div className="col-md-12 mb-3">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-4">
                        <h5>Delete Tasks</h5>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-4">
                        <p>Are you sure you want to delete this task ?</p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 text-right">
                      <button
                        type="button"
                        className="button-default"
                        onClick={deleteModalClose}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="create-button ml-2"
                        onClick={deleteSubmit}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </>
    );
  }

  function appendmodal() {
    return (
      <>
        <div className="">
          <div>
            <Modal
              isOpen={modalIsOpen}
              preventScroll={true}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Daily Task"
              ariaHideApp={false}
            >
              <div className="">
                <div className="col-md-12 mb-3">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-4">
                        <h3>Daily Tasks</h3>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="text-right">
                        <i
                          className="fa fa-times curser-point"
                          aria-hidden="true"
                          onClick={closeModal}
                        ></i>
                      </div>
                    </div>
                  </div>

                  <div className="">
                    <div className="">
                      <div className="">
                        <table className="table table-sm">
                          <thead>
                            <tr className="bg-color">
                              <th
                                scope="col"
                                className="site-color modal-border"
                              >
                                <span className="mt-2 mb-2 d-flex">
                                  {" "}
                                  Project Name{" "}
                                </span>
                              </th>
                              <th scope="col" className="site-color">
                                <span className="mt-2 mb-2 d-flex">Status</span>
                              </th>
                              <th scope="col" className="site-color">
                                <span className="mt-2 mb-2 d-flex">
                                  {" "}
                                  Billing Id
                                </span>
                              </th>
                              <th scope="col" className="site-color">
                                <span className="mt-2 mb-2 d-flex">
                                  Worked Hours
                                </span>
                              </th>
                              <th scope="col" className="site-color">
                                <span className="mt-2 mb-2 d-flex">
                                  Last Update by
                                </span>
                              </th>
                              <th scope="col" className="site-color">
                                <span className="mt-2 mb-2 d-flex">
                                  Task Description
                                </span>
                              </th>
                              <th scope="col" className="site-color">
                                <span className="mt-2 mb-2 d-flex">Manage</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {modalData?.length ? (
                              modalData?.map((data, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      <span className="mt-4 d-flex">
                                        {data?.project_id?.name}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="mt-4 d-flex">
                                        {data?.billing_type?.label}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="mt-4 d-flex">
                                        {data?.billing_id?.name}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="mt-4 d-flex">
                                        {data?.working_hrs}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="mt-4 d-flex">
                                        {/* {admin name will show here}  */}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="mt-4 d-flex">
                                        {data?.task_description}
                                      </span>
                                    </td>

                                    <td>
                                      <div className="row mt-4 ml-1">
                                        <div className="">
                                          <button
                                            onClick={openModal1}
                                            className="button-popup"
                                          >
                                            <i
                                              className="fa fa-pencil site-color"
                                              aria-hidden="true"
                                              onClick={() => {
                                                setEditModalData(data);
                                              }}
                                            ></i>
                                          </button>
                                        </div>
                                        <div className="ml-3">
                                          <i
                                            className="site-color fa fa-trash"
                                            aria-hidden="true"
                                            onClick={() => {
                                              deleteModalOpen(data);
                                            }}
                                          ></i>
                                        </div>
                                      </div>
                                    </td>

                                    <td>
                                      <Modal
                                        isOpen={modalIsOpen1}
                                        preventScroll={true}
                                        onRequestClose={closeModal1}
                                        style={customStyles1}
                                        contentLabel="Example Modal"
                                        ariaHideApp={false}
                                      >
                                        <div className="">
                                          <div className="col-md-12 mb-3">
                                            <div className="row">
                                              <div className="col-md-6">
                                                <div className="mb-4">
                                                  <h3>Tasks</h3>
                                                </div>
                                              </div>
                                              <div className="col-md-6">
                                                <div className="text-right">
                                                  <i
                                                    className="fa fa-times curser-point"
                                                    aria-hidden="true"
                                                    onClick={closeModal1}
                                                  ></i>
                                                </div>
                                              </div>
                                              <div className="col-md-12">
                                                <div className="border-bottom"></div>
                                                <div className="row">
                                                  <div className="col-md-6 manage">
                                                    <div className="form-group">
                                                      <label htmlFor="exampleInputEmail1">
                                                        Project Name
                                                      </label>
                                                      <input
                                                        type="text"
                                                        className="form-control"
                                                        readOnly
                                                        defaultValue={
                                                          data?.project_id?.name
                                                        }
                                                        onChange={() => {}}
                                                      />
                                                    </div>
                                                    <div className="form-group">
                                                      <label htmlFor="exampleInputEmail1">
                                                        Status
                                                      </label>
                                                      <Select
                                                        options={
                                                          helperData?.billing_type
                                                        }
                                                        value={billingType}
                                                        onChange={(event) => {
                                                          setBillingType({
                                                            value: event?.value,
                                                            label: event?.label,
                                                          });
                                                        }}
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-md-6">
                                                    <div className="form-group">
                                                      <label htmlFor="exampleInputEmail1">
                                                        Worked Hours
                                                      </label>
                                                      <input
                                                        type="text"
                                                        className="form-control"
                                                        value={workingHours}
                                                        onChange={(e) => {
                                                          setWorkingHours(
                                                            e.target.value
                                                          );
                                                        }}
                                                      />
                                                    </div>
                                                    <div className="form-group">
                                                      <label htmlFor="exampleInputEmail1">
                                                        Task Description
                                                      </label>
                                                      <textarea
                                                        className="form-control"
                                                        id="exampleFormControlTextarea1"
                                                        rows="5"
                                                        value={taskDescription}
                                                        onChange={(e) => {
                                                          setTaskDescription(
                                                            e.target.value
                                                          );
                                                        }}
                                                      ></textarea>
                                                    </div>
                                                  </div>
                                                  <div className="col-md-12">
                                                    <button
                                                      type="button"
                                                      className="create-button"
                                                      onClick={updateDailyTask}
                                                    >
                                                      Update
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </Modal>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td>No record found</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </>
    );
  }

  const handlePageChange = (pageNumber) => {
    setUpdate(!update);
    const page = pageNumber?.selected + 1;
    setCurrentPage(page);
  };

  return (
    <>
      <div className={props?.data?.transtion == true ? "contents" : "content"}>
        <div className="content-top-spacer"></div>
        <div className="color-body">
          <div className="col-md-12">
            <div className="mt-3">
              <h3>Manage Users Tasks</h3>
            </div>
            <div className="card-input mt-4">
              <div className="col-md-12 mb-3">
                <div className="mt-2">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="col-md-12">
                        <div className="" style={{display:"flex",gap:10,alignItems:"baseline"}}>
                          <div className="">
                            <label className="mb-0">
                              <b>From Date:</b>
                            </label>
                            <DatePicker
                              selected={fromDate}
                              onChange={(date) => setFromDate(date)}
                              className="width-date1 mt-1"
                            />
                          </div>
                          <div className="">
                            <label className="mb-0">
                              <b>To Date:</b>
                            </label>
                            <DatePicker
                              selected={toDate}
                              onChange={(date) => setToDate(date)}
                              className="width-date1 mt-1"
                            />
                          </div>
                          <div className="mt-4">
                            <button
                              type="button"
                              className="reset-button mr-2"
                              onClick={searchClick}
                            >
                              Search
                            </button>
                          </div>
                          <div className="mt-4">
                            <button
                              type="button"
                              className="reset-button mr-2"
                              onClick={searchClick}
                            >
                              clear filter
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="mt-4">
                      <table className="table table-sm top-border-none">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="site-color"
                              style={{ width: "20%" }}
                            >
                              <span className="mt-2 mb-2 d-flex"> Name</span>
                            </th>
                            <th
                              scope="col"
                              className="site-color"
                              style={{ width: "20%" }}
                            >
                              <span className="mt-2 mb-2 d-flex">
                                {" "}
                                Team Lead
                              </span>
                            </th>
                            <th
                              scope="col"
                              className="site-color"
                              style={{ width: "20%" }}
                            >
                              <span className="mt-2 mb-2 d-flex">
                                Department
                              </span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">
                                {" "}
                                Offline hours
                              </span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">
                                {" "}
                                online hours
                              </span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex text-center">
                                {" "}
                                Total working <br />
                                hours
                              </span>
                            </th>
                            <th
                              scope="col"
                              className="site-color"
                              style={{ width: "100px" }}
                            >
                              <span className="mt-2 mb-2 d-flex"> Manage</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <Select
                                options={helperData?.users}
                                className={"mt-2 mb-2"}
                                value={user ?? {}}
                                isClearable={true}
                                isSearchable={true}
                                styles={{
                                  menu: (base) => ({
                                    ...base,
                                    width: "max-content",
                                    minWidth: "100%",
                                  }),
                                }}
                                onChange={(event) => {
                                  setUser({
                                    value: event?.value,
                                    label: event?.label,
                                  });
                                  setUpdate(!update);
                                }}
                              />
                            </td>
                            <td>
                              <Select
                                options={helperData?.team_lead}
                                className={"mt-2 mb-2"}
                                value={teamlead ?? {}}
                                isClearable={true}
                                isSearchable={true}
                                styles={{
                                  menu: (base) => ({
                                    ...base,
                                    width: "max-content",
                                    minWidth: "100%",
                                  }),
                                }}
                                onChange={(event) => {
                                  setTeamlead({
                                    value: event?.value,
                                    label: event?.label,
                                  });
                                  setUpdate(!update);
                                }}
                              />
                            </td>
                            <td>
                              <Select
                                options={helperData?.departments}
                                className={"mt-2 mb-2"}
                                value={departments ?? {}}
                                isClearable={true}
                                isSearchable={true}
                                styles={{
                                  menu: (base) => ({
                                    ...base,
                                    width: "max-content",
                                    minWidth: "100%",
                                  }),
                                }}
                                onChange={(event) => {
                                  setDepartments({
                                    value: event?.value,
                                    label: event?.label,
                                  });
                                  setUpdate(!update);
                                }}
                              />
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          {tasklist?.trackers?.length ? (
                            <>
                              {tasklist?.trackers?.map((user, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      <span className="mt-4 d-flex">
                                        {user?.first_name} {user?.last_name}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="mt-4 d-flex">
                                        {user?.team_lead?.label}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="mt-4 d-flex">
                                        {user?.department?.label}
                                      </span>
                                    </td>
                                    <td
                                      className={
                                        user?.offline > 0 ? "bg-red" : ""
                                      }
                                    >
                                      <span className="mt-4 d-flex">
                                        {user?.offline}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="mt-4 d-flex">
                                        {user?.online}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="mt-4 d-flex">
                                        {user?.totalWorkingHours}
                                      </span>
                                    </td>
                                    <td>
                                      <div className="mt-4 text-center">
                                        <div className="mr-3">
                                          <button
                                            onClick={() =>
                                              setModalData(user?.tasks)
                                            }
                                            className="button-popup"
                                          >
                                            <i
                                              className="fa fa-eye site-color"
                                              onClick={openModal}
                                              aria-hidden="true"
                                            ></i>
                                          </button>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                              <tr>
                                <td colSpan={7} className="text-center w-100">
                                  <ReactPaginate
                                    previousLabel={"Previous"}
                                    nextLabel={"Next"}
                                    pageCount={tasklist?.total_pages}
                                    onPageChange={handlePageChange}
                                    containerClassName={"pagination"}
                                    activeClassName={"active"}
                                  />
                                </td>
                              </tr>
                            </>
                          ) : (
                            <tr>
                              <td colSpan="7">No record found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {appendmodal()}
      {deleteModel()}
    </>
  );
};

export default ManageTask;
