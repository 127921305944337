import React, { useState } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import Utils from "../../utility/utility";
import { STRING } from "../../constants/string";
import YearPicker from "react-year-picker";
import { postApiCall } from "../../service/PostApiCall";
import { PAYSLIP_CREATE, EDIT_PAYSLIPS,VIEW_PAYSLIPS } from "../../service/apiUrl";
import "react-month-picker/css/month-picker.css";
import { useNavigate, useParams } from "react-router-dom";
import { DEPARTMENTSUSERS, PAY_HELPER } from "../../service/apiUrl";
import AddPayslip from "./AddPayslip";

const EditPayslip = (props) => {

    const [departmentval, setdepartmentval] = useState({});
    const [month, setMonth] = useState("");
    const [year, setYear] = useState("");
    const [totalDay, setTotalDay] = useState("");
    const [totalLeaves, setTotalLeaves] = useState("");
    const [totalEligible, setEligible] = useState("");
    const [freeleaves, setFreeLeaves] = useState("");
    const [paidLeaves, setPaidLeaves] = useState("");
    const [extraworking, setExtraWorking] = useState("");
    const [totalwork, setTotalwork] = useState("");
    const [workingBonus, setWorkingBonus] = useState("");
    const [random, setRandomBonus] = useState("");
    const [providentFund, setProvidentFund] = useState("");
    const [emp, setEmp] = useState("");
    const [txpercentage, seTxpercentage] = useState("");
    const [Leaveded, seLeaveded] = useState("");
    const [basicbif, seBasicbif] = useState("");
    const [otherbif, seOtherbif] = useState("");
    const [medicalall, setMedicalall] = useState("");
    const [houserent, setHouseRent] = useState("");
    const [callow, setCallow] = useState("");
    const [Ipall, setIpall] = useState("");
    const [actsalary, setActsalary] = useState("");
    const [dpercent, setDpercent] = useState("");
    const [handsalary, setHandSalary] = useState("");
    const [creditSalary, setCreditSalary] = useState("");
    const [visibility, setVisibility] = useState("");
    const [departmentEmployees, setdepartmentEmployees] = useState(false);
    const [employes, setEmployes] = useState("");
    const [ChooseEmployee, setChooseEmployee] = useState(false);
    const [helperData, setHelperData] = useState([]);
    const [userData, setUserData] = useState({});
    const [update, setUpdate] = useState(false);
  
    const leaves = [
      { value: "1", label: "Yes" },
      { value: "0", label: "No" },
    ];
    const visible = [
      { value: "1", label: "Visibile" },
      { value: "0", label: "Hidden" },
    ];
    const months = [
      { value: "1", label: "Jan" },
      { value: "2", label: "Feb" },
      { value: "3", label: "Mar" },
      { value: "4", label: "Apr" },
      { value: "5", label: "May" },
      { value: "6", label: "Jun" },
      { value: "7", label: "Jul" },
      { value: "8", label: "Aug" },
      { value: "9", label: "Sep" },
      { value: "10", label: "Oct" },
      { value: "11", label: "Nov" },
      { value: "12", label: "Dec" },
    ];

    const departments = helperData?.departments;

    const PayslipuserId = useParams();
  
    const navigate = useNavigate();
    const signdata = useSelector((state) => state.signinReducers);
    const user_id = signdata?.data?.id;
    const access_token = signdata?.data?.access_token;

    useEffect(()=>{
      console.log("Total departments =", departments);
      console.log("Employees =", departmentEmployees);
    },[departments, departmentEmployees]);
  
    useEffect(() => {
      if(departmentval?.value){
      const departementobj = {
        department: departmentval?.value,
      };
  
      postApiCall(departementobj, access_token, DEPARTMENTSUSERS).then(
        (result) => {
          setdepartmentEmployees(result?.users); 
        }
      );
    }
    }, [departmentval]);
  
    useEffect(() => {
      postApiCall([], access_token, PAY_HELPER)
        .then((result) => {
          setHelperData(result);
        })
        .catch((error) => {
          console.error("LEAVES_HELPER", error);
        });
    }, [departmentval]);

    
  
    const payslipobj = {
      department: departmentval,
      month: month,
      year: year,
      total_work_days: totalDay,
      total_leaves: totalLeaves,
      eligible_free_leaves: totalEligible,
      taken_free_leaves: freeleaves,
      paid_leaves: paidLeaves,
      extra_working_days: extraworking,
      total_working_days: totalwork,
      created_by: user_id,
      extra_working_bonus: workingBonus,
      random_bonus: random,
      pf: providentFund,
      emp_state_insurance: emp,
      tax_percentage: txpercentage,
      leave_deduction: Leaveded,
      basic_bifurcation: basicbif,
      other_bifurcation: otherbif,
      medical_allowance: medicalall,
      house_rent: houserent,
      conveyance_allowance: callow,
      internet_phone_allowance: Ipall,
      actual_salary: actsalary,
      deduction_percentage: dpercent,
      in_hand_salary: handsalary,
      credited_salary: creditSalary,
      visibility: visibility,
      uid: employes,
    };
  
    const validationProject = () => {
      let message = "";
      if (Utils.isEmpty(departmentval)) {
        message = STRING.DEPARTMENT;
      } else if (Utils.isEmpty(year)) {
        message = STRING.YEAR;
      } else if (Utils.isEmpty(totalwork)) {
        message = STRING.TOTALWORK;
      } else if (Utils.isEmpty(totalLeaves)) {
        message = STRING.TOTALLEAVE;
      } else if (Utils.isEmpty(totalEligible)) {
        message = STRING.TOTALELIGIBLE;
      } else if (Utils.isEmpty(freeleaves)) {
        message = STRING.FREELEAVES;
      } else if (Utils.isEmpty(paidLeaves)) {
        message = STRING.PAIDLEAVE;
      } else if (Utils.isEmpty(extraworking)) {
        message = STRING.EXTRAWORKING;
      } else if (Utils.isEmpty(totalDay)) {
        message = STRING.TOTALDAY;
      }
      if (message) {
        Utils.toast("error", message);
        return false;
      } else {
        if (!Utils.isEmpty(PayslipuserId)) {
          postApiCall(payslipobj, access_token, EDIT_PAYSLIPS).then(
            (response) => {
              setUpdate(false);
            }
          );
        }
        return true;
      }
    };

    useEffect(() => {
      if (PayslipuserId) {
        let params = {
          access_token: access_token,
          id: PayslipuserId,
          
        };
        postApiCall(params, access_token, VIEW_PAYSLIPS)
          .then((result) => {
            setUpdate(true);
            setUserData(result);
            setdepartmentval(result?.department?.value);
            setEmployes();
            setMonth(result?.month?.value);
            setYear(result?.year);
            setTotalwork(result?.total_working_days);
            setTotalLeaves(result?.total_leaves);
            setEligible(result?.eligible_free_leaves?.value);
            setFreeLeaves(result?.taken_free_leaves);
            setPaidLeaves(result?.paid_leaves);
            setExtraWorking(result?.extra_working_days);
            setTotalDay(result?.total_work_days);
            setWorkingBonus(result?.extra_working_bonus);
            setRandomBonus(result?.random_bonus);
            setActsalary(result?.actual_salary);
            setDpercent(result?.deduction_percentage);
            setHandSalary(result?.in_hand_salary);
            setCreditSalary(result?.credited_salary);
            setProvidentFund(result?.pf);
            setEmp(result?.emp_state_insurance);
            seTxpercentage(result?.tax_percentage);
            seLeaveded(result?.leave_deduction);
            seBasicbif(result?.basic_bifurcation);
            seOtherbif(result?.other_bifurcation);
            setMedicalall(result?.medical_allowance);
            setHouseRent(result?.house_rent);
            setCallow(result?.conveyance_allowance);
            setIpall(result?.internet_phone_allowance);
            setVisibility(result?.visibility?.value);
            
          })
          .catch((error) => {
            console.error("view-Payslip error", error);
          });
      }
    }, [PayslipuserId, update]);
   
  

  return (
    <AddPayslip
    employeeData={userData}
    topbar_space={false}
    transtion={true}
    update={update}
    />



    
  //   <div className={props?.data?.transtion == true ? "contents" : "content"}>
  //   <div className="content-top-spacer"></div>
  //   <div className="color-body pay-slip">
  //     <div className="mt-3">
  //       <h5>Update Payslip</h5>
  //     </div>
  //     <div className="row">

       
  //       <div className="col-md-6 manage">
  //         <div className="card-input mt-2 mb-2">
  //           <div className="col-md-12">
  //             <div className="mt-3">
  //               <h5 className="border-bottom pb-2">Personal Details</h5>
  //             </div>
  //             <form>
  //               <div className="row mt-3">
  //                 <div
  //                   className={ChooseEmployee ? "col-md-6" : "col-md-12"}
  //                 >
  //                   <div className="form-group">
  //                     <label htmlFor="exampleInputEmail1">Department</label>
  //                     <Select
  //                       options={departments}
  //                       placeholder="Please Select A Technology"
  //                       value={departmentval}
  //                       onChange={(data) => {
  //                         setdepartmentval({
  //                           value: data?.value,
  //                           label: data?.label,
  //                         });
  //                         setChooseEmployee(true);
  //                       }}
  //                     />
  //                   </div>
  //                 </div>
  //                 {ChooseEmployee == true ? (
  //                   <div className="col-md-6">
  //                     <div className="form-group">
  //                       <label htmlFor="exampleInputEmail1">Employee</label>
  //                       <Select
  //                         options={departmentEmployees}
  //                         value={employes}
  //                         onChange={(data) => {
  //                           setEmployes({
  //                             value: data?.value,
  //                             label: data?.label,
  //                           });
  //                         }}
  //                         placeholder="Please Select A Employee"
  //                       />
  //                     </div>
  //                   </div>
  //                 ) : null}
  //                 <div className="col-md-6">
  //                   <div className="form-group">
  //                     <label htmlFor="exampleInputEmail1">Month</label>
  //                     <Select
  //                       options={months}
  //                       placeholder="Please Select A Month"
  //                       value={month}
  //                       onChange={(data) => {
  //                         setMonth({
  //                           value: data?.value,
  //                           label: data?.label
  //                         });
  //                       }}
  //                     />
  //                   </div>
  //                 </div>
  //                 <div className="col-md-6">
  //                   <div className="form-group">
  //                     <label htmlFor="exampleInputEmail1">Year</label>
  //                     <YearPicker
  //                     value={year}
  //                       onChange={(Year) => {
  //                         setYear(Year);
  //                       }}
  //                     />
  //                   </div>
  //                 </div>
  //               </div>
  //             </form>
  //           </div>
  //         </div>
  //         <div className="card-input mt-4 mb-2">
  //           <div className="col-md-12">
  //             <div className="mt-3">
  //               <h5 className="border-bottom pb-2">Leaves Management</h5>
  //             </div>
  //             <form>
  //               <div className="row mt-3">
  //                 <div className="col-md-6">
  //                   <div className="form-group">
  //                     <label htmlFor="exampleInputEmail1">
  //                       No. of Calculated Days
  //                     </label>
  //                     <input
  //                       type="text"
  //                       className="form-control"
  //                       value={totalwork}
  //                       onChange={(Calculated) => {
  //                         setTotalwork(Calculated.target.value);
  //                       }}
  //                     />
  //                   </div>
  //                 </div>
  //                 <div className="col-md-6">
  //                   <div className="form-group">
  //                     <label htmlFor="exampleInputEmail1">
  //                       Total Leaves in a Month
  //                     </label>
  //                     <input
  //                       type="text"
  //                       className="form-control"
  //                       value={totalLeaves}
  //                       onChange={(leave) => {
  //                         setTotalLeaves(leave.target.value);
  //                       }}
  //                     />
  //                   </div>
  //                 </div>
  //                 <div className="col-md-6">
  //                   <div className="form-group">
  //                     <label htmlFor="exampleInputEmail1">
  //                       Eligible For Free Leaves
  //                     </label>
  //                     <Select
  //                       options={leaves}
  //                       value={totalEligible}
  //                       onChange={(event) => {
  //                         setEligible({
  //                           value:event?.value,
  //                           label:event?.label,
  //                         });
  //                       }}
  //                       placeholder="Please Select A Technology"
  //                     />
  //                   </div>
  //                 </div>
  //                 <div className="col-md-6">
  //                   <div className="form-group">
  //                     <label htmlFor="exampleInputEmail1">
  //                       Free Leaves
  //                     </label>
  //                     <input
  //                       type="text"
  //                       className="form-control"
  //                       value={freeleaves}
  //                       onChange={(free) => {
  //                         setFreeLeaves(free.target.value);
  //                       }}
  //                     />
  //                   </div>
  //                 </div>
  //                 <div className="col-md-6">
  //                   <div className="form-group">
  //                     <label htmlFor="exampleInputEmail1">
  //                       Paid Leaves
  //                     </label>
  //                     <input
  //                       type="text"
  //                       className="form-control"
  //                       value={paidLeaves}
  //                       onChange={(Paid) => {
  //                         setPaidLeaves(Paid.target.value);
  //                       }}
  //                     />
  //                   </div>
  //                 </div>
  //                 <div className="col-md-6">
  //                   <div className="form-group">
  //                     <label htmlFor="exampleInputEmail1">
  //                       Extra Days Work
  //                     </label>
  //                     <input
  //                       type="text"
  //                       className="form-control"
  //                       value={extraworking}
  //                       onChange={(Extra) => {
  //                         setExtraWorking(Extra.target.value);
  //                       }}
  //                     />
  //                   </div>
  //                 </div>
  //                 <div className="col-md-12">
  //                   <div className="form-group">
  //                     <label htmlFor="exampleInputEmail1">
  //                       Total Working Days
  //                     </label>
  //                     <input
  //                       type="text"
  //                       className="form-control"
  //                       value={totalDay}
  //                       onChange={(working) =>
  //                         setTotalDay(working.target.value)
  //                       }
  //                     />
  //                   </div>
  //                 </div>
  //               </div>
  //             </form>
  //           </div>
  //         </div>
  //         <div className="card-input mt-4 mb-2">
  //           <div className="col-md-12">
  //             <div className="mt-3">
  //               <h5 className="border-bottom pb-2">Bonus</h5>
  //             </div>
  //             <form>
  //               <div className="row mt-3">
  //                 <div className="col-md-6">
  //                   <div className="form-group">
  //                     <label htmlFor="exampleInputEmail1">
  //                       Extra Working
  //                     </label>
  //                     <input
  //                       type="text"
  //                       className="form-control"
  //                       value={workingBonus}
  //                       onChange={(data) => {
  //                         setWorkingBonus(data.target.value);
  //                       }}
  //                     />
  //                   </div>
  //                 </div>
  //                 <div className="col-md-6">
  //                   <div className="form-group">
  //                     <label htmlFor="exampleInputEmail1">Random</label>
  //                     <input
  //                       type="text"
  //                       className="form-control"
  //                       value={random}
  //                       onChange={(data) => {
  //                         setRandomBonus(data.target.value);
  //                       }}
  //                     />
  //                   </div>
  //                 </div>
  //               </div>
  //             </form>
  //           </div>
  //         </div>
  //         <div className="col-md-12">
  //           <div className="row mb-5 mt-3">
  //             <div className="">
  //               <button
  //                 type="button"
  //                 className="create-button"
  //                 onClick={validationProject}
  //               >
  //                 Update
  //               </button>
  //             </div>
  //             <div className="ml-2">
  //               <button type="button" className="create-button1 btn-info">
  //                 Cancel
  //               </button>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //       <div className="col-md-6 manage">
  //         <div className="card-input mt-2 mb-2">
  //           <div className="col-md-12">
  //             <div className="mt-3">
  //               <h5 className="border-bottom pb-2">In-Hand Salary</h5>
  //             </div>
  //             <form>
  //               <div className="row mt-3">
  //                 <div className="col-md-6">
  //                   <div className="form-group">
  //                     <label htmlFor="exampleInputEmail1">
  //                       Actual Salary (Rupeees)
  //                     </label>
  //                     <input
  //                       type="text"
  //                       className="form-control"
  //                       value={actsalary}
  //                       onChange={(data) => {
  //                         setActsalary(data.target.value);
  //                       }}
  //                     />
  //                   </div>
  //                 </div>
  //                 <div className="col-md-6">
  //                   <div className="form-group">
  //                     <label htmlFor="exampleInputEmail1">
  //                       Deduction(%)
  //                     </label>
  //                     <input
  //                       type="text"
  //                       className="form-control"
  //                       value={dpercent}
  //                       onChange={(data) => {
  //                         setDpercent(data.target.value);
  //                       }}
  //                     />
  //                   </div>
  //                 </div>
  //                 <div className="col-md-6">
  //                   <div className="form-group">
  //                     <label htmlFor="exampleInputEmail1">
  //                       In-Hand Salary Rupees
  //                     </label>
  //                     <input
  //                       type="text"
  //                       className="form-control"
  //                       value={handsalary}
  //                       onChange={(data) => {
  //                         setHandSalary(data.target.value);
  //                       }}
  //                     />
  //                   </div>
  //                 </div>
  //                 <div className="col-md-6">
  //                   <div className="form-group">
  //                     <label htmlFor="exampleInputEmail1">
  //                       Salary Credit Rupees
  //                     </label>
  //                     <input
  //                       type="text"
  //                       className="form-control"
  //                       value={creditSalary}
  //                       onChange={(data) => {
  //                         setCreditSalary(data.target.value);
  //                       }}
  //                     />
  //                   </div>
  //                 </div>
  //               </div>
  //             </form>
  //           </div>
  //         </div>
  //         <div className="card-input mt-4 mb-2">
  //           <div className="col-md-12">
  //             <div className="mt-3">
  //               <h5 className="border-bottom pb-2">Deduction</h5>
  //             </div>
  //             <form>
  //               <div className="row mt-3">
  //                 <div className="col-md-6">
  //                   <div className="form-group">
  //                     <label htmlFor="exampleInputEmail1">
  //                       Provident Fund
  //                     </label>
  //                     <input
  //                       type="text"
  //                       className="form-control"
  //                       value={providentFund}
  //                       onChange={(data) => {
  //                         setProvidentFund(data.target.value);
  //                       }}
  //                     />
  //                   </div>
  //                 </div>
  //                 <div className="col-md-6">
  //                   <div className="form-group">
  //                     <label htmlFor="exampleInputEmail1">
  //                       Employee State Insurance
  //                     </label>
  //                     <input
  //                       type="text"
  //                       className="form-control"
  //                       value={emp}
  //                       onChange={(data) => {
  //                         setEmp(data.target.value);
  //                       }}
  //                     />
  //                   </div>
  //                 </div>
  //                 <div className="col-md-6">
  //                   <div className="form-group">
  //                     <label htmlFor="exampleInputEmail1">Tax (%)</label>
  //                     <input
  //                       type="text"
  //                       className="form-control"
  //                       value={txpercentage}
  //                       onChange={(data) => {
  //                         seTxpercentage(data.target.value);
  //                       }}
  //                     />
  //                   </div>
  //                 </div>
  //                 <div className="col-md-6">
  //                   <div className="form-group">
  //                     <label htmlFor="exampleInputEmail1">
  //                       Deduction For Leaves
  //                     </label>
  //                     <input
  //                       type="text"
  //                       className="form-control"
  //                       value={Leaveded }
  //                       onChange={(data) => {
  //                         seLeaveded(data.target.value);
  //                       }}
  //                     />
  //                   </div>
  //                 </div>
  //               </div>
  //             </form>
  //           </div>
  //         </div>
  //         <div className="card-input mt-4 mb-2">
  //           <div className="col-md-12">
  //             <div className="mt-3">
  //               <h5 className="border-bottom pb-2">Bifurcation</h5>
  //             </div>
  //             <form>
  //               <div className="row mt-3">
  //                 <div className="col-md-6">
  //                   <div className="form-group">
  //                     <label htmlFor="exampleInputEmail1">Basic</label>
  //                     <input
  //                       type="text"
  //                       className="form-control"
  //                       value={basicbif}
  //                       onChange={(data) => {
  //                         seBasicbif(data.target.value);
  //                       }}
  //                     />
  //                   </div>
  //                 </div>
  //                 <div className="col-md-6">
  //                   <div className="form-group">
  //                     <label htmlFor="exampleInputEmail1">Other</label>
  //                     <input
  //                       type="text"
  //                       className="form-control"
  //                       value={otherbif}
  //                       onChange={(data) => {
  //                         seOtherbif(data.target.value);
  //                       }}
  //                     />
  //                   </div>
  //                 </div>
  //                 <div className="col-md-6">
  //                   <div className="form-group">
  //                     <label htmlFor="exampleInputEmail1">
  //                       Medical Allowance
  //                     </label>
  //                     <input
  //                       type="text"
  //                       className="form-control"
  //                       value={medicalall}
  //                       onChange={(data) => {
  //                         setMedicalall(data.target.value);
  //                       }}
  //                     />
  //                   </div>
  //                 </div>
  //                 <div className="col-md-6">
  //                   <div className="form-group">
  //                     <label htmlFor="exampleInputEmail1">House Rent</label>
  //                     <input
  //                       type="text"
  //                       className="form-control"
  //                       value={houserent}
  //                       onChange={(data) => {
  //                         setHouseRent(data.target.value);
  //                       }}
  //                     />
  //                   </div>
  //                 </div>
  //                 <div className="col-md-6">
  //                   <div className="form-group">
  //                     <label htmlFor="exampleInputEmail1">
  //                       Conveyance Allowance
  //                     </label>
  //                     <input
  //                       type="text"
  //                       className="form-control"
  //                       value={callow}
  //                       onChange={(data) => {
  //                         setCallow(data.target.value);
  //                       }}
  //                     />
  //                   </div>
  //                 </div>
  //                 <div className="col-md-6">
  //                   <div className="form-group">
  //                     <label htmlFor="exampleInputEmail1">
  //                       Internet / Phone Allowance
  //                     </label>
  //                     <input
  //                       type="text"
  //                       className="form-control"
  //                       value={Ipall}
  //                       onChange={(data) => {
  //                         setIpall(data.target.value);
  //                       }}
  //                     />
  //                   </div>
  //                 </div>
  //               </div>
  //             </form>
  //           </div>
  //         </div>
  //         <div className="card-input mt-4 mb-2">
  //           <div className="col-md-12">
  //             <div className="mt-3">
  //               <h5 className="border-bottom pb-2">visibility</h5>
  //             </div>
  //             <form>
  //               <div className="row mt-3">
  //                 <div className="col-md-12">
  //                   <div className="form-group">
  //                     <label htmlFor="exampleInputEmail1">visibility</label>
  //                     <Select
  //                       options={visible}
  //                       placeholder="Please Select A visible"
  //                       value={visibility}
  //                       onChange={(data) => {
  //                         setVisibility({
  //                           value: data?.value,
  //                           label: data?.label,
  //                         });
  //                       }}
  //                     />
  //                   </div>
  //                 </div>
  //               </div>
  //             </form>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // </div>
  )
}

export default EditPayslip;