/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { FileUploader } from "react-drag-drop-files";
import { MultiSelect } from "react-multi-select-component";
import { useEffect } from "react";
import Utils from "../utility/utility";
import { STRING } from "../constants/string";
import {
  VIEW_PROJECT,
  PROJECT_CREATE,
  PROJECT_HELPER,
  DEPARTMENTSUSERS,
  PROJECT_UPDATE,
} from "../service/apiUrl";
import { postApiCall } from "../service/PostApiCall";
import { useParams, useNavigate } from "react-router-dom";

const AddProject = (props) => {
  const [projectName, setProjectName] = useState("");
  const [department, setDepartment] = useState("");
  const [technology, setTechnology] = useState([]);
  const [tags, setTags] = useState("");
  const [size, setSize] = useState("");
  const [developementLink, setDevelopementLink] = useState("");
  const [projectLink, setprojectLink] = useState("");
  const [estimateTime, setEstimateTime] = useState("");
  const [perHours, setPerHours] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [type, setType] = useState({});
  const [companyName, setCompanyName] = useState("");
  const [billingResource, setBillingResource] = useState([]);
  const [sources, setSource] = useState({});
  const [Inhouse, setInhouse] = useState(0);
  const [nda, setNda] = useState(0);
  const [isOffline, setIsOffline] = useState(0);
  const [milestone, setMilestone] = useState(0);
  const [developerAssigned, setDeveloperAssigned] = useState([]);
  const [managersAssigned, setManagersAssigned] = useState([]);
  const [helperData, setHelperData] = useState([]);
  const [file, setFile] = useState({});
  const [fileName, setFileName] = useState([]);
  const [departmentEmployees, setdepartmentEmployees] = useState([]);

  const MAX_COUNT = 5;
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [fileLimit, setFileLimit] = useState(false);

  const { projectId } = useParams();

  const navigate = useNavigate();

  const signdata = useSelector((state) => state.signinReducers);
  const user_id = signdata?.data?.id;
  const access_token = signdata?.data?.access_token;

  useEffect(() => {
    if (access_token && projectId) {
      let params = {
        id: projectId,
      };
      postApiCall(params, access_token, VIEW_PROJECT)
        .then((result) => {
          setProjectName(result?.name);
          setTags(result?.tags);
          setDevelopementLink(result?.development_link);
          setprojectLink(result?.live_link);
          setProjectDescription(result?.description);
          setPerHours(result?.per_hour);
          setEstimateTime(result?.estimated_hour);
          setCompanyName(result?.billing_company);
          setDepartment(result?.department);
          setType(result?.billing_type);
          setSource(result?.source);
          setBillingResource(result?.billing_resource);
          setTechnology(result?.technology);
          setIsOffline(result?.is_offline);
          setMilestone(result?.is_milestone);
          setInhouse(result?.in_house);
          setNda(result?.under_nda);
          setSize(result?.size?.value);
          setDeveloperAssigned(result?.assigned_developer);
          setManagersAssigned(result?.assigned_manager);
        })
        .catch((error) => {
          console.error("edit-project error", error);
        });
    }
  }, [projectId]);

  const projectobj = {
    name: projectName,
    description: projectDescription,
    department: department?.value,
    technology: technology,
    tags: tags,
    development_link: developementLink,
    in_house: Inhouse,
    live_link: projectLink,
    under_nda: nda,
    billing_type: type?.value,
    billing_resource: billingResource,
    is_offline: isOffline,
    is_milestone: milestone,
    estimated_hour: estimateTime,
    per_hour: perHours,
    source: sources?.value,
    billing_company: companyName,
    size: size,
    assigned_developer: developerAssigned,
    assigned_manager: managersAssigned,
    created_by: user_id,
    info_file: file,
  };

  const validationProject = () => {
    let message = "";
    if (Utils.isEmpty(projectName)) {
      message = STRING.PROJECTNAME;
    } else if (Utils.isEmpty(projectDescription)) {
      message = STRING.PROJECT_DESCRIPTION;
    } else if (Utils.isEmpty(type)) {
      message = STRING.TYPE;
    } else if (Utils.isEmpty(estimateTime)) {
      message = STRING.ESTIMATE_TIME;
    } else if (Utils.isEmpty(perHours)) {
      message = STRING.PER_HOURS;
    } 
    // else if (Utils.isEmpty(companyName)) {
    //   message = STRING.BILLING_COMPANY_NAME;
    // }
    if (message) {
      Utils.toast("error", message);
      return false;
    } else {


      console.log("payload :", projectobj);


      if(projectId){
        projectobj.id = projectId;
        postApiCall(projectobj, access_token, PROJECT_UPDATE,true).then((result) => {
          Utils.toast("success", "Project updated successfully!!!");
          navigate("/manage-project");
        });
      }else{
        postApiCall(projectobj, access_token, PROJECT_CREATE,true).then((result) => {
          Utils.toast("success", "Project created successfully!!!");
          navigate("/manage-project");
        });
      }
      return true;
    }
  };

  const fileTypes = ["txt", "pem", "ppk", "docs", "csv", "xlss", "pdf"];

  const handleChange = (inputfile) => {

    // setFile(Object.values(inputfile));

    var tempArray = [];
     for (const [key, value] of Object.entries(inputfile)) {
      tempArray.push(value?.name)
    }
    setFileName(tempArray)      
  };


//   const handleUploadFiles = files => {
//     const uploaded = [...uploadedFiles];
//     // let limitExceeded = false;
//     files.some((file) => {
//         uploaded.push(file);
//     setUploadedFiles(uploaded);
//     })
//     setUploadedFiles(uploaded)
  
//     //  if (!limitExceeded) 
//  }

  // const handleFileEvent =  (e) => {
  //   const chosenFiles = Array.prototype.slice.call(e)
  //   handleUploadFiles(chosenFiles);
  // }
  
  useEffect(() => {
    postApiCall([], signdata?.data?.access_token, PROJECT_HELPER)
      .then((result) => {
        setHelperData(result);
      })
      .catch((error) => {
        console.error("PROJECT_HELPER", error);
      });
  }, []);

  const getDepartmentUsers = (department) => {
    const departementParams = {
      department: department,
    };
    postApiCall(departementParams, access_token, DEPARTMENTSUSERS).then(
      (result) => {
        setdepartmentEmployees(result?.users);
      }
    );
  };

  return (
    <>
      <div className={props?.data?.transtion == true ? "contents" : "content"}>
        <div className="content-top-spacer"></div>
        <div className="color-body manage">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6">
                <div className="card-input mt-5 mb-2">
                  <div className="col-md-12">
                    <div className="mt-3">
                      <h5 className="border-bottom pb-2">
                        Projects Information
                      </h5>
                    </div>
                    <form>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Project Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={projectName}
                              onChange={(name) => {
                                setProjectName(name.target.value);
                              }}
                              // placeholder="Enter Project Name"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Department
                            </label>
                            <Select
                              options={helperData?.departments}
                              placeholder="Please Select A Department"
                              value={department}
                              onChange={(depart) => {
                                setDepartment({
                                  label: depart?.label,
                                  value: depart?.value,
                                });
                                // setDepartment(depart.value);
                                // getDepartmentUsers(depart.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Technology
                            </label>
                            <MultiSelect
                              options={helperData?.technologies ?? []}
                              value={technology}
                              onChange={(technologies) => {
                                setTechnology(technologies);
                              }}
                              labelledBy="Select"
                            />

                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Tags</label>
                            <input
                              type="text"
                              className="form-control"
                              value={tags}
                              onChange={(tags) => {
                                setTags(tags.target.value);
                              }}
                              // placeholder="Enter Project Name"
                            />
                          </div>
                        </div>
                        <div className="col-md-6 mt-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Project Developements Link
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={developementLink}
                              onChange={(developements) => {
                                setDevelopementLink(developements.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 mt-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Project Live Link
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={projectLink}
                              onChange={(projectLink) => {
                                setprojectLink(projectLink.target.value);
                              }}
                              // placeholder="Enter Project Name"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value="1"
                              checked={Inhouse?true:false}
                              id="flexCheckDisabled1"
                              onChange={(house) => {
                                setInhouse(house.target.value);
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDisabled1"
                            >
                              InHouse Project
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value="1"
                              checked={nda?true:false}
                              id="flexCheckDisabledu"
                              onChange={(nda) => {
                                setNda(nda.target.value);
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDisabledu"
                            >
                              Under NDA
                            </label>
                          </div>
                        </div>
                        <div className="col-md-12 mt-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Project Description
                            </label>
                            <textarea
                              className="form-control"
                              id="exampleFormControlTextarea1"
                              value={projectDescription}
                              onChange={(Description) => {
                                setProjectDescription(Description.target.value);
                              }}
                              rows="9"
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Project Size
                            </label>
                            <div className="col-md-12">
                              <div className="row">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="flexRadioDefaulty"
                                    value="1"
                                    checked={size == 1 ? true:false}
                                    onChange={(size) => {
                                      setSize(size.target.value);
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexRadioDefaulty"
                                  >
                                    {"Short(< $500)"}
                                  </label>
                                </div>
                                <div className="form-check ml-3">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="flexRadioDefaulte"
                                    value="2"
                                    checked={size == 2 ? true:false}
                                    onChange={(size) => {
                                      setSize(size.target.value);
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexRadioDefaulte"
                                  >
                                    {"Small(< $1000)"}
                                  </label>
                                </div>
                                <div className="form-check ml-3">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="flexRadioDefaultw"
                                    value="3"
                                    checked={size == 3 ? true:false}
                                    onChange={(size) => {
                                      setSize(size.target.value);
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexRadioDefaultw"
                                  >
                                    {"Medium(< $3000)"}
                                  </label>
                                </div>
                                <div className="form-check ml-3">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="flexRadioDefaultq"
                                    value="4"
                                    checked={size == 4 ? true:false}
                                    onChange={(size) => {
                                      setSize(size.target.value);
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexRadioDefaultq"
                                  >
                                    {"Large(< $10000)"}
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="flexRadioDefaults"
                                    value="5"
                                    checked={size == 5 ? true:false}
                                    onChange={(size) => {
                                      setSize(size.target.value);
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexRadioDefaults"
                                  >
                                    {"Extra Large(< $10000)"}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

                  <div className="card-input mt-3 mb-5">
                    <div className="col-md-12 bottom-manage">
                      <div className="mt-3">
                        <h5 className="border-bottom pb-2">
                          Resource Management
                        </h5>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label>Developer Assigned To</label>
                          <MultiSelect
                            options={helperData?.developers ?? []}
                            value={developerAssigned}
                            onChange={(developers) => {
                              setDeveloperAssigned(developers);
                            }}
                            labelledBy="Select"
                          />
                        </div>
                        <div className="col-md-6">
                          <label>Manager Assigned To</label>
                          <MultiSelect
                            options={helperData?.managers || []}
                            value={managersAssigned}
                            onChange={(managers) => {
                              setManagersAssigned(managers);
                            }}
                            labelledBy="Select"
                            hasSelectAll={false}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

              </div>
              <div className="col-md-6">
                <div className="card-input mt-5">
                  <div className="col-md-12">
                    <div className="mt-3">
                      <h5 className="border-bottom pb-2">
                        Billing Information
                      </h5>
                    </div>
                    <form>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Type (Billing ,Fixed)
                            </label>
                            <Select
                              options={helperData?.billing_type}
                              placeholder="Please Select Billing Type"
                              value={type}
                              onChange={(type) => {
                                setType(type);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Billing Resources
                            </label>
                            <MultiSelect
                              options={helperData?.billing_sources ?? []}
                              value={billingResource}
                              onChange={(Resources) => {
                                setBillingResource(Resources);
                                console.log("Resources", billingResource);
                              }}
                              labelledBy="Select"
                            />
                            


                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="col-md-10">
                            <div className="row">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value="1"
                                  checked={isOffline?true:false}
                                  id="flexCheckDisabled1f"
                                  onChange={(Offline) => {
                                    setIsOffline(Offline.target.value);
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckDisabled1f"
                                >
                                  Is Offline
                                </label>
                              </div>
                              <div className="form-check ml-3">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value="1"
                                  checked={milestone?true:false}
                                  id="flexCheckDisabled1d"
                                  onChange={(Milestone) => {
                                    setMilestone(Milestone.target.value);
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckDisabled1d"
                                >
                                  Is Milestone
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 mt-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Estimated Time (Hours)
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={estimateTime}
                              onChange={(estimated) => {
                                setEstimateTime(estimated.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 mt-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Per Hours
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={perHours}
                              onChange={(hours) => {
                                setPerHours(hours.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Project Source
                            </label>
                            <Select
                              options={helperData?.sources}
                              value={sources}
                              placeholder="Please Select Project Source"
                              onChange={(Source) => {
                                setSource(Source);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Billing Company Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={companyName}
                              onChange={(cmpname) => {
                                setCompanyName(cmpname.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="card-input mt-3">
                      <div className="col-md-12">
                        <div className="mt-3">
                          <h5 className="border-bottom pb-2">
                            Information Files (.txt,.pem,.ppk)
                          </h5>
                        </div>
                        <form>
                          <div className="col-md-12">
                            <div className="">
                              <FileUploader
                                multiple={true}
                                handleChange={(e)=>handleChange(e)}
                                // handleChange={handleFileEvent}
                                name="file"
                                types={fileTypes}     

                                

                              />                          


                              {fileName?.length ? (
                               fileName.map((value, index) =>{
                                  return <p key={index}>
                                    {value}
                                  </p>
                                  }
                               ) 
                               ): (
                               <p>
                                  {"no files uploaded yet"}
                              </p>
                              )
                            }
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
            <div className="col-md-12">
              <div className="row mb-5">
                <div className="">
                  <button
                    type="button"
                    className="create-button"
                    onClick={validationProject}
                  >
                    {projectId ? 'Update':'Create'}
                  </button>
                </div>
                <div className="ml-2">
                  <button type="button" className="create-button1 btn-info"
                  
                  onClick={() => {
                      navigate("/manage-project");                          
                  }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddProject;
