import { useDispatch } from "react-redux";
import { manageTodoActionRequest } from "../redux/action/manageTodoAction";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import LoadingBox from "../components/LoadingBox";
import Modal from "react-modal";
import { VIEW_TODO, UPDATE_TODO, TODO_HELPER, CREATE_TODO, DELETE_TODO  } from "../service/apiUrl";
import { postApiCall } from "../service/PostApiCall";
import ReactPaginate from "react-paginate";
import Utils from "../utility/utility";
import { STRING } from "../constants/string";
import { Draggable } from "react-drag-reorder";

// const { sortableContainer, sortableElement, sortableHandle } = ReactSortableHoc
// const DraggableContainer = sortableContainer(({ children }) => children)
// const DraggableElement = sortableElement(({ children }) => children)
// const DraggableHandle = sortableHandle(({ children }) => children)

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
  },
};

const ManageTodo = (props) => {

  const [assignTo, setAssignTo] = useState({ value: "", label: "" });
  const [task, setTask] = useState("");  
  const [isCompleted, setIsCompleted] = useState(0);
  const [helperData, setHelperData] = useState([]);
  const [addModalIsOpen, setAddModalIsOpen] = React.useState(false);
  const [updateModalIsOpen, setUpdateModalIsOpen] = React.useState(false);
  const [deletModalIsOpen, setdeleteModalIsOpen] = React.useState(false);
  const [updateTodoInfo, setUpdateTodoInfo] = useState();
  const [deleteTodoInfo, setdeleteTodoInfo] = useState();
  const [update, setUpdate] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const addModel = () =>{
    setAddModalIsOpen(true);
  }

  const updateModel = (todo) =>{

    setUpdateTodoInfo(todo);
    setUpdateModalIsOpen(true);

    var viewPayload = {
      id: todo
}

  postApiCall(viewPayload, access_token, VIEW_TODO)
  .then((result) => {
    setTask(result?.task);
    setAssignTo({value: result?.assignTo, label: result?.assignedUser});
    setIsCompleted(result?.isCompleted);
  })
  .catch((error) => {
    console.error("TODO_VIEW", error);
  });




  }

  const closeModal = () => {
    setAddModalIsOpen(false);
    setUpdateModalIsOpen(false);

    setdeleteModalIsOpen(false);
    setdeleteTodoInfo("");
  };
  const deleteModel = (todo) => {
    setdeleteTodoInfo(todo);
    setdeleteModalIsOpen(true);
  };
  



  const DeleteTask = () => {
      const deleteTodoParams = {
        id: deleteTodoInfo,  
      };


      postApiCall(deleteTodoParams, access_token, DELETE_TODO).then(
        (response) => {
          Utils.toast("success", "To do task Deleted successfully!!!");
          setUpdate(!update);
          setdeleteModalIsOpen(false);
        }
      );
  };
    
  const dispatch = useDispatch();
  const signdata = useSelector((state) => state.signinReducers);
  const access_token = signdata?.data?.access_token;

  const manageTodo = useSelector((state) => state.manageTodoReducers);

  const parms = {
    access_token: access_token,
    pageSize: pageSize,
    page: currentPage,
  };

  useEffect(() => {
    dispatch(manageTodoActionRequest(parms), function(resp){
    }, function(err){
      console.log("Error:", err);
    });
  }, [update]);


  const [selectedIds, setSelectedIds] = useState([]);

  const handleCheckboxChange = (event, id) => {
    if (event.target.checked) {
      setSelectedIds([...selectedIds, id]);
    } else {
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
    }
  };


  // helper data
  useEffect(() => {
    postApiCall([], access_token, TODO_HELPER)
      .then((result) => {
        setHelperData(result);
      })
      .catch((error) => {
        console.error("TODO_HELPER", error);
      });
  }, []);


//  add task 
  const AddTask = () => {
    let message = "";
    if (Utils.isEmpty(task)) {
      message = STRING.ENTER_TASK;
    } else if (Utils.isEmpty(assignTo?.value)) {
      message = STRING.ADMIN;
    } 

    if (message) {
      Utils.toast("error", message);
      return false;
    } else {
      const addTodoParams = {
        task: task,
        assignTo: assignTo?.value,
        createdBy: signdata?.data?.id,

      };

      postApiCall(addTodoParams, access_token, CREATE_TODO).then(
        (response) => {
          Utils.toast("success", "Todo task created successfully!!!");
          closeModal();
          setUpdate(!update);
        }
      );
    }
  };

// update task

const UpdateTask = () => {
  let message = "";
  if (Utils.isEmpty(task)) {
    message = STRING.ENTER_TASK;
  } else if (Utils.isEmpty(assignTo?.value)) {
    message = STRING.ADMIN;
  } 

  if (message) {
    Utils.toast("error", message);
    return false;
  } else {
    const EditTodoParams = {
      id: updateTodoInfo,
      task: task,
      assignTo: assignTo?.value,
      createdBy: signdata?.data?.id,
      isCompleted:isCompleted
    };

    postApiCall(EditTodoParams, access_token, UPDATE_TODO).then(
      (response) => {
        Utils.toast("success", "Todo task updated successfully!!!");
        closeModal()
        setUpdate(!update);
      }
    );
  }
};



  const handlePageChange = (pageNumber) => {
    const page = pageNumber?.selected + 1;
    setCurrentPage(page);
    setUpdate(!update);
  };

  return (
    <>
      {manageTodo?.isLoading === true ? <LoadingBox /> : null}
      <div className={props?.data?.transtion === true ? "contents" : "content"}>
        <div className="content-top-spacer"></div>
        <div className="color-body todo">
          <div className="col-md-12">
            <div className="card-input mt-3">
              <div className="col-md-12 mb-3">
                <div className="mt-3 flex">
                  <h5 className="float-left mt-1" >To Do</h5>
                  <div className="float-right mb-3">
                  <button
                    type="button"
                    className="reset-button"
                    onClick={addModel}
                  >
                    Add Task
                  </button>
                  </div>
                </div>
                <div className="mt-3">
                  <div className="todo_header">
                      <div className="row d-flex w-100 m-0-auto th">
                          <div className="mw-5 grabbable site-color">
                            <span className="mt-2 mb-2 ">#</span>
                          </div>
                          <div className="mw-50 grabbable site-color">
                            <span className="mt-2 mb-2 ">Task</span>
                          </div>
                          <div className="mw-15 grabbable site-color">
                            <span className="mt-2 mb-2 ">Assigned To</span>
                          </div>
                          <div className="mw-15 grabbable site-color">
                            <span className="mt-2 mb-2 ">Is Completed</span>
                          </div>
                          <div className=" mw-15 grabbable site-color">
                            <span className="mt-2 mb-2 "> Manage</span>
                          </div>
                      </div>

                        {manageTodo?.data?.todo?.length ? (
                          <>
                                <Draggable>
                           
                            {manageTodo?.data?.todo?.map((value, index) => {

                             
                 


                              return (

                                <div key={index} className="row d-flex w-100 m-0-auto b-b-1"
                                >
                                  <div className="mw-5 grab_clone">
                                    <h6 className="mb-0">
                                      <span className="mt-3 d-flex">
                                        {(currentPage - 1) * pageSize +
                                          (index + 1)}
                                      </span>
                                    </h6>
                                  </div>
                                    <div className="mw-50 grab_clone">
                                      <div className="mt-3">
                                        <div className="ml-2">
                                          <h6 className="mb-0">
                                            {value?.task ?? ""}
                                            <span className="bg-red white fit-content ago rounded ml-3 ">
                                            <i className="fa fa-clock-o" aria-hidden="true"></i> {Utils.getTimeAgo(value?.createdAt)}{" "}
                                            </span>
                                            {" "}
                                            </h6>
                                        </div>
                                      </div>
                                    </div>
                                  <div className="mw-15 grab_clone">
                                      <div className="mt-3">
                                        <div className="ml-2">
                                          <h6 className="mb-0">
                                            <span className={`${Utils.getColorByIndex(value?.assignTo)} white fit-content ago rounded`}>
                                              {value?.assignedUser ?? ""}
                                            </span>
                                            </h6>
                                        </div>
                                      </div>
                                    </div>
                                  <div className="checkboxCol mw-15 grab_clone">
                                    <label className="container mt-3 mb-2">
                                      <input
                                        type="checkbox"
                                        checked={Number(value?.isCompleted)}
                                        readOnly={true}
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  </div>
                                    <div className="d-flex mt-3 mw-15 grab_clone">

                                      <div className="">
                                        <i
                                          className="fa fa-pencil site-color"
                                          aria-hidden="true"
                                          onClick={() => {
                                            updateModel(value?.id ?? "");
                                          }}
                                        ></i>
                                      </div>


                                      <div className="ml-3">
                                        <i
                                          className="site-color fa fa-trash"
                                          aria-hidden="true"
                                          onClick={() => {
                                            deleteModel(value?.id ?? "");
                                          }}
                                        ></i>
                                      </div>
                                    </div>
                                  

                                </div>
                               

                              );
                            })}
                              </Draggable>
                              


                            <div>
                              <div colSpan={6} className="text-center w-100">
                                <ReactPaginate
                                  previousLabel={"Previous"}
                                  nextLabel={"Next"}
                                  pageCount={manageTodo?.data?.total_pages}
                                  onPageChange={handlePageChange}
                                  containerClassName={"pagination"}
                                  activeClassName={"active"}
                                />
                              </div>
                            </div>
                          </>
                        ) : (
                          <div>
                            <div colSpan="4">No record found</div>
                          </div>
                        )}
                    
                    
                     {/* Add modal */}
                    <Modal
                      isOpen={addModalIsOpen}
                      ariaHideApp={false}
                      onRequestClose={closeModal}
                      style={customStyles}
                      contentLabel="Example Modal"
                    >
                      <div className="row">
                        <div className="col-md-6">
                          <h5 className="mb-0">Add Todo Task</h5>
                        </div>
                        <div className="col-md-6 text-right">
                          <i
                            className="fa fa-times curser-point"
                            aria-hidden="true"
                            onClick={closeModal}
                          ></i>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <form>
                          <div className="col-md-12 mt-3">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Task</label>
                                <textarea
                                  className="form-control"
                                  id="exampleFormControlTextarea1"
                                  onChange={(reason) => {
                                    setTask(reason.target.value);
                                  }}
                                  rows="4"
                                ></textarea>
                              </div>
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Assign to</label>
                                <Select
                                  options={helperData?.admins}
                                  onChange={(event) => {
                                    setAssignTo({
                                      value: event?.value,
                                      label: event?.label,
                                    });
                                  }}

                                  placeholder="Please Select"
                                />
                              </div>
                          </div>
                          <div className="col-md-12">
                            <div className="row mb-5 mt-4">
                              <div className="">
                                <button
                                  type="button"
                                  className="create-button"
                                  onClick={AddTask}
                                >
                                  Add
                                </button>
                              </div>
                              <div className="ml-2">
                                <button
                                  type="button"
                                  className="create-button1 btn-info"
                                  onClick={closeModal}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Modal>

                    {/* Update modal */}
                    <Modal
                      isOpen={updateModalIsOpen}
                      ariaHideApp={false}
                      onRequestClose={closeModal}
                      style={customStyles}
                      contentLabel="Example Modal"
                    >
                      <div className="row">
                        <div className="col-md-6">
                          <h5 className="mb-0">Update Todo Task</h5>
                        </div>
                        <div className="col-md-6 text-right">
                          <i
                            className="fa fa-times curser-point"
                            aria-hidden="true"
                            onClick={closeModal}
                          ></i>
                        </div>
                      </div>

                      <div className="col-md-12">
                      <form>
                        <div className="col-md-12 mt-3 todo">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">Task</label>
                              <textarea
                                className="form-control"
                                id="exampleFormControlTextarea1"
                                value={task}
                                onChange={(e) => {
                                  setTask(e.target.value);
                                }}
                                rows="4"
                              ></textarea>
                            </div>
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">Assign to</label>
                              <Select
                                options={helperData?.admins}
                                value={assignTo}
                                onChange={(event) => {
                                  setAssignTo({
                                    value: event?.value,
                                    label: event?.label,
                                  });
                                }}

                                placeholder="Please Select"
                              />
                            </div>

                            <div className="form-group">
                              <label>Is Completed</label>
                              <label className="container mt-3 mb-2">
                                <input
                                  type="checkbox"
                                  checked={Number(isCompleted)}
                                  onChange={(e) => setIsCompleted(Number(e.target.checked))}
                                />
                                <span className="checkmark outline-grey"></span>
                              </label>
                            </div>


                        </div>
                        <div className="mt-5 col-md-12">
                          <div className="row mb-5 mt-4">
                            <div className="">
                              <button
                                type="button"
                                className="create-button"
                                onClick={UpdateTask}
                              >
                                Update
                              </button>
                            </div>
                            <div className="ml-2">
                              <button
                                type="button"
                                className="create-button1 btn-info"
                                onClick={closeModal}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                        </form>
                      </div>
                    </Modal>

                    {/* delete modal */}
                    <Modal
                      isOpen={deletModalIsOpen}
                      ariaHideApp={false}
                      onRequestClose={closeModal}
                      style={customStyles}
                      contentLabel="Example Modal"
                    >
                      <div className="row">
                        <div className="col-md-6">
                          <h5 className="mb-0">Delete Task</h5>
                        </div>
                        <div className="col-md-6 text-right">
                          <i
                            className="fa fa-times curser-point"
                            aria-hidden="true"
                            onClick={closeModal}
                          ></i>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <p>
                            Are you sure you want to delete this task{" "}
                          </p>
                        </div>
                        <div className="col-md-12 mt-2">
                          <button
                            type="button"
                            className="reset-button"
                            onClick={DeleteTask}
                          >
                            Delete
                          </button>
                          <button
                            type="button"
                            className="button-default ml-2"
                            onClick={closeModal}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </Modal>

                    

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageTodo;
