
import { MANAGE_USER_REQUEST, MANAGE_USER_REQUEST_SUCCESS, MANAGE_USER_REQUEST_ERROR, } from "../actionType/type";

export const manageUserActionRequest = (access_token,filteremail) => {
    return {
        type : MANAGE_USER_REQUEST,
        params : access_token 
        
    }
}

export const manageUserActionSuccess = (result) => {
    return {
        type: MANAGE_USER_REQUEST_SUCCESS,
        payload: result,
        error: null,
    };
}

export const manageUserActionError = (error) => {
    return {
        type: MANAGE_USER_REQUEST_ERROR,
        error:error,
        payload:null
    }
}
