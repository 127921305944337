import {
    TIMELOG_REQUEST,
    TIMELOG_REQUEST_ERROR,
    TIMELOG_REQUEST_SUCCESS,
} from "../actionType/type";

const initialState = {
    data: null,
    isLoading: false,
    refreshPage: false
};

export const timelogReducer = (state = initialState, action) => {
    let object;
    switch (action.type) {
        case TIMELOG_REQUEST:
            object = {
                ...state,
                isLoading: true,
            }
            break
        case TIMELOG_REQUEST_ERROR:
            object = {
                ...state,
                isLoading: false
            }
            break
        case TIMELOG_REQUEST_SUCCESS:
            object = {
                ...state,
                data: action.payload,
                isLoading: false
            }
            break

        default:
            object = state
            break
    }

    return object
}