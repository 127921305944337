import {toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

const options = {
  position: "top-right",
  autoClose: 5000,
  theme:'light'
}
export const showToast = (type,messsage) => {
  switch (type) {
    case 'info':
      toast.info(messsage,options)
      break;
    case 'warn':
      toast.warn(messsage,options)
      break;
    case 'success':
      toast.success(messsage,options)
      break;
    case 'error':
      toast.error(messsage,options)
      break;
  
    default:
      toast("Some error occured, please check your intenet connection or try again later!")
      break;
  }
}