import { useEffect, useState } from "react";
import { logo } from "../assets";
import { useDispatch } from "react-redux";
import { signinRequest } from "../redux/action/signinAction";
import { useNavigate } from "react-router-dom";
import Utils from "../utility/utility";
import { STRING } from "../constants/string";

const Login = () => {
  const [enteremail, setEmail] = useState(1);
  const [password, setpassword] = useState(1);
  const [emaildata, setEmaildata] = useState("");
  const [passworddata, setPassworddata] = useState("");
  const [showpassword, setshowpassword] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formSubmit = (e) => {
    e.preventDefault();
    if (validateLoginFields()) {
      let payload = {
        username: emaildata,
        password: passworddata,
        showSuccessToast: true
      };
      dispatch(signinRequest(payload, onSuccess));
    }
  };

  const onSuccess = (response) => {
    navigate('/home');
  }

  const validateLoginFields = () => {
    let message = "";
    if (Utils.isEmpty(emaildata)) {
      message = STRING.ENTER_EMAIL;
    } else if (!Utils.validateEmail(emaildata)) {
      message = STRING.ENTER_VALID_EMAIL;
    } else if (Utils.isEmpty(passworddata)) {
      message = STRING.ENTER_PASSWORD;
    } else if (passworddata.length < 6) {
      message = STRING.ENTER_SIX_DIGIT_PASSWORD;
    }
    if (message) {
      Utils.toast("error", message);
      return false;
    } else {
      return true;
    }
  };
  return (
    <>
      <div className="">
        <div className="login-bg">
          <div className="card w-login">
            <div className="col-md-12 card-body">
              <div className="text-center">
                <img src={logo} className="rapid-logo" alt="" />
              </div>
              <div className="text-center mt-5">
                <h3 className="text-rapid">Hi, Welcome Back</h3>
              </div>
              <div className="text-center mt-3">
                <p className="text-bold">Sign in with Email address</p>
              </div>
              <form autoComplete="off" onSubmit={validateLoginFields}>
                <div className="col-md-12">
                  <div className={enteremail == 1 ? "card-dark" : "card-darks"}>
                    <div className="ml-3 mr-3 set-left">
                      <div className="form-group">
                        <label
                          htmlFor="exampleInputEmail1"
                          className={
                            enteremail == 1
                              ? "text-rapid p-email"
                              : "text-rapid enter-email mt-2"
                          }
                        >
                          Email Address / Username
                        </label>
                        <input
                          type="text"
                          className={
                            enteremail == 1
                              ? "form-control b-nones set-input-email"
                              : "form-control b-none"
                          }
                          placeholder=""
                          onClick={() => {
                            setEmail(0);
                          }}
                          onFocus={()=>setEmail(0)}
                          onChange={(email) => {
                            setEmaildata(email.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      password == 1 ? "card-dark mt-3" : "card-darks mt-3"
                    }
                  >
                    <div className="ml-3 mr-3 set-left">
                      <div className="form-group">
                        <label
                          htmlFor="exampleInputEmail1"
                          className={
                            password == 1
                              ? "text-rapid p-email mt-2"
                              : "text-rapid enter-email mt-2"
                          }
                        >
                          Password
                        </label>
                        <div
                          className="input-group input-password"
                          id="show_hide_password"
                        >
                          <input
                            className={
                              password == 1
                                ? "form-control b-nones set-input-email text-white"
                                : "form-control b-none"
                            }
                            type={showpassword === true ? "text" : "password"}
                            autoComplete="on"
                            onClick={() => {
                              setpassword(0);
                            }}
                            onFocus={()=>setpassword(0)}
                            onChange={(email) => {
                              setPassworddata(email.target.value);
                            }}
                          />
                          <div
                            className="input-group-addon"
                            onClick={() => {
                              setshowpassword((wasOpened) => !wasOpened);
                            }}
                          >
                            <i
                              className={
                                password == 1
                                  ? "d-none fa fa-eye-slash"
                                  : "current fa fa-eye-slash"
                              }
                              aria-hidden="true"
                            ></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="top-check">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="1"
                        id="keep_me_signIn"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="keep_me_signIn"
                      >
                        Keep me signin
                      </label>
                    </div>
                  </div>
                  <div className="text-cnter mt-4 mb-3">
                    <button
                      type="submit"
                      className="sign-in-btn w-100"
                      onClick={formSubmit}
                    >
                      Sign In
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
