import Navigator from './app/navigation/router'
import './App.css'
import { ToastContainer,  } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { persistor, store } from '../src/app/redux/store/store'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider,  } from 'react-redux'
import { useEffect,  } from 'react'
import RestrictedAccessPage from './app/components/restricted/RestrictedPage'
import useDeviceType from './app/utility/useDeviceType'
import { NotificationProvider } from './app/context/NotificationContext'

function App() {
  let deviceType = useDeviceType()

  useEffect(() => {
    const clearPageOnUrlChange = () => {
      localStorage.removeItem('currentPage')
      localStorage.removeItem('leavePagination')
    }
    window.addEventListener('beforeunload', clearPageOnUrlChange)
    return () => {
      window.removeEventListener('beforeunload', clearPageOnUrlChange)
    }
  }, [])

  useEffect(() => {
    if (deviceType === 'Mobile') {
      localStorage.clear()
      sessionStorage.clear()
    }
  }, [deviceType])

  if (deviceType === 'Mobile') {
    return <RestrictedAccessPage />
  }

  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <NotificationProvider>
          <Navigator />
          <ToastContainer />
          </NotificationProvider>
        </PersistGate>
      </Provider>
    </>
  )
}

export default App
