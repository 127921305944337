import { defaults } from "../assets";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { manageUserActionRequest } from "../redux/action/manageUserAction";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import LoadingBox from "../components/LoadingBox";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import { USERGET_DATA,USER_PROGRESS } from "../service/apiUrl";
import { postApiCall } from "../service/PostApiCall";
import ReactPaginate from "react-paginate";
import { Chart } from "react-google-charts";
import Utils from "../utility/utility";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
  },
};

const ManageUser = (props) => {
  let subtitle;
  const getInitialPage = () => {
    const savedPage = JSON.parse(localStorage.getItem('currentPage'));
    return savedPage !== null && !isNaN(savedPage.currentPage) ? parseInt(savedPage.currentPage, 10) : 1;
  };

  const filterByName = JSON.parse(localStorage.getItem("manageUser"))
  const filterByRoles = JSON.parse(localStorage.getItem("manageUserRoles"))
  
  const filterByStatus = JSON.parse(localStorage.getItem("manageUserStatus"))
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [deletModalIsOpen, setdeletModalIsOpen] = React.useState(false);
  const [progressModalIsOpen, setprogressModalIsOpen] = React.useState(false);
  const [nameEmailSearch, setnameEmailSearch] = useState();
  const [deleteUserInfo, setdeleteUserInfo] = useState();
  const [viewUserInfo, setviewUserInfo] = useState();
  const [helperData, setHelperData] = useState([]);
  const [chart, setChart] = useState();
  const [progressData, setProgressData] = useState({});

  const [status, setStatus] = useState(filterByStatus !=null ? filterByStatus : { value: "", label: "" });
  const [role, setRole] = useState(filterByRoles !=null ? filterByRoles : { value: "", label: "" });
  const [teamlead, setTeamlead] = useState({ value: "", label: "" });
  const [user, setUser] = useState(filterByName !=null ? filterByName: { value: "", label: "" });
  const [update, setUpdate] = useState(false);
  const [currentPage, setCurrentPage] = useState(getInitialPage);
  const [pageSize] = useState(10);

  const closeModal = () => {
    setIsOpen(false);
    setdeletModalIsOpen(false);
    setprogressModalIsOpen(false);
    setviewUserInfo("");
    setdeleteUserInfo("");
  };
  const viewModel = (user) => {
    setviewUserInfo(user);
    setIsOpen(true);
  };
  const deleteModel = (user) => {
    setdeleteUserInfo(user);
    setdeletModalIsOpen(true);
  };
  
  const userProgress = (user) => {
    setprogressModalIsOpen(true);
 
    const parms = {
      uid: user?.id??"",
      start_date:Utils.dateBeforeSixMonth(new Date(), 'YYYY-MM-YY'),
      end_date: Utils.momentDate(new Date(), 'YYYY-MM-YY'),
    };
    postApiCall(parms, signdata?.data?.access_token, USER_PROGRESS)
      .then((result) => {           
        setProgressData(result);
        const data = [
          ["Progress", "Billing status"],
          ["Offline", parseInt(result?.offlineHours)],
          ["Billable", parseInt(result?.billableHours)],
          ["Fixed", parseInt(result?.fixedHours)],
        ];        
      setChart(data);
      })
      .catch((error) => {
        console.error("USER_HELPER", error);
      });

  };

  const deleteUser = () => {};
  const dispatch = useDispatch();
  const signdata = useSelector((state) => state.signinReducers);
  const access_token = signdata?.data?.access_token;
  const manageUser = useSelector((state) => state.manageUserReducers);
  const parms = {
    access_token: access_token,
    // search: nameEmailSearch,//enabled in api
    user: user.value,
    role: role.value,
    team_lead: teamlead.value,
    status: status.value,
    pageSize: pageSize,
    page: currentPage,
  };
  useEffect(() => {
    dispatch(manageUserActionRequest(parms));
  }, [update]);

  useEffect(() => {
    postApiCall([], signdata?.data?.access_token, USERGET_DATA)
      .then((result) => {
        setHelperData(result);
      })
      .catch((error) => {
        console.error("USER_HELPER", error);
      });
  }, []);


  // useEffect(() => {

    

      

  // }, [progressUserInfo]);

  const options = {
    // title: "User progress report",
  };



  const handlePageChange = (pageNumber) => {
    const page = pageNumber?.selected + 1;
    localStorage.setItem('currentPage',JSON.stringify({type:"manage-user",currentPage:page}))
    setCurrentPage(page);
    setUpdate(!update);
  };

  return (
    <>
      {manageUser?.isLoading === true ? <LoadingBox /> : null}
      <div className={props?.data?.transtion === true ? "contents" : "content"}>
        <div className="content-top-spacer"></div>
        <div className="color-body">
          <div className="col-md-12">
            <div className="card-input mt-3">
              <div className="col-md-12 mb-3">
                <div className="mt-3">
                  <h5>Users</h5>
                </div>
                <div className="mt-3">
                  <div className="">
                    <table className="table table-sm">
                      <colgroup>
                        <col width="5%" />
                        <col width="30%" />
                        <col width="20%" />
                        <col width="15%" />
                        <col width="15%" />
                        <col width="15%" />
                      </colgroup>
                      <thead>
                        <tr>
                          <th scope="col" className="site-color">
                            <span className="mt-2 mb-2 d-flex">S.No</span>
                          </th>
                          <th scope="col" className="site-color">
                            <span className="mt-2 mb-2 d-flex">Name</span>
                          </th>
                          <th scope="col" className="site-color">
                            <span className="mt-2 mb-2 d-flex">
                              Team Leader
                            </span>
                          </th>
                          <th scope="col" className="site-color">
                            <span className="mt-2 mb-2 d-flex">Role Name</span>
                          </th>
                          <th scope="col" className="site-color">
                            <span className="mt-2 mb-2 d-flex">Status</span>
                          </th>
                          <th scope="col" className="site-color">
                            <span className="mt-2 mb-2 d-flex"> Manage</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td></td>
                          <td>
                            <Select
                              options={helperData?.all_users}
                              className={"mt-2 mb-2"}
                              isSearchable={true}
                              isClearable={true}
                              value={user}
                              styles={{
                                menu: (base) => ({
                                  ...base,
                                  width: "max-content",
                                  minWidth: "100%",
                                }),
                              }}
                              onChange={(event) => {
                                if(event !=null){
                                  localStorage.removeItem("currentPage");
                                  setCurrentPage(1)
                                  localStorage.setItem("manageUser",JSON.stringify(event))
                                }else{
                                  localStorage.removeItem("manageUser")
                                }
                                setUser({
                                  value: event?.value,
                                  label: event?.label,
                                });
                                setUpdate(!update);
                              }}
                            />
                          </td>

                          <td>
                            <Select
                              options={helperData?.team_lead}
                              className={"mt-2 mb-2"}
                              isSearchable={true}
                              isClearable={true}
                              value={teamlead}
                              styles={{
                                menu: (base) => ({
                                  ...base,
                                  width: "max-content",
                                  minWidth: "100%",
                                }),
                              }}
                              onChange={(event) => {
                                setTeamlead({
                                  value: event?.value,
                                  label: event?.label,
                                });
                                setUpdate(!update);
                              }}
                            />
                          </td>
                          <td>
                            <Select
                              options={helperData?.roles}
                              className={"mt-2 mb-2"}
                              isSearchable={true}
                              isClearable={true}
                              value={role}
                              styles={{
                                menu: (base) => ({
                                  ...base,
                                  width: "max-content",
                                  minWidth: "100%",
                                }),
                              }}
                              onChange={(event) => {
                                if(event !=null){
                                  localStorage.removeItem("currentPage");
                                  setCurrentPage(1)
                                  localStorage.setItem("manageUserRoles",JSON.stringify(event))
                                }else{
                                  localStorage.removeItem("manageUserRoles")
                                }
                                setRole({
                                  value: event?.value,
                                  label: event?.label,
                                });
                                setUpdate(!update);
                              }}
                            />
                          </td>
                          <td>
                            <Select
                              options={helperData?.status}
                              className={"mt-2 mb-2"}
                              isSearchable={true}
                              isClearable={true}
                              value={status}
                              styles={{
                                
                                menu: (base) => ({
                                  ...base,
                                  width: "max-content",
                                  minWidth: "100%",
                                }),
                              }}
                              onChange={(event) => {
                                if(event !=null){
                                  localStorage.removeItem("currentPage");
                                  setCurrentPage(1)
                                  localStorage.setItem("manageUserStatus",JSON.stringify(event))
                                }else{
                                  localStorage.removeItem("manageUserStatus")
                                }
                                setStatus({
                                  value: event?.value,
                                  label: event?.label,
                                });
                                setUpdate(!update);
                              }}
                            />
                          </td>
                        </tr>
                        {manageUser?.data?.users?.length ? (
                          <>
                            {manageUser?.data?.users?.map((user, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    <span
                                      className="mt-4 d-flex mr-4"
                                    >
                                      {(currentPage - 1) * pageSize +
                                        (index + 1)}
                                    </span>
                                  </td>
                                  <td>
                                    <div className="col-md-12">
                                      <div className="row mt-3">
                                        <div className="image-height">
                                     
                                          {/* <img
                                            src={user?.profile_pic}
                                            className="default-width"
                                            alt=""
                                          /> */}
                                          <i className="fa fa-user-circle" aria-hidden="true" style={{fontSize:40}}></i>
                                        </div>
                                        <div className="ml-2">
                                          <h6 className="mb-0">
                                            {user?.first_name}
                                          </h6>
                                          <p>{user.email}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <span className="mt-4 d-flex">
                                      {user?.team_lead}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="mt-4 d-flex">
                                      {user?.role}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="mt-4 d-flex">
                                      {user?.status}
                                    </span>
                                  </td>
                                  <td>
                                    <div className="d-flex mt-4">
                                      <div className="mr-3">
                                        <i
                                          className="fa fa-eye site-color"
                                          aria-hidden="true"
                                          onClick={() => {
                                            viewModel(user);
                                          }}
                                        ></i>
                                      </div>
                                      <Link to={"/edit-user/" + user?.id}>
                                        <div className="">
                                          <i
                                            className="fa fa-pencil site-color"
                                            aria-hidden="true"
                                          ></i>
                                        </div>
                                      </Link>
                                      <div className="ml-3">
                                        <i
                                          className="site-color fa fa-trash"
                                          aria-hidden="true"
                                          onClick={() => {
                                            deleteModel(user);
                                          }}
                                        ></i>
                                      </div>
                                      <div className="ml-3">
                                        <i
                                          className="fa fa-pie-chart site-color"
                                          aria-hidden="true"
                                          onClick={() => {
                                            userProgress(user);
                                          }}
                                        ></i>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}

                            <tr>
                              <td colSpan={6} className="text-center w-100">
                                <ReactPaginate
                                  previousLabel={"Previous"}
                                  nextLabel={"Next"}
                                  pageCount={manageUser?.data?.total_pages}
                                  onPageChange={handlePageChange}
                                  containerClassName={"pagination"}
                                forcePage={currentPage - 1}
                                  activeClassName={"active"}
                                />
                              </td>
                            </tr>
                          </>
                        ) : (
                          <tr>
                            <td colSpan="6">No record found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <Modal
                      isOpen={modalIsOpen}
                      onRequestClose={closeModal}
                      ariaHideApp={false}
                      style={customStyles}
                      contentLabel="Example Modal"
                    >
                      <div className="col-md-12">
                        <div className="text-right">
                          <i
                            className="fa fa-times curser-point"
                            aria-hidden="true"
                            onClick={closeModal}
                          ></i>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <p className="mb-0">User Information</p>
                            <div className="border-bottom mt-2 mb-2"></div>
                          </div>
                          <div className="col-md-12">
                            <div className="row">
                              <div className="col-md-6">
                                <b>Name</b>
                                <br />
                                <span>{viewUserInfo?.first_name}</span>
                              </div>
                              <div className="col-md-6">
                                <b>Email</b>
                                <br />
                                <span>{viewUserInfo?.email}</span>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <b>Departments</b>
                                <br />
                                <span>{viewUserInfo?.department}</span>
                              </div>
                              <div className="col-md-6">
                                <b>Shift</b>
                                <br />
                                <span>Day</span>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <b>Contact</b>
                                <br />
                                <span>{viewUserInfo?.contact}</span>
                              </div>
                              <div className="col-md-6">
                                <b>Status</b>
                                <br />
                                <span>{viewUserInfo?.status}</span>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <b>Roles</b>
                                <br />
                                <span>{viewUserInfo?.role}</span>
                              </div>
                              <div className="col-md-6">
                                <b>Team Lead</b>
                                <br />
                                <span>{viewUserInfo?.team_lead}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal>

                    <Modal
                      isOpen={deletModalIsOpen}
                      ariaHideApp={false}
                      onRequestClose={closeModal}
                      style={customStyles}
                      contentLabel="Example Modal"
                    >
                      <div className="row">
                        <div className="col-md-6">
                          <h5 className="mb-0">Delete User</h5>
                        </div>
                        <div className="col-md-6 text-right">
                          <i
                            className="fa fa-times curser-point"
                            aria-hidden="true"
                            onClick={closeModal}
                          ></i>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <p>
                            Are you sure you want to delete the user{" "}
                            <b>{deleteUserInfo?.first_name} </b>(
                            {deleteUserInfo?.email}) ?
                          </p>
                        </div>
                        <div className="col-md-12 mt-2">
                          <button
                            type="button"
                            className="reset-button"
                            onClick={deleteUser}
                          >
                            Delete
                          </button>
                          <button
                            type="button"
                            className="button-default ml-2"
                            onClick={closeModal}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </Modal>

                    <Modal
                      isOpen={progressModalIsOpen}
                      ariaHideApp={false}
                      onRequestClose={closeModal}
                      style={customStyles}
                      contentLabel="Example Modal"
                    >
                      
                      <div className="row">
                        <div className="col-md-12">

                        {progressData?.totalWorkingHours > 0? (  
                            <>
                          
                          <div className="row">
                            <div className="col-md-6">
                              <h5 className="mb-0">User progress from last six months</h5>
                            </div>
                            <div className="col-md-6 text-right">
                              <i
                                className="fa fa-times curser-point"
                                aria-hidden="true"
                                onClick={closeModal}
                              ></i>
                            </div>
                          </div>
                          
                          
                            <Chart
                            chartType="PieChart"
                            data={chart}
                            options={options}
                            width={"100%"}
                            height={"400px"}
                          />
                          <p className="bold">Total Working Hours: {progressData?.totalWorkingHours}</p>
                          <p className="bold">Billable Hours: {progressData?.billableHours}</p>
                          <p className="bold">Offline Hours: {progressData?.offlineHours}</p>
                          <p className="bold">Fixed Hours: {progressData?.fixedHours}</p>
                          </>
                          ): (
                            <p className="bold">No record found</p>
                          )}
                          
                        </div>
                      </div>
                    </Modal>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageUser;
