import { TIMELOG_REQUEST, 
    TIMELOG_REQUEST_ERROR, 
    TIMELOG_REQUEST_SUCCESS,
 } from "../actionType/type";


export const timelogActionRequest = (params,access_token,role=1) => {
    return {
        type :  TIMELOG_REQUEST,
        params : params,
        access_token : access_token,
        role : role
    }
}

export const timelogActionSuccess = (result) => {
    return {
        type: TIMELOG_REQUEST_SUCCESS,
        payload: result,
        error: null,
    };
}

export const timelogActionError = (error) => {
    return {
        type: TIMELOG_REQUEST_ERROR,
        error:error,
        payload:null
    }
}

