import { INVENTORY_LIST_REQUEST,INVENTORY_LIST_REQUEST_ERROR,INVENTORY_LIST_REQUEST_SUCCESS } from "../actionType/type";

const initialState = {
    data: null,
    isLoading: false,
    refreshPage:false
};

export const inventoryReducers = (state=initialState,action) => {
    let object;
    switch (action.type) {
        case INVENTORY_LIST_REQUEST:
            object = {
                ...state,
                isLoading: true,
              }
            break
        case INVENTORY_LIST_REQUEST_ERROR:
            object = {
                ...state,
                isLoading:false
            }
            break
        case INVENTORY_LIST_REQUEST_SUCCESS:
            object = {
                ...state,
                data:action.payload,
                isLoading:false
            }
            break
    
        default:
            object = state
            break
    }
    
    return object
}