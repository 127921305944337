import { MANAGE_CLICKUP_REQUEST, MANAGE_CLICKUP_REQUEST_ERROR, MANAGE_CLICKUP_REQUEST_SUCCESS,FORM_REFRESH } from "../actionType/type";

const initialState = {
    data: null,
    isLoading: false,
    refreshPage:false
};

export const manageClickupReducers = (state=initialState,action) => {
    let object;
    switch (action.type) {
        case MANAGE_CLICKUP_REQUEST:
            object = {
                ...state,
                isLoading: true,
              }
            break
        case MANAGE_CLICKUP_REQUEST_ERROR:
            object = {
                ...state,
                isLoading:false
            }
            break
        case MANAGE_CLICKUP_REQUEST_SUCCESS:
            object = {
                ...state,
                data:action.payload,
                isLoading:false
            }
            break
        case FORM_REFRESH:
            object = {
                ...state,
                refreshPage:!state.refreshPage
            }
            break
    
        default:
            object = state
            break
    }
    
    return object
}