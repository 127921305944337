import {useState} from 'react';
import './assets/css/style.css';

const ImageWithSkeleton = ({src, alt, width, height}) => {
  const [isLoaded, setIsLoaded] = useState (false);

  const handleImageLoad = () => {
    setIsLoaded (true);
  };

  return (
    <div className="image-container" style={{width: width, height: height}}>
      {!isLoaded && <div className="skeleton" />}
      <img
        src={src}
        alt={alt}
        className="gallery-image"
        onLoad={handleImageLoad}
        style={{display: isLoaded ? 'block' : 'none'}}
      />
    </div>
  );
};

export default ImageWithSkeleton;
