import React, { useEffect, useState } from "react";
import "./assets/style.css";
import { Form} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Utils from "../../utility/utility";
import { Loader } from "rsuite";
import axiosInstance from "../../api/DropboxApi";

const CreateFolderForm = (props) => {
  const [title, setTitle] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setLoading] = useState(false);
  let navigate = useNavigate();

  // const createFolder = async (path) => {
  //   try {
  //     setLoading(true);
  //     const result = await axiosInstance.post(
  //       "https://api.dropboxapi.com/2/files/create_folder_v2",
  //       {
  //         path: `/${title}`,
  //         autorename: false,
  //       }
  //     );
  //     if (result.status === 200) {
  //       Utils.toast("success", `${title} Folder created successful.`);
  //       setTitle("");
  //       setLoading(false);
  //       navigate("/gallery");
  //       setError("");
  //     }
  //   } catch (err) {
  //     setLoading(false);
  //     if (err?.response?.status === 409) {
  //       Utils.toast("error", "Folder already exists.");
  //       setLoading(false);
  //     }
  //   }
  // };

  const createFolder = async (path) => {
    try {
      setLoading(true);
      const result = await axiosInstance.post(
        "https://api.dropboxapi.com/2/files/create_folder_v2",
        {
          path: `/${title}`,
          autorename: false,
        }
      );
  
      if (result.status === 200) {
   
        Utils.toast("success", `${title} Folder created successful.`);
        const createSharedLinkUrl = 'https://api.dropboxapi.com/2/sharing/create_shared_link_with_settings';
      const linkData = {
        path: result?.data?.metadata?.path_display,
        settings: {
          audience: 'public', // Can be 'public' or 'team' for broader sharing
          access: 'viewer', // Can be 'viewer' or 'editor'
        },
      };

      const response = await axiosInstance.post(createSharedLinkUrl, linkData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log("sharedFolder:==> ",response)

        setTitle("");
        setLoading(false);
        navigate("/gallery");
        setError("");
      }
    } catch (err) {
      setLoading(false);
      if (err?.response?.status === 409) {
        Utils.toast("error", "Folder already exists.");
        setLoading(false);
      }
    }
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    if (title.trim() === "") {
      setError("Folder is not allowed to be empty.");
      return;
    }
    createFolder();
  };

  useEffect(()=>{
    let urlParams = new URLSearchParams(window.location.search);
    const authorizationCode = urlParams.get("code");
    if(authorizationCode){
      exchangeCodeForAccessToken(authorizationCode)
    }
    console.log("no code exist.")
  },[])

  const exchangeCodeForAccessToken = async (code) => {
    try {
      const authHeader = `Basic ${btoa(`${process.env.REACT_APP_DROPBOX_CLIENT_KEY}:${process.env.REACT_APP_DROPBOX_CLIENT_SECRECT}`)}`;
      const response = await axiosInstance.post(
        "https://api.dropboxapi.com/oauth2/token",
        new URLSearchParams({
          code,
          grant_type: "authorization_code",
          redirect_uri: process.env.REACT_APP_DROPBOX_REDIRECT_URL_DEV,
        }).toString(),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: authHeader
          },
        }
      );
     
      const { access_token } = response.data;
    
      localStorage.setItem("dropboxAccessToken", access_token);
      Utils.toast('success','Dropbox Token Successfully Generated.')
    } catch (err) {
      console.error("Error exchanging code for access token:", err);
      Utils.toast("error",err?.response?.data?.error_description
      )
    }
  };

  return (
    <div className={props?.data?.transition === true ? "contents" : "content"}>
      <div className="color-body">
        <div className="col-md-6 manage">
          <div className="card-input mt-5 mb-5">
            <div className="col-md-12">
              <div className="mt-3">
             
                <h5 className="border-bottom pb-2">Create Folder</h5>
                <a href={`https://www.dropbox.com/oauth2/authorize?token_access_type=offline&client_id=${process.env.REACT_APP_DROPBOX_CLIENT_KEY}&response_type=code&redirect_uri=${process.env.REACT_APP_DROPBOX_REDIRECT_URL_DEV}`} target="_blank" rel="noreferrer">Generate Dropbox token</a>
              </div>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="folderTitle" className="mt-3">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    value={title}
                    placeholder="Please enter your title..."
                    onChange={(e) => setTitle(e.target.value)}
                    isInvalid={!!error}
                  />
                  <Form.Control.Feedback type="invalid">
                    {error}
                  </Form.Control.Feedback>
                </Form.Group>
                <button
                  disabled={isLoading === true ? true : false}
                  type="submit"
                  className="mt-3 mb-5 create-button"
                >
                  {isLoading ? (
                    <span>
                      <Loader /> Creating...
                    </span>
                  ) : (
                    "Create Folder"
                  )}
                </button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateFolderForm;
