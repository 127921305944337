import { put, call, takeLatest } from "redux-saga/effects";
import { MANAGE_CLICKUP_REQUEST } from "../actionType/type";
import { MANAGE_CLICKUP_ACCOUNT } from "../../service/apiUrl";
import { postApiCall } from "../../service/PostApiCall";
import { manageClickupActionError, manageClickupActionSuccess } from "../action/manageClickAction";

function* manageClickupSaga(action){
    try {
        const response = yield call(postApiCall,action?.params,action?.params?.access_token,MANAGE_CLICKUP_ACCOUNT)
        yield put(manageClickupActionSuccess(response));
        // action.onSuccess(response)
    } catch (error) {
        yield put(manageClickupActionError(error));
    }
}

export function* watchManageClickupSaga(){
    yield takeLatest(MANAGE_CLICKUP_REQUEST,manageClickupSaga)
}