
import { MANAGE_TODO_REQUEST, MANAGE_TODO_REQUEST_SUCCESS, MANAGE_TODO_REQUEST_ERROR, } from "../actionType/type";

export const manageTodoActionRequest = (access_token,filteremail) => {    
    return {
        type : MANAGE_TODO_REQUEST,
        params : access_token         
    }
}

export const manageTodoActionSuccess = (result) => {
    return {
        type: MANAGE_TODO_REQUEST_SUCCESS,
        payload: result,
        error: null,
    };
}

export const manageTodoActionError = (error) => {
    return {
        type: MANAGE_TODO_REQUEST_ERROR,
        error:error,
        payload:null
    }
}
