// import React, { Component } from "react";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import Utils from "../utility/utility";
import { STRING } from "../constants/string";
import { useNavigate, useParams } from "react-router-dom";
import {
  ANOUNCEMENT_HELPER,
  VIEW_ANNOUNCEMENT,
  ANNOUNCEMENT_CREATE,
  ANNOUNCEMENTS_UPDATES,
} from "../service/apiUrl";
import { postApiCall } from "../service/PostApiCall";

const AddAnouncements = (props) => {
  const [startDate, setStartDate] = useState(new Date());
  const [unpublishDate, setUnpublishDate] = useState(new Date());
  const dateformats = Utils.momentDate(startDate, STRING.SLASH_DATE_FORMAT);
  const dateformat1 = Utils.momentDate(unpublishDate, STRING.SLASH_DATE_FORMAT);
  const [Title, setTitle] = useState("");
  const [OptionSelect, setOptionSelect] = useState("");
  const [Description, setDescription] = useState("");
  const [helperData, setHelperData] = useState([]);

  const announcement = useParams();
  const navigate = useNavigate();

  const signdata = useSelector((state) => state.signinReducers);
  const user_id = signdata?.data?.id;
  const access_token = signdata?.data?.access_token;

  useEffect(() => {
    postApiCall([], access_token, ANOUNCEMENT_HELPER)
      .then((result) => {
        setHelperData(result);
      })
      .catch((error) => {
        console.error("PROJECT_HELPER", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateannouncement = () => {
    let message = "";
    if (Utils.isEmpty(Title)) {
      message = STRING.TITLE;
    } else if (Utils.isEmpty(OptionSelect)) {
      message = STRING.STATUS;
    } else if (Utils.isEmpty(startDate)) {
      message = STRING.DATE;
    } else if (Utils.isEmpty(Description)) {
      message = STRING.DESCRIPTION;
    }
    return message;
  };

  const createAnouncement = () => {
    const message = validateannouncement();
    if (message) {
      Utils.toast("error", message);
      return false;
    } else {
      const objdata = {
        title: Title,
        status: OptionSelect?.value,
        publish_date: dateformats,
        unpublish_date: dateformat1,
        description: Description,
        updated_by: user_id,
        showSuccessToast: true
      };
      postApiCall(objdata, access_token, ANNOUNCEMENT_CREATE).then(
        (response) => {
          navigate("/annoucement-listing");
        }
      );
      return true;
    }
  };

  useEffect(() => {
    if (announcement && announcement?.announcement_id) {
      const parms = {
        access_token: access_token,
        id: announcement?.announcement_id,
      };
      postApiCall(parms, access_token, VIEW_ANNOUNCEMENT).then((result) => {
        setTitle(result?.title);
        setStartDate(Utils.getDate(result?.publish_date));
        setUnpublishDate(Utils.getDate(result?.unpublish_date));
        setDescription(result?.description);
        setOptionSelect(result?.status);
      });
    }
  }, [announcement]);

  const updateannouncement = () => {
    const message = validateannouncement();
    if (message) {
      Utils.toast("error", message);
      return false;
    } else {
      const updateobj = {
        id: announcement?.announcement_id,
        title: Title,
        status: OptionSelect?.value,
        publish_date: dateformats,
        unpublish_date: dateformat1,
        description: Description,
        updated_by: user_id,
      };
      postApiCall(
        updateobj,
        access_token,
        ANNOUNCEMENTS_UPDATES,
        navigate
      ).then((response) => {
        Utils.toast("success", "Announcement updated successfully");
        navigate("/annoucement-listing");
      });
      return true;
    }
  };

  return (
    <>
      <div className={props?.data?.transtion == true ? "contents" : "content"}>
        <div className="content-top-spacer"></div>
        <div className="color-body">
          <div className="col-md-6 manage">
            <div className="card-input mt-5 mb-2">
              <div className="col-md-12">
                <div className="mt-3">
                  <h5 className="border-bottom pb-2">Create Announcements</h5>
                </div>
                <form>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Title</label>
                        <input
                          type="text"
                          className="form-control"
                          value={Title}
                          onChange={(title) => {
                            setTitle(title.target.value);
                          }}
                          // placeholder="Enter Project Owner"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Status</label>
                        <Select
                          options={helperData?.status}
                          placeholder="Please Select Status"
                          value={OptionSelect}
                          onChange={(status) => {
                            setOptionSelect({
                              label: status?.label,
                              value: status?.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Publish Date</label>
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          className="width-dateset w-100"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          UnPublish Date
                        </label>
                        <DatePicker
                          selected={unpublishDate}
                          onChange={(date) => setUnpublishDate(date)}
                          className="width-dateset w-100"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Description</label>
                        <CKEditor
                          data={Description}
                          editor={ClassicEditor}
                          config={{
                            toolbar: [
                              "heading",
                              "|",
                              "bold",
                              "italic",
                              "blockQuote",
                              "link",
                              "numberedList",
                              "bulletedList",
                              "imageUpload",
                              "insertTable",
                              "tableColumn",
                              "tableRow",
                              "mergeTableCells",
                              "mediaEmbed",
                              "|",
                              "undo",
                              "redo",
                            ],
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setDescription(data);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="row mb-5 mt-4">
                      <div className="">
                        {announcement && announcement?.announcement_id ? (
                          <button
                            type="button"
                            className="create-button"
                            onClick={updateannouncement}
                          >
                            Update
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="create-button"
                            onClick={createAnouncement}
                          >
                            Create
                          </button>
                        )}
                      </div>
                      <div className="ml-2">
                        <button
                          type="button"
                          className="create-button1 btn-info"
                          onClick={() => {
                            navigate("/annoucement-listing");                          
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddAnouncements;
