import { SATURDAY_LIST_REQUEST, SATURDAY_LIST_REQUEST_ERROR, SATURDAY_LIST_REQUEST_SUCCESS } from "../actionType/type";

const initialState = {
    data: null,
    isLoading: false,
    refreshPage: false
};

export const saturdayListReducer = (state = initialState, action) => {
    let object;
    switch (action.type) {
        case SATURDAY_LIST_REQUEST:
            object = {
                ...state,
                isLoading: true,
            }
            break
        case SATURDAY_LIST_REQUEST_ERROR:

            object = {
                ...state,
                isLoading: false
            }
            break
        case SATURDAY_LIST_REQUEST_SUCCESS:

            object = {
                ...state,
                data: action.payload?.result,
                isLoading: false
            }
            break

        default:
            object = state
            break
    }

    return object
}