
import { ANNOUNCEMENT_REQUEST, ANNOUNCEMENT_REQUEST_SUCCESS, ANNOUNCEMENT_REQUEST_ERROR,FORM_REFRESH } from "../actionType/type";

export const manageAnnouncementActionRequest = (access_token) => {
    return {
        type :  ANNOUNCEMENT_REQUEST,
        params : access_token 
        
    }
}

export const manageAnnouncementActionSuccess = (result) => {

    return {
        type: ANNOUNCEMENT_REQUEST_SUCCESS,
        payload: result,
        error: null,
    };
}
export const manageAnnouncementActionError = (error) => {
    return {
        type: ANNOUNCEMENT_REQUEST_ERROR,
        error:error,
        payload:null
    }
}
export const actionRefreshForm = (msg) => {
    return {
        type: FORM_REFRESH,
        error:null,
        payload:null,
        message:msg
    }
}
