import { put, call, takeLatest } from "redux-saga/effects";
import { manageAnnouncementActionSuccess, manageAnnouncementActionError } from "../action/manageAnnouncementAction";
import { ANNOUNCEMENT_REQUEST } from "../actionType/type";
import { MANAGE_ANNOUNCEMENTS } from "../../service/apiUrl";
import { postApiCall } from "../../service/PostApiCall";


function* announcementSaga(action){
    try {
        const response = yield call(postApiCall,action?.params,action?.params?.access_token,MANAGE_ANNOUNCEMENTS)
        yield put(manageAnnouncementActionSuccess(response));
        // action.onSuccess(response)
    } catch (error) {
        yield put(manageAnnouncementActionError(error));
    }
}

export function* watchAnnouncementSaga(){
    yield takeLatest(ANNOUNCEMENT_REQUEST,announcementSaga)
}