import moment from "moment";
import { STRING } from "../constants/string";
import { showToast } from "./toast";
import {saveAs} from "file-saver";

class Utils {
    static rootFolder = 'Programming'
    
    static BILLING_STATUS_OFFLINE = '0';

    static validateEmail = (email) => {
        var pattern = new RegExp(
            /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        );
        return pattern.test(email);
    };

    static passCheck = (password) => {
        const upperCase = new RegExp("(?=.*[A-Z])");
        const lowerCase = new RegExp("(?=.*[a-z])");
        const number = new RegExp("(?=.*[0-9])");
        const eightChar = new RegExp("(?=.{8,})");
        if (
            eightChar.test(password) &&
            upperCase.test(password) &&
            lowerCase.test(password) &&
            number.test(password)
        )
            return true;
        else return false;
    };

    static setRoot = (root = 'NcFit Admin Panel') => this.rootFolder = root

    static getDate = (date = new Date()) => new Date(date)

    static getCurrentDate = () => {
        var today = new Date();
        return today.getDate() + ' ' + today.toLocaleString('default', { month: 'long' }) + ', ' + today.getFullYear();
    }

    static momentYesterdayDate = () => {  
        var yesterday = new Date();                  
        yesterday.setDate(yesterday.getDate() - 1);
        return yesterday;
    }

    // static getYesterdayDay = () => moment().add(-1, 'days').format("dddd")

    static numberFilter = (e) => {
        if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
        }
    }

    static getWeekStartAndEnd = (weekDate = this.getDate()) => {
        var curr = weekDate;
        var day = curr.getDay(),
            diff = curr.getDate() - day + (day == 0 ? -6 : 1);
        var f_date = new Date(curr.setDate(diff))
        var l_date = new Date(curr.setDate(curr.getDate() + 6))
        return { weekStart: f_date, weekEnd: l_date }
    }

    static getWeekDates = (startDate) => {
        const dateArray = []
        for (let i = 0; i < 7; i++) {
            dateArray.push(moment(startDate).add(i, 'days').format('MM/DD/YY'))
        }
        return dateArray
    }

    static htmlData = (str = '') => {
        var tmp = document.createElement("DIV");
        tmp.innerHTML = str;
        return tmp.textContent || tmp.innerText || "";
    }

    static fromDateTimeStamp = (date)=>{
        var date = date.toDateString()
        var timestamp = Math.floor(new Date(date).getTime() / 1000);
        return timestamp
    }

    static toDateTimeStamp = (date)=>{
        var date = moment(date).add(1, 'd')._d;
        var date = date.toDateString();
        var timestamp = Math.floor(new Date(date).getTime() / 1000);
        return timestamp-1
    }

    static getRoot = () => this.rootFolder

    static setTitle = (title = 'React App') => document.title = title

    static getTitle = () => document.title

    static moment = () => moment()

    static momentDate = (date = this.getDate(), format) => moment(date).format(format)

    static momentDateFromTimestamp = (format, timestamp = this.timeStamp) => moment.unix(timestamp).format(format)

    static momentDateAddDay = (date = this.getDate(), days) => moment(date).add(days, 'days')

    static momentSubtract = (number,entity="months",format="MMMM") => moment().subtract(number, entity).format(format);

    static momentTimeDiff = (startTime) => {
        var endDate = new moment(this.getDate())
        var startDate = moment.unix(startTime)
        var duration = moment.duration(endDate.diff(startDate));
        var hours = duration.hours().toString().padStart(2,'0');
        var minutes = duration.minutes().toString().padStart(2,'0');
        var seconds = duration.seconds().toString().padStart(2,'0');
        return {
            hours:hours,
            minutes:minutes,
            seconds:seconds,
        }
    }

    static momentHoursDiff = (startTime, endTime) => {
        var startDate = moment.unix(startTime)
        var endDate = moment.unix(endTime)
        var duration = moment.duration(endDate.diff(startDate));
        var hours = duration.hours().toString().padStart(2,'0');
        var minutes = duration.minutes().toString().padStart(2,'0');
        var seconds = duration.seconds().toString().padStart(2,'0');
        return(hours +':'+ minutes +':'+ seconds +' Hours')
    }

    

    static dateBeforeSixMonth = (start = this.getDate(), format) => {
        return(moment(start).subtract(6, 'months').format(format))
    }
   


    static momentHours = (time) => moment(time, ["H"]).hours();

    static momentTimeFromTimestamp = (format, timestamp = this.timeStamp) => moment.unix(timestamp).format(format)

    static pad(num) {
        return ("0"+num).slice(-2);
    }

    static getHoursFromSeconds = secs => {
        // seconds ? moment.utc(180928*1000).format('HH:mm:ss') : '00:00:00';
        var minutes = Math.floor(secs / 60);
        secs = secs%60;
        var hours = Math.floor(minutes/60)
        minutes = minutes%60;
        return `${this.pad(hours)}:${this.pad(minutes)}:${this.pad(secs)}`;
    }

    static timeStamp = (date) => Math.floor(new Date(date).getTime() / 1000);

    static toast = (type, message) => showToast(type, message)

    static isEmptyObject = (obj) => {
        if (obj != null && typeof obj !== "undefined") {
            return Object.keys(obj).length === 0;
        }
        return true
    }
    static timestampDate = (timestamp, format) => moment.unix(timestamp).format(format)

    static monday = (date) => {
        const dateObj = new Date(date)
        // const first =dateObj.getDate() - dateObj.getDay() + 1;
        // return new Date(dateObj.setDate(first));
        var day = dateObj.getDay(),
            diff = dateObj.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
        return new Date(dateObj.setDate(diff));
    }

    static isEmpty(item_to_check) {
        if (
            item_to_check === null ||
            item_to_check === undefined ||
            item_to_check === "" ||
            item_to_check === "null"
        )
            return true;
        else return false;
    }
    static isEmptyArray(item_to_check) {
        if (
            item_to_check.length === 0
        )
            return true;
        else return false;
    }

    static isEmptyString(item_to_check) {
        if (item_to_check === "") return true;
        else return false;
    }

    static isObjEmpty(obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) return false;
        }
        return true;
    }

    static matchPasswords(password, confirm_password) {
        return password.trim().toLowerCase() === confirm_password.trim().toLowerCase();
    }

    static wait(seconds = 3) {
        setInterval(() => { }, seconds * 1000)
    }

    static secondtoTime = (startTime) => {
        const hours = Math.floor(startTime / 3600);
        const minutes = Math.floor((startTime - hours * 3600) / 60);
        const seconds = startTime - hours * 3600 - minutes * 60;
        const timeString =
            hours.toString().padStart(2, "0") +
            ":" +
            minutes.toString().padStart(2, "0") +
            ":" +
            seconds.toString().padStart(2, "0");
        return timeString
    }

    static BorderExample() {
        return <div className="spinner-border text-danger" role="status">
            <span className="sr-only">Loading...</span>
        </div>;
    }
    static getObjectExceptProperty(object, property = []) {
        return Object.keys(object).reduce((result, key) => {
            if (!property.includes(key)) {
                result[key] = object[key];
            }
            return result;
        }, {});
    }
    static copyToClipboard = (data="") => {
        if(data)
        navigator.clipboard.writeText(data)
        .then(()=>{this.toast("success",STRING.COPIED)})
        .catch(()=>{this.toast("error",STRING.COPIED_ERROR)})
    }

    // static imageDownload = async (imagePath,imageName="image") => {
    //     const originalImage=imagePath;
    //     // const image = await fetch(originalImage);

    //     //Split image name
    //     const nameSplit = originalImage.split("/");
    //     const duplicateName = nameSplit.pop();

    //     // const imageBlog = await image.blob()
    //     const file = new Blob(
    //     [
    //         imagePath
    //     ],
    //     { type: "image/jpeg" }
    //     );
    //     const imageURL = URL.createObjectURL(file)
    //     const link = document.createElement('a')
    //     link.href = imageURL;
    //     link.download = ""+duplicateName+"";
    //     document.body.appendChild(link)
    //     link.click()
    //     document.body.removeChild(link)  
    // }

    static imageDownload = async (imagePath,imageName="image") => {
        const nameSplit = imageName.split("/");
        const duplicateName = nameSplit.pop();
        saveAs(imagePath, duplicateName); 
    }

    static getMonthTimestamp = () => {
        const startOfMonth = moment().startOf('month').format('YYYY-MM-DD hh:mm');
        const endOfMonth   = moment().endOf('month').format('YYYY-MM-DD hh:mm');
    }

    static getCurrentMonthDates = () => {
        return {
            "start":moment().date(1).toDate(),
            "end":moment().endOf('month').toDate(),
        }
    }

    static getPreviousMonthDates = () => {
        return {
            "start":moment().subtract(1, "months").date(1).toDate(),
            "end":moment().subtract(1, "months").endOf('month').toDate(),
        }
    }

    static getLastSaturday = (format = '') => {
        const result = moment().endOf('month');
        while (result.day() !== 6) {
            result.subtract(1, 'day');
        }
        return result.format('YYYY-MM-DD')
    }

    static stringReducer = string => {
        if(string?.length > 15){
            return string.slice(0, 15) + '...';
        }
        return string;
    }


    static timeStampToMDY2(timestamp) {
        const date = new Date(timestamp*1000).toLocaleString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric'
        });
        return date // Renders "April 24, 2023"
      }

    static timeStampToMDY(timestamp) {
      const date = new Date(timestamp*1000).toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric"
      });
      return date // Renders "April 24, 2023"
    }
    
    static timeFormatToSec(hms) {        
        var a = hms.split(':');
        var seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);         
        return seconds;
    }




    static getMonthLastDate(date) {
        const currentDate = new Date(); // current date and time
        const lastDateOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);    
        return lastDateOfMonth;
    }

    static getTimeAgo(timestamp) {
        const now = new Date();
        const timeDiff = now.getTime() - timestamp *1000;      
        const seconds = timeDiff / 1000;   
        if (seconds < 60) {
          return 'less than a minute ago';
        } else if (seconds < 3600) {
          const minutes = Math.floor(seconds / 60);
          return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
        } else if (seconds < 86400) {
          const hours = Math.floor(seconds / 3600);
          return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
        } else if (seconds < 604800) {
          const days = Math.floor(seconds / 86400);
          return `${days} ${days === 1 ? 'day' : 'days'} ago`;
        } else {
          const weeks = Math.floor(seconds / 604800);
          return `${weeks} ${weeks === 1 ? 'week' : 'weeks'} ago`;
        }
      }


      static getColorByIndex(index) {
        const colors = ["bg-info", "bg-success", "bg-warning", "bg-danger"];
        const normalizedIndex = index % colors.length;
        const color = colors[normalizedIndex];        
        return color;
      }
      
      

}
export default Utils;