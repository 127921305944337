import { put, call, takeLatest } from "redux-saga/effects";
import { manageTrackerActionSuccess ,manageTrackerActionError } from "../action/manageTrackerAction";
import { MANAGE_TRACKER_REQUEST } from "../actionType/type";
import { MANAGE_TRACKERS } from "../../service/apiUrl";
import { postApiCall } from "../../service/PostApiCall";

function* manageTrackerSaga(action){
    try {
        const response = yield call(postApiCall,action?.params,action?.params?.access_token,MANAGE_TRACKERS)
        yield put(manageTrackerActionSuccess(response));
        // action.onSuccess(response)
    } catch (error) {
        yield put(manageTrackerActionError(error));
    }
}

export function* watchManageTrackerSaga(){
    yield takeLatest(MANAGE_TRACKER_REQUEST,manageTrackerSaga)
}