import {
    MANAGE_PAYSLIPS_REQUEST, 
    MANAGE_PAYSLIPS_REQUEST_ERROR, 
    MANAGE_PAYSLIPS_REQUEST_SUCCESS,
} from "../actionType/type";

const initialState = {
    data: null,
    isLoading: false,
    refreshPage: false
};

export const managePayslipsReducer = (state = initialState, action) => {
    let object;
    switch (action.type) {
        case  MANAGE_PAYSLIPS_REQUEST:
            object = {
                ...state,
                isLoading: true,
            }
            break;
            
        case  MANAGE_PAYSLIPS_REQUEST_ERROR:
            object = {
                ...state,
                isLoading: false
            }
            break;

        case  MANAGE_PAYSLIPS_REQUEST_SUCCESS:
            object = {
                ...state,
                data: action.payload,
                isLoading: false
            }
            break;

           
        default:
            object = state
            break
    }

    return object
}