
import { SIGNIN_REQUEST, SIGNIN_REQUEST_ERROR, SIGNIN_REQUEST_SUCCESS, SIGNOUT_REQUEST } from "../actionType/type";

export const signinRequest = (paramsData,onSuccess) => {
    return {
        type : SIGNIN_REQUEST,
        params : paramsData,
        onSuccess,
    }
}

export const actionSigninSuccess = (result) => {
    return {
        type: SIGNIN_REQUEST_SUCCESS,
        payload: result,
        error: null,
    };
}

export const actionSigninError = (error) => {
    return {
        type: SIGNIN_REQUEST_ERROR,
        error:error,
        payload:null
    }
}

export const signoutRequest = (onSuccess) => {
    return {
        type:SIGNOUT_REQUEST,
        payload:{},
        onSuccess
    }
}