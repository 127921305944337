const PaySlips = (props) => {
    return (
        <>
        <div className={ props?.data?.transtion == true ? "contents" : "content"}>
        <div className="content-top-spacer"></div>
        <div className="color-body">

        </div>
        </div>
        </>
    )

}

export default PaySlips;