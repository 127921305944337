// import React, { Component } from "react";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { USERGET_DATA} from "../service/apiUrl";
import { useSelector } from "react-redux";
import { postApiCall } from "../service/PostApiCall";
import { INVENTORY_CREATED } from "../service/apiUrl";
import { useNavigate } from "react-router-dom";
import { STRING } from "../constants/string";
import Utils from "../utility/utility";

const AddInventory = (props) => {
  const [name , setName] = useState();
  const [serialno , setSerialNo] = useState();
  const [brand , setBrand] = useState();
  const [type , setType] = useState();
  const [assign , setAssign] = useState();
  const navigate = useNavigate();
  const validateannouncement = () => {
    let message = "";
    if (Utils.isEmpty(name)) {
      message = STRING.NAME;
    } else if (Utils.isEmpty(serialno)) {
      message = STRING.SERIALNO;
    } else if (Utils.isEmpty(brand)) {
      message = STRING.BRAND;
    } else if (Utils.isEmpty(type)) {
      message = STRING.TYPE;
    }else if (Utils.isEmpty(assign)) {
      message = STRING.ASSIGN;
    }
    return message;
  };
  const createInventort = ()=>{
    const message = validateannouncement();
    if (message) {
      Utils.toast("error", message);
      return false;
    } else{
    const data = {
      name : name,
      brand : brand,
      type : type,
      serial_number : serialno,
      assign_to : assign,

    }
    postApiCall(data, signdata?.data?.access_token,  INVENTORY_CREATED)
    .then((result) => {
      navigate("/inventory-listing");
      Utils.toast("success", STRING.INVENTORY_SUCCESSFUL);
    })
    .catch((error) => {
      console.error("USER_HELPER", error);
    });
  }
  }
const signdata = useSelector((state) => state.signinReducers);
const [typeOption , setOption] = useState([]);
const [helperData, setHelperData] = useState([]);
useEffect(()=>{
  const type = [
    {value : 'keyboard',label : 'Keyboard'},
    {value : "mouse",label : "Mouse"},
    {value : "destop",label : "Destop"},
    {value : "cpu",label : "CPU"},
    {value : "UPS",label : "UPS"},
    {value : "laptop",label : "Laptop"},
    {value : "datacable",label : "Datacable"},
    {value : "ctype datacable",label : "Ctype Datacable"},
    {value : "mobile",label : "Mobile"},
    {value : "multiport",label : "Multiport"},
   ]
   setOption(type)
},[])
useEffect(() => {
  postApiCall([], signdata?.data?.access_token, USERGET_DATA)
    .then((result) => {
      setHelperData(result?.all_users);
    })
    .catch((error) => {
      console.error("USER_HELPER", error);
    });
}, []);
  return (
    <>
      <div className={props?.data?.transtion == true ? "contents" : "content"}>
        <div className="content-top-spacer"></div>
        <div className="color-body">
          <div className="col-md-6 manage">
            <div className="card-input mt-5 mb-2">
              <div className="col-md-12">
                <div className="mt-3">
                  <h5 className="border-bottom pb-2">Create Inventory</h5>
                </div>
                <form>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Name</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(data)=>{
                            setName(data.target.value)
                          }}

                          // placeholder="Enter Project Owner"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Serial Number
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(data)=>{
                            setSerialNo(data.target.value)
                          }}

                          // placeholder="Enter Project Owner"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Brand</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(data)=>{
                            setBrand(data.target.value)
                          }}

                          // placeholder="Enter Project Owner"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Type</label>
                        <Select options={typeOption} onChange={(data)=> setType(data.value)}/>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Assign To</label>
                        <Select options={helperData} onChange={(data)=>{
                          setAssign(data.value)
                        }}/>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="row mb-5 mt-4 ml-2">
                        <div className="">
                          <button type="button" className="create-button" onClick={createInventort}>
                            Create
                          </button>
                        </div>
                        <div className="ml-2">
                          <button
                            type="button"
                            className="create-button1 btn-info"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12"></div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddInventory;
