import { put, call, takeLatest } from "redux-saga/effects";
import { manageLeaveActionSuccess , manageLeaveActionError } from "../action/manageLeaveAction";
import { LEAVE_REQUEST } from "../actionType/type";
import { LEAVEMANAGE } from "../../service/apiUrl";
import { postApiCall } from "../../service/PostApiCall";


function* leaveSaga(action){
    try {
        const response = yield call(postApiCall,action?.params,action?.params?.access_token,LEAVEMANAGE)
        yield put(manageLeaveActionSuccess(response));
        // action.onSuccess(response)
    } catch (error) {
        yield put(manageLeaveActionError(error));
    }
}

export function* watchLeaveSaga(){
    yield takeLatest(LEAVE_REQUEST,leaveSaga)
}