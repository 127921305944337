import { useState } from "react";
import Header from "./Header";
import { STRING } from "../constants/string";
import { Outlet, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RiGalleryFill } from "react-icons/ri";

const Sidebar = (props) => {
  const [active, setactive] = useState(window.location.pathname);
  const [Manage, setManage] = useState(false);
  const [Todo, setTodo] = useState(false);
  const [Pay, setPay] = useState(false);
  const [Mproject, setMproject] = useState(false);
  const [Task, setTask] = useState(false);
  const [Tracker, setTracker] = useState(false);
  const [Clickup, setClickup] = useState(false);
  const [Anoucement, setAnoucement] = useState(false);
  const [Leave, setLeave] = useState(false);
  const [Timelog, setTimelog] = useState(false);
  const [Inventory, setInventory] = useState(false);
  const [Support, setSupport] = useState(false);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [isFolderOpen, setIsFolderOpen] = useState(false);
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const signdata = useSelector((state) => state.signinReducers);
  const location = useLocation();

  useEffect(() => {
    if (signdata && !signdata?.data) {
      navigate("login");
    }
  }, [navigate, signdata]);

  useEffect(() => {
    console.log("pathname:: ", location.pathname);
    if (
      location.pathname !== "/manage-user" &&
      !location.pathname.startsWith("/edit-user/")
    ) {
      localStorage.removeItem("manageUser");
      localStorage.removeItem("currentPage");
      localStorage.removeItem("manageUserRoles");
      localStorage.removeItem("manageUserStatus");
    }
    if (
      location.pathname !== "/manage-leave" &&
      !location.pathname.startsWith("/edit-leave/")
    ) {
      localStorage.removeItem("leaveDateFilter");
      localStorage.removeItem("leavePagination");
    }
    if (
      location.pathname !== "/manage-clickup" &&
      !location.pathname.startsWith("/add-clickup/")
    ) {
      localStorage.removeItem("filterEmail");
    }

    // filterEmail manage-clickup add-clickup
  }, [location.pathname]);

  // let folderData = localStorage.getItem("folder") !== null ? JSON.parse(localStorage.getItem("folder")):[]
  const [folderData, setFolderData] = useState([]);

  useEffect(() => {
    const storedData = localStorage.getItem("folderData");
    if (storedData) {
      setFolderData(JSON.parse(storedData));
    }
  }, []);

  let handleToggleFolder = () => {
    setIsFolderOpen(!isFolderOpen);
  };

  return (
    <>
      <Header menuside={props.data.settranstion} />
      <div
        className={
          props?.data?.transtion === true ? "sidebar rapid" : "sidebars rapid"
        }
      >
        <div className="col-md-12 set-data-width">
          <Link to={"home"} className="text-decoration-none text-dark">
            <div
              className={
                active === STRING.DASHBOARD
                  ? "sidebar-menu mt-2 "
                  : "sidebar-menus mt-2  "
              }
            >
              <div
                className=""
                onClick={() => {
                  setactive(STRING.DASHBOARD);
                  window.history.replaceState("", "", "../home");
                }}
              >
                <p className="mb-0">
                  <span className="ml-3">
                    <i className="fa fa-tachometer" aria-hidden="true"></i>
                  </span>
                  <span className="ml-3">Dashboard</span>
                </p>
              </div>
            </div>
          </Link>
          <div className="border-bottom mt-4 mr-4"></div>
          <div className="mt-3"></div>

          {/* to do list start */}
          {signdata?.data?.role == 1 ? (
            <>
              <div
                className={
                  active === STRING.TODO
                    ? "sidebar-menu mt-2 "
                    : "sidebar-menus mt-2 "
                }
                onClick={() => {
                  setactive(STRING.TODO);
                  window.history.replaceState("", "", "../manage-todo");
                  setTodo((wasOpened) => !wasOpened);
                  setManage(false);
                  setPay(false);
                  setMproject(false);
                  setTask(false);
                  setTracker(false);
                  setAnoucement(false);
                  setLeave(false);
                  setSupport(false);
                  setIsGalleryOpen(false);
                  setTimelog(false);
                }}
              >
                <div className="">
                  <Link
                    to="manage-todo"
                    className="text-decoration-none text-dark"
                  >
                    <p className="mb-0">
                      <span className="ml-3">
                        <i className="fa fa-clipboard" aria-hidden="true"></i>
                      </span>
                      <span className="ml-3">To do</span>
                    </p>
                  </Link>
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          {/* to do end */}

          {/* add users */}
          {signdata?.data?.role == 1 ? (
            <>
              <div
                className={
                  active === STRING.MANAGE
                    ? "sidebar-menu mt-2 "
                    : "sidebar-menus mt-2 "
                }
                onClick={() => {
                  setactive(STRING.MANAGE);
                  window.history.replaceState("", "", "../manage-users");
                  setManage((wasOpened) => !wasOpened);
                  setTodo(false);
                  setPay(false);
                  setMproject(false);
                  setTask(false);
                  setTracker(false);
                  setAnoucement(false);
                  setLeave(false);
                  setIsGalleryOpen(false);
                  setTimelog(false);
                  setSupport(false);
                }}
              >
                <div className="">
                  <p className="mb-0">
                    <span className="ml-3">
                      <i className="fa fa-users" aria-hidden="true"></i>
                    </span>
                    <span className="ml-3">Manage Users</span>
                    <span className="right-set">
                      {Manage === true ? (
                        <i className="fa fa-angle-up" aria-hidden="true"></i>
                      ) : (
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                      )}
                    </span>
                  </p>
                </div>
              </div>
              <div className={Manage === true ? "transitions" : "transition"}>
                {Manage === true ? (
                  <div className="row">
                    <div className="col-md-3">
                      <div className="border-timelines"></div>
                    </div>
                    <div className="col-md-9">
                      <Link
                        to="add-user"
                        className="text-decoration-none text-dark"
                      >
                        <div className="row">
                          <p className="mb-0">
                            <span className="time-left">Add User</span>
                          </p>
                        </div>
                      </Link>
                      <Link
                        to="manage-user"
                        className="text-decoration-none text-dark"
                      >
                        <div className="row">
                          <p className="mb-0 mt-2">
                            <span className="time-left">Manage Users</span>
                          </p>
                        </div>
                      </Link>
                      <div className="row">
                        <p className="mb-0 mt-2">
                          <span className="time-left">Deleted Users</span>
                        </p>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </>
          ) : (
            ""
          )}

          {/* add payslips */}
          {signdata?.data?.role == 1 ? (
            <>
              <div
                className={
                  active === STRING.PAY
                    ? "sidebar-menu mt-2 "
                    : "sidebar-menus mt-2  "
                }
                onClick={() => {
                  setactive(STRING.PAY);
                  window.history.replaceState("", "", "../pay-slip");
                  setTodo(false);
                  setPay((wasOpened) => !wasOpened);
                  setManage(false);
                  setMproject(false);
                  setTask(false);
                  setTracker(false);
                  setAnoucement(false);
                  setLeave(false);
                  setIsGalleryOpen(false);
                  setSupport(false);
                  setTimelog(false);
                }}
              >
                <div className="">
                  <p className="mb-0">
                    <span className="ml-3">
                      <i className="fa fa-money" aria-hidden="true"></i>
                    </span>
                    <span className="ml-3">Pay Slips</span>
                    <span className="right-set">
                      {Pay === true ? (
                        <i className="fa fa-angle-up" aria-hidden="true"></i>
                      ) : (
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                      )}
                    </span>
                  </p>
                </div>
              </div>
              <div className={Pay === true ? "transitions" : "transition"}>
                {Pay === true ? (
                  <div className="row">
                    <div className="col-md-3">
                      <div className="border-timeline"></div>
                    </div>
                    <div className="col-md-9">
                      <Link
                        to="add-payslip"
                        className="text-decoration-none text-dark"
                      >
                        <div className="row">
                          <p className="mb-0">
                            <span className="time-left">Add Payslips</span>
                          </p>
                        </div>
                      </Link>
                      <Link
                        to="manage-payslip"
                        className="text-decoration-none text-dark"
                      >
                        <div className="row">
                          <p className="mb-0 mt-2">
                            <span className="time-left">Manage Payslips</span>
                          </p>
                        </div>
                      </Link>
                    </div>
                  </div>
                ) : null}
              </div>
            </>
          ) : (
            ""
          )}

          {/* add projects */}
          {signdata?.data?.role == 1 ? (
            <>
              <div
                className={
                  active === STRING.PROJECT
                    ? "sidebar-menu mt-2 "
                    : "sidebar-menus mt-2 "
                }
                onClick={() => {
                  setactive(STRING.PROJECT);
                  window.history.replaceState("", "", "../manage-project");
                  setMproject((wasOpened) => !wasOpened);
                  setManage(false);
                  setTodo(false);
                  setPay(false);
                  setTask(false);
                  setTracker(false);
                  setAnoucement(false);
                  setLeave(false);
                  setIsGalleryOpen(false);
                  setTimelog(false);
                  setSupport(false);
                }}
              >
                <div className="">
                  <p className="mb-0">
                    <span className="ml-3">
                      <i className="fa fa-tasks"></i>
                    </span>
                    <span className="ml-3">Manage Projects</span>
                    <span className="right-set">
                      {Mproject === true ? (
                        <i className="fa fa-angle-up" aria-hidden="true"></i>
                      ) : (
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                      )}
                    </span>
                  </p>
                </div>
              </div>
              <div className={Mproject === true ? "transitions" : "transition"}>
                {Mproject === true ? (
                  <div className="row">
                    <div className="col-md-3">
                      <div className="border-manage"></div>
                    </div>
                    <div className="col-md-9">
                      <Link
                        to="add-project"
                        className="text-decoration-none text-dark"
                      >
                        <div className="row">
                          <p className="mb-0">
                            <span className="time-left">Add Projects</span>
                          </p>
                        </div>
                      </Link>
                      <Link
                        to="manage-project"
                        className="text-decoration-none text-dark"
                      >
                        <div className="row">
                          <p className="mb-0 mt-2">
                            <span className="time-left">Manage Projects</span>
                          </p>
                        </div>
                      </Link>
                      <div className="row">
                        <p className="mb-0 mt-2">
                          <span className="dot-side"></span>
                          <span className="time-left">Hold Projects</span>
                        </p>
                      </div>
                      <div className="row">
                        <p className="mb-0 mt-2">
                          <span className="dot-side"></span>
                          <span className="time-left">Closed Projects</span>
                        </p>
                      </div>
                      <div className="row">
                        <p className="mb-0 mt-2">
                          <span className="dot-side"></span>
                          <span className="time-left">Add Client</span>
                        </p>
                      </div>
                      <div className="row">
                        <p className="mb-0 mt-2">
                          <span className="dot-side"></span>
                          <span className="time-left">Manage Clients</span>
                        </p>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </>
          ) : (
            ""
          )}

          {/* manage tasks */}
          {signdata?.data?.role == 1 ? (
            <>
              <div
                className={
                  active === STRING.TASK
                    ? "sidebar-menu mt-2"
                    : "sidebar-menus mt-2"
                }
                onClick={() => {
                  setactive(STRING.TASK);
                  window.history.replaceState("", "", "../task-list");
                  setTask((wasOpened) => !wasOpened);
                  setManage(false);
                  setTodo(false);
                  setPay(false);
                  setMproject(false);
                  setTracker(false);
                  setAnoucement(false);
                  setLeave(false);
                  setTimelog(false);
                  setIsGalleryOpen(false);
                  setSupport(false);
                }}
              >
                <div className="">
                  <p className="mb-0">
                    <span className="ml-3">
                      <i className="fa fa-list" aria-hidden="true"></i>
                    </span>
                    <span className="ml-3">Task List</span>
                    <span className="right-set">
                      {Task === true ? (
                        <i className="fa fa-angle-up" aria-hidden="true"></i>
                      ) : (
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                      )}
                    </span>
                  </p>
                </div>
              </div>
              <div
                className={Task === true ? "transition-tasks" : "transition"}
              >
                {Task === true ? (
                  <Link
                    to="manage-task"
                    className="text-decoration-none text-dark"
                  >
                    <div className="row">
                      <div className="col-md-3">
                        <div className="border-timeline2"></div>
                      </div>
                      <div className="col-md-9">
                        <div className="row">
                          <p className="mb-0">
                            <span className="time-left">Manage Tasks</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                ) : null}
              </div>
            </>
          ) : (
            ""
          )}

          {/* add tracker */}
          {signdata?.data?.role == 1 ? (
            <>
              <div
                className={
                  active === STRING.TRACKER
                    ? "sidebar-menu mt-2"
                    : "sidebar-menus mt-2"
                }
                onClick={() => {
                  setactive(STRING.TRACKER);
                  window.history.replaceState("", "", "../manage-tracker");
                  setTracker((wasOpened) => !wasOpened);
                  setManage(false);
                  setTodo(false);
                  setPay(false);
                  setMproject(false);
                  setTask(false);
                  setAnoucement(false);
                  setLeave(false);
                  setTimelog(false);
                  setIsGalleryOpen(false);
                  setClickup(false);
                  setSupport(false);
                }}
              >
                <div className="">
                  <p className="mb-0">
                    <span className="ml-3">
                      <i className="fa fa-list" aria-hidden="true"></i>
                    </span>
                    <span className="ml-3">Manage Tracker</span>
                    <span className="right-set">
                      {Tracker === true ? (
                        <i className="fa fa-angle-up" aria-hidden="true"></i>
                      ) : (
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                      )}
                    </span>
                  </p>
                </div>
              </div>
              <div className={Tracker === true ? "transition-h" : "transition"}>
                {Tracker === true ? (
                  <div className="row">
                    <div className="col-md-3">
                      <div className="border-timeline"></div>
                    </div>
                    <div className="col-md-9">
                      <Link
                        to="add-tracker"
                        className="text-decoration-none text-dark"
                      >
                        <div className="row">
                          <p className="mb-0">
                            <span className="time-left">Add Tracker</span>
                          </p>
                        </div>
                      </Link>
                      <Link
                        to="manage-tracker"
                        className="text-decoration-none text-dark"
                      >
                        <div className="row">
                          <p className="mb-0 mt-2">
                            <span className="time-left">Manage Tracker</span>
                          </p>
                        </div>
                      </Link>
                    </div>
                  </div>
                ) : null}
              </div>
            </>
          ) : (
            ""
          )}

          {/* add Clickup account */}
          {signdata?.data?.role == 1 ? (
            <>
              <div
                className={
                  active === STRING.CLICKUP
                    ? "sidebar-menu mt-2"
                    : "sidebar-menus mt-2"
                }
                onClick={() => {
                  setactive(STRING.CLICKUP);
                  window.history.replaceState("", "", "../manage-clickup");
                  setTracker(false);
                  setManage(false);
                  setTodo(false);
                  setPay(false);
                  setMproject(false);
                  setTask(false);
                  setAnoucement(false);
                  setLeave(false);
                  setTimelog(false);
                  setClickup((wasOpened) => !wasOpened);
                  setIsGalleryOpen(false);
                  setSupport(false);
                }}
              >
                <div className="">
                  <p className="mb-0">
                    <span className="ml-3">
                      <i className="fa fa-list" aria-hidden="true"></i>
                    </span>
                    <span className="ml-3">Clickup Tracker</span>
                    <span className="right-set">
                      {Clickup === true ? (
                        <i className="fa fa-angle-up" aria-hidden="true"></i>
                      ) : (
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                      )}
                    </span>
                  </p>
                </div>
              </div>
              <div className={Clickup === true ? "transition-h" : "transition"}>
                {Clickup === true ? (
                  <div className="row">
                    <div className="col-md-3">
                      <div className="border-timeline"></div>
                    </div>
                    <div className="col-md-9">
                      <Link
                        to="add-clickup"
                        className="text-decoration-none text-dark"
                      >
                        <div className="row">
                          <p className="mb-0">
                            <span className="time-left">Add Tracker</span>
                          </p>
                        </div>
                      </Link>
                      <Link
                        to="manage-clickup"
                        className="text-decoration-none text-dark"
                      >
                        <div className="row">
                          <p className="mb-0 mt-2">
                            <span className="time-left">Manage Tracker</span>
                          </p>
                        </div>
                      </Link>
                    </div>
                  </div>
                ) : null}
              </div>
            </>
          ) : (
            ""
          )}
          {/* {signdata?.data?.role == 1 || signdata?.data?.role == 5 ? (
            <>
              <div
                className={
                  active === STRING.SUPPORT
                    ? "sidebar-menu mt-2 "
                    : "sidebar-menus mt-2"
                }
                onClick={() => {
                  setactive(STRING.SUPPORT);
                  window.history.replaceState("", "", "../support");
                  setSupport((wasOpened) => !wasOpened);
                  setManage(false);
                  setTodo(false);
                  setPay(false);
                  setMproject(false);
                  setTask(false);
                  setTracker(false);
                  setLeave(false);
                  setTimelog(false);
                }}
              >
                <div className="">
                  <p className="mb-0">
                    <span className="ml-3">
                      <i class="fa fa-cog" aria-hidden="true"></i>
                    </span>
                    <span className="ml-3">Support</span>
                    <span className="right-set">
                      {Support === true ? (
                        <i className="fa fa-angle-up" aria-hidden="true"></i>
                      ) : (
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                      )}
                    </span>
                  </p>
                </div>
              </div>
              <div
                className={Support === true ? "transition-tasks" : "transition"}
              >
                {Support === true ? (
                  <div className="row">
                    <div className="col-md-3">
                      <div className="border-timeline"></div>
                    </div>
                    <div className="col-md-9">
                      <Link
                        to="add-support"
                        className="text-decoration-none text-dark"
                      >
                        <div className="row">
                          <p className="mb-0">
                            <span className="time-left">Add Support</span>
                          </p>
                        </div>
                      </Link>
                      <Link
                        to="view-support"
                        className="text-decoration-none text-dark"
                      >
                        <div className="row">
                          <p className="mb-0 mt-2">
                            <span className="time-left">View Support</span>
                          </p>
                        </div>
                      </Link>
                    </div>
                  </div>
                ) : null}
              </div>
            </>
          ) : (
            ""
          )} */}
          {/* {signdata?.data?.role == 1 ||
          signdata?.data?.role == 5 ||
          signdata?.data?.role == 14 ||
          signdata?.data?.role == 8 ||
          signdata?.data?.role == 10 ||
          signdata?.data?.role == 6 ||
          signdata?.data?.role == 9 ||
          signdata?.data?.role == 7 ? (
            <>
              <div
                className={
                  active === STRING.SUPPORT
                    ? "sidebar-menu mt-2 "
                    : "sidebar-menus mt-2"
                }
                onClick={() => {
                  setactive(STRING.SUPPORT);
                  window.history.replaceState("", "", "../support");
                  setSupport((wasOpened) => !wasOpened);
                  setManage(false);
                  setTodo(false);
                  setPay(false);
                  setMproject(false);
                  setTask(false);
                  setTracker(false);
                  setLeave(false);
                  setIsGalleryOpen(false);
                  setTimelog(false);
                }}
              >
                <div className="">
                  <p className="mb-0">
                    <span className="ml-3">
                      <i class="fa fa-cog" aria-hidden="true"></i>
                    </span>
                    <span className="ml-3">Support</span>
                    <span className="right-set">
                      {Support === true ? (
                        <i className="fa fa-angle-up" aria-hidden="true"></i>
                      ) : (
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                      )}
                    </span>
                  </p>
                </div>
              </div>
              <div
                className={Support === true ? "transition-tasks" : "transition"}
              >
                {Support === true ? (
                  <div className="row">
                    <div className="col-md-3">
                      <div className="border-timeline"></div>
                    </div>
                    <div className="col-md-9">
                      <Link
                        to="add-support"
                        className="text-decoration-none text-dark"
                      >
                        <div className="row">
                          <p className="mb-0">
                            <span className="time-left">Add Support</span>
                          </p>
                        </div>
                      </Link>
                      <Link
                        to="view-support"
                        className="text-decoration-none text-dark"
                      >
                        <div className="row">
                          <p className="mb-0 mt-2">
                            <span className="time-left">View Support</span>
                          </p>
                        </div>
                      </Link>
                    </div>
                  </div>
                ) : null}
              </div>
            </>
          ) : (
            ""
          )} */}
          <>
              <div
                className={
                  active === STRING.SUPPORT
                    ? "sidebar-menu mt-2 "
                    : "sidebar-menus mt-2"
                }
                onClick={() => {
                  setactive(STRING.SUPPORT);
                  window.history.replaceState("", "", "../support");
                  setSupport((wasOpened) => !wasOpened);
                  setManage(false);
                  setTodo(false);
                  setPay(false);
                  setMproject(false);
                  setTask(false);
                  setTracker(false);
                  setLeave(false);
                  setIsGalleryOpen(false);
                  setTimelog(false);
                }}
              >
                <div className="">
                  <p className="mb-0">
                    <span className="ml-3">
                      <i class="fa fa-cog" aria-hidden="true"></i>
                    </span>
                    <span className="ml-3">Support</span>
                    <span className="right-set">
                      {Support === true ? (
                        <i className="fa fa-angle-up" aria-hidden="true"></i>
                      ) : (
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                      )}
                    </span>
                  </p>
                </div>
              </div>
              <div
                className={Support === true ? "transition-tasks" : "transition"}
              >
                {Support === true ? (
                  <div className="row">
                    <div className="col-md-3">
                      <div className="border-timeline"></div>
                    </div>
                    <div className="col-md-9">
                      <Link
                        to="add-support"
                        className="text-decoration-none text-dark"
                      >
                        <div className="row">
                          <p className="mb-0">
                            <span className="time-left">Add Support</span>
                          </p>
                        </div>
                      </Link>
                      <Link
                        to="view-support"
                        className="text-decoration-none text-dark"
                      >
                        <div className="row">
                          <p className="mb-0 mt-2">
                            <span className="time-left">View Support</span>
                          </p>
                        </div>
                      </Link>
                    </div>
                  </div>
                ) : null}
              </div>
            </>

          {signdata?.data?.role == 1 ? (
            <>
              <div
                className={
                  active === STRING.GALLERY
                    ? "sidebar-menu mt-2 "
                    : "sidebar-menus mt-2"
                }
                onClick={() => {
                  setactive(STRING.GALLERY);
                  window.history.replaceState("", "", "../gallery");
                  setIsGalleryOpen((wasOpened) => !wasOpened);
                  setManage(false);
                  setTodo(false);
                  setPay(false);
                  setMproject(false);
                  setTask(false);
                  setTracker(false);
                  setLeave(false);
                  setSupport(false);
                  setAnoucement(false);
                  setTimelog(false);
                }}
              >
                <div className="">
                  <p className="mb-0">
                    <span className="ml-3">
                      {/* <i className="fa fa-bullhorn" aria-hidden="true"></i>
                       */}
                      <RiGalleryFill />
                    </span>
                    <span className="ml-3">Gallery</span>
                    <span className="right-set">
                      {isGalleryOpen === true ? (
                        <i className="fa fa-angle-up" aria-hidden="true"></i>
                      ) : (
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                      )}
                    </span>
                  </p>
                </div>
              </div>
              <div
                className={
                  isGalleryOpen === true ? "transition-tasks" : "transition"
                }
              >
                {isGalleryOpen === true ? (
                  <div className="row">
                    <div className="col-md-3">
                      <div className="border-timeline"></div>
                    </div>
                    <div className="col-md-9">
                      <Link
                        to="createFolder"
                        className="text-decoration-none text-dark"
                      >
                        <div className="row">
                          <p className="mb-0">
                            <span className="time-left">Create Folder</span>
                          </p>
                        </div>
                      </Link>
                      <Link
                        to="gallery"
                        className="text-decoration-none text-dark"
                      >
                        <div className="row">
                          <p className="mb-0 mt-2">
                            <span className="time-left">Listing</span>
                          </p>
                        </div>
                      </Link>
                      {/* <div className="folder-content mt-3">
                        <div onClick={handleToggleFolder}>Folder</div>
                        <ul>
                        {
                          isFolderOpen === true && <>{ folderData.map((folder) => (
                            <Link to={`/gallery/${folder.id}`}>
                            <li
                              key={folder.id}
                              // onClick={() => handleFolderClick(folder.id)}
                              // className={
                              //   activeFolder === folder.id
                              //    ? 'folder-active'
                              //     : 'folder-content-item'
                              // }
                            >
                              {folder.title}
                            </li>
                            </Link>
                          ))}</>
                         }
                        </ul>
                      </div> */}
                    </div>
                  </div>
                ) : null}
              </div>
            </>
          ) : (
            ""
          )}

          {signdata?.data?.role == 1 ? (
            <>
              <div
                className={
                  active === STRING.ANOUCEMENT
                    ? "sidebar-menu mt-2 "
                    : "sidebar-menus mt-2"
                }
                onClick={() => {
                  setactive(STRING.ANOUCEMENT);
                  window.history.replaceState("", "", "../anouncements");
                  setAnoucement((wasOpened) => !wasOpened);
                  setManage(false);
                  setTodo(false);
                  setPay(false);
                  setMproject(false);
                  setTask(false);
                  setTracker(false);
                  setLeave(false);
                  setSupport(false);
                  setIsGalleryOpen(false);
                  setTimelog(false);
                }}
              >
                <div className="">
                  <p className="mb-0">
                    <span className="ml-3">
                      <i className="fa fa-bullhorn" aria-hidden="true"></i>
                    </span>
                    <span className="ml-3">Announcements</span>
                    <span className="right-set">
                      {Anoucement === true ? (
                        <i className="fa fa-angle-up" aria-hidden="true"></i>
                      ) : (
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                      )}
                    </span>
                  </p>
                </div>
              </div>
              <div
                className={
                  Anoucement === true ? "transition-tasks" : "transition"
                }
              >
                {Anoucement === true ? (
                  <div className="row">
                    <div className="col-md-3">
                      <div className="border-timeline"></div>
                    </div>
                    <div className="col-md-9">
                      <Link
                        to="add-anouncements"
                        className="text-decoration-none text-dark"
                      >
                        <div className="row">
                          <p className="mb-0">
                            <span className="time-left">Add Announcements</span>
                          </p>
                        </div>
                      </Link>
                      <Link
                        to="annoucement-listing"
                        className="text-decoration-none text-dark"
                      >
                        <div className="row">
                          <p className="mb-0 mt-2">
                            <span className="time-left">Listing</span>
                          </p>
                        </div>
                      </Link>
                    </div>
                  </div>
                ) : null}
              </div>
            </>
          ) : (
            ""
          )}

          {/* Manage leave */}
          {signdata?.data?.role == 1 ? (
            <>
              <div
                className={
                  active === STRING.LEAVE
                    ? "sidebar-menu mt-2"
                    : "sidebar-menus mt-2"
                }
                onClick={() => {
                  setactive(STRING.LEAVE);
                  window.history.replaceState("", "", "../leave");
                  setLeave((wasOpened) => !wasOpened);
                  setManage(false);
                  setTodo(false);
                  setPay(false);
                  setMproject(false);
                  setTask(false);
                  setTracker(false);
                  setAnoucement(false);
                  setTimelog(false);
                  setIsGalleryOpen(false);
                  setSupport(false);
                }}
              >
                <div className="">
                  <p className="mb-0">
                    <span className="ml-3">
                      <i className="fa fa-list" aria-hidden="true"></i>
                    </span>
                    <span className="ml-3">Leave</span>
                    <span className="right-set">
                      {Leave === true ? (
                        <i className="fa fa-angle-up" aria-hidden="true"></i>
                      ) : (
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                      )}
                    </span>
                  </p>
                </div>
              </div>
              <div
                className={Leave === true ? "transition-leave" : "transition"}
              >
                {Leave === true ? (
                  <div className="row">
                    <div className="col-md-3">
                      <div className="border-timelines"></div>
                    </div>
                    <div className="col-md-9">
                      <Link
                        to="add-leave"
                        className="text-decoration-none text-dark"
                      >
                        <div className="row">
                          <p className="mb-0">
                            <span className="time-left">Add Leave</span>
                          </p>
                        </div>
                      </Link>
                      <Link
                        to="manage-leave"
                        className="text-decoration-none text-dark"
                      >
                        <div className="row">
                          <p className="mb-0 mt-2">
                            <span className="time-left">Manage Leave</span>
                          </p>
                        </div>
                      </Link>
                      <div className="row">
                        <p className="mb-0 mt-2">
                          <span className="time-left">My Leaves</span>
                        </p>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </>
          ) : (
            <>
              <div
                className={
                  active === STRING.LEAVE
                    ? "sidebar-menu mt-2"
                    : "sidebar-menus mt-2"
                }
                onClick={() => {
                  setactive(STRING.LEAVE);
                  window.history.replaceState("", "", "../leave");
                  setLeave((wasOpened) => !wasOpened);
                  setTimelog(false);
                }}
              >
                <div className="">
                  <p className="mb-0">
                    <span className="ml-3">
                      <i className="fa fa-list" aria-hidden="true"></i>
                    </span>
                    <span className="ml-3">Leave</span>
                    <span className="right-set">
                      {Leave === true ? (
                        <i className="fa fa-angle-up" aria-hidden="true"></i>
                      ) : (
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                      )}
                    </span>
                  </p>
                </div>
              </div>
              <div
                className={Leave === true ? "transition-leave" : "transition"}
              >
                {Leave === true ? (
                  <div className="row">
                    <div className="col-md-3">
                      <div
                        className="border-timelines"
                        style={{ height: "100%" }}
                      ></div>
                    </div>
                    <div className="col-md-9">
                      <Link
                        to="add-leave"
                        className="text-decoration-none text-dark"
                      >
                        <div className="row">
                          <p className="mb-0">
                            <span className="time-left">Add Leave</span>
                          </p>
                        </div>
                      </Link>
                      <Link
                        to="my-leave"
                        className="text-decoration-none text-dark"
                      >
                        <div className="row">
                          <p className="mb-0 mt-2">
                            <span className="time-left">My Leaves</span>
                          </p>
                        </div>
                      </Link>
                    </div>
                  </div>
                ) : null}
              </div>
            </>
          )}

          {/* Manage timelog */}
          {signdata?.data?.role == 1 ? (
            <>
              <div
                className={
                  active === STRING.TIMELOG
                    ? "sidebar-menu mt-2"
                    : "sidebar-menus mt-2 "
                }
                onClick={() => {
                  setactive(STRING.TIMELOG);
                  window.history.replaceState("", "", "../timelog");
                  setTimelog((wasOpened) => !wasOpened);
                  setManage(false);
                  setTodo(false);
                  setPay(false);
                  setMproject(false);
                  setTask(false);
                  setTracker(false);
                  setAnoucement(false);
                  setLeave(false);
                  setSupport(false);
                  setIsGalleryOpen(false);
                }}
              >
                <div className="">
                  <p className="mb-0">
                    <span className="ml-3">
                      <i className="fa fa-clock-o" aria-hidden="true"></i>
                    </span>
                    <span className="ml-3">Timelog</span>
                    <span className="right-set">
                      {Timelog === true ? (
                        <i className="fa fa-angle-up" aria-hidden="true"></i>
                      ) : (
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                      )}
                    </span>
                  </p>
                </div>
              </div>
              <div
                className={Timelog === true ? "transition-leave" : "transition"}
              >
                {Timelog === true ? (
                  <div className="row">
                    <div className="col-md-3">
                      <div className="border-timeline"></div>
                    </div>
                    <div className="col-md-9">
                      <Link
                        to="timelog"
                        className="text-decoration-none text-dark"
                      >
                        <div className="row">
                          <p className="mb-0">
                            <span className="time-left">My Timelog</span>
                          </p>
                        </div>
                      </Link>
                      <Link
                        to="saturday-working"
                        className="text-decoration-none text-dark"
                      >
                        <div className="row">
                          <p className="mb-0 mt-2">
                            <span className="time-left">Saturday Working</span>
                          </p>
                        </div>
                      </Link>
                    </div>
                  </div>
                ) : null}
              </div>
            </>
          ) : (
            <>
              <div
                className={
                  active === STRING.TIMELOG
                    ? "sidebar-menu mt-2"
                    : "sidebar-menus mt-2 "
                }
                onClick={() => {
                  setactive(STRING.TIMELOG);
                  window.history.replaceState("", "", "../timelog");
                  setTimelog((wasOpened) => !wasOpened);
                  setLeave(false);
                }}
              >
                <div className="">
                  <p className="mb-0">
                    <span className="ml-3">
                      <i className="fa fa-clock-o" aria-hidden="true"></i>
                    </span>
                    <span className="ml-3">Timelog</span>
                    <span className="right-set">
                      {Timelog === true ? (
                        <i className="fa fa-angle-up" aria-hidden="true"></i>
                      ) : (
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                      )}
                    </span>
                  </p>
                </div>
              </div>
              <div
                className={Timelog === true ? "transition-leave" : "transition"}
              >
                {Timelog === true ? (
                  <div className="row">
                    <div className="col-md-3">
                      <div
                        className="border-timeline"
                        style={{ height: "100%" }}
                      ></div>
                    </div>
                    <div className="col-md-9">
                      <Link
                        to="timelog"
                        className="text-decoration-none text-dark"
                      >
                        <div className="row">
                          <p className="mb-0">
                            <span className="time-left">My Timelog</span>
                          </p>
                        </div>
                      </Link>
                    </div>
                  </div>
                ) : null}
              </div>
            </>
          )}
          {/* <Link
            to="inventory-listing"
            className="text-decoration-none text-dark"
          >
            <div
              className={
                active == STRING.INVENTORY
                  ? "sidebar-menu mt-2"
                  : "sidebar-menus mt-2"
              }
              onClick={() => {
                setactive(STRING.INVENTORY);
                window.history.replaceState("", "", "../inventory");
              }}
            >
              <p className="mb-0">
                <span className="ml-3">
                  <i className="fa fa-clock-o" aria-hidden="true"></i>
                </span>
                <span className="ml-3">Inventory</span>
              </p>
            </div>
          </Link> */}
          {signdata?.data?.role == 1 ? (
            <>
              <div
                className={
                  active === STRING.INVENTORY
                    ? "sidebar-menu mt-2 "
                    : "sidebar-menus mt-2"
                }
                onClick={() => {
                  setactive(STRING.INVENTORY);
                  window.history.replaceState("", "", "../inventory");
                  setInventory((wasOpened) => !wasOpened);
                  setManage(false);
                  setTodo(false);
                  setPay(false);
                  setMproject(false);
                  setTask(false);
                  setTracker(false);
                  setLeave(false);
                  setTimelog(false);
                  setIsGalleryOpen(false);
                  setIsGalleryOpen(false);
                  setSupport(false);
                }}
              >
                <div className="">
                  <p className="mb-0">
                    <span className="ml-3">
                      <i className="fa fa-bullhorn" aria-hidden="true"></i>
                    </span>
                    <span className="ml-3">Inventory</span>
                    <span className="right-set">
                      {Inventory === true ? (
                        <i className="fa fa-angle-up" aria-hidden="true"></i>
                      ) : (
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                      )}
                    </span>
                  </p>
                </div>
              </div>
              <div
                className={
                  Inventory === true ? "transition-tasks" : "transition"
                }
              >
                {Inventory === true ? (
                  <div className="row">
                    <div className="col-md-3">
                      <div className="border-timeline"></div>
                    </div>
                    <div className="col-md-9">
                      <Link
                        to="add-inventory"
                        className="text-decoration-none text-dark"
                      >
                        <div className="row">
                          <p className="mb-0">
                            <span className="time-left">Add Inventory</span>
                          </p>
                        </div>
                      </Link>
                      <Link
                        to="inventory-listing"
                        className="text-decoration-none text-dark"
                      >
                        <div className="row">
                          <p className="mb-0 mt-2">
                            <span className="time-left">Listing</span>
                          </p>
                        </div>
                      </Link>
                    </div>
                  </div>
                ) : null}
              </div>
            </>
          ) : null}
          {/* Manage leave */}
          {signdata?.data?.role === 0 ? (
            <></>
          ) : (
            <>
              <div
                className={
                  active === STRING.TIMELOG
                    ? "sidebar-menus mt-2"
                    : "sidebar-menus mt-2 "
                }
              >
                <div className="">
                  <p
                    className="mb-0"
                    onClick={() =>
                      window.open(
                        "https://docs.google.com/document/d/1LJw2CE6_t_WXf5kd76NeaAxH9dQiu91MvYo_uolHpYk/edit?usp=sharing",
                        "_blank",
                        "noreferrer"
                      )
                    }
                  >
                    <span className="ml-3">
                      <i className="fa fa-clock-o" aria-hidden="true"></i>
                    </span>
                    <span className="ml-3">Rulebook</span>
                    <span className="right-set">
                      {/* <i className="fa fa-angle-down" aria-hidden="true"></i> */}
                    </span>
                  </p>
                </div>
              </div>
            </>
          )}

          {/* Logout */}
          {/* <div className="sidebar-menus mt-2">
            <div className="">
              <p className="mb-0">
                <span className="ml-3">
                  <i className="fa fa-sign-out" aria-hidden="true"></i>
                </span>
                <span className="ml-3" onClick={logout}>Logout</span>
              </p>
            </div>
          </div> */}
        </div>
      </div>
      <Outlet />
    </>
  );
};
export default Sidebar;
