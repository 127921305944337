
import {DASHBOARD_REQUEST,DASHBOARD_REQUEST_SUCCESS, DASHBOARD_REQUEST_ERROR } from "../actionType/type";

export const dashboardActionRequest = (access_token) => {
    return {
        type :  DASHBOARD_REQUEST,
        params : access_token 
        
    }
}

export const dashboardActionSuccess = (result) => {

    return {
        type: DASHBOARD_REQUEST_SUCCESS,
        payload: result,
        error: null,
    };
}

export const dashboardActionError = (error) => {
    return {
        type: DASHBOARD_REQUEST_ERROR,
        error:error,
        payload:null
    }
}
// export const actionRefreshForm = (msg) => {
//     return {
//         type: FORM_REFRESH,
//         error:null,
//         payload:null,
//         message:msg
//     }
// }
