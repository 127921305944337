import React, {createContext, useContext} from 'react';

const NotificationContext = createContext ();

export const NotificationProvider = ({children}) => {
  const showNotification = (
    title = 'Hello',
    options = {body: 'This is a template'}
  ) => {
    if (!('Notification' in window)) {
      console.error ('This browser does not support notifications.');
      return;
    }

    if (Notification.permission === 'granted') {
      new Notification (title, options);
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission ().then (permission => {
        if (permission === 'granted') {
          new Notification ('Permission Granted', {
            body: 'You can now receive notifications.',
          });
          new Notification (title, options);
        }
      });
    }
  };

  return (
    <NotificationContext.Provider value={{showNotification}}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => useContext (NotificationContext);
