import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { VIEW_PAYSLIPS } from "../../service/apiUrl";
import { postApiCall } from "../../service/PostApiCall";
import { useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import ReactToPdf from "react-to-pdf";

const ViewPayslip = (props) => {
  const [payslipData, setPayslipData] = useState({});

  const { PayslipId } = useParams();
  let componentRef = useRef(null);

  const options = {
    orientation: "landscape",
    unit: "in",
    format: [14, 12],
  };

  const signdata = useSelector((state) => state.signinReducers);
  const user_id = signdata?.data?.id;
  const access_token = signdata?.data?.access_token;

  useEffect(() => {
    if (access_token && PayslipId) {
      let params = {
        id: PayslipId,
      };
      postApiCall(params, access_token, VIEW_PAYSLIPS)
        .then((result) => {
          setPayslipData(result);
        })
        .catch((error) => {
          console.error("view-Payslip error", error);
        });
    }
  }, [PayslipId]);



  return (
    <div className={props?.data?.transtion == true ? "contents" : "content"}>
      <div className="content-top-spacer"></div>
      <div className="color-body manage">
        <div className="color-body pay-slip">
          <div className="mt-3">
            <div className="row">
              <div className="col-md-6">
                <h3>
                  {payslipData?.first_name} {payslipData?.last_name}
                </h3>
              </div>
              <div className="col-md-6">
                <div className="flex float-right">
                  <ReactToPrint
                    trigger={() => (
                      <button
                        type="button"
                        className=" py-2 px-3 btn-primary text-white rounded"
                      >
                        Print
                      </button>
                    )}
                    content={() => componentRef.current}
                  />
                  <ReactToPdf
                    targetRef={componentRef}
                    options={options}
                    filename={`${payslipData?.first_name} payslip.pdf`}
                    x={0.5}
                    y={0.5}
                    scale={0.8}
                  >
                    {({ toPdf }) => (
                      <button
                        type="button"
                        className="px-2 py-2 btn-primary text-white ml-2 rounded"
                        onClick={toPdf}
                      >
                        Download PDF
                      </button>
                    )}
                  </ReactToPdf>
                </div>
              </div>
            </div>
          </div>
          <div
            ref={componentRef}
            className="card-input container-fluid py-3 my-4"
          >
            <div className="  mt-3 bg-white">
              <h3 className="text-center mt-2">Rapidsofts</h3>
              <h3 className="text-center mt-2 mb-2">
                Salary Slip For The Month of {payslipData?.month?.label}{" "}
                {payslipData?.year}
              </h3>
              <div className="row mt-3">
                <div className="col-md-2 h6">Emp Code :</div>
                <div className="col-md-2">
                  01245
                </div>
                <div className="col-md-2 h6">Total Leaves :</div>
                <div className="col-md-2">{payslipData?.total_leaves} </div>
                <div className="col-md-2 h6">Extra Working Days :</div>
                <div className="col-md-2">
                  {payslipData?.extra_working_days}
                </div>
              </div>
              <div className="row">
                <div className="col-md-2 h6">Name :</div>
                <div className="col-md-2">
                  {payslipData?.first_name} {payslipData?.last_name}
                </div>
                <div className="col-md-2 h6">Total Salary :</div>
                <div className="col-md-2"><i className="fa fa-rupee "></i> {payslipData?.actual_salary}</div>
                <div className="col-md-2 h6">Leave Without Pay :</div>
                <div className="col-md-2">{payslipData?.taken_free_leaves}</div>
               
              </div>
              <div className="row">
                <div className="col-md-2 h6">Date of Joining :</div>
                <div className="col-md-2">21654651 </div>
                <div className="col-md-2 h6">Earned Leaves :</div>
                <div className="col-md-2">{payslipData?.paid_leaves}</div>
                <div className="col-md-2 h6">Account no :</div>
                <div className="col-md-2">
                 123456
                </div>
              </div>
              <div className="row">
                <div className="col-md-2 h6">Designation :</div>
                <div className="col-md-2">
                 Designer
                </div>
                <div className="col-md-2 h6">Deduction% :</div>
                <div className="col-md-2">
                {payslipData?.deduction_percentage}
                </div>
                <div className="col-md-2 h6">Pan no :</div>
                <div className="col-md-2">
                  BCDPR8715D
                </div>
              </div>
              <div className=" mt-4">
                <table className="table table-bordered border-dark">
                  <thead>
                    <tr>
                      <th>Pay & Allowances</th>
                      <th>Amounts</th>
                      <th>Deductions</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Basic</td>
                      <td>{payslipData?.basic_bifurcation}</td>
                      <td>Tax</td>
                      <td>{payslipData?.tax_percentage}</td>
                    </tr>
                    <tr>
                      <td>House Rent</td>
                      <td>{payslipData?.house_rent}</td>
                      <td>Leaves</td>
                      <td> {payslipData?.leave_deduction}</td>
                    </tr>
                    <tr>
                      <td>Conveyance</td>
                      <td> {payslipData?.conveyance_allowance}</td>
                      <td>Provident Fund</td>
                      <td>{payslipData?.pf}</td>
                    </tr>
                    <tr>
                      <td>Telephone</td>
                      <td> {payslipData?.internet_phone_allowance}</td>
                      <td>Employee State Insurance</td>
                      <td>{payslipData?.emp_state_insurance}</td>
                    </tr>
                    <tr>
                      <td>Medical</td>
                      <td> {payslipData?.medical_allowance}</td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Other</td>
                      <td>{payslipData?.other_bifurcation}</td>
                      <td className="h6">Total DED</td>
                      <td className="h6"><i className="fa fa-rupee mr-1"></i>0.00</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td colSpan="2" className="text-center h6">
                        Incentives
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td>Extra Working</td>
                      <td> {payslipData?.extra_working_days}</td>
                    </tr>
                    <tr>
                      <td className="h6">Gross Total</td>
                      <td className="h6"><i className="fa fa-rupee mr-1"></i>{payslipData?.credited_salary}</td>
                      <td>Random</td>
                      <td>{payslipData?.random_bonus}</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td className="h6">Total</td>
                      <td className="h6"><i className="fa fa-rupee mr-1"></i> 00</td>
                    </tr>
                  </tbody>
                </table>
                <h3>
                  Salary Credited To Your Account# :: <i className="fa fa-rupee mr-1"></i>{payslipData?.credited_salary}
                </h3>
                <p>
                  This is a Computer Generated slip does not require any
                  signatures
                </p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default ViewPayslip;
