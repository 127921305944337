import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import Utils from "../utility/utility";
import { STRING } from "../constants/string";
import { postApiCall } from "../service/PostApiCall";
import { useNavigate, useParams } from "react-router-dom";
import {
  TODO_HELPER,
  VIEW_TODO,
  UPDATE_TODO
} from "../service/apiUrl";

const EditTodo = (props) => {
  const { todoId } = useParams();
  const [task, setTask] = useState("");
  const [isCompleted, setIsCompleted] = useState(0);
  const [assignTo, setAssignTo] = useState({value: "", label: ""});  
  const [helperData, setHelperData] = useState([]);
  const navigate = useNavigate();

  const signdata = useSelector((state) => state.signinReducers);
  const user_id = signdata?.data?.id;
  const access_token = signdata?.data?.access_token;

  useEffect(() => {
    postApiCall([], access_token, TODO_HELPER)
      .then((result) => {
        setHelperData(result);
      })
      .catch((error) => {
        console.error("TODO_HELPER", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    var viewPayload = {
          id: todoId
    }

    postApiCall(viewPayload, access_token, VIEW_TODO)
      .then((result) => {
        setTask(result?.task);
        setAssignTo({value: result?.assignTo, label: result?.assignedUser});
        setIsCompleted(result?.isCompleted);
      })
      .catch((error) => {
        console.error("TODO_VIEW", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const UpdateTask = () => {
    let message = "";
    if (Utils.isEmpty(task)) {
      message = STRING.ENTER_TASK;
    } else if (Utils.isEmpty(assignTo?.value)) {
      message = STRING.ADMIN;
    } 

    if (message) {
      Utils.toast("error", message);
      return false;
    } else {
      const EditTodoParams = {
        task: task,
        assignTo: assignTo?.value,
        createdBy: user_id,
        isCompleted:isCompleted
      };

      postApiCall(EditTodoParams, access_token, UPDATE_TODO).then(
        (response) => {
          Utils.toast("success", "Todo task updated successfully!!!");
          navigate("/manage-todo");
        }
      );
    }
  };


  const handleCheckboxChange = (event) => {
    setIsCompleted(event.target.checked)
  };


  return (
    <>
      <div className={props?.data?.transtion === true ? "contents" : "content"}>
        <div className="content-top-spacer"></div>
        <div className="color-body">
          <div className="row">
            <div className="col-md-6 manage">
              <div className="card-input mt-5 mb-2">
                <div className="col-md-12">
                  <div className="mt-3">
                    <h5 className="border-bottom pb-2">Edit Todo Task</h5>
                  </div>
                  <form>

                    <div className="col-md-12 mt-3 todo">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Task</label>
                          <textarea
                            className="form-control"
                            id="exampleFormControlTextarea1"
                            value={task}
                            onChange={(e) => {
                              setTask(e.target.value);
                            }}
                            rows="4"
                          ></textarea>
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Assign to</label>
                          <Select
                            options={helperData?.admins}
                            value={assignTo}
                            onChange={(event) => {
                              setAssignTo({
                                value: event?.value,
                                label: event?.label,
                              });
                            }}

                            placeholder="Please Select"
                          />
                        </div>

                        <div className="form-group">
                          <label>Is Completed</label>
                          <label className="container mt-3 mb-2">
                            <input
                              type="checkbox"
                              checked={isCompleted}
                              onChange={(e) => setIsCompleted(e.target.checked)}
                            />
                            <span className="checkmark outline-grey"></span>
                          </label>
                        </div>


                    </div>
                    <div className="mt-5 col-md-12">
                      <div className="row mb-5 mt-4">
                        <div className="">
                          <button
                            type="button"
                            className="create-button"
                            onClick={UpdateTask}
                          >
                            Update
                          </button>
                        </div>
                        <div className="ml-2">
                          <button
                            type="button"
                            className="create-button1 btn-info"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditTodo;
