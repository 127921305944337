/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Select from "react-select";
import { default_pic_user } from "../assets";
import Utils from "../utility/utility";
import { STRING } from "../constants/string";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { USER_CREATE, USERGET_DATA, USER_UPDATE } from "../service/apiUrl";
import { postApiCall } from "../service/PostApiCall";
import { isEmpty } from "lodash";

const AddUser = (props) => {
  
  const [image, setImage] = useState({image : '', object :{}});
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");
  const [employeeData, setEmployeeData] = useState({});
  const [status, setStatus] = useState({ value: null, label: null });
  const [shift, setShift] = useState({ value: null, label: null });
  const [departments, setDepartments] = useState({ value: null, label: null });
  const [role, setRole] = useState({ value: null, label: null });
  const [teamlead, setTeamlead] = useState({ value: null, label: null });
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [contact, setContact] = useState("");
  const [helperData, setHelperData] = useState([]);
  const [passwordEye, setPasswordEye] = useState(false);
  const [passwordEyeSlash, setPasswordEyeSlash] = useState(false);
  const navigate = useNavigate();
  const signdata = useSelector((state) => state.signinReducers);
  const access_token = signdata?.data?.access_token;
  const { employee_id } = useParams();
  useEffect(() => {
    if (props.update) {
      setEmployeeData(props.employeeData);
    }
  }, [props.update]);

  const objectdata = {
    uid: employee_id,
    tab: "user_info",
    email: email,
    password: password,
    updated_password: password,
    role: role.value,
    first_name: firstname,
    last_name: lastname,
    contact: contact,
    status: status.value,
    department: departments.value,
    shift: shift.value,
    team_lead: teamlead.value,
    image: image?.object,
  };
  const validationuser = () => {
    let message = "";
    if (Utils.isEmpty(email)) {
      message = STRING.ENTER_EMAIL;
    } else if (!Utils.validateEmail(email)) {
      message = STRING.ENTER_VALID_EMAIL;
    } else if (!props.update && Utils.isEmpty(password)) {
      message = STRING.ENTER_PASSWORD;
    } else if (!Utils.isEmpty(password) && password.length < 6) {
      message = STRING.ENTER_SIX_DIGIT_PASSWORD;
    } else if (!Utils.isEmpty(password) && Utils.isEmpty(confirmpassword)) {
      message = STRING.ENTER_CONFIRM_PASSWORD;
    } else if (password !== confirmpassword) {
      message = STRING.CONFIRM_PASSWORD;
    } else if (Utils.isEmpty(role.value)) {
      message = STRING.ADMIN_ROLE;
    } else if (Utils.isEmpty(status.value)) {
      message = STRING.STATUS;
    } else if (Utils.isEmpty(firstname)) {
      message = STRING.FIRSTNAME;
    } else if (Utils.isEmpty(shift.value)) {
      message = STRING.SHIFT;
    } else if (Utils.isEmpty(departments.value)) {
      message = STRING.DEPARTMENT;
    }
    if (message) {
      Utils.toast("error", message);
      return false;
    } else {
      if (props.update && !Utils.isEmpty(employee_id)) {
        if(Utils.isEmpty(password)){
          // delete objectdata.password;
        }
        postApiCall(objectdata, access_token, USER_UPDATE,true).then((response) => {
          navigate("/manage-user");
        });
      } else {
        postApiCall(objectdata, access_token, USER_CREATE,true).then((response) => {
          navigate("/manage-user");
        });
      }

      return true;
    }
  };
  useEffect(() => {
    postApiCall([], signdata?.data?.access_token, USERGET_DATA)
      .then((result) => {
        setHelperData(result);
      })
      .catch((error) => {
        console.error("USER_HELPER", error);
      });
  }, []);



  useEffect(() => {
    if (!isEmpty(employeeData)) {
      setEmail(employeeData?.email);
      setTeamlead(employeeData?.team_lead);
      setFirstname(employeeData?.first_name);
      setLastname(employeeData?.last_name);
      setContact(employeeData?.contact);
      setStatus(employeeData?.status);
      setShift(employeeData?.shift);
      setDepartments(employeeData?.department);
      setRole(employeeData?.role);
      setImage({object:{},image:employeeData?.profile_pic ?? ""})
    }
  }, [employeeData]);

  return (
    <>
      <div
        className={
          props?.data?.transtion === true || props?.transtion === true
            ? "contents"
            : "content"
        }
      >
        <div
          className={props?.topbar_space === false ? "" : "content-top-spacer"}
        ></div>
        <div className="color-body">
          <div className="col-md-12  pading-none">
            <div className="mt-3">
              <h3> {props.update ? "" : "Add New User"} </h3>
            </div>
            <div className="row">
              <div className="col-md-6 manage pading-none">
                <div className="card-input mt-4 mb-2">
                  <div className="col-md-12">
                    <div className="mt-3">
                      <h5 className="border-bottom pb-2">Account Details</h5>
                    </div>
                    <form>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Email</label>
                            <input
                              type="text"
                              className="form-control"
                              value={email}
                              onChange={(email) => {
                                setEmail(email.target.value);
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group" style={{position:'relative'}}>
                            <label htmlFor="exampleInputEmail1">Password</label>
                            <input
                              type={`${passwordEye=== true ? 'text':'Password'}`}
                              className="form-control"
                              placeholder="********"
                              autoComplete="off"
                              value={password}
                              onChange={(password) => {
                                setPassword(password.target.value);
                              }}
                            />
                            <span
                              style={{
                                position: "absolute",
                                right: "10px",
                                top: "50%",
                                // transform: "translateY(-50%)",
                                cursor: "pointer",
                              }}
                              onClick={() => setPasswordEye(!passwordEye)}
                            >
                              {passwordEye === true ? (
                                <i
                                  className="fa fa-eye-slash"
                                  aria-hidden="true"
                                ></i>
                              ) : (
                                <i className="fa fa-eye" aria-hidden="true"></i>
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 mb-5">
                          <div className="form-group" style={{position:'relative'}}>
                            <label htmlFor="exampleInputEmail1">
                              Confirm Password
                            </label>
                            <input
                              type={`${passwordEyeSlash === true ? 'text':'Password'}`}
                              className="form-control"
                              placeholder="********"
                              value={confirmpassword}
                              autoComplete="off"
                              onChange={(e) => {
                                setConfirmpassword(e.target.value);
                              }}
                            />
                             <span
                              style={{
                                position: "absolute",
                                right: "10px",
                                top: "50%",
                                // transform: "translateY(-50%)",
                                cursor: "pointer",
                              }}
                              onClick={() => setPasswordEyeSlash(!passwordEyeSlash)}
                            >
                              {passwordEyeSlash === true ? (
                                <i
                                  className="fa fa-eye-slash"
                                  aria-hidden="true"
                                ></i>
                              ) : (
                                <i className="fa fa-eye" aria-hidden="true"></i>
                              )}
                              </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12"></div>
                    </form>
                  </div>
                </div>
                <div className="card-input mt-4 mb-2">
                  <div className="col-md-12">
                    <div className="mt-3">
                      <h5 className="border-bottom pb-2">Access Level</h5>
                    </div>
                    <div className="">
                      <div className="form-group">
                        <label>Roles</label>
                        <Select
                          options={helperData?.roles}
                          placeholder="Select Role"
                          value={role}
                          onChange={(event) => {
                            setRole({
                              value: event?.value,
                              label: event?.label,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="">
                      <label>Team Lead</label>
                      <Select
                        options={helperData?.team_lead}
                        placeholder="Select Team Lead"
                        value={teamlead}
                        onChange={(event) => {
                          setTeamlead({
                            value: event?.value,
                            label: event?.label,
                          });
                        }}
                      />
                    </div>
                    <div className="mt-3 mb-5">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Status</label>

                        <Select
                          options={helperData?.status}
                          placeholder="Select Status"
                          value={status}
                          onChange={(event) => {
                            setStatus({
                              value: event?.value,
                              label: event?.label,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="row mb-5 mt-3">
                    <div className="">
                      <button
                        type="button"
                        className="create-button"
                        onClick={validationuser}
                      >
                        {props.update ? "Update" : "Create"}
                      </button>
                    </div>
                    <div className="ml-2">
                      <button
                        type="button"
                        className="create-button1 btn-info"
                        onClick={() => {
                            navigate("/manage-user");                          
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 manage">
                <div className="card-input mt-4 mb-2">
                  <div className="col-md-12">
                    <div className="mt-3">
                      <h5 className="border-bottom pb-2">
                        Personal Information
                      </h5>
                    </div>
                    <div className="">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Firstname</label>
                        <input
                          type="text"
                          className="form-control"
                          value={firstname}
                          onChange={(name) => {
                            setFirstname(name.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Lastname</label>
                        <input
                          type="text"
                          className="form-control"
                          value={lastname}
                          onChange={(name) => {
                            setLastname(name.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Contact</label>
                        <input
                          type="text"
                          className="form-control"
                          value={contact}
                          onChange={(contact) => {
                            setContact(contact.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Shift</label>
                        <Select
                          options={helperData?.shifts}
                          placeholder="Select Shift"
                          value={shift}
                          onChange={(event) => {
                            setShift({
                              value: event?.value,
                              label: event?.label,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Departments</label>
                        <Select
                          options={helperData?.departments}
                          placeholder="Select Department"
                          value={departments}
                          onChange={(event) => {
                            setDepartments({
                              value: event?.value,
                              label: event?.label,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="">
                      <div className="row mt-3 mb-4">
                        <div className="ml-3">
                          <img
                          // src={image?.image ?? default_pic_user}
                            src={image?.image !="" ? image?.image : default_pic_user }
                            className="profile-head"
                            alt=""
                          />
                        </div>
                        <div className="ml-3 mt-4">
                          <div className="row mt-2">
                            <label
                              htmlFor="filePicker"
                              className="change-btn1 Blender-Strong"
                            >
                              Change
                            </label>
                            <input
                              style={{
                                visibility: "hidden",
                                display: "none",
                              }}
                              type="file"
                              id="filePicker"
                              className="change-btn Blender-Strong"
                              accept="image/png, image/gif, image/jpeg"
                              // onChange={handleChange}
                              onChange={(e)=>
                                setImage({object:e.target.files[0],image:URL.createObjectURL(e.target.files[0])})
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddUser;
