import { put, call, takeLatest } from "redux-saga/effects";
import { LOGIN_URL } from "../../service/apiUrl";
import { postApiCall } from "../../service/PostApiCall";
import Utils from "../../utility/utility";
import { actionSigninError, actionSigninSuccess } from "../action/signinAction";
import { SIGNIN_REQUEST } from "../actionType/type";

function* signinSaga(action){
    try {
        const response = yield call(postApiCall,action?.params,action?.params?.access_token,LOGIN_URL)
        yield put(actionSigninSuccess(response));
        action.onSuccess(response)
    } catch (error) {
        Utils.toast('error',error?.data?.message);
        yield put(actionSigninError(error));
    }
}

export function* watchSigninSaga(){
    yield takeLatest(SIGNIN_REQUEST,signinSaga)
}