import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import "./assets/style.css";
import { Accordion, Modal, Button, Form } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import { FaEye, FaTimes, FaTrash } from "react-icons/fa";
import { FaUpload } from "react-icons/fa6";
import axiosInstance from "../../api/DropboxApi";
import { Loader } from "rsuite";
import Utils from "../../utility/utility";
import ImageWithSkeleton from "../../components/skeletonEffect/ImageWithSkeleton";
import Resizer from 'react-image-file-resizer';

const GalleryViewer = (props) => {
  const { id } = useParams();
  const [images, setImages] = useState([]);
  const [isCarouselOpen, setCarouselOpen] = useState(false);
  const [selectedAlbum, setSelectedAlbum] = useState(null);
  const [initialSlide, setInitialSlide] = useState(0);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [folderId, setFolderId] = useState(null);
  const [fieldValue, setFieldValue] = useState({
    title: "",
    uploadFiles: [],
  });
  const [isError, setError] = useState({
    title: false,
    files: false,
  });
  const [isDeleteOpen, setDeleteOpen] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);
  const [deletedId, setDeletedId] = useState(null);
  const [isImageId, setImageId] = useState(false);
  const [deleteImageId, setDeleteImageId] = useState("");

  const handleFolderData = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.post(
        "https://api.dropboxapi.com/2/files/list_folder",
        {
          path: `${id}`,
        }
      );

      if (response.status === 200) {
        const folderData = response.data.entries.filter(
          (data) => data[".tag"] === "folder"
        );

        const formattedImages = await Promise.all(
          folderData.map(async (folder) => {
            const folderPath = folder.path_display;

            const folderContentsResponse = await axiosInstance.post(
              "https://api.dropboxapi.com/2/files/list_folder",
              {
                path: folderPath,
              }
            );

            if (folderContentsResponse.status === 200) {
              const folderContents = folderContentsResponse.data.entries;
              const files = folderContents.filter(
                (entry) => entry[".tag"] === "file"
              );

              const fileDataPromises = files.map(async (file) => {
                const filePath = file.path_display;
                const fileLink = await getTemporaryLink(filePath);
            

                return {
                  id: file.id,
                  originalName: file.name,
                  fileLink: fileLink || file.path_display,
                  path_display: file.path_display,
                  size: file.size || "Unknown",
                  type: file.name.match(/\.(jpg|jpeg|webp|png|gif)$/i)
                    ? "images"
                    : "video",
                };
              });

              const fileData = await Promise.all(fileDataPromises);

              return {
                id: folder.id,
                folderDeatils: {
                  id: folder.id,
                  name: folder.name,
                  type: "images",
                },
                title: folder.name,
                data: fileData,
              };
            } else {
              setLoading(false);
              console.error(
                `Error fetching contents of folder '${folder.name}':`,
                folderContentsResponse.status
              );
              return null;
            }
          })
        );
        const validFormattedImages = formattedImages.filter(
          (item) => item !== null
        );
        setImages(validFormattedImages);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching folder data:", error);
    }
  };

  useEffect(() => {
    if (id) {
      handleFolderData();
    }
  }, [id]);

  const getTemporaryLink = async (filePath) => {
    try {
      const response = await axiosInstance.post(
        "https://api.dropboxapi.com/2/files/get_temporary_link",
        {
          path: filePath,
        }
      );

      if (response.status === 200) {
        return response.data.link;
      } else {
        throw new Error("Failed to get temporary link");
      }
    } catch (error) {
      console.error("Error getting temporary link:", error);
      return null;
    }
  };

  const openCarousel = (album, index) => {
    setSelectedAlbum(album);
    setInitialSlide(index);
    setCarouselOpen(true);
  };

  const closeCarousel = () => {
    setCarouselOpen(false);
    setSelectedAlbum(null);
  };

  const handleUploadButtonClick = (albumTitle, folderId) => {
    if (albumTitle !== "") {
      setFieldValue({ ...fieldValue, title: albumTitle });
      setFolderId(folderId);
    }
    if (typeof albumTitle === "object") {
      setFieldValue({ ...fieldValue, title: "" });
    }

    setShowUploadModal(true);
  };

  const handleUploadModalClose = () => {
    setFieldValue({ ...fieldValue, title: "", uploadFiles: [] });
    setShowUploadModal(false);
  };

  const handleChange = (event) => {
    setFieldValue({ ...fieldValue, [event.target.name]: event.target.value });
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    if (droppedFiles.length > 0) {
      const newFiles = Array.from(droppedFiles);
      setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };

  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
    setFieldValue({ ...fieldValue, uploadFiles: files });
  };

  const handleFileRemove = (index) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const checkOrCreateFolder = async (parentPath, folderName) => {
    console.log("foldername ", folderName);
    try {
      // List folders in the parent path
      const response = await axiosInstance.post(
        "https://api.dropboxapi.com/2/files/list_folder",
        {
          path: `${parentPath}`,
          recursive: true,
          include_deleted: false,
          include_media_info: false,
          include_mounted_folders: true,
        }
      );

      if (response.status === 200) {
        // Find the folder by ID

        let details = response.data.entries.find(
          (item) => item[".tag"] === "folder" && item.id === id
        );

        let folderDetails = response.data.entries.find(
          (item) => item[".tag"] === "folder" && item.id === folderId
        );

        if (folderDetails) {
          return folderDetails;
        } else {
          // Folder not found, create a new one
          const createFolderResponse = await axiosInstance.post(
            "https://api.dropboxapi.com/2/files/create_folder_v2",
            {
              path: `${details.path_display}/${folderName}`,
              autorename: true,
            }
          );

          if (createFolderResponse.status === 200) {
            console.log("Folder created successfully.");
            return createFolderResponse.data.metadata;
          } else {
            console.error(
              "Failed to create folder:",
              createFolderResponse.status
            );
            throw new Error("Failed to create folder");
          }
        }
      } else {
        console.error("Failed to list folder contents:", response.status);
        throw new Error("Failed to list folder contents");
      }
    } catch (error) {
      console.error("Error in checkOrCreateFolder:", error.message);
      throw error;
    }
  };

  const renameFolder = async (currentPath, newFolderName) => {
    try {
      const newPath = `${currentPath.substring(
        0,
        currentPath.lastIndexOf("/")
      )}/${newFolderName}`;

      const response = await axiosInstance.post(
        "https://api.dropboxapi.com/2/files/move_v2",
        JSON.stringify({
          from_path: currentPath,
          to_path: newPath,
          allow_shared_folder: false,
          autorename: false,
        })
      );

      console.log("Folder renamed successfully:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error renaming folder:", error.message);
      throw error;
    }
  };

  // const handleUploadData = async (event) => {
  //   event.preventDefault();

  //   if (fieldValue.title === "") {
  //     setError({ ...isError, title: true });
  //     Utils.toast("error", "Title is required.");
  //     return;
  //   }
  //   try {
  //     let folderDetails;

  //     if (folderId !== undefined) {
  //       let data = await checkOrCreateFolder(id, fieldValue.title);

  //       folderDetails = data;

  //       if (data.id === folderId && data.name !== fieldValue.title) {
  //         await renameFolder(data.path_display, fieldValue.title);
  //         folderDetails = data;
  //         // return (folderDetails = renamedFolder.metadata);
  //       }
  //     } else {
  //       folderDetails = await checkOrCreateFolder(id, fieldValue.title);
  //     }
  //     setLoading(true);

  //     const uploadPromises = selectedFiles.map(async (file) => {
  //       try {
  //         const dropboxApiArg = JSON.stringify({
  //           autorename: false,
  //           mode: "add",
  //           mute: false,
  //           path: `${folderDetails.path_display}/${file.name}`,
  //           strict_conflict: false,
  //         });

  //         const uploadResponse = await axiosInstance.post(
  //           "https://content.dropboxapi.com/2/files/upload",
  //           file,
  //           {
  //             headers: {
  //               "Content-Type": "application/octet-stream",
  //               "Dropbox-API-Arg": dropboxApiArg,
  //             },
  //           }
  //         );

  //         if (uploadResponse.status === 200) {
  //           console.log(`File '${file.name}' uploaded successfully.`);
  //           return uploadResponse.data;
  //         } else {
  //           console.error(
  //             `Failed to upload file '${file.name}':`,
  //             uploadResponse.status
  //           );
  //           return null;
  //         }
  //       } catch (error) {
  //         setLoading(false);
  //         console.error(`Error uploading file '${file.name}':`, error.message);
  //         return null;
  //       }
  //     });

  //     const uploadedFiles = await Promise.all(uploadPromises);

  //     const getTemporaryLinks = async (files) => {
  //       const temporaryLinkPromises = files.map(async (file) => {
  //         try {
  //           const linkResponse = await axiosInstance.post(
  //             "https://api.dropboxapi.com/2/files/get_temporary_link",
  //             {
  //               path: file.path_display,
  //             }
  //           );

  //           if (linkResponse.status === 200) {
  //             return linkResponse.data.link;
  //           } else {
  //             console.error(
  //               `Failed to get temporary link for '${file.name}':`,
  //               linkResponse.status
  //             );
  //             return null;
  //           }
  //         } catch (error) {
  //           console.error("Error fetching temporary link:", error.message);
  //           return null;
  //         }
  //       });

  //       return await Promise.all(temporaryLinkPromises);
  //     };

  //     const temporaryLinks = await getTemporaryLinks(uploadedFiles);
  //     const newImages = uploadedFiles
  //       .filter((file) => file !== null)
  //       .map((file, index) => ({
  //         id: file.id,
  //         originalName: file.name,
  //         fileLink: temporaryLinks[index],
  //         type: file.name.match(/\.(jpg|jpeg|png|gif)$/i) ? "images" : "video",
  //       }));

  //     const updatedImages = [...images];
  //     const albumIndex = updatedImages.findIndex(
  //       (album) => album.title === fieldValue.title
  //     );

  //     if (albumIndex !== -1) {
  //       updatedImages[albumIndex] = {
  //         ...updatedImages[albumIndex],
  //         folderDetails: {
  //           id: folderDetails.id,
  //           name: fieldValue.title,
  //           type: "images",
  //         },
  //         data: [...updatedImages[albumIndex].data, ...newImages],
  //       };
  //     } else {
  //       const newAlbum = {
  //         id: updatedImages.length + 1,
  //         folderDetails: {
  //           id: folderDetails.id,
  //           name: fieldValue.title,
  //           type: "images",
  //         },
  //         title: fieldValue.title,
  //         data: newImages,
  //       };
  //       updatedImages.push(newAlbum);
  //     }

  //     setLoading(false);
  //     setImages(updatedImages);

  //     setFieldValue({ title: "", uploadFiles: [] });
  //     setSelectedFiles([]);
  //     setShowUploadModal(false);
  //     setError({ ...isError, title: false, files: false });
  //     Utils.toast("success", "Image Upload Successfully.");
  //   } catch (error) {
  //     console.error("Error uploading data:", error.message);
  //   }
  // };

  const handleUploadData = async (event) => {
    event.preventDefault();
  
    if (fieldValue.title === '') {
      setError({ ...isError, title: true });
      Utils.toast('error', 'Title is required.');
      return;
    }
  
    try {
      let folderDetails;
  
      // Check if folder exists or create it
      if (folderId !== undefined) {
        let data = await checkOrCreateFolder(id, fieldValue.title);
  
        folderDetails = data;
  
        if (data.id === folderId && data.name !== fieldValue.title) {
          await renameFolder(data.path_display, fieldValue.title);
          folderDetails = data;
        }
      } else {
        folderDetails = await checkOrCreateFolder(id, fieldValue.title);
      }
  
      setLoading(true);
  
      // Resize and upload images
      const uploadPromises = selectedFiles.map(async (file) => {
        try {
          // Resizing the image
          const imageData = await resizeFile(file);
  
          // Log the results
          console.log('Original File:', imageData.originalName, `${imageData.originalSizeMB} MB`);
          console.log('New File:', imageData.newName, `${imageData.newSizeMB} MB`);
          console.log('Size Reduced By:', `${imageData.reducedSizeMB} MB`);
  
          // Prepare the upload request
          const dropboxApiArg = JSON.stringify({
            autorename: false,
            mode: 'add',
            mute: false,
            path: `${folderDetails.path_display}/${imageData.newName}`,
            strict_conflict: false,
          });
  
          // Upload the resized image to Dropbox
          const uploadResponse = await axiosInstance.post(
            'https://content.dropboxapi.com/2/files/upload',
            imageData.newFile, // Use resized image here
            {
              headers: {
                'Content-Type': 'application/octet-stream',
                'Dropbox-API-Arg': dropboxApiArg,
              },
            }
          );
  
          if (uploadResponse.status === 200) {
            console.log(`File '${imageData.originalName}' uploaded successfully.`);
            return uploadResponse.data;
          } else {
            console.error(`Failed to upload file '${imageData.originalName}':`, uploadResponse.status);
            return null;
          }
        } catch (error) {
          setLoading(false);
          console.error(`Error uploading file '${file.name}':`, error.message);
          return null;
        }
      });
  
      const uploadedFiles = await Promise.all(uploadPromises);
  
      // Get temporary links for uploaded files
      const getTemporaryLinks = async (files) => {
        const temporaryLinkPromises = files.map(async (file) => {
          try {
            const linkResponse = await axiosInstance.post(
              'https://api.dropboxapi.com/2/files/get_temporary_link',
              {
                path: file.path_display,
              }
            );
  
            if (linkResponse.status === 200) {
              return linkResponse.data.link;
            } else {
              console.error(`Failed to get temporary link for '${file.name}':`, linkResponse.status);
              return null;
            }
          } catch (error) {
            console.error('Error fetching temporary link:', error.message);
            return null;
          }
        });
  
        return await Promise.all(temporaryLinkPromises);
      };
  
      const temporaryLinks = await getTemporaryLinks(uploadedFiles);
  
      const newImages = uploadedFiles
        .filter((file) => file !== null)
        .map((file, index) => ({
          id: file.id,
          originalName: file.name,
          fileLink: temporaryLinks[index],
          type: file.name.match(/\.(jpg|jpeg|png|gif)$/i) ? 'images' : 'video',
        }));
  
      const updatedImages = [...images];
      const albumIndex = updatedImages.findIndex((album) => album.title === fieldValue.title);
  
      if (albumIndex !== -1) {
        updatedImages[albumIndex] = {
          ...updatedImages[albumIndex],
          folderDetails: {
            id: folderDetails.id,
            name: fieldValue.title,
            type: 'images',
          },
          data: [...updatedImages[albumIndex].data, ...newImages],
        };
      } else {
        const newAlbum = {
          id: updatedImages.length + 1,
          folderDetails: {
            id: folderDetails.id,
            name: fieldValue.title,
            type: 'images',
          },
          title: fieldValue.title,
          data: newImages,
        };
        updatedImages.push(newAlbum);
      }
  
      setLoading(false);
      setImages(updatedImages);
  
      setFieldValue({ title: '', uploadFiles: [] });
      setSelectedFiles([]);
      setShowUploadModal(false);
      setError({ ...isError, title: false, files: false });
      Utils.toast('success', 'Image Upload Successfully.');
    } catch (error) {
      console.error('Error uploading data:', error.message);
    }
  };
  
  // Function to resize the file
  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1920, // Maximum width
        1920, // Maximum height
        'JPEG', // Format (can change to 'WEBP' or others)
        50, // Reduce quality to 50% to reduce size
        0, // Rotation
        (uri) => {
          const originalSizeMB = file.size / 1024 / 1024;
          const newFile = new File([uri], file.name, { type: 'image/jpeg' });
          const newSizeMB = newFile.size / 1024 / 1024;
  
          // Calculate the reduced size
          const reducedSize = (originalSizeMB - newSizeMB).toFixed(2);
  
          resolve({
            originalFile: file,
            originalName: file.name,
            originalSizeMB: originalSizeMB.toFixed(2),
            newFile,
            newName: newFile.name,
            newSizeMB: newSizeMB.toFixed(2),
            reducedSizeMB: reducedSize,
          });
        },
        'blob' // Output format as blob
      );
    });
  const handleDeleteOpen = (item, indexKey) => {
    setDeleteOpen(!isDeleteOpen);
    setDeleteItem(item);
    setDeletedId(item.id);
  };

  const handleDeleteImage = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.post(
        "https://api.dropboxapi.com/2/files/delete_v2",
        JSON.stringify({
          path: deleteItem.id,
        })
      );

      if (response.status === 200) {
        setLoading(false);

        const updatedImages = images.map((folder) => {
          return {
            ...folder,
            data: folder.data.filter((image) => image.id !== deleteItem.id),
          };
        });

        const nonEmptyImages = updatedImages.filter(
          (folder) => folder.data.length > 0
        );

        setImages(nonEmptyImages);
        setDeleteOpen(false);
        Utils.toast("success", "Image deleted successfully.");
      }
    } catch (error) {
      setLoading(false);
      Utils.toast("error", "An error occurred while deleting the file.");
    }
  };

  const handleDeleteFolder = async (item, pathId) => {
    setDeleteOpen(!isDeleteOpen);
    console.log("sds=d> 0sd", item);
    setDeleteItem(item);
    setDeletedId(pathId);
  };

  const deleteImageItem = async (item) => {
    setImageId(true);
    setDeleteImageId(item.id);
    setDeleteItem(item)
  };


  const deleteImageItems = async (fileId) => {
    try {
      setLoading(true);
      const response = await axiosInstance.post(
        "https://api.dropboxapi.com/2/files/delete_v2",
        {
          path: `${deleteItem.path_display}` 
        },
      );
  
      if (response.status === 200) {
      
        setImages((prevImages) =>
          prevImages.map((folder) => ({
            ...folder,
            data: folder.data.filter((file) => file.id !== deleteItem.id)
          }))
        );
        setImageId(false)
              setDeleteItem(null)
              setDeleteImageId(null)
              setLoading(false)
        Utils.toast("success", "Image deleted successfully.");
      }
    } catch (error) {
      setLoading(false)
      Utils.toast("error", "Unable to delete image.");
      console.error('Error deleting file:', error);
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div className={props?.data?.transition === true ? "contents" : "content"}>
      <div className="gallery-viewer">
        <div className="gallery-content">
          <div className="galleryHeader">
            <div className="gallery-title">Gallery</div>
            <div>
              <button
                className="view-all-button create-button"
                onClick={handleUploadButtonClick}
              >
                Upload Image
              </button>
            </div>
          </div>
          <Accordion defaultActiveKey="0">
            {images.length > 0 ? (
              images.map((item, index) => (
                <Accordion.Item
                  key={item.id}
                  eventKey={String(index)}
                  className="gallery-section"
                >
                  <Accordion.Header key={item.id}>
                    <div className="section-header">
                      <div className="section-title">{item.title}</div>
                      <div className="section-info">
                        <span className="date">24 July 2024</span>
                        <span className="photo-count">
                          {item.data.length} Photos
                        </span>
                        <span
                          className="photo-count"
                          onClick={() =>
                            handleUploadButtonClick(item.title, item.id)
                          }
                          style={{
                            cursor: "pointer",
                            position: "relative",
                            zIndex: 2,
                            fontSize: 20,
                          }}
                        >
                          <FaUpload />
                        </span>
                        <span
                          className="photo-count"
                          onClick={() => handleDeleteFolder(item, item.id)}
                          style={{
                            cursor: "pointer",
                            position: "relative",
                            zIndex: 2,
                            fontSize: 20,
                          }}
                        >
                          <FaTrash color="#DE3163" />
                        </span>
                      </div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body className="image-grid">
                    {item.data.length > 0 ? (
                      item.data.map((image, imageIndex) => (
                        <>
                          {image.type === "images" && (
                            <div key={image.id} className="image-wrapper">
                              <ImageWithSkeleton
                                width={"100%"}
                                height={"100%"}
                                src={image.fileLink}
                              />
                              <div className="icon-overlay">
                                <span
                                  onClick={() => openCarousel(item, imageIndex)}
                                >
                                  <FaEye className="eye-icon" />
                                </span>
                                {/* <span onClick={() =>handleDeleteOpen(image,imageIndex)}>
                                  <FaTrash className="delete-icon" />
                                </span> */}
                                <span onClick={() => deleteImageItem(image)}>
                                  <FaTrash className="delete-icon" />
                                </span>
                              </div>
                            </div>
                          )}
                          {image.type === "video" && (
                            <div key={image.id} className="image-wrapper">
                              <video
                                style={{ width: "50%", height: "50%" }}
                                // controls
                                // autoPlay
                              >
                                <source src={image.fileLink} type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>
                              <div className="icon-overlay">
                                <span
                                  onClick={() => openCarousel(item, imageIndex)}
                                >
                                  <FaEye className="eye-icon" />
                                </span>
                                {/* <span onClick={()=> handleDeleteOpen(image,imageIndex)}>
                                  <FaTrash className="delete-icon" />
                                </span> */}
                                <span
                                  onClick={() => console.log("images ", image)}
                                >
                                  <FaTrash className="delete-icon" />
                                </span>
                              </div>
                            </div>
                          )}
                        </>
                      ))
                    ) : (
                      <div className="image-wrapper fallback">
                        <img
                          src="https://t3.ftcdn.net/jpg/05/52/37/18/360_F_552371867_LkVmqMEChRhMMHDQ2drOS8cwhAWehgVc.png"
                          alt="No images available"
                          className="gallery-image"
                          width="50%"
                        />
                      </div>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              ))
            ) : (
              <p className="no-images">
                {isLoading === true ? <Loader /> : " No images available."}
              </p>
            )}
          </Accordion>
        </div>

        {isCarouselOpen && selectedAlbum && (
          <div className="carousel-backdrop">
            <Swiper
              key={selectedAlbum.id}
              slidesPerView={1}
              spaceBetween={30}
              loop={true}
              pagination={{
                clickable: true,
                el: ".custom-pagination",
                renderBullet: (index, className) => {
                  return `<img src="${selectedAlbum.data[index].fileLink}" class="${className}" />`;
                },
              }}
              navigation={true}
              modules={[Pagination, Navigation]}
              initialSlide={initialSlide}
              className="swiper-container"
            >
              {selectedAlbum.data.map((image) => (
                <SwiperSlide key={image.id}>
                  {image.type === "images" ? (
                    <img
                      src={image.fileLink}
                      alt={image.originalName}
                      className="caraouselSlide-image"
                    />
                  ) : (
                    <video
                      style={{ width: "100%", height: "100%" }}
                      controls
                      autoplay
                    >
                      <source src={image.fileLink} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  )}
                </SwiperSlide>
              ))}
            </Swiper>
            <FaTimes
              className="close-carousel-icon"
              onClick={closeCarousel}
              style={{
                position: "absolute",
                top: "20px",
                right: "20px",
                fontSize: "24px",
                color: "#fff",
                cursor: "pointer",
              }}
            />
            <div className="custom-pagination"></div>
          </div>
        )}

        <Modal show={showUploadModal} onHide={handleUploadModalClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Upload Image or Video</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="upload-section">
              <Form id="uploadForm" onSubmit={handleUploadData}>
                <Form.Group controlId="titleInput">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your title"
                    name="title"
                    value={fieldValue.title}
                    onChange={handleChange}
                    isInvalid={isError.title}
                  />
                  <Form.Control.Feedback type="invalid">
                    Title is required.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="fileInput">
                  <Form.Label className="upload-label">
                    {selectedFiles.length > 0
                      ? "Your selected Files are"
                      : "Drag & drop files here or click to select files"}
                  </Form.Label>
                  <div
                    className={
                      selectedFiles.length > 0
                        ? "uploadSections"
                        : "uploadSection"
                    }
                    onDrop={handleDrop}
                    onDragOver={(event) => event.preventDefault()}
                  >
                    {selectedFiles.length > 0 ? (
                      selectedFiles.map((file, index) => (
                        <div key={index} className="preview-item p-2 m-2">
                          {file?.type?.split("/")[0] === "image" ? (
                            <img
                              src={URL.createObjectURL(file)}
                              alt={file.name}
                              className="preview-image"
                            />
                          ) : (
                            <video controls className="preview-video">
                              <source
                                src={URL.createObjectURL(file)}
                                type={file.type}
                              />
                              Your browser does not support the video tag.
                            </video>
                          )}
                          <button
                            className="remove-button "
                            onClick={() => handleFileRemove(index)}
                          >
                            <FaTimes />
                          </button>
                        </div>
                      ))
                    ) : (
                      <div className="upload-input">
                        {/* <button
                          variant="primary"
                          onClick={() => fileUploadRef.current.click()}
                          className="customUpload create-button"
                        >
                          Upload Files
                        </button> */}
                        <Form.Control
                          type="file"
                          accept=".png, .jpeg, .jpg, .gif, .mp4"
                          onChange={handleFileSelect}
                          multiple
                          // ref={fileUploadRef}
                          // style={{ display: "none" }}
                          custom
                        />
                      </div>
                    )}
                  </div>
                </Form.Group>
              </Form>
              <div className="preview-container"></div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                setFieldValue({ ...fieldValue, title: "", uploadFiles: [] });
                setSelectedFiles([]);
                setShowUploadModal(false);
              }}
            >
              Close
            </Button>
            <button
              // variant="primary"
              type="submit"
              form="uploadForm"
              className="create-button"
              disabled={isLoading === true ? true : false}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {isLoading ? (
                <span>
                  <Loader /> Uploading...
                </span>
              ) : (
                "Upload"
              )}
            </button>
          </Modal.Footer>
        </Modal>

        {isDeleteOpen == true && (
          <Modal show={isDeleteOpen} centered>
            <Modal.Header closeButton>
              <Modal.Title>Delete Image or Video</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure you want to delete this image or video?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setDeleteOpen(false)}>
                Close
              </Button>
              <Button
                variant="danger"
                onClick={handleDeleteImage}
                disabled={isLoading ? true : false}
              >
                {isLoading ? (
                  <>
                    <Loader /> Deleting...
                  </>
                ) : (
                  "Delete"
                )}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        {isImageId === true && (
          <Modal show={isImageId} centered>
            <Modal.Header closeButton>
              <Modal.Title>
                Delete File?
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure you want to delete this image or video?</p>
              <p>Image Id: {deleteImageId}</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setImageId(false)}>
                Close
              </Button>
              <Button
                variant="danger"
                onClick={deleteImageItems}
                disabled={isLoading ? true : false}
              >
                {isLoading ? (
                  <>
                    <Loader /> Deleting...
                  </>
                ) : (
                  "Delete"
                )}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default GalleryViewer;
