import { put, call, takeLatest } from "redux-saga/effects";
import { MANAGE_USERS } from "../../service/apiUrl";
import { manageUserActionSuccess , manageUserActionError} from "../action/manageUserAction";
import { MANAGE_USER_REQUEST } from "../actionType/type";
import { postApiCall } from "../../service/PostApiCall";

function* manageUserSaga(action){
    try {
        const response = yield call(postApiCall,action?.params,action?.params?.access_token,MANAGE_USERS)
        yield put(manageUserActionSuccess(response));
    } catch (error) {
        yield put(manageUserActionError(error));
    }
}

export function* watchManageUserSaga(){
    yield takeLatest(MANAGE_USER_REQUEST,manageUserSaga)
}