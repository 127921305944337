
import { VIEW_CLICKUP_REQUEST, VIEW_CLICKUP_REQUEST_SUCCESS, VIEW_CLICKUP_REQUEST_ERROR, } from "../actionType/type";

export const viewClickupActionRequest = (params) => {
    return {
        type :  VIEW_CLICKUP_REQUEST,
        params : params 
        
    }
}

export const viewClickupActionSuccess = (result) => {

    return {
        type: VIEW_CLICKUP_REQUEST_SUCCESS,
        payload: result,
        error: null,
    };
}

export const viewClickupActionError = (error) => {
    return {
        type: VIEW_CLICKUP_REQUEST_ERROR,
        error:error,
        payload:null
    }
}
