/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import DatePicker from "react-datepicker";
import { useSelector, useDispatch } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { Chart } from "react-google-charts";
import { manageLeaveActionRequest } from "../redux/action/manageLeaveAction";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import { postApiCall } from "../service/PostApiCall";
import { LEAVE_DELETE, LEAVEMANAGEHELPER } from "../service/apiUrl";
import Utils from "../utility/utility";
import ReactPaginate from "react-paginate";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { FaArrowUp } from "react-icons/fa";

const customStylesDelete = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
  },
};
const ManageLeave = (props) => {
  const filterDate = JSON.parse(localStorage.getItem("leaveDateFilter"));
  const filterByName = JSON.parse(localStorage.getItem("filterName"));
  const getInitialPage = () => {
    const savedPage = JSON.parse(localStorage.getItem('leavePagination'));
    return savedPage !== null && !isNaN(savedPage.currentPage) ? parseInt(savedPage.currentPage, 10) : 1;
  };

  const [chart, setChart] = useState();
  const [deletModalIsOpen, setDeletModalIsOpen] = useState(false);
  const [deleteLeaveId, setDeleteLeaveId] = useState("");
  const [update, setUpdate] = useState(false);
  const [helperData, setHelperData] = useState([]);
  const [userSearch, setUserSearch] = useState(
    filterByName != null ? filterByName : {value:"",label:""}
  );
  const [fromDate, setFromDate] = useState(
    filterDate != null
      ? new Date(filterDate.fromDate)
      : Utils.getCurrentMonthDates().start
  );
  const [toDate, setToDate] = useState(
    filterDate != null
      ? new Date(filterDate.toDate)
      : Utils.getMonthLastDate(fromDate)
  );
  const [currentPage, setCurrentPage] = useState(getInitialPage);
  const [pageSize, setPageSize] = useState(10);
  const [filteredParams, setFilteredParams] = useState({
    fromDate: Utils.getCurrentMonthDates().start,
    toDate: Utils.getMonthLastDate(fromDate),
    uid: null,
  });

  const dispatch = useDispatch();
  const leaveManage = useSelector((state) => state?.manageLeaveReducer);
  const location = useLocation();
  useEffect(() => {
    const data = [
      ["Leave", "leaves"],
      ["Pending Leaves", parseInt(leaveManage?.data?.extraDetails?.pending)],
      [
        "Unsanction Leaves",
        parseInt(leaveManage?.data?.extraDetails?.unsanction),
      ],
      [
        "Dispproved Leaves",
        parseInt(leaveManage?.data?.extraDetails?.disApproved),
      ],
      ["Hold Leaves", parseInt(leaveManage?.data?.extraDetails?.hold)],
    ];
    setChart(data);
  }, [leaveManage]);

  const options = {
    title: "Manage All Leaves",
    sliceVisibilityThreshold: 0.2,
  };

  const signdata = useSelector((state) => state.signinReducers);
  // const user_id = signdata?.data?.id;
  const access_token = signdata?.data?.access_token;

  const closeModal = () => {
    setDeletModalIsOpen(false);
    setDeleteLeaveId("");
  };

  const deleteModel = (user) => {
    setDeleteLeaveId(user?.id);
    setDeletModalIsOpen(true);
  };

  const deleteSubmit = () => {
    const deleteObject = {
      id: deleteLeaveId,
      showSuccessToast: true,
    };

    postApiCall(deleteObject, access_token, LEAVE_DELETE).then((response) => {
      setDeletModalIsOpen(false);
      setUpdate(!update);
    });
    return true;
  };

  useEffect(() => {
    postApiCall([], access_token, LEAVEMANAGEHELPER)
      .then((result) => {
        setHelperData(result);
      })
      .catch((error) => {
        console.error("LEAVEMANAGEHELPER", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    setFilteredParams({
      fromDate: queryParams.get("fromDate"),
      toDate: queryParams.get("toDate"),
      uid: queryParams.get("uid"),
    });

    if (queryParams.get("fromDate")) {
      setFromDate(new Date(+queryParams.get("fromDate") * 1000));
    }
    if (queryParams.get("uid")) {
      setUserSearch({ value: queryParams.get("uid") });
    }
    if (queryParams.get("toDate")) {
      setToDate(new Date(+queryParams.get("toDate") * 1000));
    }
    setUpdate(!update);
  }, [location.search]);

  useEffect(() => {
    const parms = {
      access_token: access_token,
      pageSize: pageSize,
      page: currentPage,
      uid: userSearch?.value,
      start_time: Utils.fromDateTimeStamp(fromDate),
      end_time: Utils.toDateTimeStamp(toDate),
    };

    localStorage.setItem(
      "leaveDateFilter",
      JSON.stringify({ fromDate: fromDate, toDate: toDate })
    );
    setFilteredParams({
      fromDate: parms.start_time,
      toDate: parms.end_time,
      uid: userSearch?.value,
    });

    dispatch(manageLeaveActionRequest(parms, access_token));
  }, [update]);

  // const filterLeaves = (statusID) => {
  //   const currentTimestampInSeconds = moment().unix();
  //   const date = new Date().getTime()
  //   console.log("sdsds=>",date)

  // const threeMonthsLaterTimestamp = moment().add(3, 'months').valueOf();

  //   localStorage.removeItem("leavePagination")
  //   setCurrentPage(1)
  //   // localStorage.removeItem("leavePagination")
 
  //   localStorage.removeItem("filterName");
  //   setUserSearch({value:"",label:""})
  //   const parms = {
  //     access_token: access_token,
  //     status: statusID,
  //     pageSize: pageSize,
  //     page: currentPage,
  //     // start_time: Utils.fromDateTimeStamp(fromDate),
  //     // end_time: Utils.toDateTimeStamp(toDate),
  //   };
  //   console.log("sds-> ",+statusID === 7)
  //   if(+statusID === 7){
  //     parms.start_time = currentTimestampInSeconds;
  //     parms.end_time = threeMonthsLaterTimestamp;
  //    return dispatch(manageLeaveActionRequest(parms));
  //   }
  //   else if(+statusID === 8){

  //   }

  //   dispatch(manageLeaveActionRequest(parms));
  // };

  const filterLeaves = (statusID) => {
    const currentTimestampInSeconds = moment().unix();
    const tenDaysAgoTimestampInSeconds = moment().subtract(20, 'days').unix();
    const threeMonthsLaterTimestamp = moment().add(3, 'months').unix();
    console.log("cuureent next timestamp::==> ",threeMonthsLaterTimestamp)

  
    localStorage.removeItem("leavePagination");
    setCurrentPage(1);
  
    localStorage.removeItem("filterName");
    setUserSearch({ value: "", label: "" });
  
    const parms = {
      access_token: access_token,
      status: statusID,
      pageSize: pageSize,
      page: currentPage,
    };
  
    if (+statusID === 7) {
      delete parms.status
      parms.start_time = currentTimestampInSeconds;
      parms.end_time = threeMonthsLaterTimestamp;
    } else if (+statusID === 8) {
      delete parms.status
      parms.start_time = tenDaysAgoTimestampInSeconds;
      parms.end_time = currentTimestampInSeconds;
    }
  
    dispatch(manageLeaveActionRequest(parms));
  };
  

  const searchClick = () => {
    setUpdate(!update);
  };

  const handlePageChange = (pageNumber) => {
    const page = pageNumber?.selected + 1;
    localStorage.setItem("leavePagination", JSON.stringify({type:"manage-leave", currentPage: page }));
    setCurrentPage(page);
    setUpdate(!update);
  };
  const formatTime = (time) => {
    return moment(time, "HH:mm:ss").format("hh:mm A");
  };

  const getDisplayDate = (type, duration, timeStart, timeEnd) => {
    switch (type) {
      case "Half day":
        return duration == "First Half"
          ? "09:00 AM - 02:00 PM"
          : "02:45 PM - 06:45 PM";

      case "Short Leave":
        return `${formatTime(timeStart)} - ${formatTime(timeEnd)}`;

      case "Full day":
        return `09:00 AM - 06:45 PM`;

      default:
        return "Invlaid Time.";
    }
  };

  const clearFilter = () => {
    // localStorage.removeItem("leaveDateFilter");
    // setCurrentPage(1)
    localStorage.removeItem("filterName");
    let lsds = Utils.getCurrentMonthDates().start;
    let currentLastDate = Utils.getMonthLastDate(fromDate);
    setFromDate(lsds);
    setToDate(currentLastDate);
    setUpdate(!update);
  };
  const customStyles = {
    menu: (css) => ({
      ...css,
      width: 'auto',
      minWidth: '100%',
    }),
    control: (css) => ({
      ...css,
      display: "inline-flex",
      width: '100%',
    }),
    valueContainer: (css) => ({
      ...css,
      width: '9rem',
    }),
  };

  return (
    <>
      <div className={props?.data?.transtion == true ? "contents" : "content"}>
        <div className="content-top-spacer"></div>
        <div className="color-body">
          <div className="col-md-12">
            <div className="chart-width mt-4">
              {/* <div className="text-right">
                <button type="button" className="reset-button mr-4">
                  Monthly Leaves
                </button>
                <button type="button" className="reset-button">
                  Download Leaves
                </button>
              </div> */}
              <div className="mt-3 card-input p-2">
                <Chart
                  chartType="PieChart"
                  data={chart}
                  options={options}
                  width={"100%"}
                  height={"400px"}
                />
              </div>
              <div className="mt-3 leaveDropbox">
                <div>
                <button
                  type="button"
                  className="reset-button mr-2 mt-1"
                  onClick={() => filterLeaves(0)}
                >
                  All
                </button>
                <button
                  type="button"
                  className="reset-button mr-2 mt-1"
                  onClick={() => filterLeaves(4)}
                >
                  <i class="fa fa-hourglass-start mr-1" aria-hidden="true"></i>
                  {/* <i className="fa fa-pause" aria-hidden="true"></i>  */}
                  Pending(
                  {leaveManage?.data?.extraDetails?.pending})
                </button>
                <button
                  type="button"
                  className="reset-button mr-2 mt-1"
                  onClick={() => filterLeaves(2)}
                >
                  <i
                    className="fa fa-thumbs-o-down mr-1"
                    aria-hidden="true"
                  ></i>
                  Disapproved({leaveManage?.data?.extraDetails?.disApproved})
                </button>
                <button
                  type="button"
                  className="reset-button mr-2 mt-1"
                  onClick={() => filterLeaves(3)}
                >
                  <i className="fa fa-pause" aria-hidden="true"></i> Hold(
                  {leaveManage?.data?.extraDetails?.hold})
                </button>
                <button
                  type="button"
                  className="reset-button mr-2 mt-1"
                  onClick={() => filterLeaves(6)}
                >
                  <i
                    className="fa fa-thumbs-o-down mr-1"
                    aria-hidden="true"
                  ></i>
                  Unsanctioned({leaveManage?.data?.extraDetails?.unsanction})
                </button>
                </div>
                <div>
                <button
                  type="button"
                  className="reset-button mr-2 mt-1"
                  onClick={() => filterLeaves(7)}
                >
                  <FaArrowUp className="mr-1 mb-1" style={{color:"#fff"}} />
                  Upcoming Leave
                </button>
                <button
                  type="button"
                  className="reset-button mr-2 mt-1"
                  onClick={() => filterLeaves(8)}
                >
                  Recent Leaves
                </button>
                </div>
               
                {/* <button type="button" className="reset-button mr-2 mt-1" onClick={() => filterLeaves(1)}>
                  Short({leaveManage?.data?.extraDetails?.shortLeave})
                </button> */}
                {/* <button type="button" className="reset-button mr-2 mt-1">
                  <i className="fa fa-adjust mr-1" aria-hidden="true"></i>
                  Uncompensated(2)
                </button> */}
                {/* <button type="button" className="reset-button mr-2 mt-1">
                  <i className="fa fa-gift" aria-hidden="true"></i> Special(
                  {leaveManage?.data?.extraDetails?.specialLeave})
                </button> */}
              </div>
            </div>
            <div className="card-input mt-3">
              <div className="col-md-12 mb-3">
                <div className="mt-4">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-6 d-flex">
                            <div className="">
                              <label className="mb-0">
                                <b>From Date:</b>
                              </label>
                              <DatePicker
                                selected={fromDate}
                                onChange={(date) => setFromDate(date)}
                                className="width-date1 mt-1"
                              />
                            </div>
                            <div className="">
                              <label className="mb-0">
                                <b>To Date:</b>
                              </label>
                              <DatePicker
                                selected={toDate}
                                onChange={(date) => setToDate(date)}
                                className="width-date1 mt-1"
                              />
                            </div>
                            <div className="mt-4">
                              <button
                                type="button"
                                className="reset-button ml-2 mr-2"
                                onClick={searchClick}
                              >
                                Search
                              </button>
                            </div>
                            <div className="mt-4">
                              <button
                                type="button"
                                className="reset-button mr-2"
                                onClick={clearFilter}
                                style={{ width: "8em" }}
                              >
                                clear filter
                              </button>
                            </div>
                          </div>
                          <div
                            className="col-md-6 d-flex"
                            style={{
                              justifyContent: "end",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <h3>Filter by name: </h3>

                            <Select
                              options={helperData?.users}
                              className={"mt-2 mb-2 "}
                              isSearchable={true}
                              isClearable={true}
                              value={userSearch}
                              placeholder="Select name..."
                              styles={customStyles}
                              // styles={{
                              //   menu: (css) => ({
                              //     ...css,
                              //     width: "100px",
                              //   }),
                              //   control: (css) => ({
                              //     ...css,
                              //     display: "inline-flex ",
                              //   }),
                              //   valueContainer: (css) => ({
                              //     ...css,
                              //     width: "100px",
                              //   }),
                              // }}
                              onChange={(event) => {
                             
                                if (event == null) {
                                  localStorage.removeItem("leavePagination")
                                  setCurrentPage(1)
                                  localStorage.removeItem("filterName");
                                } else {
                                  localStorage.removeItem("leavePagination")
                                  setCurrentPage(1)
                                  localStorage.setItem(
                                    "filterName",
                                    JSON.stringify(event)
                                  );
                                }
                                console.log("sadas-", event);
                                setUserSearch({
                                  value: event?.value,
                                  label: event?.label,
                                });
                                setUpdate(!update);
                              }}
                            />
                            <div></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="mt-3">
                      <table className="table table-sm">
                        <thead>
                          <tr>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">S.No</span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">Name</span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">Type</span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">Duration</span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">
                                Time Frame
                              </span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">Status</span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">
                                Leave Date
                              </span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">
                                Applied Date
                              </span>
                            </th>
                            <th scope="col" className="site-color ">
                              <span
                                className="mt-2 mb-2 d-flex"
                                style={{ width: "10rem" }}
                              >
                                Updated By
                              </span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">Manage</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody style={{ textAlign: "center" }}>
                          {leaveManage?.data?.leaves.length ? (
                            <>
                              {leaveManage?.data?.leaves.map((data, idx) => {
                                return (
                                  <tr key={idx} className="manageLeave">
                                    <td>
                                      <span className="mt-4 d-flex mr-4">
                                        {(currentPage - 1) * pageSize +
                                          (idx + 1)}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="mt-3 d-flex">
                                        {data.user.name}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="mt-3 d-flex">
                                        {data?.type}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="mt-3 d-flex">
                                        {data?.duration}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="mt-3 d-flex">
                                        {getDisplayDate(
                                          data?.type,
                                          data?.duration,
                                          data?.time_from,
                                          data?.time_to
                                        )}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="mt-3 d-flex">
                                        {data?.status}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="mt-3 d-flex">
                                        {Utils.timeStampToMDY(
                                          data?.from_timestamp
                                        )}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="mt-3 d-flex">
                                        {Utils.timeStampToMDY2(
                                          data?.created_at
                                        )}
                                      </span>
                                    </td>
                                    <td
                                      style={{
                                        borderBottom: "1px solid #DEE2E6",
                                      }}
                                    >
                                      <span className="mt-3 d-flex">
                                        {data?.updated_by?.name}
                                      </span>
                                    </td>

                                    <td
                                      style={{
                                        borderBottom: "1px solid #DEE2E6",
                                      }}
                                    >
                                      <div className="row mt-3 mb-3">
                                        {/* <div className="ml-3 mr-3">
                                          <Link
                                            to={`/edit-leave/${data?.id}?fromDate=${filteredParams.fromDate}&toDate=${filteredParams.toDate}&uid=${data.uid}`}
                                          >
                                            <div className="">
                                              <i
                                                className="fa fa-pencil site-color"
                                                aria-hidden="true"
                                              ></i>
                                            </div>
                                          </Link>
                                        </div> */}
                                        <div className="ml-3 mr-3">
                                          <Link
                                            to={`/edit-leave/${data?.id}?uid=${data?.uid}`}
                                          >
                                            <div className="">
                                              <i
                                                className="fa fa-pencil site-color"
                                                aria-hidden="true"
                                              ></i>
                                            </div>
                                          </Link>
                                        </div>
                                        <div className="">
                                          <i
                                            className="site-color fa fa-trash"
                                            aria-hidden="true"
                                            onClick={() => {
                                              deleteModel(data);
                                            }}
                                          ></i>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                              <tr>
                                <td colSpan={8} className="text-center w-100">
                                  <ReactPaginate
                                    previousLabel={"Previous"}
                                    nextLabel={"Next"}
                                    pageCount={leaveManage?.data?.total_pages}
                                    onPageChange={handlePageChange}
                                    containerClassName={"pagination"}
                                    activeClassName={"active"}
                                    forcePage={currentPage - 1}
                                  />
                                </td>
                              </tr>
                            </>
                          ) : (
                            <tr>
                              <td colSpan="8">No record found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>

                      <Modal
                        isOpen={deletModalIsOpen}
                        ariaHideApp={false}
                        onRequestClose={closeModal}
                        style={customStylesDelete}
                        contentLabel="Example Modal"
                      >
                        <div className="row">
                          <div className="col-md-6">
                            <h5 className="mb-0">Delete User</h5>
                          </div>
                          <div className="col-md-6 text-right">
                            <i
                              className="fa fa-times curser-point"
                              aria-hidden="true"
                              onClick={closeModal}
                            ></i>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <p>Are you sure you want to delete this leave ?</p>
                          </div>
                          <div className="col-md-12 mt-2">
                            <button
                              type="button"
                              className="reset-button"
                              onClick={deleteSubmit}
                            >
                              Delete
                            </button>
                            <button
                              type="button"
                              className="button-default ml-2"
                              onClick={closeModal}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageLeave;
