import { put, call, takeLatest } from "redux-saga/effects";
import { DASHBOARD_REQUEST } from "../actionType/type";
import { dashboardActionSuccess , dashboardActionError } from "../action/dashboardAction";
import { DASHBOARD } from "../../service/apiUrl";
import { postApiCall } from "../../service/PostApiCall";

function* dashboardSaga(action){
    try {
        const response = yield call(postApiCall,action?.params,action?.params?.access_token,DASHBOARD)
        yield put(dashboardActionSuccess(response
            ));
        // action.onSuccess(response)
    } catch (error) {
        yield put(dashboardActionError(error));
    }
}

export function* watchdashboardSaga(){
    yield takeLatest(DASHBOARD_REQUEST,dashboardSaga)
}