import React from 'react'
import { useSelector } from 'react-redux';
import AdminTimelog from "./../Timelog";
import DeveloperTimelog from "./DeveloperTimelog";

const Timelog = (props) => {

    const signdata = useSelector((state) => state.signinReducers);

    return (
        <>
            {
                signdata?.data?.role == 1 ?
                    <AdminTimelog data={{ transtion: props?.data?.transtion }} />
                    :
                    signdata?.data?.role != 1 ?
                        <DeveloperTimelog data={{ transtion: props?.data?.transtion }} />
                        :
                        ""
            }
        </>
    )
}

export default Timelog