import React from 'react'
import { useSelector } from 'react-redux';
import AdminLeave from "./../AddLeave";
import AddDeveloperLeave from './AddDeveloperLeave';

const AddLeave = (props) => {

    const signdata = useSelector((state) => state.signinReducers);

    return (
        <>
            {
                signdata?.data?.role == 1 ?
                    <AdminLeave data={{ transtion: props?.data?.transtion }} />
                    :
                    signdata?.data?.role !== 1 ?
                        <AddDeveloperLeave data={{ transtion: props?.data?.transtion }} />
                        :
                        ""
            }
            
        </>
    )
}

export default AddLeave