import { useState } from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import Modal from "react-modal";
import { useEffect } from "react";
import Utils from "../utility/utility";
import { STRING } from "../constants/string";

const Addtasks = ({ index, dropdowns, on_close_container, on_change, task, isView }) => {

  const [show, setShow] = useState(false);
  const [tracker, setTracker] = useState({});
  const [project, setProject] = useState({});


  const RenderProjectsList = () => {

    return (
      dropdowns?.projectsList?.map((val, i) => {
        return (
          <option key={i} value={val.id}>{val.name}</option>
        )
      })
    );

  }

  const RenderProjectsTrackersList = () => {

    if (dropdowns?.projectTrackersList?.length == 0) return (<></>);

    return (
      dropdowns?.projectTrackersList?.[index]?.map((val, i) => {
        return (
          <option key={i} value={val?.id}>{val?.name}</option>
        )
      })
    );

  }

  const RenderBillingTypes = () => {

    return (
      <>
        <option value={''}>Select Status</option>
        {
          dropdowns?.billingTypes?.[index]?.map((val, i) => {
            return (
              <option key={i} value={val?.value}>{val?.label}</option>
            )
          })
        }
      </>
    );

  }

  const RenderClickupAccounts = () => {

    return (
      <>
        {
          dropdowns?.clickupAccounts?.map((val, i) => {
            return (
              <option key={i} value={val?.id}>{val?.email}</option>
            )
          })
        }
      </>
    );

  }

  useEffect(() => {

    if (task?.project_id) {
      const projects = dropdowns?.projectsList?.filter(val => val.id == task.project_id)
      setProject(projects?.length ? projects[0] : {});
    } else {
      setProject({});
    }

  }, [task?.project_id])

  useEffect(() => {
    const tracker = dropdowns?.projectTrackersList?.[index]?.filter(value => value.id == task?.billing_id)
    if (tracker?.[0]) {
      setTracker(tracker?.[0])
    } else { setTracker({}) }
  }, [index,dropdowns,task?.billing_id])

  return (
    <>
      <div className="mt-3 card-input">
        <div className="row">
          <div className="col-md-4">
            <div className="col-md-12 mt-2">
              <div className="form-group">
                <label>Select Project</label>
                <select disabled={isView} onChange={on_change} name="project_id" value={task?.project_id} className="form-control">
                  <option value=''>Select Project</option>
                  {<RenderProjectsList />}
                </select>
              </div>
              <div className="form-group">
                <label>Status</label>
                <select disabled={isView} onChange={on_change} name="billing_type" value={task?.billing_type} className="form-control">
                  {<RenderBillingTypes />}
                  {dropdowns?.isOffline?.[index] === '1' ? <option value={`0`}>Offline</option> : ''}
                </select>
              </div>
              {
                task?.billing_type != '0' ?
                  <div className="form-group">
                    <label>Select Billing Id</label>
                    <select disabled={isView} onChange={on_change} name="billing_id" value={task?.billing_id} className="form-control">
                      <option value=''>Select Billing Id</option>
                      {<RenderProjectsTrackersList />}
                    </select>
                  </div>
                  : ''
              }
              {
                dropdowns?.clickupAccounts?.length > 0 ?
                  <div className="form-group">
                    <label>Select Clickup Id</label>
                    <select disabled={isView} onChange={on_change} name="clickup_id" value={task?.clickup_id} className="form-control">
                      <option value=''>Select Clickup Id</option>
                      {<RenderClickupAccounts />}
                    </select>
                  </div>
                  : ''
              }
            </div>
          </div>
          <div className="col-md-4">
            <div className="col-md-12 mt-2">
              <div className="form-group">
                <div>
                  <label>Working Hours</label>
                </div>
                <input disabled={isView} name="working_hrs" onChange={on_change} value={task?.working_hrs} type="number" onKeyPress={Utils.numberFilter} className={`form-control ${dropdowns?.errors[index]?.working_hrs === true ? 'error' : ''}`} />
                {
                  dropdowns?.errors[index]?.working_hrs === true ?
                    <p className="text-error">{STRING.ERROR_WORKING_HOURS}</p>
                    :
                    <p className="text-error"></p>

                }
              </div>
            </div>
            {task?.billing_id && (task?.billing_type == '1' || task?.billing_type == '2') ? <div className="col-md-12 z-1">
              <div className="float-right curser-point text-underline" onClick={() => setShow(true)}>Tracker Details</div>
            </div> : ''
            }
            <div className="col-md-12 mt-2">
              <div className="form-group">
                <label>Description</label>

                <textarea disabled={isView} name="task_description" onChange={on_change} value={task?.task_description} rows="4" className="form-control" />
              </div>
            </div>
          </div>

          <div className="col-md-4 ml-0">
            {!isView ?<div className="row justify-content-end">
              <div className=" pr-4">
                <span className="close-task-container" onClick={on_close_container}><FontAwesomeIcon icon={faTimes} /></span>
              </div>
            </div> :''}
            {
              task?.project_id ?
                <div className="col-md-12 px-0 mt-2" style={{
                  fontSize: "small"
                }}>
                  <h5 className="text-capitalize">{project?.name}</h5>
                  <hr className="" />
                  <div className="d-flex">
                    <div className="mr-1 my-2 font-weight-bold" style={{ width: "150px" }}>Team Leader <span className="float-right">::</span></div>
                    <div className="ml-1 my-2"></div>
                  </div>
                  <div className="d-flex">
                    <div className="mr-1 my-2 font-weight-bold" style={{ width: "150px" }}>Total Allocated Hours <span className="float-right">::</span></div>
                    <div className="ml-1 my-2">{project?.estimated_hour ?? '0'} Hours</div>
                  </div>
                  <div className="d-flex">
                    <div className="mr-1 my-2 font-weight-bold" style={{ width: "150px" }}>Total Hours Consumed <span className="float-right">::</span></div>
                    <div className="ml-1 my-2">{`${project?.activity?.offline + project?.activity?.online} Hours(${project?.activity?.online} online+${project?.activity?.offline} Offline)`}</div>
                  </div>
                  <div className="d-flex">
                    <div className="mr-1 my-2 font-weight-bold" style={{ width: "150px" }}>Online Hours Pending <span className="float-right">::</span></div>
                    <div className="ml-1 my-2">{(project?.estimated_hour - project?.activity?.online) ?? 'NAN'} Hours</div>
                  </div>
                  {task?.clickup_id ? 
                    <div className="d-flex">
                      <div className="mr-1 my-2 font-weight-bold" style={{ width: "150px" }}>Clickup Details <span className="float-right">::</span></div>
                      <div className="ml-1 my-2">{(task?.clickup_email + " / "+ task?.clickup_password)}</div>
                    </div> 
                    : ""
                  }
                </div>
                : ""
            }
          </div>

        </div>
      </div>
      <Modal
        isOpen={show}
        ariaHideApp={false}
        contentLabel="Tracker Details"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "40%",
          },
        }}
        onRequestClose={() => setShow(false)}

      >
        <div className="col-md-12">
          <div className="text-right">
            <i
              className="fa fa-times curser-point"
              aria-hidden="true"
              onClick={() => setShow(false)}
            ></i>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group billing-details">
                <label>Billing Details</label>
                <p className="mb-0">
                  Username: <span>{tracker?.username}</span><i className={"fa fa-clone ml-2 curser-point"} onClick={() => { Utils.copyToClipboard(tracker?.username) }} />
                </p>
                <p className="mt-0">
                  Password: <span>{tracker?.password}</span><i className={"fa fa-clone ml-2 curser-point"} onClick={() => { Utils.copyToClipboard(tracker?.password) }} />
                </p>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Addtasks;
