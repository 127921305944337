/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import {} from "../assets";
import { INVENTORY_DELETE, VIEW_INVENTORY } from "../service/apiUrl";
import { postApiCall } from "../service/PostApiCall";
import { useDispatch, useSelector } from "react-redux";
import { inventoryRequest } from "../redux/action/inventoryAction";
import ReactPaginate from "react-paginate";
import Modal from "react-modal";
import Select from "react-select";
import { Link } from "react-router-dom";
import { STRING } from "../constants/string";
import Utils from "../utility/utility";
import { USERGET_DATA} from "../service/apiUrl";

const InventoryListing = (props) => {
  const [searchData, setSearchData] = useState({
    searchName: "",
    searchSerialNumber: "",
    searchBrand: ""
  });

  const handleSearch = (event) => {
    const { name, value } = event.target;
    setSearchData(prevSearchData => ({
      ...prevSearchData,
      [name]: value
    }));
    setUpdate(!update);
  };

  const [searchType, setSearchType] = useState({ value: "", label: "" });
  const [searchAssignTo, setSearchAssignTo] = useState({ value: "", label: "" });



  const helperDataType = [
    {value : 'keyboard',label : 'Keyboard'},
    {value : "mouse",label : "Mouse"},
    {value : "destop",label : "Destop"},
    {value : "cpu",label : "CPU"},
    {value : "UPS",label : "UPS"},
    {value : "laptop",label : "Laptop"},
    {value : "datacable",label : "Datacable"},
    {value : "ctype datacable",label : "Ctype Datacable"},
    {value : "mobile",label : "Mobile"},
    {value : "multiport",label : "Multiport"},
   ]


  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [deleteModalIsOpen, setdeleteModalIsOpen] = React.useState(false);
  const [update, setUpdate] = useState(false);
  const [typeOption , setOption] = useState([]);
  const [helperData, setHelperData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const [viewData, setViewData] = useState("");
  const [deleteId, setDeleteId] = useState();
  const dispatch = useDispatch();
  const signdata = useSelector((state) => state.signinReducers);
  const inventoryData = useSelector((state) => state.inventoryReducers);
  console.log("viewData", viewData);
  //   const user_id = signdata?.data?.id;
  const access_token = signdata?.data?.access_token;
  const handlePageChange = (pageNumber) => {
    const page = pageNumber?.selected + 1;
    setCurrentPage(page);
    setUpdate(!update);
  };
  useEffect(() => {
    const parms = {
      name: searchData.searchName,
      serialNumber: searchData.searchSerialNumber,
      brand: searchData.searchBrand,
      type: searchType.value,
      assignTo: searchAssignTo.value,
      access_token: access_token,
      pageSize: pageSize,
      page: currentPage,
    };
    dispatch(inventoryRequest(parms));
  }, [update]);
  function openModal() {
    setIsOpen(true);
  }
  const viewModel = (id) => {
    const iddata = {
      id: id,
    };
    postApiCall(iddata, access_token, VIEW_INVENTORY).then((data) => {
      setViewData(data);
    });
    openModal();
  };
  function closeModal() {
    setIsOpen(false);
    setdeleteModalIsOpen(false);
  }
  

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "60%",
    },
  };
  const customStylesDelete = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "30%",
    },
  };
  const deletedata = () => {
    const iddelete = {
      id: deleteId,
    };
    postApiCall(iddelete, access_token, INVENTORY_DELETE).then(
      (response) => {
        setUpdate(!update)
        Utils.toast("success", STRING.INVENTORY_SUCCESSFUL_Delete );
      }
    );
    setdeleteModalIsOpen(false);
  };
  
  function openDeleteModal(id) {
    setDeleteId(id);
    setdeleteModalIsOpen(true);
  }

  useEffect(() => {
    postApiCall([], signdata?.data?.access_token, USERGET_DATA)
      .then((result) => {
        setHelperData(result?.all_users);
      })
      .catch((error) => {
        console.error("USER_HELPER", error);
      });
  }, []);

  return (
    <>
      {/* {announcementreduce?.isLoading == true ? <LoadingBox /> : null} */}
      <div className={props?.data?.transtion == true ? "contents" : "content"}>
        <div className="content-top-spacer"></div>
        <div className="color-body">
          <div className="col-md-12">
            <div className="card-input mt-3">
              <div className="col-md-12 mb-3">
                <div className="mt-3">
                  <h5>Inventory</h5>
                </div>
                <div className="mt-4">
                  <div className="">
                    <div className="mt-3">
                      <table className="table table-sm">
                        <thead>
                          <tr>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">#</span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">Name</span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">
                                Serial Number
                              </span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">Brand</span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">Type</span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">
                                Assign to
                              </span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">Manage</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td></td>
                            <td>
                              <input
                                type="text"
                                name="searchName"
                                className="w-75 listing-serach min-h-38 mt-2 mb-2"
                                value={searchData.searchName}
                                onChange={handleSearch}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="searchSerialNumber"
                                className="w-75 listing-serach min-h-38 mt-2 mb-2"
                                value={searchData.searchSerialNumber}
                                onChange={handleSearch}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="searchBrand"
                                className="w-75 listing-serach min-h-38 mt-2 mb-2"
                                value={searchData.searchBrand}
                                onChange={handleSearch}
                              />
                            </td>
                            <td>
                              <Select
                                options={helperDataType}
                                className="w-100 listing-serach mt-2 mb-2"
                                placeholder="Select"
                                isSearchable={true}
                                isClearable={true}
                                value={searchType}
                                styles={{
                                  menu: (base) => ({
                                    ...base,
                                    width: "max-content",
                                    minWidth: "100%",
                                  }),
                                }}
                                onChange={(event)=>{
                                  setSearchType({
                                    value: event?.value,
                                    label: event?.label,
                                  });
                                  setUpdate(!update);  
                                
                                }} 
                              />
                            </td>
                            <td>
                            <Select
                                options={helperData}
                                className="w-100 listing-serach mt-2 mb-2"
                                placeholder="Select"
                                isSearchable={true}
                                isClearable={true}
                                value={searchAssignTo}
                                styles={{
                                  menu: (base) => ({
                                    ...base,
                                    width: "max-content",
                                    minWidth: "100%",
                                  }),
                                }}
                                onChange={(event)=>{
                                  setSearchAssignTo({
                                    value: event?.value,
                                    label: event?.label,
                                  });
                                  setUpdate(!update);                                  
                                }}  
                              />
                            </td>
                            </tr>
                          <>
                            {inventoryData?.data?.trackers?.map((data, idx) => {
                              return (
                                <tr>
                                  <td>
                                    <span className="mt-3 d-flex mr-4 mb-3">
                                      {(currentPage - 1) * pageSize + (idx + 1)}
                                    </span>
                                  </td>
                                  <td className="w-des">
                                    <span className="mt-3 d-flex length-des mb-3">
                                      {data?.name}
                                    </span>
                                  </td>
                                  <td className="w-des">
                                    <span className="mt-3 d-flex length-des mb-3">
                                      {data?.serial_number}
                                    </span>
                                  </td>
                                  <td className="w-des">
                                    <span className="mt-3 d-flex length-des mb-3">
                                      {data?.brand}
                                    </span>
                                  </td>
                                  <td className="w-des">
                                    <span className="mt-3 d-flex length-des mb-3">
                                      {data?.type}
                                    </span>
                                  </td>
                                  <td className="w-des">
                                    <span className="mt-3 d-flex length-des mb-3">
                                      {data?.first_name  + " " + data?.last_name}
                                    </span>
                                  </td>
                                  <td>
                                    <div className="d-flex mt-4">
                                      <div className="mr-3">
                                        <i
                                          className="fa fa-eye site-color curser-point"
                                          aria-hidden="true"
                                          onClick={() => {
                                            viewModel(data?.id);
                                          }}
                                        ></i>
                                      </div>
                                      <div className="">
                                        <Link to={"/edit-inventory/" + data?.id}>
                                        <i
                                          className="fa fa-pencil site-color curser-point"
                                          aria-hidden="true"
                                        ></i></Link>
                                      </div>

                                      <div className="ml-3">
                                        <i
                                          className="site-color fa fa-trash curser-point"
                                          aria-hidden="true"
                                          onClick={() =>
                                            openDeleteModal(data?.id)
                                          }
                                        ></i>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                            <tr>
                              <td colSpan={7} className="text-center w-100">
                                <ReactPaginate
                                  previousLabel={"Previous"}
                                  nextLabel={"Next"}
                                  pageCount={inventoryData?.data?.total_pages}
                                  onPageChange={handlePageChange}
                                  containerClassName={"pagination"}
                                  activeClassName={"active"}
                                />
                              </td>
                            </tr>
                          </>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Example Modal"
      >
        <div className="d-flex border-bottom mt-2 mb-4">
          <div className="col-md-6">
            <h5>Inventory Information</h5>
          </div>
          <div className="col-md-6 text-right">
            <i
              className="fa fa-times curser-point"
              aria-hidden="true"
              onClick={closeModal}
            ></i>
          </div>
        </div>

        <div className="col-md-12">
          <div className="row">
            {/* <div className="col-md-12">
                            
                            <div className="border-bottom mt-2 mb-2"></div>
                          </div> */}

            <div className="col-md-12 d-flex">
              <div className="col-md-6">
                <b>Name</b>
                <p className="mb-0">{viewData?.name}</p>
              </div>
              <div className="col-md-6">
                <b>Serial Number</b>
                <p>{viewData?.serial_number}</p>
              </div>
            </div>

            <div className="col-md-12 d-flex">
              <div className="col-md-6">
                <b>Brand</b>
                <p className="mb-0">{viewData?.brand}</p>
              </div>
              <div className="col-md-6">
                <b>Type</b>
                <p>{viewData?.type}</p>
              </div>
            </div>

            <div className="col-md-12 d-flex">
              <div className="col-md-6">
                <b>Assign to</b>
                <p className="mb-0">{viewData?.first_name+' '+viewData?.last_name}</p>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      
      <Modal
                        isOpen={deleteModalIsOpen}
                        onRequestClose={closeModal}
                        preventScroll={true}
                        style={customStylesDelete}
                        contentLabel="Delete TAsk"
                        ariaHideApp={false}
                      >
                        <div className="">
                          <div className="col-md-12 mb-3">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-4">
                                  <h5>Delete Tasks</h5>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="mb-4">
                                  <p>
                                    Are you sure you want to delete this
                                    Inventory ?
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12 text-right">
                                <button
                                  type="button"
                                  className="button-default"
                                  onClick={closeModal}
                                >
                                  Cancel
                                </button>
                                <button
                                  type="button"
                                  className="create-button ml-2"
                                  onClick={deletedata}
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal>
    </>
  );
};

export default InventoryListing;
