import React from 'react';
import styles from './RestrictedAccessPage.module.css';
import CompanyImage from '../../assets/images/rapid.png';
const RestrictedAccessPage = () => {
  return (
    <div className={styles.mainContent}>
      <div className={styles.container}>
        <div className={styles.header_logo}>
          <img src={CompanyImage} alt="Rapidsofts" className={styles.logo} />
        </div>
        {/* <div className={styles.icon}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.0"
            width="50px"
            height="50px"
            viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet"
          >
            <g
              transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
              fill="#FF0000"
              stroke="none"
            >
              <path d="M2400 5114 c-542 -47 -966 -204 -1378 -511 -431 -322 -766 -806 -917 -1324 -71 -244 -99 -448 -99 -719 0 -271 28 -475 99 -719 232 -798 855 -1444 1651 -1709 265 -88 500 -125 799 -126 275 0 477 27 724 99 578 168 1090 550 1424 1060 209 320 336 664 393 1060 24 165 24 505 0 670 -80 558 -311 1039 -689 1431 -410 427 -916 685 -1507 769 -104 15 -414 27 -500 19z m389 -929 c173 -25 350 -80 502 -156 198 -99 437 -296 572 -473 313 -411 415 -937 277 -1437 -149 -542 -591 -986 -1133 -1138 -308 -87 -655 -79 -957 20 -231 76 -444 205 -623 378 -254 245 -403 514 -479 864 -19 87 -22 133 -22 317 0 237 12 321 74 511 160 488 561 889 1048 1048 87 29 253 66 332 74 93 10 313 6 409 -8z" />
              <path d="M2402 3950 c-227 -29 -454 -115 -641 -243 -98 -67 -281 -250 -349 -349 -63 -93 -141 -246 -175 -348 -51 -149 -70 -271 -71 -445 0 -245 44 -430 155 -645 73 -141 84 -156 101 -143 9 7 2095 1732 2127 1760 12 10 -152 148 -247 207 -75 47 -214 113 -296 140 -181 60 -431 87 -604 66z" />
              <path d="M3070 2824 c-349 -289 -826 -684 -1059 -877 -233 -194 -429 -355 -435 -359 -14 -10 22 -47 119 -123 260 -203 538 -299 865 -299 383 0 716 138 985 409 272 272 409 602 409 985 0 271 -58 477 -202 728 -20 34 -39 62 -42 62 -3 0 -291 -237 -640 -526z" />
            </g>
          </svg>
        </div> */}
        <div className={styles.message}>
          <p>You cannot access this site on a mobile device.</p>
          {/* <p>Please access the website using a laptop or desktop computer.</p> */}
        </div>
      </div>
    </div>
  );
};

export default RestrictedAccessPage;
