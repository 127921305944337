import {
    PROJECT_REQUEST,
    PROJECT_REQUEST_ERROR,
    PROJECT_REQUEST_SUCCESS,
    PROJECT_DELETE_REQUEST,
    PROJECT_DELETE_REQUEST_SUCCESS,
    PROJECT_DELETE_REQUEST_ERROR
} from "../actionType/type";

const initialState = {
    data: null,
    isLoading: false,
    refreshPage: false
};

export const manageProjectReducer = (state = initialState, action) => {
    let object;
    switch (action.type) {
        case PROJECT_REQUEST:
            object = {
                ...state,
                isLoading: true,
            }
            break
        case PROJECT_REQUEST_ERROR:
            object = {
                ...state,
                isLoading: false
            }
            break
        case PROJECT_REQUEST_SUCCESS:
            object = {
                ...state,
                data: action.payload,
                isLoading: false
            }
            break

        case PROJECT_DELETE_REQUEST:
            object = {
                ...state,
                isLoading: true,
            };

            break;

        case PROJECT_DELETE_REQUEST_SUCCESS:
            object = {
                ...state,
                isLoading: false,
            };
            break;

        case PROJECT_DELETE_REQUEST_ERROR:
            object = {
                ...state,
                isLoading: false,
            };
            break;

        default:
            object = state
            break
    }

    return object
}