/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import {} from "../assets";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { manageAnnouncementActionRequest } from "../redux/action/manageAnnouncementAction";
import Utils from "../utility/utility";
import Modal from "react-modal";
import Select from "react-select";
import DatePicker from "react-datepicker";
import LoadingBox from "../components/LoadingBox";
import { Link } from "react-router-dom";
import { DELETE_ANNOUNCEMENTS } from "../service/apiUrl";
import { postApiCall } from "../service/PostApiCall";
import { ANOUNCEMENT_HELPER } from "../service/apiUrl";
import { actionRefreshForm } from "../redux/action/manageTrackerAction";
import { STRING } from "../constants/string";
import ReactPaginate from "react-paginate";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
  },
};

const customStylesDelete = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
  },
};

const AnnoucementListing = (props) => {
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [deleteModalIsOpen, setdeleteModalIsOpen] = React.useState(false);
  const [searchTitle, setTitle] = useState();
  const [description, setDescription] = useState();
  const [viewdata, setViewdata] = useState();
  const [deleteId, setDeleteId] = useState();
  const [viewDescription, setViewDescription] = useState();
  const ckeditores = Utils.htmlData(viewDescription);

  const [status, setStatus] = useState({ value: "", label: "" });
  const [publishDate, setPublshDate] = useState("");
  const [unPblishDate, setUnPublishDate] = useState("");

  const [helperData, setHelperData] = useState([]);
  const [update, setUpdate] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const dispatch = useDispatch();
  const signdata = useSelector((state) => state.signinReducers);
  const access_token = signdata?.data?.access_token;
  const announcementreduce = useSelector(
    (state) => state.manageAnnouncementReducer
  );

  useEffect(() => {
    const parms = {
      access_token: access_token,
      title: searchTitle,
      description: description,
      status: status?.value,
      publishDate: publishDate
        ? Utils.momentDate(publishDate, STRING.SLASH_DATE_FORMAT)
        : "",
      unPblishDate: unPblishDate
        ? Utils.momentDate(unPblishDate, STRING.SLASH_DATE_FORMAT)
        : "",
      pageSize: pageSize,
      page: currentPage,
    };

    dispatch(manageAnnouncementActionRequest(parms));
  }, [update, announcementreduce?.refreshPage]);

  function openModal() {
    setIsOpen(true);
  }

  function openDeleteModal(id) {
    setDeleteId(id);
    setdeleteModalIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    setdeleteModalIsOpen(false);
  }
  const viewModel = (viewdata) => {
    openModal();
    setViewdata(viewdata);
    setViewDescription(viewdata?.description);
  };

  useEffect(() => {
    postApiCall([], signdata?.data?.access_token, ANOUNCEMENT_HELPER)
      .then((result) => {
        setHelperData(result);
      })
      .catch((error) => {
        console.error("ANOUNCEMENT_HELPER", error);
      });
  }, []);

  const iddelete = {
    id: deleteId,
    showSuccessToast: true,
  };

  const deletedata = () => {
    postApiCall(iddelete, access_token, DELETE_ANNOUNCEMENTS).then(
      (response) => {
        dispatch(actionRefreshForm());
      }
    );
    setdeleteModalIsOpen(false);
  };

  const handlePageChange = (pageNumber) => {
    const page = pageNumber?.selected + 1;
    setCurrentPage(page);
    setUpdate(!update);
  };

  return (
    <>
      {announcementreduce?.isLoading == true ? <LoadingBox /> : null}
      <div className={props?.data?.transtion == true ? "contents" : "content"}>
        <div className="content-top-spacer"></div>
        <div className="color-body">
          <div className="col-md-12">
            <div className="card-input mt-3">
              <div className="col-md-12 mb-3">
                <div className="mt-3">
                  <h5>Announcements</h5>
                </div>
                <div className="mt-4">
                  <div className="">
                    <div className="mt-3">
                      <table className="table table-sm">
                        <thead>
                          <tr>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">#</span>
                            </th>
                            <th
                              scope="col"
                              className="site-color"
                              style={{ width: "20%" }}
                            >
                              <span className="mt-2 mb-2 d-flex">Title</span>
                            </th>
                            <th
                              scope="col"
                              className="site-color"
                              style={{ width: "25%" }}
                            >
                              <span className="mt-2 mb-2 d-flex">
                                Description
                              </span>
                            </th>
                            <th
                              scope="col"
                              className="site-color"
                              style={{ width: "15%" }}
                            >
                              <span className="mt-2 mb-2 d-flex">Status</span>
                            </th>
                            <th
                              scope="col"
                              className="site-color"
                              style={{ width: "10%" }}
                            >
                              <span className="mt-2 mb-2 d-flex">
                                Publish Date
                              </span>
                            </th>
                            <th
                              scope="col"
                              className="site-color"
                              style={{ width: "10%" }}
                            >
                              <span className="mt-2 mb-2 d-flex">
                                unPublish Date
                              </span>
                            </th>
                            <th
                              scope="col"
                              className="site-color"
                              style={{ width: "10%" }}
                            >
                              <span className="mt-2 mb-2 d-flex">
                                Updated By
                              </span>
                            </th>
                            <th
                              scope="col"
                              className="site-color"
                              style={{ width: "10%" }}
                            >
                              <span className="mt-2 mb-2 d-flex">Manage</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td></td>
                            <td>
                              <input
                                type="text"
                                className="w-100 listing-serach min-h-38 mt-2 mb-2"
                                onChange={(title) => {
                                  setTitle(title.target.value);
                                  setUpdate(!update);
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="w-100 listing-serach min-h-38 mt-2 mb-2"
                                onChange={(des) => {
                                  setDescription(des.target.value);
                                  setUpdate(!update);
                                }}
                              />
                            </td>
                            <td>
                              <Select
                                options={helperData?.status}
                                className="w-100 listing-serach mt-2 mb-2"
                                placeholder="Select"
                                isSearchable={true}
                                isClearable={true}
                                value={status}
                                onChange={(event) => {
                                  setStatus({
                                    value: event?.value,
                                    label: event?.label,
                                  });
                                  setUpdate(!update);
                                }}
                              />
                            </td>
                            <td>
                              <DatePicker
                                className="border-inputs min-h-38 mt-2 mb-2"
                                selected={publishDate}
                                onChange={(date) => {
                                  setPublshDate(date);
                                  setUpdate(!update);
                                }}
                              />
                            </td>
                            <td>
                              <DatePicker
                                className="border-inputs min-h-38 mt-2 mb-2"
                                selected={unPblishDate}
                                onChange={(date) => {
                                  setUnPublishDate(date);
                                  setUpdate(!update);
                                }}
                              />
                            </td>
                          </tr>
                          {announcementreduce?.data?.announcements.length ? (
                            <>
                              {announcementreduce?.data?.announcements?.map(
                                (data, idx) => {
                                  const viewdata = {
                                    title: data?.title,
                                    description: data?.description,
                                  };
                                  const ckeditore = Utils.htmlData(
                                    data?.description
                                  );
                                  return (
                                    <tr key={idx}>
                                      <td>
                                        <span className="mt-4 d-flex mr-4">
                                          {(currentPage - 1) * pageSize +
                                            (idx + 1)}
                                        </span>
                                      </td>
                                      <td className="w-des">
                                        <span className="mt-3 d-flex length-des">
                                          {data?.title}
                                        </span>
                                      </td>
                                      <td className="w-des">
                                        <span className="mt-3 d-flex length-des">
                                          {ckeditore}
                                        </span>
                                      </td>
                                      <td>
                                        <span className="mt-3 d-flex">
                                          {data?.status}
                                        </span>
                                      </td>
                                      <td>
                                        <span className="mt-3 d-flex">
                                          {data?.publish_date}
                                        </span>
                                      </td>
                                      <td>
                                        <span className="mt-3 d-flex">
                                          {data?.unpublish_date}
                                        </span>
                                      </td>
                                      <td>
                                        <span className="mt-3 d-flex">
                                          {data?.updated_by?.name}
                                        </span>
                                      </td>
                                      <td>
                                        <div className="row mt-3 mb-3">
                                          <div className="mr-3">
                                            <i
                                              className="fa fa-eye site-color curser-point"
                                              aria-hidden="true"
                                              onClick={() => {
                                                viewModel(data);
                                              }}
                                            ></i>
                                          </div>
                                          <Link
                                            to={"/add-anouncements/" + data?.id}
                                          >
                                            <div className="">
                                              <i
                                                className="fa fa-pencil site-color curser-point"
                                                aria-hidden="true"
                                              ></i>
                                            </div>
                                          </Link>
                                          <div className="ml-3">
                                            <i
                                              className="site-color fa fa-trash curser-point"
                                              aria-hidden="true"
                                              onClick={() =>
                                                openDeleteModal(data?.id)
                                              }
                                            ></i>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                              <tr>
                                <td colSpan={7} className="text-center w-100">
                                  <ReactPaginate
                                    previousLabel={"Previous"}
                                    nextLabel={"Next"}
                                    pageCount={
                                      announcementreduce?.data?.total_pages
                                    }
                                    onPageChange={handlePageChange}
                                    containerClassName={"pagination"}
                                    activeClassName={"active"}
                                  />
                                </td>
                              </tr>
                            </>
                          ) : (
                            <tr>
                              <td colSpan="7">No record found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                        style={customStyles}
                        ariaHideApp={false}
                        contentLabel="Example Modal"
                      >
                        <div className="d-flex border-bottom mt-2 mb-4">
                          <div className="col-md-6">
                            <h5>Announcement Information</h5>
                          </div>
                          <div className="col-md-6 text-right">
                            <i
                              className="fa fa-times curser-point"
                              aria-hidden="true"
                              onClick={closeModal}
                            ></i>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="row">
                            {/* <div className="col-md-12">
                            
                            <div className="border-bottom mt-2 mb-2"></div>
                          </div> */}

                            <div className="col-md-12 d-flex">
                              <div className="col-md-6">
                                <b>Title</b>
                                <p className="mb-0">{viewdata?.title}</p>
                              </div>
                              <div className="col-md-6">
                                <b>Status</b>
                                <p>{viewdata?.status}</p>
                              </div>
                            </div>

                            <div className="col-md-12 d-flex">
                              <div className="col-md-6">
                                <b>Publish Date</b>
                                <p className="mb-0">{viewdata?.publish_date}</p>
                              </div>
                              <div className="col-md-6">
                                <b>Unpublish Date</b>
                                <p>{viewdata?.unpublish_date}</p>
                              </div>
                            </div>

                            <div className="col-md-12 d-flex">
                              <div className="col-md-6">
                                <b>Title</b>
                                <p className="mb-0">{viewdata?.title}</p>
                              </div>
                              <div className="col-md-6">
                                <b>Description</b>
                                <p>{ckeditores}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal>

                      <Modal
                        isOpen={deleteModalIsOpen}
                        onRequestClose={closeModal}
                        preventScroll={true}
                        style={customStylesDelete}
                        contentLabel="Delete TAsk"
                        ariaHideApp={false}
                      >
                        <div className="">
                          <div className="col-md-12 mb-3">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-4">
                                  <h5>Delete Tasks</h5>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="mb-4">
                                  <p>
                                    Are you sure you want to delete this
                                    annoucement ?
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12 text-right">
                                <button
                                  type="button"
                                  className="button-default"
                                  onClick={closeModal}
                                >
                                  Cancel
                                </button>
                                <button
                                  type="button"
                                  className="create-button ml-2"
                                  onClick={deletedata}
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AnnoucementListing;
