import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Sidebar from '../components/Sidebar.js';
import Login from '../views/Login.js';
import {useCallback, useState} from 'react';
import Home from '../views/dashbaord/Home.js';
import Timeline from '../views/Timeline.js';
import Analysis from '../views/Analysis.js';
import ManageProject from '../views/ManageProject.js';
import PaySlips from '../views/pay-slips/PaySlip.js';
import AddProject from '../views/AddProject.js';
import ManageTask from '../views/ManageTask.js';
import AddTracker from '../views/AddTracker.js';
import AddAnouncements from '../views/AddAnouncements.js';
import Leave from '../views/Leave.js';
import Timelog from '../views/timelog/Timelog.js';
import ManageTracker from '../views/ManageTracker.js';
import AnnoucementListing from '../views/AnnoucementListing.js';
import AddLeave from '../views/leaves/AddLeave.js';
import ManageLeave from '../views/ManageLeave.js';
import EditLeave from '../views/EditLeave.js';
import AddUser from '../views/AddUser.js';
import ManageUser from '../views/ManageUser.js';
import ManagePayslip from '../views/pay-slips/ManagePayslip';
import AddPayslip from '../views/pay-slips/AddPayslip.js';
import SaturdayWorking from '../views/SaturdayWorking.js';
import UserEdit from '../views/UserEdit.js';
import ViewProject from '../views/ViewProject.js';
import ViewPayslip from '../views/pay-slips/ViewPayslip.js';
import EditPayslip from '../views/pay-slips/EditPayslip.js';
import MyLeaves from '../views/leaves/MyLeaves.js';
import AddTodo from '../views/AddTodo.js';
import EditTodo from '../views/EditTodo.js';
import ManageTodo from '../views/ManageTodo.js';
import InventoryListing from '../views/InventoryListing.js';
import AddInventory from '../views/AddInventory.js';
import EditInveintory from '../views/EditInveintory.js';
import AddClickupAccount from '../views/AddClickupAccount.js';
import ManageClickupAccount from '../views/ManageClickupAccount.js';
import AddSupport from '../views/support/AddSupport.js';
import ManageSupport from '../views/support/ManageSupport.js';
import CreateFolder from '../views/gallery/CreateFolder.js';
import Gallery from '../views/gallery/Gallery.js';
import GalleryViewer from '../views/gallery/GalleryViewer.js';

function Navigator () {
  const [transtion, settranstion] = useState (false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const menuside = useCallback (data => {
    settranstion (data);
  });
  return (
    <Router>
      <Routes>
        <Route path="login" element={<Login />} />
        {/* <Route path="/" element={<Sidebar />} /> */}
        <Route
          path="/"
          element={
            <Sidebar data={{settranstion: menuside, transtion: transtion}} />
          }
        >
          <Route
            index
            /*path="home"*/ element={<Home data={{transtion: transtion}} />}
          />
          <Route path="home" element={<Home data={{transtion: transtion}} />} />
          <Route
            path="timeline"
            element={<Timeline data={{transtion: transtion}} />}
          />
          <Route
            path="analysis"
            element={<Analysis data={{transtion: transtion}} />}
          />
          <Route
            path="manage-project"
            element={<ManageProject data={{transtion: transtion}} />}
          />
          <Route
            path="pay-slip"
            element={<PaySlips data={{transtion: transtion}} />}
          />
          <Route
            path="add-project"
            element={<AddProject data={{transtion: transtion}} />}
          />
          <Route
            path="update-project/:projectId"
            element={<AddProject data={{transtion: transtion}} />}
          />
          <Route
            path="view-project/:projectId"
            element={<ViewProject data={{transtion: transtion}} />}
          />
          <Route
            path="manage-task"
            element={<ManageTask data={{transtion: transtion}} />}
          />
          <Route
            path="add-tracker"
            element={<AddTracker data={{transtion: transtion}} />}
          >
            <Route
              path=":tracker_id"
              element={<AddTracker data={{transtion: transtion}} />}
            />
          </Route>
          <Route
            path="add-clickup"
            element={<AddClickupAccount data={{transtion: transtion}} />}
          >
            <Route
              path=":clickup_id"
              element={<AddClickupAccount data={{transtion: transtion}} />}
            />
          </Route>
          <Route>
            <Route
              path="add-support"
              element={<AddSupport data={{transtion: transtion}} />}
            />
            <Route
              path="add-support/:id"
              element={<AddSupport data={{transtion: transtion}} />}
            />
            <Route
              path="view-support"
              element={<ManageSupport data={{transtion: transtion}} />}
            />

          </Route>

          <Route
            path="add-anouncements"
            element={<AddAnouncements data={{transtion: transtion}} />}
          >
            <Route
              path=":announcement_id"
              element={<AddAnouncements data={{transtion: transtion}} />}
            />
          </Route>
          <Route
            path="leave"
            element={<Leave data={{transtion: transtion}} />}
          />
          <Route
            path="timelog"
            element={<Timelog data={{transtion: transtion}} />}
          />
          <Route
            path="manage-tracker"
            element={<ManageTracker data={{transtion: transtion}} />}
          />
          <Route
            path="manage-clickup"
            element={<ManageClickupAccount data={{transtion: transtion}} />}
          />
          <Route
            path="annoucement-listing"
            element={<AnnoucementListing data={{transtion: transtion}} />}
          />

          <Route
            path="add-leave"
            element={<AddLeave data={{transtion: transtion}} />}
          />
          <Route
            path="manage-leave"
            element={<ManageLeave data={{transtion: transtion}} />}
          />
          <Route
            path="my-leave"
            element={<MyLeaves data={{transtion: transtion}} />}
          />
          <Route
            path="edit-leave"
            element={<EditLeave data={{transtion: transtion}} />}
          >
            <Route
              path=":leave_id"
              element={<EditLeave data={{transtion: transtion}} />}
            />
          </Route>

          <Route
            path="add-user"
            element={<AddUser data={{transtion: transtion}} />}
          />
          <Route
            path="edit-user"
            element={<UserEdit data={{transtion: transtion}} />}
          >
            <Route
              path=":employee_id"
              element={<UserEdit data={{transtion: transtion}} />}
            />
          </Route>
          <Route
            path="manage-user"
            element={<ManageUser data={{transtion: transtion}} />}
          />
          <Route
            path="manage-payslip"
            element={<ManagePayslip data={{transtion: transtion}} />}
          />
          <Route
            path="add-payslip"
            element={<AddPayslip data={{transtion: transtion}} />}
          />
          <Route
            path="view-payslip/:PayslipId"
            element={<ViewPayslip data={{transtion: transtion}} />}
          />
          <Route
            path="edit-payslip/:PayslipuserId"
            element={<EditPayslip data={{transtion: transtion}} />}
          />
          <Route
            path="saturday-working"
            element={<SaturdayWorking data={{transtion: transtion}} />}
          />
          <Route
            path="add-todo"
            element={<AddTodo data={{transtion: transtion}} />}
          />
          <Route
            path="edit-todo/:todoId"
            element={<EditTodo data={{transtion: transtion}} />}
          />
          <Route
            path="manage-todo"
            element={<ManageTodo data={{transtion: transtion}} />}
          />
          <Route
            path="inventory-listing"
            element={<InventoryListing data={{transtion: transtion}} />}
          />
          <Route
            path="add-inventory"
            element={<AddInventory data={{transtion: transtion}} />}
          />
          {/* gallery uploaded */}
          <Route
            path="createFolder"
            element={<CreateFolder data={{transtion: transtion}} />}
          />
          <Route
            path="gallery"
            element={<Gallery data={{transtion: transtion}} />}
          />
          <Route
            path="gallery/:id"
            element={<GalleryViewer data={{transtion: transtion}} />}
          />
          <Route
            path="edit-inventory"
            element={<EditInveintory data={{transtion: transtion}} />}
          >
            <Route
              path=":inventory_id"
              element={<AddTracker data={{transtion: transtion}} />}
            />
          </Route>
        </Route>
        <Route
          path="*"
          element={
            <Sidebar data={{settranstion: menuside, transtion: transtion}} />
          }
        />
      </Routes>
    </Router>
  );
}

export default Navigator;
