import { useSelector } from "react-redux";
import Admin from "./Admin.js";
import Developer from "./Developer.js";

const Home = (props) => {
  const signdata = useSelector((state) => state.signinReducers);
  return (
    <>
    {signdata?.data?.role == 1 ? (
      <Admin data={{transtion: props?.data?.transtion}} />
    ) : (
      <>
        {signdata?.data?.role != 1 ? (
          <Developer data={{transtion: props?.data?.transtion}} />
        ) : (
          ""
        )}
      </>
    )}
    </>
  );
};

export default Home;
