import { MANAGE_PAYSLIPS_REQUEST, 
    MANAGE_PAYSLIPS_REQUEST_ERROR, 
    MANAGE_PAYSLIPS_REQUEST_SUCCESS,

 } from "../actionType/type";


export const managePayslipActionRequest = (params,access_token) => {
    return {
        type :  MANAGE_PAYSLIPS_REQUEST,
        params : params,
        access_token : access_token    

    }
}

export const managePayslipActionSuccess = (result) => {
    return {
        type: MANAGE_PAYSLIPS_REQUEST_SUCCESS,
        payload: result,
        error: null,
    };
}

export const managePayslipActionError = (error) => {
    return {
        type: MANAGE_PAYSLIPS_REQUEST_ERROR,
        error:error,
        payload:null
    }
}



