/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import Select from "react-select";
import { upwork } from "../assets";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { manageTrackerActionRequest } from "../redux/action/manageTrackerAction";
import Utils from "../utility/utility";
import { Link } from "react-router-dom";
import LoadingBox from "../components/LoadingBox";
import { postApiCall } from "../service/PostApiCall"; 
import { DELETE_TRACKER, TRACKER_HELPER } from "../service/apiUrl";
import { actionRefreshForm } from "../redux/action/manageTrackerAction";
import ReactPaginate from "react-paginate";


const ManageTracker = (props) => {
  const platformTrack = JSON.parse(localStorage.getItem("managePlatform"))
  const [trackername, settrackername] = useState("");
  const [helperData, setHelperData] = useState([]);
  const [platform, setPlatform] = useState(platformTrack !=null ? platformTrack: { value: "", label: "" });
  const [update, setUpdate] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);

  const dispatch = useDispatch();
  const signdata = useSelector((state) => state.signinReducers);
  const access_token = signdata?.data?.access_token;

  const datamangetracker = useSelector((state) => state.manageTrackerReducers);

  useEffect(() => {
    postApiCall([], access_token, TRACKER_HELPER)
      .then((result) => {
        setHelperData(result);
      })
      .catch((error) => {
        console.error("TRACKER_HELPER", error);
      });
  }, []);


  useEffect(() => {
    const parms = {
      access_token: access_token,
      search: trackername,
      platform:platform.value,
      pageSize: pageSize,
      page: currentPage,
    };
    
    dispatch(manageTrackerActionRequest(parms));
  }, [update, datamangetracker.refreshPage]);

  const deletedata = (objectdata) => {
    if (window.confirm("Are you sure you want to delete it?")) {
      postApiCall(objectdata, access_token, DELETE_TRACKER).then((response) => {
        Utils.toast("success", "Tracker deleted successfully!");
        dispatch(actionRefreshForm());
      });
    }
  };
  const username = async (data) => {
    await navigator.clipboard.writeText(data);
    Utils.toast("success", "Username copied");
  };
  const password = async (data) => {
    await navigator.clipboard.writeText(data);
    Utils.toast("success", "Password copied");
  };

  const handlePageChange = (pageNumber) => {
    const page = pageNumber?.selected + 1;
    setCurrentPage(page);
    setUpdate(!update);
  };


  return (
    <>
      {datamangetracker?.isLoading === true ? <LoadingBox /> : null}
      <div className={props?.data?.transtion === true ? "contents" : "content"}>
        <div className="content-top-spacer"></div>
        <div className="color-body">
          <div className="col-md-12">
            <div className="card-input mt-3">
              <div className="col-md-12 mb-3">
                <div className="mt-3">
                  <h3>Trackers</h3>
                </div>
                <div className="mt-4">
                  <div className="">
                    <div className="mt-3">
                      <table className="table table-sm">
                      <colgroup>
                        <col width="2%" />
                        <col width="20%" />
                        <col width="15%" />
                        <col width="19%" />
                        <col width="19%" />
                        <col width="15%" />
                        <col width="10%" />
                      </colgroup>
                        <thead>
                          <tr>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">#</span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">Platform</span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">Name</span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">Username</span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">Password</span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">
                                Updated At
                              </span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">Manage</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td></td>
                            <td>                              
                              <Select
                                options={helperData?.sources}
                                className={"mt-2 mb-2"}
                                isSearchable={true}
                                isClearable={true}
                                value={platform}
                                styles={{
                                  menu: (base) => ({
                                    ...base,
                                    width: "max-content",
                                    minWidth: "100%"
                                 })
                                }}
                                onChange={(event) => {
                                  if(event !=null){
                                    localStorage.setItem("managePlatform",JSON.stringify(event))
                                  }else{
                                    localStorage.removeItem("managePlatform")
                                  }
                                  setPlatform({
                                    value: event?.value,
                                    label: event?.label,
                                  });
                                  setUpdate(!update);
                                }}
                                />

                            </td>
                            <td style={{position:"relative"}}>
                              <input
                                type="text"
                                className="w-100 pl-2 listing-serach min-h-38 mt-2 mb-2"
                                value={trackername}
                                onChange={(name) => {
                                  settrackername(name.target.value);
                                  setUpdate(!update);
                                }}
                              />
                              {
                                trackername.length > 0 && <span 
                                style={{
                                  position: "absolute",
                                  fontSize: 30,
                                  right: 8,
                                  top: "50%",
                                  transform: "translate(-50%, -55%)",
                                  cursor: "pointer",
                                  color: "#ccc",
                                }}
                                onClick={()=>{ settrackername("");
                                  setUpdate(!update);}}
                              >
                                &times;
                              </span>
                              }
                            </td>
                            <td>
                              
                            </td>
                            <td>
                              
                            </td>
                          </tr>
                          {datamangetracker?.data?.trackers.length ?(
                          <>
                          {datamangetracker?.data?.trackers?.map((data, idx) => {
                            const datestamp = Utils.timestampDate(
                              data?.updated_at, "MM/DD/YYYY"
                            );
                            const objectdata = {
                              id: data?.id,
                              access_token: access_token,
                            };

                            return (
                              <tr key={idx}>
                                <td>
                                <span
                                      className="mt-4 d-flex mr-4"
                                    >
                                      {(currentPage - 1) * pageSize +
                                        (idx + 1)}
                                    </span>
                                </td>
                                <td>
                                  
                                  <span className="mt-3 d-flex">
                                  <div className="mr-3">
                                      <img
                                        src={upwork}
                                        className="upwork"
                                        alt=""
                                      ></img>
                                    </div>
                                    {data?.platform}
                                  </span>
                                </td>
                                <td>
                                  <span className="mt-3 d-flex">
                                    {data?.name}
                                  </span>
                                </td>
                                <td>
                                  <div className="d-flex row mt-3 ml-2">
                                    <span className="">
                                      {data?.username}
                                    </span>
                                    <span className="ml-2">
                                     <i
                                        className="fa fa-clipboard site-color curser-point"
                                        aria-hidden="true"
                                        onClick={() => {
                                          username(data?.username);
                                        }}
                                      ></i>
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <div className="row d-flex mt-3 ml-2">
                                    <span className="">
                                      {data?.password}
                                    </span>
                                    <span className="ml-2">
                                      <i
                                        className="fa site-color fa-clipboard curser-point"
                                        aria-hidden="true"
                                        onClick={() => {
                                          password(data?.password);
                                        }}
                                      ></i>
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <span className="mt-3 d-flex">
                                    {datestamp}
                                  </span>
                                </td>

                                <td>
                                  <div className="row mt-3 mb-3">
                                    
                                    <Link to={"/add-tracker/" + data?.id}>
                                      <div className="">
                                        <i
                                          className="fa fa-pencil site-color"
                                          aria-hidden="true"
                                        ></i>
                                      </div>
                                    </Link>
                                    <div className="ml-3">
                                      <i
                                        className="site-color fa fa-trash curser-point"
                                        aria-hidden="true"
                                        onClick={() => deletedata(objectdata)}
                                      ></i>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                          <tr>
                              <td colSpan={7} className="text-center w-100">
                                <ReactPaginate
                                  previousLabel={"Previous"}
                                  nextLabel={"Next"}
                                  pageCount={datamangetracker?.data?.total_pages}
                                  onPageChange={handlePageChange}
                                  containerClassName={"pagination"}
                                  activeClassName={"active"}
                                />
                              </td>
                            </tr>
                            </>

                          ) : (
                            <tr>
                              <td colSpan="7">No record found</td>
                            </tr>
                          )}

                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageTracker;
