import React, { useEffect, useState } from "react";
import { FaFolder } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import "./assets/gallery.css";
import axiosInstance from "../../api/DropboxApi";
import Utils from "../../utility/utility";
import { Loader } from "rsuite";

const Gallery = (props) => {
  const [folders, setFolders] = useState([]);
  const [viewType, setViewType] = useState("grid");
  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
    folderId: null,
  });
  const [renameFolderId, setRenameFolderId] = useState(null);
  const [newFolderName, setNewFolderName] = useState("");
  const [folderName, setFolderName] = useState("");
  const [isLoading, setLoading] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    fetchAllFolders();
    const listSharedFolders = async () => {
      try {
        const response = await axiosInstance.post(
          'https://api.dropboxapi.com/2/sharing/list_folders',
          {},
          {
            headers: {
          
              'Content-Type': 'application/json',
            },
          }
        );
        console.log('Shared folders:', response.data);
        return response.data.entries;
      } catch (error) {
        console.error('Error listing shared folders:', error);
      }
    };
    listSharedFolders()
  }, []);

  const handleFolderClick = (folder) => {
    navigate(`/gallery/${folder.id}`);
  };

  const handleRightClick = (event, folderId) => {
    event.preventDefault();
    setContextMenu({ visible: true, x: event.pageX, y: event.pageY, folderId });
  };

  const handleRename = async (folderId) => {
    const folderToRename = folders.find((data) => data.id === folderId);

    if (!folderToRename) {
      Utils.toast("error", "Folder not found.");
      return;
    }

    // Ensure new folder name is not empty
    if (newFolderName.trim() === "") {
      Utils.toast("error", "Folder name cannot be empty.");
      return;
    }

    const currentFolderPath = folderToRename.path_display;
    const newFolderPath = `${folderToRename.path_display
      .split("/")
      .slice(0, -1)
      .join("/")}/${newFolderName}`; // Construct the new path properly

    try {
      setLoading(true);
      const response = await axiosInstance.post(
        "https://api.dropboxapi.com/2/files/move_v2",
        {
          from_path: currentFolderPath,
          to_path: newFolderPath,
        }
      );

      if (response.status === 200) {
        setLoading(false);
        const updatedFolders = folders.map((folder) => {
          if (folder.id === folderId) {
            return { ...folder, name: newFolderName, path_display: newFolderPath };
          }
          return folder;
        });

        setFolders(updatedFolders);
        setRenameFolderId(null);
        setNewFolderName("");
        Utils.toast("success", "Folder renamed successfully.");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error renaming folder:", error);
      Utils.toast("error", "Unable to rename folder.");
    }
  };

  const handleDelete = async (folderId, folderPath) => {
    try {
      setLoading(true);
      const response = await axiosInstance.post(
        "https://api.dropboxapi.com/2/files/delete_v2",
        {
          path: folderPath,
        }
      );

      if (response.status === 200) {
        Utils.toast("success", "Folder deleted successfully.");
        const updatedFolders = folders.filter(
          (folder) => folder.id !== folderId
        );
        setFolders(updatedFolders);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Utils.toast("error", "Unable to delete folder.");
    }
  };

  const handleClickOutside = () => {
    if (contextMenu.visible) {
      setContextMenu({ ...contextMenu, visible: false });
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [contextMenu]);

  const fetchAllFolders = async (path = "") => {
    try {
      setLoading(true);
      let result = await axiosInstance.post("files/list_folder", {
        path: path,
        recursive: false,
        include_deleted: false,
        include_media_info: false,
        include_mounted_folders: true,
      });

      let entries = result.data.entries;

      while (result.data.has_more) {
        result = await axiosInstance.post("files/list_folder/continue", {
          cursor: result.data.cursor,
        });
        entries = entries.concat(result.data.entries);
      }

      const folderEntries = entries.filter(
        (entry) => entry[".tag"] === "folder"
      );

      const folderData = [];

      for (const folder of folderEntries) {
        const folderPath = folder.path_lower;

        let folderContents = await axiosInstance.post("files/list_folder", {
          path: folderPath,
          recursive: true,
          include_deleted: false,
          include_media_info: false,
          include_mounted_folders: true,
        });

        let files = folderContents.data.entries;
        while (folderContents.data.has_more) {
          folderContents = await axiosInstance.post(
            "files/list_folder/continue",
            {
              cursor: folderContents.data.cursor,
            }
          );
          files = files.concat(folderContents.data.entries);
        }

        const totalSizeBytes = files.reduce((acc, entry) => {
          if (entry[".tag"] === "file") {
            return acc + entry.size;
          }
          return acc;
        }, 0);

        const fileCount = files.filter((entry) => entry[".tag"] === "file")
          .length;

        const humanReadableSize = formatBytes(totalSizeBytes);

        folderData.push({
          id: folder.id,
          name: folder.name,
          path: folderPath,
          path_display: folder.path_display,
          size: humanReadableSize,
          fileCount: fileCount,
        });
      }
      setLoading(false);
      setFolders(folderData);
    } catch (err) {
      setLoading(false);
      console.error("Error fetching folders from Dropbox:", err);
    }
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  return (
    <div className={props?.data?.transition === true ? "contents" : "content"}>
      <div className="galleryContainer">
        <header className="gallery-header">
          <h2>Gallery</h2>

        </header>

        {folders.length === 0 ? (
          <div className="no-folders-message">
            {isLoading ? <Loader /> : <p>No folders exist.</p>}
          </div>
        ) : (
          <div className={`folder-container ${viewType}`}>
            {viewType === "grid" &&
              folders.map((folder) => (
                <div
                  key={folder.id}
                  className="folder"
                  onClick={() => handleFolderClick(folder)}
                  onContextMenu={(event) =>
                    handleRightClick(event, folder.id)
                  }
                >
                  <div className="folder-icon">
                    <FaFolder className="folder-image" />
                  </div>

                  {renameFolderId === folder.id ? (
                    <>
                      <input
                        type="text"
                        value={newFolderName}
                        onChange={(e) => setNewFolderName(e.target.value)}
                        onBlur={() => handleRename(folder.id)}
                        onKeyPress={(e) =>
                          e.key === "Enter" && handleRename(folder.id)
                        }
                        disabled={isLoading ? true : false}
                        autoFocus
                        className="rename-input"
                      />
                      {isLoading && <Loader />}
                    </>
                  ) : (
                    <div className="folder-details">
                      <h4 className="folder-info">{folder?.name}</h4>
                      <div className="folder-info">
                        <span>{folder.fileCount} files</span>
                        <span>{folder.size}</span>
                      </div>
                    </div>
                  )}
                </div>
              ))}
          </div>
        )}

        {contextMenu.visible && (
          <ul
            className="context-menu"
            style={{ top: contextMenu.y, left: contextMenu.x }}
          >
            <li
              onClick={() => {
                const folder = folders.find(
                  (f) => f.id === contextMenu.folderId
                );
                setRenameFolderId(contextMenu.folderId);
                setNewFolderName(folder.name);
                setContextMenu({ visible: false, x: 0, y: 0, folderId: null });
              }}
            >
              Rename
            </li>
            <li
              onClick={() => {
                const folder = folders.find(
                  (f) => f.id === contextMenu.folderId
                );
                handleDelete(folder.id, folder.path_display);
                setContextMenu({ visible: false, x: 0, y: 0, folderId: null });
              }}
            >
              Delete
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export default Gallery;
