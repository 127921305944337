const ENVIRONMENT_TYPES = {
  dev: 'dev',
  prod: 'prod',
};
const ENVOIRMENT_URL = {
  dev: 'https://ems-liveapi.rapidsofts.com',
  prod: 'https://ems-liveapi.rapidsofts.com',
};
const selectedEnv = ENVIRONMENT_TYPES.dev;

export const BASE_URL = ENVOIRMENT_URL[selectedEnv];
