
import { VIEW_TRACKER_REQUEST, VIEW_TRACKER_REQUEST_SUCCESS, VIEW_TRACKER_REQUEST_ERROR, } from "../actionType/type";

export const viewTrackerActionRequest = (access_token) => {
    return {
        type :  VIEW_TRACKER_REQUEST,
        params : access_token 
        
    }
}

export const viewTrackerActionSuccess = (result) => {

    return {
        type: VIEW_TRACKER_REQUEST_SUCCESS,
        payload: result,
        error: null,
    };
}

export const viewTrackerActionError = (error) => {
    return {
        type: VIEW_TRACKER_REQUEST_ERROR,
        error:error,
        payload:null
    }
}
