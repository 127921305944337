// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RestrictedAccessPage_mainContent__JoXBr{
    display: grid;
    place-items: center;
    padding: 10px;
    height: calc(100vh - 50px);
   
}
.RestrictedAccessPage_container__5VgU8 {
    max-width: 600px;
    margin: 0 auto;
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    
}

.RestrictedAccessPage_header_logo__b\\+2gf{
    text-align: center;
}

.RestrictedAccessPage_logo__\\+Qcqy {
    max-width: 200px;
    margin-bottom: 20px;
}

.RestrictedAccessPage_icon__-Hewb {
    color: #FF5733;
    font-size: 48px;
    margin-bottom: 20px;
    text-align: center;
}

.RestrictedAccessPage_message__vvR5g {
    font-size: 18px;
    margin-bottom: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/restricted/RestrictedAccessPage.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,aAAa;IACb,0BAA0B;;AAE9B;AACA;IACI,gBAAgB;IAChB,cAAc;IACd,sBAAsB;IACtB,aAAa;IACb,kBAAkB;IAClB,uCAAuC;;AAE3C;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB","sourcesContent":[".mainContent{\n    display: grid;\n    place-items: center;\n    padding: 10px;\n    height: calc(100vh - 50px);\n   \n}\n.container {\n    max-width: 600px;\n    margin: 0 auto;\n    background-color: #fff;\n    padding: 30px;\n    border-radius: 8px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n    \n}\n\n.header_logo{\n    text-align: center;\n}\n\n.logo {\n    max-width: 200px;\n    margin-bottom: 20px;\n}\n\n.icon {\n    color: #FF5733;\n    font-size: 48px;\n    margin-bottom: 20px;\n    text-align: center;\n}\n\n.message {\n    font-size: 18px;\n    margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContent": `RestrictedAccessPage_mainContent__JoXBr`,
	"container": `RestrictedAccessPage_container__5VgU8`,
	"header_logo": `RestrictedAccessPage_header_logo__b+2gf`,
	"logo": `RestrictedAccessPage_logo__+Qcqy`,
	"icon": `RestrictedAccessPage_icon__-Hewb`,
	"message": `RestrictedAccessPage_message__vvR5g`
};
export default ___CSS_LOADER_EXPORT___;
