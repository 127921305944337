import React from 'react'
import Modal from "react-modal";


const CustomModal = ({ isShow, onHide = () => { }, heading = "", children }) => {
    return (
        <Modal
            isOpen={isShow}
            preventScroll={true}
            ariaHideApp={false}
            contentLabel="Tracker Details"
            style={{
                content: {
                    top: "50%",
                    left: "50%",
                    right: "auto",
                    bottom: "auto",
                    marginRight: "-50%",
                    transform: "translate(-50%, -50%)",
                    height:"fit-content",
                    width:"40%",
                    padding:'0px',
                },
            }}
            onRequestClose={onHide}

        >
            <div className="col-md-12 mx-0">
                <div className='row mt-3'>
                    <div className='col-md-10 '>{heading ? <h4 className='mr-2' style={{ width: 'max-content' }}>{heading}</h4> : ''}</div>
                    <div className='col-md-2'>
                        <div className="text-right">
                            <i
                                className="fa fa-times curser-point"
                                aria-hidden="true"
                                onClick={onHide}
                            ></i>
                        </div>
                    </div>
                </div>
                <hr className='my-1-0'/>
                {children}
            </div>
        </Modal>
    )
}

export default CustomModal