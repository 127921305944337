import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import { postApiCall } from '../../service/PostApiCall'
import {
  ADMIN_LISTING,
  DELETE_SUPPORT,
  MANAGE_SUPPORT,
  SUPPORT_UPDATE,
  SUPPORT_VIEW,
  USER_HELPER,
  USER_SUPPORT_LISTING,
  USER_SUPPORT_UPDATE,
  USER_SUPPORT_VIEW,
} from '../../service/apiUrl'
import { useSelector } from 'react-redux'
import moment from 'moment'
import ReactPaginate from 'react-paginate'
import './style/manageSupport.css'


const ManageSupport = (props) => {
  const getInitialPage = () => {
    const savedPage = JSON.parse(localStorage.getItem('currentPage'))
    return savedPage !== null && !isNaN(savedPage.currentPage)
      ? parseInt(savedPage.currentPage, 10)
      : 1
  }
  const [adminList, setAdminList] = useState([])
  const [supportListing, setSupportListing] = useState([])
  const [filteredProjects, setFilteredProjects] = useState([])
  const [selectedName, setSelectedName] = useState(null)
  const [selectedStatus, setSelectedStatus] = useState(null)
  const [deletModalIsOpen, setdeletModalIsOpen] = useState(false)
  const [supportId, setSupportId] = useState('')
  const [currentPage, setCurrentPage] = useState(getInitialPage)
  const [totalPage, setTotalPage] = useState(0)
  const [isView, setIsView] = useState(false)
  const [finalData, setFinalData] = useState(null)
  const [pageSize, setPageSize] = useState(10)

  const [statusList, setStatusList] = useState([])
  const navigate = useNavigate()
  const signData = useSelector((state) => state.signinReducers)
  const userRoles = signData?.data?.role
  const token = signData?.data?.access_token

  const fetchManageSupports = async () => {
    try {
      let params = {
        page: currentPage,
        pageSize: pageSize,
      }
      let URL_CHECKER = userRoles == 1 ? MANAGE_SUPPORT : USER_SUPPORT_LISTING
      const response = await postApiCall(params, token, URL_CHECKER)
      if (response !== null || response !== undefined) {
        setTotalPage(response?.total_pages)
        setSupportListing(response?.supports)
        setFilteredProjects(response?.supports)
      }
    } catch (error) {
      console.error('sd==> ', error.message)
    }
  }

  const handleAdminListing = async () => {
    try {
      let params = {}
      let URL_CHECKER = userRoles == 1 ? ADMIN_LISTING : USER_HELPER
      let response = await postApiCall(params, token, URL_CHECKER)
      if (response?.admins !== null || response?.admins !== undefined) {
        setAdminList(response?.admins)
      }
    } catch (error) {
      console.error('Error in fetching admin users', error)
    }
  }

  useEffect(() => {
    fetchManageSupports()
  }, [currentPage])

  useEffect(() => {
    handleAdminListing()
    setStatusList([
      { value: '1', label: 'Pending' },
      { value: '2', label: 'Open' },
      { value: '3', label: 'Hold' },
      { value: '4', label: 'Closed' },
    ])
  }, [])

  const handleNameChange = (selectedOption) => {
    setSelectedName(selectedOption)
    filterProjects(selectedOption, selectedStatus)
  }

  const handleStatusChange = (selectedOption) => {
    setSelectedStatus(selectedOption)
    filterProjects(selectedName, selectedOption)
  }

  const filterProjects = (nameFilter, statusFilter) => {
    let filteredData = [...filteredProjects]
    if (nameFilter?.value) {
      filteredData = filteredData.filter((project) =>
        project.assigned_to.name
          .toLowerCase()
          .includes(nameFilter.label.toLowerCase()),
      )
    }
    if (statusFilter?.value) {
      filteredData = filteredData.filter((project) => {
        return project.status === statusFilter.value
      })
    }
    setSupportListing(filteredData)
  }

  const changesBgColor = (status) => {
    switch (status) {
      case 'Pending':
        return 'dot-wraning'
      case 'Hold':
        return 'dot-hold'
      case 'Open':
        return 'dot-active'
      case 'Closed':
        return 'dot-danger'
      default:
        break
    }
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '40%',
    },
  }

  const checkStatus = (status) => {
    switch (status) {
      case '1':
        return 'Pending'
      case '2':
        return 'Open'
      case '3':
        return 'Hold'
      case '4':
        return 'Closed'
      default:
        break
    }
  }

  const handleDeleteSupport = async (supportId) => {
    try {
      let params = {
        id: supportId,
      }
      let response = await postApiCall(params, token, DELETE_SUPPORT)
      if (response) {
        fetchManageSupports()
        setdeletModalIsOpen(false)
      }
    } catch (error) {
      console.error('sd==> ', error.message)
    }
  }

  const handlePageChange = (pageNumber) => {
    const page = pageNumber?.selected + 1
    localStorage.setItem(
      'currentPage',
      JSON.stringify({ type: 'manage-support', currentPage: page }),
    )
    setCurrentPage(page)
    // setUpdate(!update);
  }
  {
    console.log('curent', currentPage)
  }

  const handleOpenModel = async (supportId) => {
    setIsView(!isView)
    const CHECK_URL = userRoles == 1 ? SUPPORT_VIEW : USER_SUPPORT_VIEW
    let params = {
      id: supportId,
    }
    let response = await postApiCall(params, token, CHECK_URL)
    if (response) {
      console.log('response ', response)
      setFinalData(response)
      // setIsView(true);
    }
  }

  return (
    <div className={props?.data?.transtion === true ? 'contents' : 'content'}>
      <div className="color-body">
        <div className="col-md-12">
          <div className="card-input mt-3">
            <div className="col-md-12 mb-3">
              <div className="mt-5">
                <div className="row">
                  <div className="flex-row">
                    <div className="row mr-4 mt-1 mt-2">
                      <div className="dot dot-wraning mt-1 mr-1"></div>
                      <p>Pending</p>
                    </div>
                    <div className="row mr-4 mt-1 mt-2">
                      <div className="dot dot-hold mt-1 mr-1"></div>
                      <p>Hold</p>
                    </div>
                    <div className="row mr-4 mt-1 mt-2">
                      <div className="dot dot-active mt-1 mr-1"></div>
                      <p>Open</p>
                    </div>
                    <div className="row mr-4 mt-1 mt-2">
                      <div className="dot dot-danger mt-1 mr-1"></div>
                      <p>Closed</p>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="mt-2">
                    <h4>Support Listing</h4>
                  </div>
                  <div className="">
                    <table className="table table-sm">
                      <colgroup>
                        <col width="3%" />
                        <col width="16%" />
                        <col width="14%" />
                        <col width="16%" />
                        <col width="15%" />
                        <col width="12%" />
                        <col width="12%" />
                      </colgroup>
                      <thead>
                        <tr>
                          <th scope="col" className="site-color">
                            <span className="mt-2 mb-2 d-flex">#</span>
                          </th>
                          <th
                            scope="col"
                            className="site-color"
                            style={{ width: '12%', }}
                          >
                            <span className="mt-2 mb-2 d-flex">Assigned</span>
                          </th>
                          <th scope="col" className="site-color">
                            <span className="mt-2 mb-2 d-flex">Title</span>
                          </th>
                          <th scope="col" className="site-color">
                            <span className="mt-2 mb-2 d-flex">Status</span>
                          </th>
                          <th scope="col" className="site-color">
                            <span className="mt-2 mb-2 d-flex">Created At</span>
                          </th>
                          {
                            userRoles != 5 &&  <th scope="col" className="site-color">
                            <span className="mt-2 mb-2 d-flex">Created By</span>
                          </th>
                          }
                         
                          <th scope="col" className="site-color">
                            <span className="mt-2 mb-2 d-flex">Updated By</span>
                          </th>
                          <th scope="col" className="site-color">
                            <span className="mt-2 mb-2 d-flex">Manage</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td></td>
                          <td style={{ position: 'relative' }}>
                            <Select
                              placeholder="Select Name"
                              value={selectedName}
                              isSearchable={true}
                              isClearable={true}
                              onChange={handleNameChange}
                              options={adminList.map((item) => ({
                                value: item?.value,
                                label: item?.label,
                              }))}
                            />
                          </td>
                          <td></td>
                          <td>
                            <Select
                              placeholder="Select Status"
                              value={selectedStatus}
                              onChange={handleStatusChange}
                              isClearable={true}
                              options={statusList.map((status) => ({
                                value: status.value,
                                label: status.label,
                              }))}
                            />
                          </td>
                          <td></td>
                        </tr>
                        {supportListing.length > 0 ? (
                          <>
                            {supportListing.map((data, index) => {
                              const formatDate = moment
                                .unix(data.created_at)
                                .format('DD MMMM YYYY')
                              return (
                                <tr
                                  key={index}
                                  style={{
                                    margin: '10px 0',
                                    padding: '10px',
                                    // textAlign:"center"
                                  }}
                                  // style={{}}
                                  className={`dot ${changesBgColor(
                                    checkStatus(data?.status),
                                  )}`}
                                >
                                  <td className="mt-3">
                                    <span className="mt-3 d-flex mr-4">
                                      {(currentPage - 1) * pageSize +
                                        (index + 1)}
                                    </span>
                                  </td>
                                  <td className="mt-3">
                                    <span className="mt-3 d-flex mr-4">
                                      {data?.assigned_to?.name}
                                    </span>
                                  </td>
                                  <td className="mt-3">
                                    <span className="mt-3 d-flex mr-4">
                                      {data.title}
                                    </span>
                                  </td>
                                  <td className="mt-3">
                                    <span className="mt-3 d-flex mr-4">
                                      {checkStatus(data?.status)}
                                    </span>
                                  </td>
                                  <td className="mt-3">
                                    <span className="mt-3 d-flex mr-4">
                                      {formatDate}
                                    </span>
                                  </td>
                                  {
                                    userRoles != 5 &&   <td
                                    className="mt-3"
                                    style={{ textAlign: 'center' }}
                                  >
                                    <span className="mt-4 d-flex text-center mr-4">
                                      {data.created_by?.name }
                                    </span>
                                  </td>
                                  }
                                
                                  <td
                                    className="mt-3"
                                    style={{ textAlign: 'center' }}
                                  >
                                    <span className="mt-4 d-flex text-center mr-4">
                                      {data.updated_by?.name !== undefined ? (
                                        data?.updated_by?.name
                                      ) : (
                                        <> -- </>
                                      )}
                                    </span>
                                  </td>
                                  <td style={{ textAlign: 'center' }}>
                                    <span
                                      style={{
                                        cursor: 'pointer',
                                      }}
                                      className="mt-3  mr-4"
                                      onClick={() => handleOpenModel(data.id)}
                                    >
                                      <i className="fa fa-eye mt-4 site-color"></i>
                                    </span>

                                   
                                    {userRoles == 1 &&  <> <span
                                      style={{
                                        cursor: 'pointer',
                                      }}
                                      className="mt-3  mr-4"
                                      onClick={() =>
                                        navigate(`/add-support/${data.id}`)
                                      }
                                    >
                                      <i className="fa fa-pencil mt-4 site-color"></i>
                                    </span>  <span
                                      style={{
                                        cursor: 'pointer',
                                      }}
                                      className="mt-4  mr-4"
                                      onClick={() => {
                                        setSupportId(data?.id)
                                        setdeletModalIsOpen(!deletModalIsOpen)
                                      }}
                                    >
                                      <i className="fa fa-trash site-color"></i>
                                    </span></>}
                                  
                                  </td>
                                </tr>
                              )
                            })}
                            <tr>
                              
                              <td colSpan={8} className="text-center w-100">
                                <ReactPaginate
                                  previousLabel={'Previous'}
                                  nextLabel={'Next'}
                                  onPageChange={handlePageChange}
                                  pageCount={totalPage}
                                  containerClassName={'pagination'}
                                  forcePage={currentPage - 1}
                                  activeClassName={'active'}
                                />
                              </td>
                            </tr>
                          </>
                        ) : (
                          <tr>
                            <td
                              colSpan="7"
                              className="text-center"
                              style={{ margin: '2em 0' }}
                            >
                              No Records Found.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <Modal
                      isOpen={deletModalIsOpen}
                      ariaHideApp={false}
                      onRequestClose={() =>
                        setdeletModalIsOpen(!deletModalIsOpen)
                      }
                      style={customStyles}
                      contentLabel="Example Modal"
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <p>Are you sure you want to delete the user?</p>
                        </div>
                        <div className="col-md-12 mt-2">
                          <button
                            type="button"
                            className="reset-button"
                            onClick={() => handleDeleteSupport(supportId)}
                          >
                            Delete
                          </button>
                          <button
                            type="button"
                            className="button-default ml-2"
                            onClick={() =>
                              setdeletModalIsOpen(!deletModalIsOpen)
                            }
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </Modal>

                    {/* {isView && (
                      <Modal
                        isOpen={isView}
                        ariaHideApp={false}
                        onRequestClose={() => setIsView(!isView)}
                        style={customStyles}
                        contentLabel="Example Modal"
                      >
                        <div className="row">
                          <div className="col-md-12">
                            <p>Support View Details</p>
                          </div>
                          <div className="col-md-12">
                            {finalData === null ? (
                              <div>Loading...</div>
                            ) : (
                              <>
                                <h4>Ticket Title : {finalData?.title}</h4>
                                <p className="ticketAssigend">
                                  <strong>Assigned To:</strong>
                                  <span>
                                    <img
                                      className="userProfile"
                                      src={finalData?.assigned_to?.image}
                                      alt={`${finalData?.assigned_to?.name}'s profile`}
                                    />
                                  </span>
                                  {finalData?.assigned_to?.name}
                                </p>

                                <p>
                                  <strong>Status:</strong> {checkStatus(finalData?.status)}
                                </p>
                              
                                <p>
                                  <strong>Created At:</strong>{' '}
                         
                                  {moment(finalData?.created_at * 1000).format('DD MMMM YYYY, h:mm A')}
                                </p>
                                {finalData?.updated_at && (
                                  <p>
                                    <strong>Updated At:</strong>{' '}
                        
                                     {moment(finalData?.updated_at * 1000).format('DD MMMM YYYY, h:mm A')}
                                  </p>
                                )}
                              </>
                            )}
                          </div>
                          <div className="col-md-12 mt-2">
                            <button
                              type="button"
                              className="button-default ml-2"
                              onClick={() => setIsView(!isView)}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </Modal>
                    )} */}
                      {isView && (
                     <Modal
                     isOpen={isView}
                     ariaHideApp={false}
                     onRequestClose={() => setIsView(!isView)}
                     style={customStyles}
                     contentLabel="Example Modal"
                   >
                     <div className="modal-header">
                       <h2>Support Ticket Details</h2>
                       <button
                         type="button"
                         className="close-button"
                         onClick={() => setIsView(!isView)}
                       >
                         &times;
                       </button>
                     </div>
                     <div className="modal-content">
                       {finalData === null ? (
                         <div>Loading...</div>
                       ) : (
                         <>
                           <h4>Ticket Title: {finalData?.title}</h4>
                           <p className="ticket-assigned">
                             <strong>Assigned To:</strong>
                             <span>
                               <img
                                 className="userProfile"
                                 src={finalData?.assigned_to?.image}
                                 alt={`${finalData?.assigned_to?.name}'s profile`}
                               />
                             </span>
                             {finalData?.assigned_to?.name}
                           </p>
                           <p>
                             <strong>Status:</strong> {checkStatus(finalData?.status)}
                           </p>
                           <p className="reply-content">
                            <strong>Reply:</strong> {finalData?.reply !==undefined ? finalData?.reply: "--"}
                             
                           </p>
                           <p>
                             <strong>Created At:</strong>{' '}
                             {moment(finalData?.created_at * 1000).format('DD-MMM-YYYY, h:mm A')}
                           </p>
                           {finalData?.updated_at && (
                             <p>
                               <strong>Updated At:</strong>{' '}
                               {moment(finalData?.updated_at * 1000).format('DD-MMM-YYYY, h:mm A')}
                             </p>
                           )}
                         </>
                       )}
                     </div>
                     <div className="modal-footer">
                       <button
                         type="button"
                         className="cancel-button"
                         onClick={() => setIsView(!isView)}
                       >
                         Cancel
                       </button>
                     </div>
                   </Modal>
                   
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ManageSupport
