import { put, call, takeLatest } from "redux-saga/effects";
import { MANAGE_PAYSLIPS, DELETE_PAYSLIPS} from "../../service/apiUrl";
import { postApiCall } from "../../service/PostApiCall";
import { managePayslipActionError, 
    managePayslipActionSuccess,
   
 } from "../action/managePayslipsAction";
import { MANAGE_PAYSLIPS_REQUEST} from "../actionType/type";


function* managePayslipSaga(action){
    try {
        const response = yield call(postApiCall,action.params,action.access_token,MANAGE_PAYSLIPS)
        yield put(managePayslipActionSuccess(response));
    } catch (error) {
        yield put(managePayslipActionError(error));
    }
}

export function* watchManagePayslipSaga(){
    yield takeLatest(MANAGE_PAYSLIPS_REQUEST,managePayslipSaga)
}


