import React, { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import { useSelector } from 'react-redux'
import TimePicker from 'react-bootstrap-time-picker'
import { useNavigate } from 'react-router-dom'
import {
  DEPARTMENTSUSERS,
  LEAVECREATE,
  LEAVES_HELPER,
  RECENTAPPROVED,
  USER_LEAVECREATE,
  USER_LEAVES_HELPER,
  USER_RECENTAPPROVED,
} from '../../service/apiUrl'
import { STRING } from '../../constants/string'
import Utils from '../../utility/utility'
import { postApiCall } from '../../service/PostApiCall'
import moment from 'moment'

const AddDeveloperLeave = (props) => {
  const [departmentval, setdepartmentval] = useState()
  const [leavetable, setleavetable] = useState([])
  const [employee, setEmployee] = useState(false)
  const [departmentEmployees, setdepartmentEmployees] = useState(false)
  const [type, settype] = useState()
  const [fromDate, setfromDate] = useState(new Date())
  const fromDates = Utils.momentDate(fromDate, STRING.SLASH_DATE_FORMAT)
  const [toDate, settoDate] = useState(new Date())
  const toDates = Utils.momentDate(toDate, STRING.SLASH_DATE_FORMAT)
  const [reason, setReason] = useState()
  const [contact, setcontact] = useState()
  const [startTime, setStartTime] = useState(32400)
  const [employes, setEmployes] = useState()
  const startTimeformat = Utils.secondtoTime(startTime)
  const [endtime, setEndTime] = useState(32400)
  const endTimeformat = Utils.secondtoTime(endtime)
  const [half, setHalf] = useState()
  const [helperData, setHelperData] = useState([])
  const [hasAlreadyTakenShortLeave, setTakenShortLeave] = useState(false)
  const [isSandwichLeave,setSandwichLeave] = useState(false)
  const [sandwichLeaveType, setSandwichLeaveType] = useState("")

  const navigate = useNavigate()

  const signdata = useSelector((state) => state.signinReducers)
  const user_id = signdata?.data?.id
  const access_token = signdata?.data?.access_token

  useEffect(() => {
    postApiCall([], access_token, USER_LEAVES_HELPER)
      .then((result) => {
        setHelperData(result)
      })
      .catch((error) => {
        console.error('LEAVES_HELPER', error)
      })
    getRecentLeaves()
  }, [])

  function timeToSeconds(timeString) {
    const [hours, minutes, seconds] = timeString.split(':').map(Number)
    return hours * 3600 + minutes * 60 + seconds
  }

  function validateTimeDifference(timeFrom, timeTo) {
    const secondsFromMidnightTimeFrom = timeToSeconds(timeFrom)

    const secondsFromMidnightTimeTo = timeToSeconds(timeTo)
    const timeDifferenceInSeconds =
      secondsFromMidnightTimeTo - secondsFromMidnightTimeFrom
    const timeDifferenceInHours = timeDifferenceInSeconds / 3600

    return timeDifferenceInHours
  }

  const AddLeave = () => {
    let message = ''
    if (Utils.isEmpty(type)) {
      message = STRING.TYPE
    } else if (startTime > endtime && type == 3) {
      message = STRING.TIMEGREATHER
    } else if (Utils.isEmpty(reason)) {
      message = STRING.REASON
    } else if (Utils.isEmpty(contact)) {
      message = STRING.CONTACT
    }

    if (message) {
      Utils.toast('error', message)
      return false
    } else {
      const addLeaveParams = {
        // department: departmentval,
        type: type,
        from: fromDates,
        to: toDates,
        half_day: String(half ?? ''),
        time_from: startTimeformat,
        time_to: endTimeformat,
        reason: reason,
        contact: contact,
        isSandwichLeave:isSandwichLeave
        // created_by: user_id,
        // uid: employes,
      }

      if(+type === 1 && isSandwichLeave === true){
        addLeaveParams.isSandwichLeave = isSandwichLeave
      }

      if (type == 3) {
        const isValidTimeDifference = validateTimeDifference(
          addLeaveParams.time_from,
          addLeaveParams.time_to,
        )

        if (isValidTimeDifference <= 0 || isValidTimeDifference > 2.5) {
          Utils.toast(
            'error',
            'Short leave limited to 2.5 hours. Please adjust the duration',
          )
          return
        }
      }
      postApiCall(addLeaveParams, access_token, USER_LEAVECREATE, navigate)
      
        .then((response) => {
          Utils.toast('success', 'Leave created successfully!!!')
          navigate('/my-leave')
        })
        .catch((error) => {
          Utils.toast('error', error?.data?.message)
        })
    }
  }

  const getRecentLeaves = () => {
    const params = {}
    postApiCall(params, access_token, USER_RECENTAPPROVED).then((result) => {
      setleavetable(result?.approved_leaves)
    })
  }

  // check user take twice a short leave ?

  const takeAlreadyShortLeave = () => {
    const startOfWeek = moment().startOf('week').format('MM/DD/YYYY')
    const endOfWeek = moment().endOf('week').format('MM/DD/YYYY')

    const weeklyLeaveData = leavetable.filter((leave) =>
      moment(leave.from).isBetween(startOfWeek, endOfWeek, 'day', '[]'),
    )

    const shortLeaveCount = weeklyLeaveData.filter(
      (leave) => leave.type === 'Short Leave',
    ).length

    if (shortLeaveCount >= 1) {
      setTakenShortLeave(true)
      return true
    }
    setTakenShortLeave(false)
    return false
  }

// const checkForSandwichLeave = () => {

//   const startDate = moment(fromDate);
//   const endDate = moment(toDate);
//   const startDateDay = startDate.format('dddd');
//   const endDateDay = endDate.format('dddd');

//   const lastThreeMonths = [];
//   for (let i = 1; i <= 3; i++) {
//     const startOfMonth = moment().subtract(i, 'months').startOf('month');
//     const endOfMonth = moment().subtract(i, 'months').endOf('month');
//     lastThreeMonths.push({
//       start: startOfMonth,
//       end: endOfMonth,
//       monthName: startOfMonth.format('MMMM'),
//       year: startOfMonth.format('YYYY'),
//     });
//   }

//   const parsedLeaveData = leavetable
//     .filter((leave) => leave.type === 'Full day')
//     .map((leave) => {
//       const leaveDate = moment(leave.from, 'MM/DD/YYYY');
//       const dayName = leaveDate.format('dddd');
//       return {
//         ...leave,
//         date: leaveDate,
//         day: dayName,
//       };
//     });

//   const filteredLeaves = parsedLeaveData.filter((leave) => {
//     const isInLastThreeMonths = lastThreeMonths.some((month) => {
//       return leave.date.isBetween(month.start, month.end, 'day', '[]');
//     });

//     const isFridayOrMonday = leave.day === 'Friday' || leave.day === 'Monday';

//     return isInLastThreeMonths && isFridayOrMonday;
//   });

//   const sandwichLeaveCount = filteredLeaves.length;
//   {console.log("===>Adsd",sandwichLeaveCount)}
//   if (sandwichLeaveCount >= 3) {
//     const isTodayOrTomorrowLeave =
//       startDateDay === 'Friday' ||
//       startDateDay === 'Monday' ||
//       endDateDay === 'Friday' ||
//       endDateDay === 'Monday';


//     const hasSandwichLeave =
//       isTodayOrTomorrowLeave &&
//       filteredLeaves.some((leave) => {
//         return leave.day === startDateDay || leave.day === endDateDay;
//       });

//     if (hasSandwichLeave) {

//       let newEndDate = moment(startDate);
//       const daysToExtend = 2;

//       if (startDateDay === 'Friday') {

//         newEndDate = newEndDate.add(daysToExtend, 'days');
//       } else if (startDateDay === 'Monday') {

//         newEndDate = endDate;
//       }
 
//       settoDate(newEndDate.toDate());

//       setSandwichLeaveType(startDateDay);
//       setSandwichLeave(true);

   
//       console.log("Sandwich Leave Detected: You have already taken 3 sandwich leaves in the last three months.");
//     } else {

//       setSandwichLeave(false);
//       setSandwichLeaveType(false);
//     }
//   } else {

//     setSandwichLeave(false);
//     setSandwichLeaveType(false);

//   }
// };

// const checkForSandwichLeave = () => {
//   const startDate = moment(fromDate);
//   const endDate = moment(toDate);
//   const startDateDay = startDate.format('dddd');
//   const endDateDay = endDate.format('dddd');

//   const lastThreeMonths = [];
//   for (let i = 1; i <= 3; i++) {
//     const startOfMonth = moment().subtract(i, 'months').startOf('month');
//     const endOfMonth = moment().subtract(i, 'months').endOf('month');
//     lastThreeMonths.push({
//       start: startOfMonth,
//       end: endOfMonth,
//       monthName: startOfMonth.format('MMMM'),
//       year: startOfMonth.format('YYYY'),
//     });
//   }

//   const parsedLeaveData = leavetable
//     .filter((leave) => leave.type === 'Full day')
//     .map((leave) => {
//       const leaveDate = moment(leave.from, 'MM/DD/YYYY');
//       const dayName = leaveDate.format('dddd');
//       return {
//         ...leave,
//         date: leaveDate,
//         day: dayName,
//       };
//     });

//   const filteredLeaves = parsedLeaveData.filter((leave) => {
//     const isInLastThreeMonths = lastThreeMonths.some((month) => {
//       return leave.date.isBetween(month.start, month.end, 'day', '[]');
//     });

//     const isFridayOrMonday = leave.day === 'Friday' || leave.day === 'Monday';

//     return isInLastThreeMonths && isFridayOrMonday;
//   });

//   const sandwichLeaveCount = filteredLeaves.length;

//   if (sandwichLeaveCount >= 3) {
//     const isTodayOrTomorrowLeave =
//       startDateDay === 'Friday' ||
//       startDateDay === 'Monday' ||
//       endDateDay === 'Friday' ||
//       endDateDay === 'Monday';

//     const hasSandwichLeave = filteredLeaves.some((leave) => {
//       const isMatchingDay =
//         (startDateDay === 'Friday' && leave.day === 'Monday') ||
//         (startDateDay === 'Monday' && leave.day === 'Friday') ||
//         (endDateDay === 'Friday' && leave.day === 'Monday') ||
//         (endDateDay === 'Monday' && leave.day === 'Friday');

//       return isMatchingDay || leave.day === startDateDay || leave.day === endDateDay;
//     });

//     if (hasSandwichLeave) {
//       if (startDateDay === 'Monday') {
//         const newStartDate = moment(startDate).subtract(2, 'days');
//         setfromDate(newStartDate.toDate());
//         settoDate(startDate.toDate());
//       } else if (startDateDay === 'Friday') {
//         const newEndDate = moment(startDate).add(2, 'days');
//         settoDate(newEndDate.toDate());
//       }

//       setSandwichLeaveType(startDateDay);
//       setSandwichLeave(true);

//       console.log("Sandwich Leave Detected: You have already taken 3 sandwich leaves in the last three months.");
//     } else {
//       setSandwichLeave(false);
//       setSandwichLeaveType(false);
//     }
//   } else {
//     setSandwichLeave(false);
//     setSandwichLeaveType(false);
//   }
// };

const checkForSandwichLeave = () => {
  const startDate = moment(fromDate);
  const endDate = moment(toDate);
  const startDateDay = startDate.format('dddd');
  const endDateDay = endDate.format('dddd');

  // const isFutureMonth = startDate.isAfter(moment(), 'month') || endDate.isAfter(moment(), 'month');
  const isFutureMonth = startDate.isAfter(moment(), 'month') 
  if (isFutureMonth) {
    setSandwichLeave(false);
    setSandwichLeaveType(false);
    return;
  }

  const lastThreeMonths = [];
  for (let i = 1; i <= 3; i++) {
    const startOfMonth = moment().subtract(i, 'months').startOf('month');
    const endOfMonth = moment().subtract(i, 'months').endOf('month');
    lastThreeMonths.push({
      start: startOfMonth,
      end: endOfMonth,
      monthName: startOfMonth.format('MMMM'),
      year: startOfMonth.format('YYYY'),
    });
  }

  const parsedLeaveData = leavetable
    .filter((leave) => leave.type === 'Full day')
    .map((leave) => {
      const leaveDate = moment(leave.from, 'MM/DD/YYYY');
      const dayName = leaveDate.format('dddd');
      return {
        ...leave,
        date: leaveDate,
        day: dayName,
      };
    });

  const filteredLeaves = parsedLeaveData.filter((leave) => {
    const isInLastThreeMonths = lastThreeMonths.some((month) => {
      return leave.date.isBetween(month.start, month.end, 'day', '[]');
    });

    const isFridayOrMonday = leave.day === 'Friday' || leave.day === 'Monday';

    return isInLastThreeMonths && isFridayOrMonday;
  });

  const sandwichLeaveCount = filteredLeaves.length;

  if (sandwichLeaveCount >= 3) {

    const hasSandwichLeave = filteredLeaves.some((leave) => {
      const isMatchingDay =
        (startDateDay === 'Friday' && leave.day === 'Monday') ||
        (startDateDay === 'Monday' && leave.day === 'Friday') ||
        (endDateDay === 'Friday' && leave.day === 'Monday') ||
        (endDateDay === 'Monday' && leave.day === 'Friday');

      return isMatchingDay || leave.day === startDateDay || leave.day === endDateDay;
    });

    if (hasSandwichLeave) {
      if (startDateDay === 'Monday') {
        const newStartDate = moment(startDate).subtract(2, 'days');
        setfromDate(newStartDate.toDate());
        settoDate(startDate.toDate());
        setSandwichLeaveType("Monday");
        setSandwichLeave(true);
      } else if (startDateDay === 'Friday') {
        const newEndDate = moment(startDate).add(2, 'days');
        settoDate(newEndDate.toDate());
        setSandwichLeaveType(startDateDay);
        setSandwichLeave(true);
      }
      else if (startDateDay === 'Saturday'){
        setSandwichLeaveType("Monday");
        setSandwichLeave(true);
      }
      else{
        setSandwichLeaveType(startDateDay);
        setSandwichLeave(false);
      }
    } else {
      setSandwichLeave(false);
      setSandwichLeaveType(false);
    }
  } else {
    setSandwichLeave(false);
    setSandwichLeaveType(false);
  }
};

  useEffect(() => {
    if (+type === 3) {
      takeAlreadyShortLeave()
      setSandwichLeave(false)
    } else if (+type === 1) {
      checkForSandwichLeave()
    } else {
      setSandwichLeave(false)
      setTakenShortLeave(false)
    }
  }, [type, fromDate])

  return (
    <>
      <div className={props?.data?.transtion == true ? 'contents' : 'content'}>
        <div className="content-top-spacer"></div>
        
        <div className="color-body">
          <div className="row">
            <div className="col-md-6 manage">
              <div className="card-input mt-5 mb-2">
                <div className="col-md-12">
                  <div className="mt-3">
                    <h5 className="border-bottom pb-2">Apply for Leave</h5>
                    {console.log("sdakmkmasd", isSandwichLeave, sandwichLeaveType)}
                  </div>
                  <form>
                    <div className="row mt-3">
                      {/* <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Department</label>
                          <Select
                            options={helperData?.departments}
                            // value={{label : departments?.label , value:departments?.value}}
                            onChange={(departments) => {
                              setdepartmentval(departments.value);
                              getDepartmentUsers(departments.value);
                              setEmployee(true);
                            }}
                            placeholder="Please Select"
                          />
                        </div>
                      </div> */}
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Leave Type</label>
                          <Select
                            options={helperData?.leave_type}
                            // value={{label : departments?.label , value:departments?.value}}
                            onChange={(leavetype) => {
                              settype(leavetype.value)
                              if (leavetype.value == '1') {
                                setHalf('')
                                setStartTime('')
                                setEndTime('')
                              }
                              if (leavetype.value == '2') {
                                setStartTime('')
                                setEndTime('')
                              }
                              if (leavetype.value == '3') {
                                setHalf('')
                              }
                            }}
                            placeholder="Please Select"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        {employee == true ? (
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Employee</label>
                            <Select
                              options={departmentEmployees}
                              // value={{label : departments?.label , value:departments?.value}}
                              onChange={(employe) => {
                                setEmployes(employe.value)
                                getRecentLeaves(employe.value)
                              }}
                              placeholder="Please Select"
                            />
                          </div>
                        ) : null}
                      </div>
                      {type == 1 || type == 2 || type == 3 ? (
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              From Date
                            </label>
                            <DatePicker
                              selected={fromDate}
                              onChange={(date) => {
                                setfromDate(date)
                              }}
                              className="width-dateset w-100"
                            />
                          </div>
                        </div>
                      ) : null}
                      {type == 1 ? (
                        <>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                End Date
                              </label>
                           
                              <DatePicker
                                selected={toDate}
                                onChange={(date) => settoDate(date)}
                                disabled={sandwichLeaveType === "Friday" ? true: false}
                                className="width-dateset w-100"
                              />
                            </div>
                          </div>
                        </>
                      ) : null}
                      {type == 2 ? (
                        <>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">Half</label>
                              <Select
                                options={helperData?.half_leave_type}
                                // value={{label : departments?.label , value:departments?.value}}
                                onChange={(element) => {
                                  setHalf(element.value)
                                  settoDate(fromDate)
                                }}
                                placeholder="Please Select"
                              />
                            </div>
                          </div>
                        </>
                      ) : null}
                      {type == 3 ? (
                        <>
                          <div className="col-md-3">
                            <label htmlFor="exampleInputEmail1">
                              Start Time
                            </label>
                            <TimePicker
                              start="09:00"
                              end="20:00"
                              onChange={(start) => {
                                setStartTime(start)
                              }}
                              value={startTime}
                            />
                          </div>
                          <div className="col-md-3">
                            <label htmlFor="exampleInputEmail1">End Time</label>
                            <TimePicker
                              start="09:00"
                              end="20:00"
                              step={30}
                              onChange={(end) => {
                                setEndTime(end)
                              }}
                              value={endtime}
                            />
                          </div>
                        </>
                      ) : null}

                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Reason</label>
                          <textarea
                            className="form-control"
                            id="exampleFormControlTextarea1"
                            onChange={(reason) => {
                              setReason(reason.target.value)
                            }}
                            rows="4"
                          ></textarea>
                        </div>
                        <div className="mt-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Contact During Leave
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              onChange={(Contact) => {
                                setcontact(Contact.target.value)
                              }}
                              // placeholder="Enter Project Name"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {hasAlreadyTakenShortLeave === true && (
                      <span className="shortLeaveErrror">
                        You can't apply for short leave more than 1 times in a
                        month.
                      </span>
                    )}
                       {isSandwichLeave === true && (
                      <span className="shortLeaveErrror">
                        Sandwich leave detected: 3 days salary will be deducted due to a similar leave within the last 3 months.
                      </span>
                    )}
                    <div className="col-md-12">
                      <div className="row mb-5 mt-4">
                        <div className="">
                          <button
                            type="button"
                            className={`${
                              hasAlreadyTakenShortLeave
                                ? 'disabledBtn'
                                : 'create-button'
                            }`}
                            onClick={AddLeave}
                            disabled={hasAlreadyTakenShortLeave}
                          >
                            Apply
                          </button>
                        </div>
                        <div className="ml-2">
                          <button
                            type="button"
                            className="create-button1 btn-info"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-6 manage">
              <div className="card-input mt-5 mb-2">
                <div className="col-md-12">
                  <div className="mt-3">
                    <h5>My Recent Approved Leaves</h5>
                  </div>
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col" className="text-center">
                          Date
                        </th>
                        <th scope="col" className="text-center">
                          Type
                        </th>
                        <th scope="col" className="text-center">
                          Duration
                        </th>
                        <th scope="col" className="text-center">
                          Reason
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {leavetable?.map((datae, idx) => {
                        return (
                          <tr key={idx}>
                            <td className="text-center">
                              {Utils.momentDate(datae?.from, 'MMMM DD, YYYY')}
                            </td>
                            <td className="text-center">{datae?.type}</td>
                            <td className="text-center">{datae?.duration}</td>
                            <td className="text-center" title={datae?.reason}>
                              {Utils.stringReducer(datae?.reason)}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                  <div className="border-bottom mt-3"></div>
                  {leavetable == '' ? (
                    <div className="text-center">
                      <div className="no-approved">
                        <p className="mb-0">No Recent Approved Leaves</p>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddDeveloperLeave
