// import React, { Component } from "react";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { USERGET_DATA} from "../service/apiUrl";
import { useSelector } from "react-redux";
import { postApiCall } from "../service/PostApiCall";
import { INVENTORY_UPDATE, VIEW_INVENTORY } from "../service/apiUrl";
import { useNavigate, useParams } from "react-router-dom";
import { STRING } from "../constants/string";
import Utils from "../utility/utility";
import { Link } from "react-router-dom";

const EditInveintory = (props) => {
  const {inventory_id} = useParams();
  const [name , setName] = useState("");
  const [serialno , setSerialNo] = useState("");
  const [brand , setBrand] = useState();
  const [type , setType] = useState("");
  console.log ("type111" ,type?.value)
  const [assign , setAssign] = useState("");
  const navigate = useNavigate();
  const validateannouncement = () => {
    let message = "";
    if (Utils.isEmpty(name)) {
      message = STRING.NAME;
    } else if (Utils.isEmpty(serialno)) {
      message = STRING.SERIALNO;
    } else if (Utils.isEmpty(brand)) {
      message = STRING.BRAND;
    } else if (Utils.isEmpty(type)) {
      message = STRING.TYPE;
    }else if (Utils.isEmpty(assign)) {
      message = STRING.ASSIGN;
    }
    return message;
  };
  const updateInventort = ()=>{
    const message = validateannouncement();
    if (message) {
      Utils.toast("error", message);
      return false;
    } else{
    const data = {
      id : inventory_id,
      name : name,
      brand : brand,
      type : type?.value,
      serial_number : serialno,
      assign_to : assign?.value,

    }
    postApiCall(data, signdata?.data?.access_token,  INVENTORY_UPDATE)
    .then((result) => {
      navigate("/inventory-listing");
      Utils.toast("success", STRING.INVENTORY_SUCCESSFUL_UPDATE );
    })
    .catch((error) => {
      console.error("USER_HELPER", error);
    });
  }
  }
const signdata = useSelector((state) => state.signinReducers);
const [typeOption , setOption] = useState([]);
const [helperData, setHelperData] = useState([]);
useEffect(()=>{
  const type = [
    {value : 'keyboard',label : 'Keyboard'},
    {value : "mouse",label : "Mouse"},
    {value : "destop",label : "Destop"},
    {value : "cpu",label : "CPU"},
    {value : "UPS",label : "UPS"},
    {value : "laptop",label : "Laptop"},
    {value : "datacable",label : "Datacable"},
    {value : "ctype datacable",label : "Ctype Datacable"},
    {value : "mobile",label : "Mobile"},
    {value : "multiport",label : "Multiport"},
   ]
   setOption(type)
},[])
useEffect(() => {
  postApiCall([], signdata?.data?.access_token, USERGET_DATA)
    .then((result) => {
      setHelperData(result?.all_users);
    })
    .catch((error) => {
      console.error("USER_HELPER", error);
    });
}, []);

useEffect(()=>{
  const id = {
    id : inventory_id
  }
  postApiCall(id, signdata?.data?.access_token, VIEW_INVENTORY )
  .then((result) => {
   console.log("result11111" , result)
   setName(result?.name)
   setSerialNo(result?.serial_number)
   setBrand(result?.brand)
   setType({label : result?.type ,value : result?.type})
   setAssign({label : result?.first_name+' '+result?.last_name ,value : result?.assign_to})
  })
  .catch((error) => {
    console.error("USER_HELPER", error);
  });
},[])
  return (
    <>
      <div className={props?.data?.transtion == true ? "contents" : "content"}>
        <div className="content-top-spacer"></div>
        <div className="color-body">
          <div className="col-md-6 manage">
            <div className="card-input mt-5 mb-2">
              <div className="col-md-12">
                {/* <div className="mt-3">
                  <h5 className="border-bottom pb-2">Create Inventory</h5>
                </div> */}
                <form>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Name</label>
                        <input
                          type="text"
                          value={name}
                          className="form-control"
                          onChange={(data)=>{
                            setName(data.target.value)
                          }}

                          // placeholder="Enter Project Owner"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Serial Number
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={serialno}
                          onChange={(data)=>{
                            setSerialNo(data.target.value)
                          }}

                          // placeholder="Enter Project Owner"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Brand</label>
                        <input
                          type="text"
                          className="form-control"
                          value={brand}
                          onChange={(data)=>{
                            setBrand(data.target.value)
                          }}

                          // placeholder="Enter Project Owner"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Type</label>
                        <Select options={typeOption} value={type} onChange={(data)=> setType({
                                value: data?.value,
                                label: data?.label,
                              })}/>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Assign To</label>
                        <Select options={helperData} value={assign} onChange={(data)=>{
                          setAssign({
                            value: data?.value,
                            label: data?.label,
                          })
                        }}/>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="row mb-5 mt-4 ml-2">
                        <div className="">
                          <button type="button" className="create-button" onClick={updateInventort}>
                            Update
                          </button>
                        </div>
                        <div className="ml-2">
                          <Link to="/inventory-listing">
                          <button
                            type="button"
                            className="create-button1 btn-info"
                          >
                            Cancel
                          </button></Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12"></div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditInveintory;
