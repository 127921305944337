import { put, call, takeLatest } from "redux-saga/effects";
import { TIMELOG_LISTING, USER_TIMELOG_LISTING } from "../../service/apiUrl";
import { postApiCall } from "../../service/PostApiCall";
import { timelogActionError, 
    timelogActionSuccess,
 } from "../action/timelogAction";
import { TIMELOG_REQUEST } from "../actionType/type";


function* timelogSaga(action){
    try {
        const response = yield call(postApiCall,action.params,action.access_token,action.role == 1 ? TIMELOG_LISTING : USER_TIMELOG_LISTING)
        yield put(timelogActionSuccess(response));
    } catch (error) {
        yield put(timelogActionError(error));
    }
}

export function* watchTimelogSaga(){
    yield takeLatest(TIMELOG_REQUEST,timelogSaga)
}
