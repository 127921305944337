import Select from "react-select";
import "rsuite/dist/rsuite.css";
import { DateRangePicker } from "rsuite";
import isAfter from "date-fns/isAfter";

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { timelogActionRequest } from "../../redux/action/timelogAction";
import Utils from "../../utility/utility";
import { postApiCall } from "../../service/PostApiCall";
import { STRING } from "../../constants/string";
import LoadingBox from "../../components/LoadingBox";
import { MANAGE_TIMELOG_HELPER } from "../../service/apiUrl";
import Modal from "react-modal";
import moment from "moment";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
  },
};

const DeveloperTimelog = (props) => {
  const [fromDate, setFromDate] = useState(Utils.getCurrentMonthDates().start);
  const [toDate, setToDate] = useState(Utils.getCurrentMonthDates().end);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState("");

  const dispatch = useDispatch();

  const timelogList = useSelector((state) => state.timelogReducer);
  const signdata = useSelector((state) => state.signinReducers);
  const access_token = signdata?.data?.access_token;

  useEffect(() => {
    const params = {
      start_time: fromDate ? Utils.fromDateTimeStamp(fromDate) : "",
      end_time: toDate ? Utils.toDateTimeStamp(toDate) : "",
      pageSize: 10,
      orderby: "",
      order: "",
      page: 1,
    };

    if (access_token && signdata?.data?.role) {
      dispatch(
        timelogActionRequest(params, access_token, signdata?.data?.role)
      );
    } else {
      console.error("Timelog List error");
    }
  }, [access_token, fromDate, toDate]);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const changeTimestampforPreviousMonth = () => {
    const previous = Utils.getPreviousMonthDates();
    setFromDate(previous?.start);
    setToDate(previous?.end);
  };

  const changeTimestampForCurrentMonth = () => {
    const current = Utils.getCurrentMonthDates();
    setFromDate(current?.start);
    setToDate(current?.end);
  };

  return (
    <>
      <div className={props?.data?.transtion == true ? "contents" : "content"}>
        <div className="content-top-spacer"></div>
        <div className="color-body">
          <div className="col-md-12">
            <div className="card-input mt-3">
              <div className="col-md-12 mb-3">
                <div className="mt-3">
                  <div className="row">
                    <h5 className="ml-4">Timelog</h5>
                    <div className="flex-row">
                      <div className="row mr-4 mt-1 mt-2">
                        <div className="dot bg-red mt-1 mr-1"></div>
                        <p>Not Completed</p>
                      </div>
                      <div className="row mr-4 mt-1 mt-2">
                        <div className="dot bg-green mt-1 mr-1"></div>
                        <p>Completed</p>
                      </div>
                      <div className="row mr-4 mt-1 mt-2">
                        <div className="dot bg-orange mt-1 mr-1"></div>
                        <p>Not Clocked Out</p>
                      </div>
                      <div className="row mr-4 mt-1 mt-2">
                        <div className="dot bg-warning mt-1 mr-1"></div>
                        <p>Saturday</p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="btn btn-success mr-2"
                    onClick={changeTimestampForCurrentMonth}
                  >
                    {Utils.momentDate(new Date(), "MMMM")}
                  </div>
                  <div
                    className="btn btn-primary"
                    onClick={changeTimestampforPreviousMonth}
                  >
                    {Utils.momentSubtract(1)}
                  </div>
                </div>
                <div className="mt-4">
                  <div className="col-md-12"></div>
                  <div className="">
                    <div className="mt-3">
                      <table className="table table-sm table-v-align-m">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="site-color"
                              style={{ width: "5%" }}
                            >
                              <span className="mt-2 mb-2 d-flex">#</span>
                            </th>
                            <th
                              scope="col"
                              className="site-color"
                              style={{ width: "25%" }}
                            >
                              <span className="mt-2 mb-2 d-flex">Date</span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">Checkin</span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">Checkout</span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">Break</span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">
                                Total Hour
                              </span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">
                                Productive Hour
                              </span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">Comments</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="">
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          {timelogList?.isLoading ? (
                            <tr style={{ height: "100px" }}>
                              <td colSpan={`100%`}>
                                <LoadingBox />{" "}
                              </td>
                            </tr>
                          ) : timelogList?.data?.timelog?.length ? (
                            timelogList?.data?.timelog?.map((timelog, idx) => {
                              return (
                                <tr
                                  key={idx}
                                //   className={
                                //     timelog?.isSaturday
                                //       ? "bg-warning white h-40"
                                //       : timelog?.is_clockout == "1"
                                //       ? "bg-orange white h-40"
                                //       : Utils.momentHours(
                                //           timelog?.productive_time
                                //         ) < 9
                                //       ? "bg-red white h-40"
                                //       : "bg-green white h-40"
                                //   }

                                  className={
                                    timelog?.isSaturday
                                      ? "bg-warning white h-40"
                                      : timelog?.is_clockout == "1"
                                      ? "bg-orange white h-40"
                                      : timelog?.end_time
                                      == ''
                                      ? "timeLog_Not_END white h-40"
                                      : Utils.momentHours(timelog?.productive_time) < 9
                                      ? "bg-red white h-40"
                                      : "bg-green white h-40"
                                  }
                                >
                                  <td>
                                    <span className="d-flex mr-4">
                                      {idx + 1}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="d-flex">
                                      {timelog?.formated_date ?? "NA"}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="d-flex">
                                      {timelog?.start_time
                                        ? Utils.momentTimeFromTimestamp(
                                            STRING.PROJECT_TIME_FORMAT,
                                            timelog?.start_time
                                          )
                                        : "NA"}
                                    </span>
                                  </td>
                                  <td>
                                    {console.log("asdasd",timelog)}
                                    <span className="d-flex">
                                      {timelog?.end_time
                                        ? Utils.momentTimeFromTimestamp(
                                            STRING.PROJECT_TIME_FORMAT,
                                            timelog?.end_time
                                          )
                                        : "NA"}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="d-flex">
                                      {timelog?.break ? timelog?.break : "NA"}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="d-flex">
                                      {timelog?.total_time ?? "NA"}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="d-flex">
                                      {timelog?.productive_time ?? "NA"}
                                    </span>
                                  </td>
                                  <td>
                                    {timelog?.no_break_reason ? (
                                      <div className="text-center">
                                        <button
                                          onClick={() =>
                                            setModalData(
                                              timelog?.no_break_reason ?? "NA"
                                            )
                                          }
                                          className="button-popup"
                                        >
                                          <i
                                            className="fa fa-eye white"
                                            onClick={openModal}
                                            aria-hidden="true"
                                          ></i>
                                        </button>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="9">No record found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>

                      <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                        ariaHideApp={false}
                        style={customStyles}
                        contentLabel="Example Modal"
                      >
                        <div className="col-md-12">
                          <div className="text-right">
                            <i
                              className="fa fa-times curser-point"
                              aria-hidden="true"
                              onClick={closeModal}
                            ></i>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <h5 className="mb-0">No Break Reason</h5>
                              <div className="border-bottom mt-2 mb-2"></div>
                            </div>
                            <div className="col-md-12">
                              <p> {modalData ?? "NA"} </p>
                            </div>
                          </div>
                        </div>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeveloperTimelog;
