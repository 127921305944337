import { logo, default_pic } from '../assets'
import { useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import { useDispatch, useSelector } from 'react-redux'
import { signoutRequest } from '../redux/action/signinAction'
import { useEffect, useMemo } from 'react'
import Confetti from 'react-confetti'
import { useRef } from 'react'
import Utils from '../utility/utility'
import { STRING } from '../constants/string'
import moment from 'moment'
import { Badge } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { GoDotFill } from 'react-icons/go'
import { postApiCall } from '../service/PostApiCall'
import { NOTIFICATION, NOTIFICATION_READ } from '../service/apiUrl'
import NotificationComponent from './NotificationComponent'
import { FaUser } from 'react-icons/fa'
import { BiSupport } from 'react-icons/bi'
import addNotification from 'react-push-notification';

const Header = (props) => {
  const dispatch = useDispatch()
  const [isActive, setIsActive] = useState('2')
  const [birthday, setBirthday] = useState({
    status: false,
  })
  const [showWelcomeMsg, setShowWelcomeMsg] = useState('')
  let [totalCount, setTotalCount] = useState(0)
  let [notificationData, setNotificationData] = useState([])

  const windowSize = useRef([window.innerWidth, window.innerHeight])
  const signdata = useSelector((state) => state.signinReducers)
  let token = signdata?.data?.access_token

  const handleClick = (value) => {
    // 👇️ toggle
    setIsActive(value)

    // 👇️ or set to true
    // setIsActive(true);
  }

  const logout = () => {
    dispatch(signoutRequest())
  }

  useEffect(() => {
    ;(function () {
      var id = localStorage.getItem('_id')
      var date = localStorage.getItem('_date')
      if (
        !id ||
        !date ||
        parseInt(id) !== parseInt(signdata?.data?.id) ||
        date.toString() !==
          Utils.momentDate(STRING.SLASH_DATE_FORMAT, new Date()).toString()
      ) {
        setShowWelcomeMsg('Welcome Back')
        localStorage.setItem('_id', signdata?.data?.id)
        localStorage.setItem(
          '_date',
          Utils.momentDate(STRING.SLASH_DATE_FORMAT, new Date()),
        )
      }

      const currentDate = moment().format('yyyy-MM-DD').split('-')
      const birthdate = signdata?.data?.dob?.split('-')

      if (
        birthdate?.length === 3 &&
        currentDate.length === 3 &&
        birthdate[1] === currentDate[1] &&
        birthdate[2] === currentDate[2]
      ) {
        setShowWelcomeMsg('Happy BirthDay!')
        setBirthday({ status: true })
        setTimeout(() => {
          setBirthday({ status: false })
        }, 10000)
      }
    })()
  }, [])

  useEffect(() => {
    if (!signdata?.data?.hasOwnProperty('dob')) {
      logout()
    }
    const currentDate = moment().format('yyyy-MM-DD').split('-')
    const birthdate = signdata?.data?.dob?.split('-')

    if (
      birthdate?.length === 3 &&
      currentDate.length === 3 &&
      birthdate[1] === currentDate[1] &&
      birthdate[2] === currentDate[2]
    ) {
      setShowWelcomeMsg('Happy Birthday')
      setBirthday({ status: true })
      setTimeout(() => {
        setBirthday({ status: false })
      }, 10000)
    }

    // if(showWelcomeMsg && Utils.momentDate(new Date(),'DD/MM/YYYY') === signdata?.data?.dob){
    //   setBirthday({status:true});
    //   setTimeout(() => {
    //     setBirthday({status:false});
    //   }, 10000);
    // }
  }, [])

 
  const browserNotify = (title,message) => {

    if (!('Notification' in window)) {
      console.error('This browser does not support notifications.');
      return;
    }
 

    if(Notification.permission == 'denied' || Notification.permission == "default"){
      Notification.requestPermission().then(permission =>{
 
        if(permission === 'granted'){
          new Notification(title, {
            body: message,
            icon:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtuphMb4mq-EcVWhMVT8FCkv5dqZGgvn_QiA&s",
      
          });
        }
      })
      return;
    }

    if (Notification.permission === "granted") {
      new Notification(title, {
        body: message,
        icon:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtuphMb4mq-EcVWhMVT8FCkv5dqZGgvn_QiA&s",
      });
    }
  };

  const handleNotification = async () => {
    try {
      let params = {}
      let response = await postApiCall(params, token, NOTIFICATION);
      if (!('Notification' in window)) {
        console.error('This browser does not support notifications.');
        return;
      }

      if (response.length > 0) {
        let notificationCount = response.filter(items => +items.isRead === 0)?.length
   
        setTotalCount(notificationCount)
        setNotificationData(response)
        
        response.forEach(element => {
          const createdAt = moment.unix(element.created_at);
          const today = moment();
       
          if (element.type === 'leave') {
            if (
              today.diff(createdAt, "seconds") <= 12 &&
              !localStorage.getItem(`read_${element.id}`)
            ) {
        
              browserNotify("New Leave Notification",element?.description)
              localStorage.setItem(`read_${element.id}`, true);
            }
          } else if (element.type === 'support') {
            if (
              today.diff(createdAt, "seconds") <= 12 &&
              !localStorage.getItem(`read_${element.id}`)
            ) {
              browserNotify("New Support Notification",element?.description)
              localStorage.setItem(`read_${element.id}`, true);
            }
          }
        
        });
      }
    } catch (error) {
      // Utils.toast('error', 'An error occurred while fetching notifications')
    }
  }
  useEffect(() => {
    if (signdata?.data?.role == 1) {
      handleNotification()
      const interval = setInterval(handleNotification, 10 * 1000)

      return () => clearInterval(interval)
    }
  }, [dispatch, token])

  const handleMarkAsRead = async (values) => {
    try {
      let params = {
        id: values,
      }

      let response = await postApiCall(params, token, NOTIFICATION_READ)
      handleNotification()
    } catch (error) {
  
      Utils.toast('error', error?.data?.message)
    }
  }

  const notify = () => {
    const message = `Anuj has applied for Short leave from 22/115/5sd.`;
    if (!('Notification' in window)) {
      console.error('This browser does not support notifications.');
      return;
    }
 

    if(Notification.permission == 'denied' || Notification.permission == "default"){
      Notification.requestPermission().then(permission =>{
 
        if(permission === 'granted'){
          new Notification("Hello worlds", {
            body: message,
          
          });
        }
      })
      return;
    }

    if (Notification.permission === "granted") {
      new Notification("New Leave Notification", {
        body: message,
        icon:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtuphMb4mq-EcVWhMVT8FCkv5dqZGgvn_QiA&s",
      });
    }
  };


  return (
    <>
      {birthday?.status ? (
        <Confetti
          width={windowSize.current[0]}
          height={windowSize.current[1]}
        />
      ) : (
        ''
      )}
      <div className="card-headers">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-8">
              <div className="row">
                {/* <button onClick={notify}>asd</button> */}

                <div className="">
                  <img
                    src={logo}
                    className="header-logo mt-3 ml-3"
                    style={{ objectFit: 'cover' }}
                    alt=""
                  />
                </div>
                <div
                  className="heder-close curser-point"
                  onClick={() => props.menuside((wasOpened) => !wasOpened)}
                >
                  <i className="fa fa-bars top-bars" aria-hidden="true"></i>
                </div>
                {/* <button onClick={handleClickss}>Show Notification</button> */}
                {/* <div className="header-serch">
                  <div className="form">
                    <i className="fa fa-search"></i>
                    <input
                      type="text"
                      className="form-control form-input"
                      placeholder="Search anything..."
                    />
                    <span className="left-pan">
                      <i className="fa fa-microphone"></i>
                    </span>
                  </div>
                </div> */}
                {showWelcomeMsg ? (
                  <div className="header-serch">
                    <h4 className="px-3 mt-1 mb-0 w-fit">
                      {showWelcomeMsg},
                      <span className="site-color ">
                        {` ${signdata?.data?.first_name ?? ''} ${
                          signdata?.data?.last_name ?? ''
                        }`}{' '}
                      </span>
                    </h4>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div className="profile-header">
                <Dropdown>
                  <Dropdown.Toggle variant="notification" id="dropdown-basic">
                    <div className="" style={{ position: 'relative' }}>
                      {totalCount > 0 && (
                        <Badge
                          bg="danger"
                          style={{ position: 'absolute', top: -5, right: 0 }}
                          text="white"
                        >
                          {totalCount}
                        </Badge>
                      )}

                      <i
                        className="fa fa-bell-o"
                        aria-hidden="true"
                        style={{ fontSize: 18 }}
                      ></i>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <div className="notificaton-block">
                      <div className="col-md-12 d-flex">
                        {/* <div className="col-md-4 text-center">
                          <button
                            className="bg-trans"
                            onClick={() => handleClick(1)}
                          >
                            <span>
                              <i
                                className={
                                  isActive == 1
                                    ? "fa fa-tasks green"
                                    : "fa fa-tasks"
                                }
                                aria-hidden="true"
                              ></i>{" "}
                              Projects
                            </span>
                          </button>
                        </div> */}
                        <div className="col-md-4 text-center">
                          <button
                            className="bg-trans"
                            onClick={() => handleClick(2)}
                          >
                           {  notificationData.filter(item => item.type === 'support' && item.isRead == "0") ?<GoDotFill color='#F39E11' />:""}
                          
                            <span>
                              <i
                                className={
                                  isActive == 2
                                    ? 'fa fa-cog yellow'
                                    : 'fa fa-cog'
                                }
                                aria-hidden="true"
                              ></i>
                              Support
                            </span>
                          </button>
                        </div>
                        <div className="col-md-4 text-center">
                          <button
                            className="bg-trans leaveBtn"
                            onClick={() => handleClick(3)}
                          >
                            { notificationData.length > 0 && notificationData.filter((element => {
                          
                            return element.type === 'leave' && +element?.isRead !== "1"
                           })) ? <GoDotFill color='#F39E11' />:""}
                           
                            <span>
                              <i
                                className={
                                  isActive == 3
                                    ? 'fa fa-users red'
                                    : 'fa fa-users'
                                }
                                aria-hidden="true"
                              ></i>
                              Leaves
                            </span>
                          </button>
                        </div>
                      </div>

                      <div className="border-bottom mt-2 mb-2"></div>
                      <div className="col-md-12" style={{overflow:"hidden",height:"22rem",overflowY:"scroll"}}>
                        <NotificationComponent
                          notificationData={notificationData}
                          signdata={signdata}
                          isActive={isActive}
                          markAsReadHandler={handleMarkAsRead}
                        />
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>

                <Dropdown>
                  <Dropdown.Toggle variant="profile" id="dropdown-basic">
                    <div className="profile-card">
                      <i className="fa fa-user" aria-hidden="true"></i>
                      <p className="ml-2">
                        {signdata?.data?.first_name} {signdata?.data?.last_name}
                      </p>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <div className="col-md-12">
                      <div className="profile-block bg-light-sky">
                        <img
                          src={signdata?.data?.profile_pic}
                          className="profile-image"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mt-2 text-right">
                      <button
                        type="button"
                        className="create-button1 btn-info"
                        onClick={logout}
                      >
                        Sign Out
                      </button>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
