import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import {} from "../../assets";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import { useDispatch, useSelector } from "react-redux";
import { managePayslipActionRequest } from "../../redux/action/managePayslipsAction";
import { postApiCall } from "../../service/PostApiCall";
import { DELETE_PAYSLIPS, PAY_HELPER } from "../../service/apiUrl";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import Select from "react-select";
import ReactPaginate from "react-paginate";

const ManagePayslip = (props) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const [nameSearch, setNameSearch] = useState("");
  const [visibilitySearch, setVisibilitySearch] = useState({
    value: "",
    label: "",
  });
  const [helperData, setHelperData] = useState([]);
  const [update, setUpdate] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "30%",
    },
  };

  const numbers = [1, 2, 3, 4, 5, 6, 1, 1, 1, 1];
  const options1 = {
    responsive: true,
    legend: {
      display: false,
    },
    type: "bar",
    //   scales: {
    //     xAxes: [{
    //         stacked: true
    //     }],
    //     yAxes: [{
    //         stacked: true
    //     }]
    // }
  };
  const barData = {
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    datasets: [
      {
        label: "My First dataset",
        backgroundColor: "rgba(255,99,132,0.2)",
        borderColor: "rgba(255,99,132,1)",
        borderWidth: 1,
        //stack: 1,
        hoverBackgroundColor: "rgba(255,99,132,0.4)",
        hoverBorderColor: "rgba(255,99,132,1)",
        data: [65, 59, 80, 81, 56, 55, 40, 12, 10, 10, 10, 50],
      },
      {
        // label: "My second dataset",
        // backgroundColor: "rgba(155,231,91,0.2)",
        // borderColor: "rgba(255,99,132,1)",
        // borderWidth: 1,
        // //stack: 1,
        // hoverBackgroundColor: "rgba(255,99,132,0.4)",
        // hoverBorderColor: "rgba(255,99,132,1)",
        // data: [45, 79, 50, 41, 16, 85, 20]
      },
    ],
  };
  const visible = [
    { value: "1", label: "Visible" },
    { value: "0", label: "Hidden" },
  ];

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  const dispatch = useDispatch();
  const signdata = useSelector((state) => state.signinReducers);
  const access_token = signdata?.data?.access_token;

  const managePayslipList = useSelector((state) => state.managePayslipsReducer);
  const payslipList = managePayslipList?.data?.payslips;

  // useEffect(() => {
  //   const parms = {};
  //   dispatch(managePayslipActionRequest(parms, access_token));
  // }, []);

  useEffect(() => {
    postApiCall([], access_token, PAY_HELPER)
      .then((result) => {
        setHelperData(result);
      })
      .catch((error) => {
        console.error("LEAVES_HELPER", error);
      });
  }, []);

  useEffect(() => {
    const parms = {
      access_token: access_token,
      search: nameSearch,
      visibility: visibilitySearch?.value,
      pageSize: pageSize,
      page: currentPage,
    };
    if (access_token) {
      dispatch(managePayslipActionRequest(parms, access_token));
    } else {
      console.error("payslip error");
    }
  }, [access_token, nameSearch, visibilitySearch, update]);

  useEffect(() => {
    console.log("payslipList", payslipList);
  }, [payslipList, deleteData]);

  const deletePayslip = async (payslipUser_id) => {
    if (access_token && payslipUser_id) {
      const params = {
        id: payslipUser_id,
      };
      await postApiCall(params, access_token, DELETE_PAYSLIPS)
        .then((result) => {
          dispatch(managePayslipActionRequest({}, access_token));
          closeModal();
        })
        .catch((error) => {
          console.error("payslip-delete error", error);
        });
    }
  };

  Chart.register(...registerables);

  const handlePageChange = (pageNumber) => {
    const page = pageNumber?.selected + 1;
    setCurrentPage(page);
    setUpdate(!update);
  };

  return (
    <>
      <div className={props?.data?.transtion == true ? "contents" : "content"}>
        <div className="content-top-spacer"></div>
        <div className="color-body">
          <div className="col-md-12">
            <div className="chart-width mt-4">
              <div className="mt-3 graph-leave card-input">
                <Bar
                  data={barData}
                  width={null}
                  height={null}
                  options={options1}
                  className=" p-3"
                />
              </div>
              <div className="mt-3">
                <h3>Payslips</h3>
              </div>
            </div>
            <div className="card-input mt-3">
              <div className="col-md-12 mb-3">
                <div className="mt-4">
                  <div className="col-md-12"></div>
                  <div className="">
                    <div className="mt-3">
                      <table className="table table-sm">
                        <thead>
                          <tr>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">#</span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">Name</span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">
                                Month Year
                              </span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">
                                Total Leaves
                              </span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">
                                Actual Salary
                              </span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">
                                Deduction (%)
                              </span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">Tax</span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">
                                Salary Credit
                              </span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">
                                Last Update By
                              </span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">
                                Visibility
                              </span>
                            </th>
                            <th
                              scope="col"
                              className="site-color"
                              style={{ width: "100px" }}
                            >
                              <span className="mt-2 mb-2 d-flex"></span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td></td>
                            <td>
                              <input
                                type="text"
                                className="w-75 p-1 listing-serach mt-2 mb-2"
                                onChange={(event) => {
                                  setNameSearch(event.target.value);
                                  setUpdate(!update);
                                }}
                              />
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className="col-2">
                              <Select
                                options={
                                  helperData?.visibility
                                    ? [
                                        { value: "", label: "All" },
                                        ...helperData?.visibility,
                                      ]
                                    : []
                                }
                                placeholder=""
                                className="w-75 listing-serach mt-2 mb-2 "
                                classNamePrefix="p-0"
                                isSearchable={true}
                                isClearable={true}
                                value={visibilitySearch}
                                onChange={(event) => {
                                  setVisibilitySearch({
                                    value: event?.value,
                                    label: event?.label,
                                  });
                                }}
                              />
                            </td>
                          </tr>
                          {payslipList?.length ? (
                            <>
                              {payslipList?.map((items, idx) => {
                                return (
                                  <tr key={idx}>
                                    <td>
                                      <span className="mt-3 d-flex mr-4">
                                        {(currentPage-1)*pageSize+(idx + 1)}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="mt-3 d-flex">
                                        {items?.uid.name}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="mt-3 d-flex">
                                        {items?.month} / {items.year}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="mt-3 d-flex">
                                        {items?.total_leaves}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="mt-3 d-flex align-items-center">
                                        <i className="fa fa-rupee mr-1"></i>{" "}
                                        {items?.actual_salary}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="mt-3 d-flex">
                                        {items?.deduction_percentage}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="mt-3 d-flex align-items-center">
                                        <i className="fa fa-rupee mr-1"></i>{" "}
                                        {items?.tax_percentage}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="mt-3 d-flex align-items-center">
                                        <i className="fa fa-rupee mr-1"></i>{" "}
                                        {items?.credited_salary}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="mt-3 d-flex">
                                        {items?.created_by.name}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="mt-3 d-flex">
                                        {items?.visibility?.label}
                                      </span>
                                    </td>

                                    <td>
                                      <div className="row mt-3 mb-3">
                                        <div className="">
                                          <Link
                                            to={`/view-payslip/${items.id}`}
                                          >
                                            <i
                                              className="fa fa-eye site-color curser-point"
                                              aria-hidden="true"
                                            ></i>
                                          </Link>
                                        </div>
                                        <div className="mx-2">
                                          <Link
                                            to={`/edit-payslip/${items.id}`}
                                          >
                                            <i
                                              className="fa fa-pencil site-color"
                                              aria-hidden="true"
                                            ></i>
                                          </Link>
                                        </div>
                                        <div className="">
                                          <button
                                            onClick={openModal}
                                            className="button-popup"
                                          >
                                            <i
                                              className="site-color fa fa-trash curser-point"
                                              aria-hidden="true"
                                              onClick={() =>
                                                setDeleteData(items.id)
                                              }
                                            ></i>
                                          </button>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}

                              <tr>
                                <td colSpan={10} className="text-center w-100">
                                  <ReactPaginate
                                    previousLabel={"Previous"}
                                    nextLabel={"Next"}
                                    pageCount={managePayslipList?.data?.total_pages}
                                    onPageChange={handlePageChange}
                                    containerClassName={"pagination"}
                                    activeClassName={"active"}
                                  />
                                </td>
                              </tr>
                            </>
                          ) : (
                            <tr>
                              <td colSpan="6">No record found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        preventScroll={true}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Daily Task"
        ariaHideApp={false}
      >
        <div className="">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <div className="text-right">
                  <i
                    className="fa fa-times curser-point"
                    aria-hidden="true"
                    onClick={closeModal}
                  ></i>
                </div>
              </div>
            </div>
            <div className="col-md-8 mx-auto text-center">
              <h6>Are you sure, You want to delete this?</h6>
            </div>
          </div>
          <div className="col-md-6 mx-auto text-center mt-4">
            <div className="row mb-5 mt-3 ">
              <div className=" text-center ml-5">
                <button
                  type="button"
                  className="create-button1 btn-info"
                  onClick={closeModal}
                >
                  Cancel
                </button>
              </div>
              <div className="ml-4">
                <button
                  type="button"
                  className="create-button"
                  onClick={() => {
                    deletePayslip(deleteData);
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ManagePayslip;
