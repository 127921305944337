import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingBox from "../components/LoadingBox";
import {
  manageProjectActionRequest,
  projectDeleteRequest,
} from "../redux/action/manageProjectAction";
import Utils from "../utility/utility";
import { STRING } from "../constants/string";
import Select from "react-select";
import { NavLink, Link } from "react-router-dom";
import { postApiCall } from "../service/PostApiCall";
import { DELETE_PROJECT, PROJECT_HELPER } from "../service/apiUrl";
import ReactPaginate from "react-paginate";
import CustomModal from "../components/CustomModal";

const ManageProject = (props) => {
  const [searchName, setSearchName] = useState("");
  const [helperData, setHelperData] = useState([]);
  const [technology, setTechnology] = useState({ value: "", label: "" });
  const [developer, setDeveloper] = useState({ value: "", label: "" });
  const [source, setSource] = useState({ value: "", label: "" });
  const [projectSize, setProjectSize] = useState({ value: "", label: "" });
  const [manager, setManager] = useState({ value: "", label: "" });
  const [trackerId, setTrackerId] = useState({ value: "", label: "" });
  const [billingType, setBillingType] = useState({ value: "", label: "" });
  const [update, setUpdate] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [showDevelpers, setShowDevelpers] = useState({
    status: false,
    data: [],
  });

  const projectList = useSelector((state) => state.manageProjectReducer);
  const signdata = useSelector((state) => state.signinReducers);
  const access_token = signdata?.data?.access_token;
  

  const dispatch = useDispatch();
  useEffect(() => {
    const params = {
      name: searchName,
      type: billingType?.value,
      technology: technology?.value,
      developer: developer?.value,
      manager: manager?.value,
      projectSize: projectSize?.value,
      source: source?.value,
      trackerId: trackerId?.value,
      pageSize: pageSize,
      page: currentPage,
    };

    if (access_token) {
      dispatch(manageProjectActionRequest(params, access_token));
    } else {
      console.error("projectList error");
    }
  }, [access_token, dispatch, update]);

  useEffect(() => {
    postApiCall([], signdata?.data?.access_token, PROJECT_HELPER)
      .then((result) => {
        setHelperData(result);
      })
      .catch((error) => {
        console.error("PROJECT_HELPER", error);
      });
  }, []);

  const deleteProject = (project_id) => {
    if (window.confirm("Are you sure you want to delete this Project?")) {
      const params = {
        id: project_id,
      };
      // dispatch(projectDeleteRequest(params, access_token))
      postApiCall(params, signdata?.data?.access_token, DELETE_PROJECT)
        .then((result) => {
          dispatch(manageProjectActionRequest(params, access_token));
        })
        .catch((error) => {
          console.error("PROJECT_deleted", error);
        });
    }
  };

  const handlePageChange = (pageNumber) => {
    const page = pageNumber?.selected + 1;
    setCurrentPage(page);
    setUpdate(!update);
  };

  return (
    <>
      <div className={props?.data?.transtion === true ? "contents" : "content"}>
        <div className="content-top-spacer"></div>
        <div className="color-body">
          <div className="col-md-12">
            <div className="card-input mt-3">
              <div className="col-md-12 mb-3">
                <div className="mt-5">
                  <div className="row">
                    <div className="col-md-4">
                      {/* <Link to="/add-project" className="create-button">
                        Create Project
                      </Link> */}
                    </div>
                    <div className="flex-row">
                      <div className="row mr-4 mt-1 mt-2">
                        <div className="dot dot-wraning mt-1 mr-1"></div>
                        <p>Waiting for completion</p>
                      </div>
                      <div className="row mr-4 mt-1 mt-2">
                        <div className="dot dot-hold mt-1 mr-1"></div>
                        <p>Hold</p>
                      </div>
                      <div className="row mr-4 mt-1 mt-2">
                        <div className="dot dot-active mt-1 mr-1"></div>
                        <p>Open</p>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="mt-2">
                      <p>
                        Successfully found{" "}
                        <b>{projectList?.data?.projects?.length}</b> Records
                      </p>
                    </div>
                    <div className="">
                      <table className="table table-sm">
                        <colgroup>
                          <col width="3%" />
                          <col width="20%" />
                          <col width="12%" />
                          <col width="12%" />
                          <col width="15%" />
                          <col width="15%" />
                          <col width="12%" />
                          <col width="11%" />
                        </colgroup>
                        <thead>
                          <tr>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex"> #</span>
                            </th>
                            <th
                              scope="col"
                              className="site-color"
                              style={{ width: "12%" }}
                            >
                              <span className="mt-2 mb-2 d-flex">
                                Manage Project
                              </span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">Type</span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">
                                Technology
                              </span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">
                                Developers
                              </span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">Managers</span>
                            </th>
                            {/* <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">
                                {" "}
                                Project Size
                              </span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">
                                Project Source
                              </span>
                            </th> */}
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">
                                Tracker Id
                              </span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex"> Manage</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td></td>
                            <td style={{ position: "relative" }}>
                              <input
                                type="text"
                                className="w-75 pl-2 listing-serach min-h-38 mt-2 mb-2"
                                value={searchName}
                                onChange={(event) => {
                                  setSearchName(event.target.value);
                                  setUpdate(!update);
                                }}
                              />
                              {searchName.length > 0 && (
                                <span
                                  style={{
                                    position: "absolute",
                                    color: "#949494",
                                    top: "50%",
                                    right: 90,
                                    transform: "translate(0%, -50%)",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setSearchName("");
                                    setUpdate(!update);
                                  }}
                                >
                                  &#x2715;
                                </span>
                              )}
                            </td>
                            <td>
                              <Select
                                options={helperData?.billing_type}
                                className="w-100 listing-serach mt-2 mb-2"
                                placeholder="Select"
                                isSearchable={true}
                                isClearable={true}
                                value={billingType}
                                styles={{
                                  menu: (base) => ({
                                    ...base,
                                    width: "max-content",
                                    minWidth: "100%",
                                  }),
                                }}
                                onChange={(event) => {
                                  setBillingType({
                                    value: event?.value,
                                    label: event?.label,
                                  });
                                  setUpdate(!update);
                                }}
                              />
                            </td>
                            <td>
                              <Select
                                options={helperData?.technologies}
                                className="w-100 listing-serach mt-2 mb-2"
                                placeholder="Select"
                                isSearchable={true}
                                isClearable={true}
                                value={technology}
                                styles={{
                                  menu: (base) => ({
                                    ...base,
                                    width: "max-content",
                                    minWidth: "100%",
                                  }),
                                }}
                                onChange={(event) => {
                                  setTechnology({
                                    value: event?.value,
                                    label: event?.label,
                                  });
                                  setUpdate(!update);
                                }}
                              />
                            </td>
                            <td>
                              <Select
                                options={helperData?.developers}
                                className="w-100 listing-serach mt-2 mb-2"
                                placeholder="Select"
                                isSearchable={true}
                                isClearable={true}
                                value={developer}
                                styles={{
                                  menu: (base) => ({
                                    ...base,
                                    width: "max-content",
                                    minWidth: "100%",
                                  }),
                                }}
                                onChange={(event) => {
                                  setDeveloper({
                                    value: event?.value,
                                    label: event?.label,
                                  });
                                  setUpdate(!update);
                                }}
                              />
                            </td>
                            <td>
                              <Select
                                options={helperData?.managers}
                                className="w-100 listing-serach mt-2 mb-2"
                                placeholder="Select"
                                isSearchable={true}
                                isClearable={true}
                                value={manager}
                                styles={{
                                  menu: (base) => ({
                                    ...base,
                                    width: "max-content",
                                    minWidth: "100%",
                                  }),
                                }}
                                onChange={(event) => {
                                  setManager({
                                    value: event?.value,
                                    label: event?.label,
                                  });
                                  setUpdate(!update);
                                }}
                              />
                            </td>
                            {/* <td>
                            <Select
                                options={helperData?.project_size}
                                className="w-100 listing-serach mt-2 mb-2"
                                placeholder="Select"
                                isSearchable={true}
                                isClearable={true}
                                value={projectSize}
                                autosize={true}
                                styles={{
                                  menu: (base) => ({
                                    ...base,
                                    width: "max-content",
                                    minWidth: "100%",
                                  }),
                                }}
                                onChange={(event) => {
                                  setProjectSize({
                                    value: event?.value,
                                    label: event?.label,
                                  });
                                  setUpdate(!update);
                                }}
                              />
                            </td>
                            <td>
                              <Select
                                options={helperData?.sources}
                                className="w-100 listing-serach mt-2 mb-2"
                                placeholder="Select"
                                isSearchable={true}
                                isClearable={true}
                                value={source}
                                styles={{
                                  menu: (base) => ({
                                    ...base,
                                    width: "max-content",
                                    minWidth: "100%",
                                  }),
                                }}
                                onChange={(event) => {
                                  setSource({
                                    value: event?.value,
                                    label: event?.label,
                                  });
                                  setUpdate(!update);
                                }}
                              />
                            </td> */}
                            <td>
                              <Select
                                options={helperData?.billing_sources}
                                className="w-100 listing-serach mt-2 mb-2"
                                placeholder="Select"
                                isSearchable={true}
                                isClearable={true}
                                value={trackerId}
                                styles={{
                                  menu: (base) => ({
                                    ...base,
                                    width: "max-content",
                                    minWidth: "100%",
                                  }),
                                }}
                                onChange={(event) => {
                                  setTrackerId({
                                    value: event?.value,
                                    label: event?.label,
                                  });
                                  setUpdate(!update);
                                }}
                              />
                            </td>
                          </tr>
                          {projectList?.isLoading ? (
                            <tr style={{ height: "100px" }}>
                              <td colSpan={`100%`}>
                                <LoadingBox />
                              </td>
                            </tr>
                          ) : projectList?.data?.projects?.length ? (
                            <>
                              {projectList?.data?.projects?.map(
                                (project, idx) => {
                                  return (
                                    <tr key={idx}>
                                      <td>
                                        <span className="mt-4 d-flex mr-4">
                                          {(currentPage - 1) * pageSize +
                                            (idx + 1)}
                                        </span>
                                      </td>
                                      <td>
                                        <div className="mt-2 mb-2">
                                          <div className="row">
                                            <div className="ml-3">
                                              {project?.name ?? "NA"}
                                            </div>
                                            <div className="dot-set"></div>
                                            <div className="dot-light"></div>
                                          </div>
                                          <div className="">
                                            <p>
                                              (
                                              {Utils.momentDateFromTimestamp(
                                                STRING.PROJECT_DATE_FORMAT,
                                                project?.created_at
                                              )}
                                              )
                                            </p>
                                          </div>
                                          <div className="">
                                            <div className="progress">
                                              <div
                                                className="progress-bar bg-info"
                                                role="progressbar"
                                                style={{ width: "50%" }}
                                                aria-valuenow="50"
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                              ></div>
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <span className="mt-4 d-flex">
                                          {project?.billing_type ?? "NA"}
                                        </span>
                                      </td>

                                      <td style={{ verticalAlign: "//middle" }}>
                                        <ul>
                                          {project?.technology?.map((dev) => {
                                            return (
                                              <li key={dev?.id}>{dev?.name}</li>
                                            );
                                          })}
                                        </ul>
                                      </td>

                                      <td style={{ verticalAlign: "//middle" }}>
                                        <ul>
                                          {project?.assigned_developer?.map(
                                            (dev) => {
                                              return (
                                                <li key={dev.id}>{dev.name}</li>
                                              );
                                            }
                                          )}
                                        </ul>
                                      </td>
                                      <td style={{ verticalAlign: "//middle" }}>
                                        <ul>
                                          {project?.assigned_manager?.map(
                                            (dev) => {
                                              return (
                                                <li key={dev.id}>{dev.name}</li>
                                              );
                                            }
                                          )}
                                        </ul>
                                      </td>
                                      {/* <td>
                                        <span className="mt-4 d-flex">
                                          {project?.size ?? "NA"}
                                        </span>
                                      </td> */}
                                      {/* <td>
                                        <span className="mt-4 d-flex">
                                          {project?.source ?? "NA"}
                                        </span>
                                      </td> */}

                                      <td style={{ verticalAlign: "//middle" }}>
                                        <ul>
                                          {project?.billing_resource?.map(
                                            (dev) => {
                                              return (
                                                <li key={dev?.id}>
                                                  {dev?.name}
                                                </li>
                                              );
                                            }
                                          )}
                                        </ul>
                                      </td>

                                      <td>
                                        <div className="d-flex mt-4">
                                          <div className="mr-3">
                                            <Link
                                              to={`/view-project/${project.id}`}
                                            >
                                              <i
                                                className="fa fa-eye site-color curser-point"
                                                aria-hidden="true"
                                              ></i>
                                            </Link>
                                          </div>
                                          <div className="">
                                            <Link
                                              to={`/update-project/${project.id}`}
                                            >
                                              <i
                                                className="fa fa-pencil site-color curser-point"
                                                aria-hidden="true"
                                              ></i>
                                            </Link>
                                          </div>
                                          <div className="ml-3">
                                            <i
                                              className="site-color fa fa-trash curser-point"
                                              aria-hidden="true"
                                              onClick={() =>
                                                deleteProject(project.id)
                                              }
                                            ></i>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </>
                          ) : (
                            <tr>
                              <td colSpan="10">No record found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>

                      {projectList?.data?.projects?.length > 0 && (
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          pageCount={projectList?.data?.total_pages}
                          onPageChange={handlePageChange}
                          containerClassName={"pagination"}
                          activeClassName={"active"}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        isShow={showDevelpers.status}
        onHide={() => {
          setShowDevelpers({ data: [], status: false });
        }}
        heading="Developers List"
      >
        <div className="row">
          <div className="col-md-12">
            {/* <div className="mb-0">
              <label>Are you sure you want to delete it?</label>
              <div className="modal-footer mt-3" style={{ clear: "both" }}>
                <div style={{ float: "right", height: "auto" }}>
                  <button type="input" name="button" onClick={deleteTaskContainer} id="deleteTask" className="btn btn-danger btn-icon mr-2"><i className="fa fa-check-square-o"></i>Delete Task</button>
                  <button type="button" onClick={() => { setDeleteConfirmationShow({ ...deleteConfirmationShow, status: false }) }} className="btn btn-secondary btn-icon" data-dismiss="modal"><i className="fa fa-times-circle-o"></i> Cancel</button>
                </div>
              </div>
            </div> */}
            <ul className="row">
              {showDevelpers?.data?.map((value, i) => {
                return (
                  <li className="col-md-4" key={i}>
                    {value?.name}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default ManageProject;
