import { put, call, takeLatest } from "redux-saga/effects";
import { inventoryActionSuccess,inventoryActionError } from "../action/inventoryAction";
import { INVENTORY_LIST_REQUEST } from "../actionType/type";
import { INVENTORY_MANAGE} from "../../service/apiUrl";
import { postApiCall } from "../../service/PostApiCall";


function* inventorySaga(action){
    try {
        const response = yield call(postApiCall,action?.params,action?.params?.access_token,INVENTORY_MANAGE)
       
        yield put(inventoryActionSuccess(response));
    } catch (error) {
        yield put(inventoryActionError(error));
    }
}

export function* watchInventorySaga(){
    yield takeLatest(INVENTORY_LIST_REQUEST,inventorySaga)
}