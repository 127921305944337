import { VIEW_TRACKER_REQUEST,  VIEW_TRACKER_REQUEST_ERROR,  VIEW_TRACKER_REQUEST_SUCCESS } from "../actionType/type";

const initialState = {
    data: null,
    isLoading: false,
};

export const viewTrackerReducers = (state=initialState,action) => {
    let object
    switch (action.type) {
        case  VIEW_TRACKER_REQUEST:
            object = {
                ...state,
                isLoading: true,
              }
            break
        case  VIEW_TRACKER_REQUEST_ERROR:
            object = {
                ...state,
                isLoading:false
            }
            break
        case  VIEW_TRACKER_REQUEST_SUCCESS:
            object = {
                ...state,
                data:action.payload,
                isLoading:false
            }
            break
    
        default:
            object = state
            break
    }
    return object
}