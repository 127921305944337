/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { useParams, useSearchParams } from "react-router-dom";
import Select from "react-select";
import { useSelector } from "react-redux";
import Utils from "../utility/utility";
import { STRING } from "../constants/string";
import { postApiCall } from "../service/PostApiCall";
import TimePicker from "react-bootstrap-time-picker";
import { useNavigate } from "react-router-dom";
import {
 
  LEAVES_HELPER,
  RECENTAPPROVED,
  LEAVE_UPDATE,
  LEAVE_VIEW,
  DEPARTMENTSUSERS,
} from "../service/apiUrl";
// import { start } from "repl";
import { useLocation } from "react-router-dom";


const EditLeave = (props) => {
  const leaveId = useParams();
  const [update, setUpdate] = useState(false);
  const [department, setdepartment] = useState({ value: "", label: "" });
  const [leaveStatus, setLeaveStatus] = useState({ value: "", label: "" });
  const [comment, setComment] = useState(""); 
  
  const [leavetable, setleavetable] = useState([]);
  const [employee,] = useState(false);
  const [departmentEmployees, setdepartmentEmployees] = useState(false);
  const [type, setType] = useState({ value: "", label: "" });
  const [fromDate, setfromDate] = useState(new Date());
  const fromDates = Utils.momentDate(fromDate, STRING.SLASH_DATE_FORMAT);
  const [toDate, settoDate] = useState(new Date());
  const toDates = Utils.momentDate(toDate, STRING.SLASH_DATE_FORMAT);
  const [reason, setReason] = useState("");
  const [contact, setContact] = useState("");
  const [startTime, setStartTime] = useState(32400);
  const [startTimeformat, setStartTimeFormat] = useState(Utils.secondtoTime(startTime));
  // eslint-disable-next-line no-unused-vars
  const [employes, setEmployes] = useState();
  const [endTime, setEndTime] = useState(32400);
  const [endTimeformat, setEndTimeFormat] = useState(Utils.secondtoTime(endTime));
  const [half, setHalf] = useState();
  const [helperData, setHelperData] = useState([]);
  const [filteredParams, setFilteredParams] = useState({});
  

  const navigate = useNavigate();

  const signdata = useSelector((state) => state.signinReducers);
  const access_token = signdata?.data?.access_token;

  const getDepartmentUsers = (department) => {
    const departementParams = {
      department: department,
    };
    postApiCall(departementParams, access_token, DEPARTMENTSUSERS).then(
      (result) => {
        setdepartmentEmployees(result?.users);
      }
    );
  };

  useEffect(() => {
    postApiCall([], access_token, LEAVES_HELPER)
      .then((result) => {
        setHelperData(result);
      })
      .catch((error) => {
        console.error("LEAVES_HELPER", error);
      });
  }, []);

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    setFilteredParams({
      fromDate: queryParams.get('fromDate'),
      toDate: queryParams.get('toDate'),
      uid: queryParams.get('uid'),
    })
  }, [location.search]);


  useEffect(() => {
    if (leaveId) {
      const parms = {
        access_token: access_token,
        id: leaveId?.leave_id,
      };
      postApiCall(parms, access_token, LEAVE_VIEW)
        .then((result) => {
          setUpdate(true);
          // setUserData(result);
          setdepartment(result?.department);
          setReason(result?.reason);
          setType(result?.type);
          setContact(result?.contact)
          setLeaveStatus(result?.status)

          setfromDate(new Date(result?.from_timestamp*1000))
          settoDate(new Date(result?.to_timestamp*1000))
          setStartTimeFormat(result?.time_from);
          setEndTimeFormat(result?.time_to);
          // setHalf(result?.half_day_type);
          setHalf(result?.half_day_type);
          
          
        //   setPersonalAddress(result?.hrDetails?.personal_address);
        //   setPgAddress(result?.hrDetails?.pg_address);
        })
        .catch((error) => {
        });
    }
  }, [access_token, leaveId, update]);

  const updateLeave = () => {
    let message = "";
    if (Utils.isEmpty(department?.value)) {
      message = STRING.DEPARTMENT;
    } else if (Utils.isEmpty(type)) {
      message = STRING.TYPE;
    } else if (startTime > endTime && type === 3) {
      message = STRING.TIMEGREATHER;
    } else if (Utils.isEmpty(reason)) {
      message = STRING.REASON;
    } else if (Utils.isEmpty(contact)) {
      message = STRING.CONTACT;
    }

    if (message) {
      Utils.toast("error", message);
      return false;
    } else {
      const EditLeaveParams = {
        id: leaveId?.leave_id,
        type: type?.value,
        department: department?.value,
        from: fromDates,
        to: toDates,
        // half_day: String(half.value ?? ""),
        half_day: half.value,
        time_from: startTimeformat,
        time_to: endTimeformat,
        reason: reason,
        contact: contact,
        status: parseInt(leaveStatus?.value),
        comment:comment,
        showSuccessToast: true
      };

      postApiCall(EditLeaveParams, access_token, LEAVE_UPDATE, navigate).then(
        (response) => {
          // navigate(`/manage-leave?fromDate=${filteredParams.fromDate}&toDate=${filteredParams.toDate}&uid=${filteredParams.uid}`);
          navigate(`/manage-leave`);
        }
      );
    }
  };

  const getRecentLeaves = (employee_id) => {
    if(employee_id){
    const params = {
      uid: employee_id,
    };
    postApiCall(params, access_token, RECENTAPPROVED).then((result) => {
     
      setleavetable(result?.approved_leaves);
    });
  }
  };
 
  const [searchParams] = useSearchParams();
  let userId = searchParams.get("uid");

  useEffect(()=>{
    getRecentLeaves(userId)
  },[userId])

 

  return (
    <>
      <div className={props?.data?.transtion === true ? "contents" : "content"}>
        <div className="content-top-spacer"></div>
        <div className="color-body">
          <div className="row">
            <div className="col-md-6 manage">
              <div className="card-input mt-5 mb-2">
                <div className="col-md-12">
                  <div className="mt-3">
                    <h5 className="border-bottom pb-2">Edit Leave</h5>
                  </div>
                  <form>
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Department</label>
                          <Select
                            options={helperData?.departments}
                            value={department}

                            onChange={(event) => {
                              setdepartment({
                                value: event?.value,
                                label: event?.label,
                              });
                              getDepartmentUsers(event?.value);
                              setUpdate(!update);
                            }}
                            placeholder="Please Select"
                            isDisabled={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Leave Type</label>

                          <Select
                            options={helperData?.leave_type}
                            value={type}
                            onChange={(leavetype) => {
                              setType(leavetype);
                              if (leavetype.value == "1") {
                                setHalf("");
                                setStartTime("");
                                setEndTime("");
                              }
                              if (leavetype.value == "2") {
                                setStartTime("");
                                setEndTime("");
                              }
                              if (leavetype.value == "3") {
                                setHalf("");
                              }
                            }}
                            placeholder="Please Select"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        
                        {employee == true ? (
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Employee</label>
                            <Select
                              options={departmentEmployees}
                              value={employee}
                              // onChange={(employe) => {
                            
                              //   setEmployes(employe.value);
                            
                              // }}
                              placeholder="Please Select"
                              isDisabled={true}
                            />
                          </div>
                        ) : null}
                      </div>
                      {type.value == 1 || type.value == 2 || type.value == 3 ? (
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              From Date
                            </label>
                            <DatePicker
                              selected={fromDate}
                              onChange={(date) => {
                                setfromDate(date);
                              }}
                              className="width-dateset w-100"
                            />
                          </div>
                        </div>
                      ) : null}
                      {type.value == 1 ? (
                        <>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                End Date
                              </label>
                              <DatePicker
                                selected={toDate}
                                onChange={(date) => settoDate(date)}
                                className="width-dateset w-100"
                              />
                            </div>
                          </div>
                        </>
                      ) : null}
                      {type.value == 2 ? (
                        <>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">Half</label>
                              <Select
                                options={helperData?.half_leave_type}
                                onChange={(element) => {
                            
                                  setHalf(element);
                                  settoDate(fromDate);
                                }}
                                placeholder="Please Select"
                                value={half}

                              />
                            </div>
                          </div>
                        </>
                      ) : null}
                      {type.value == 3 ? (
                        <>
                          <div className="col-md-3">
                            <label htmlFor="exampleInputEmail1">
                              Start Time
                            </label>
                            <TimePicker
                              start="09:00"
                              end="20:00"
                              onChange={(start) => {
                                setStartTime(start);
                              }}
                              value={Utils.timeFormatToSec(startTimeformat)}
                            />
                          </div>
                          <div className="col-md-3">
                            <label htmlFor="exampleInputEmail1">End Time</label>
                            <TimePicker
                              start="09:00"
                              end="20:00"
                              step={30}
                              onChange={(end) => {
                                setEndTime(end);
                              }}
                              value={Utils.timeFormatToSec(endTimeformat)}

                            />
                          </div>
                        </>
                      ) : null}

                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Reason</label>
                          <textarea
                            className="form-control"
                            id="exampleFormControlTextarea1"
                            onChange={(reason) => {
                              setReason(reason.target.value);
                            }}
                            rows="4"
                            value={reason}
                          ></textarea>
                        </div>
                        <div className="mt-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Contact During Leave
                            </label>
                            <input
                              type="text"
                              value={contact}
                              className="form-control"
                              onChange={(Contact) => {
                                setContact(Contact.target.value);
                              }}
                            />
                          </div>
                        </div>

                        <div className="mt-2 form-group">
                          <label htmlFor="exampleInputEmail1">
                            Leave Status
                          </label>
                          <Select
                              options={helperData?.leave_status}
                              value={leaveStatus}
                              onChange={(event) => {
                                setLeaveStatus({
                                  value: event?.value,
                                  label: event?.label
                                });
                              }}

                              


                              placeholder="Please Select"
                            />
                        </div>

                        <div className="mt-2 form-group">
                          <label htmlFor="exampleInputEmail1">Comment</label>
                          <textarea
                            className="form-control"
                            onChange={(reason) => {
                              setComment(reason.target.value);
                            }}
                            rows="4"
                            value={comment}
                          ></textarea>
                        </div>


                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="row mb-5 mt-4">
                        <div className="">
                          <button
                            type="button"
                            className="create-button"
                            onClick={updateLeave}
                          >
                            Continue
                          </button>
                        </div>
                        <div className="ml-2">
                          <button
                            type="button"
                            className="create-button1 btn-info"
                          //   onClick={() => {
                          //     navigate(`/manage-leave?fromDate=${filteredParams.fromDate}&toDate=${filteredParams.toDate}&uid=${filteredParams.uid}`);
                          // }}
                          onClick={() => {
                            navigate(`/manage-leave`);
                        }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-6 manage">
              <div className="card-input mt-5 mb-2">
                <div className="col-md-12">
                  <div className="mt-3">
                    <h5>
                      My Recent Approved Leaves
                    </h5>
                  </div>
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col" className="text-center">
                          Date
                        </th>
                        <th scope="col" className="text-center">
                          Type
                        </th>
                        <th scope="col" className="text-center">
                          Duration
                        </th>
                        <th scope="col" className="text-center">
                          Reason
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {leavetable?.map((datae, idx) => {
                        return (
                          <tr key={idx}>
                            <td className="text-center">{Utils.momentDate(datae?.from,'MMMM DD, YYYY')}</td>
                            <td className="text-center">{datae?.type}</td>
                            <td className="text-center">{datae?.duration}</td>
                            <td className="text-center" title={datae?.reason}>{Utils.stringReducer(datae?.reason)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div className="border-bottom mt-3"></div>
                  {leavetable == "" ? (
                    <div className="text-center">
                      <div className="no-approved">
                        <p className="mb-0">No Recent Approved Leaves</p>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


export default EditLeave;
