import { PROJECT_REQUEST, 
    PROJECT_REQUEST_ERROR, 
    PROJECT_REQUEST_SUCCESS,
    PROJECT_DELETE_REQUEST,
    PROJECT_DELETE_REQUEST_SUCCESS,
    PROJECT_DELETE_REQUEST_ERROR
 } from "../actionType/type";


export const manageProjectActionRequest = (params,access_token) => {
    return {
        type :  PROJECT_REQUEST,
        params : params,
        access_token : access_token
        
    }
}

export const manageProjectActionSuccess = (result) => {
    return {
        type: PROJECT_REQUEST_SUCCESS,
        payload: result,
        error: null,
    };
}

export const manageProjectActionError = (error) => {
    return {
        type: PROJECT_REQUEST_ERROR,
        error:error,
        payload:null
    }
}

export const projectDeleteRequest = (paramsData, onEmpDeleteSuccess) => {
    return {
      type: PROJECT_DELETE_REQUEST,
      params: paramsData,
      onEmpDeleteSuccess,
    };
  };
  
  export const projectDeleteSuccess = (result) => {
    return {
      type: PROJECT_DELETE_REQUEST_SUCCESS,
      payload: result,
      error: null,
    };
  };
  
  export const projectDeleteError = (error) => {
    return {
      type: PROJECT_DELETE_REQUEST_ERROR,
      error: error,
      payload: null,
    };
  };