import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import Utils from "../utility/utility";
import { STRING } from "../constants/string";
import { postApiCall } from "../service/PostApiCall";
import { useNavigate } from "react-router-dom";
import {
  TODO_HELPER,
  CREATE_TODO
} from "../service/apiUrl";

const AddTodo = (props) => {
  const [assignTo, setAssignTo] = useState({ value: "", label: "" });
  const [task, setTask] = useState("");
  
  const [helperData, setHelperData] = useState([]);

  const navigate = useNavigate();

  const signdata = useSelector((state) => state.signinReducers);
  const user_id = signdata?.data?.id;
  const access_token = signdata?.data?.access_token;





  useEffect(() => {
    postApiCall([], access_token, TODO_HELPER)
      .then((result) => {
        setHelperData(result);
      })
      .catch((error) => {
        console.error("TODO_HELPER", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const AddTask = () => {
    let message = "";
    if (Utils.isEmpty(task)) {
      message = STRING.ENTER_TASK;
    } else if (Utils.isEmpty(assignTo?.value)) {
      message = STRING.ADMIN;
    } 

    if (message) {
      Utils.toast("error", message);
      return false;
    } else {
      const addTodoParams = {
        task: task,
        assignTo: assignTo?.value,
        createdBy: user_id,

      };

      postApiCall(addTodoParams, access_token, CREATE_TODO).then(
        (response) => {
          Utils.toast("success", "Todo task created successfully!!!");
          navigate("/manage-todo");
        }
      );
    }
  };


  return (
    <>
      <div className={props?.data?.transtion == true ? "contents" : "content"}>
        <div className="content-top-spacer"></div>
        <div className="color-body">
          <div className="row">
            <div className="col-md-6 manage">
              <div className="card-input mt-5 mb-2">
                <div className="col-md-12">
                  <div className="mt-3">
                    <h5 className="border-bottom pb-2">Add Todo Task</h5>
                  </div>
                  <form>

                    <div className="col-md-12 mt-3">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Task</label>
                          <textarea
                            className="form-control"
                            id="exampleFormControlTextarea1"
                            onChange={(reason) => {
                              setTask(reason.target.value);
                            }}
                            rows="4"
                          ></textarea>
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Assign to</label>
                          <Select
                            options={helperData?.admins}
                            onChange={(event) => {
                              setAssignTo({
                                value: event?.value,
                                label: event?.label,
                              });
                            }}

                            placeholder="Please Select"
                          />
                        </div>
                    </div>
                    <div className="col-md-12">
                      <div className="row mb-5 mt-4">
                        <div className="">
                          <button
                            type="button"
                            className="create-button"
                            onClick={AddTask}
                          >
                            Add
                          </button>
                        </div>
                        <div className="ml-2">
                          <button
                            type="button"
                            className="create-button1 btn-info"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTodo;
