import React from 'react'
import moment from 'moment'
import { BiSupport, BiCheck } from 'react-icons/bi'
import { FaCheckCircle, FaUser } from 'react-icons/fa'
import { GoDotFill } from 'react-icons/go'
import { useNavigate } from 'react-router-dom'

const NotificationComponent = ({
  notificationData,
  signdata,
  isActive,
  markAsReadHandler,
}) => {
  let navigate = useNavigate()
  const sortedNotificationData = notificationData.sort(
    (a, b) => b.created_at - a.created_at,
  )
  const supportData = sortedNotificationData.filter(
    (item) => item.type === 'support',
  )
  const leaveDetails = sortedNotificationData.filter(
    (item) => item.type === 'leave',
  )

  const handleNavigation = (type) => {
    if (type.type == 'leave') {
      markAsReadHandler(type.id)
      navigate(`/edit-leave/${type.leaveId}?uid=${type.uid}`)
    } else if (type.type == 'support') {
      markAsReadHandler(type.id)
      navigate(`/add-support/${type.supportId}`)
    }
  }

  return (
    <div className="col-md-12">
      {notificationData.length === 0 ? (
        <p>No data available</p>
      ) : (
        <>
          {isActive == 2 && signdata?.data?.role == 1 && (
            <>
              {supportData.length > 0 ? (
                supportData.map((support, index) => {
                  const timeFromNow = moment.unix(support?.created_at).fromNow()

                  return (
                    <div
                      className={`supportBox ${
                        support.isRead === 0 ? 'unread' : 'read'
                      }`}
                      key={index}
                    >
                      <div className="supportHeader">
                        <div
                          style={{ display: 'flex' }}
                          onClick={() =>
                            handleNavigation({
                              type: support.type,
                              id:support.id,
                              supportId: support.doc_id,
                            })
                          }
                        >
                          <span className="supportIcon">
                            <BiSupport />
                          </span>
                          <div className="userDetails">
                            <p className="supportTitle">{support?.title}</p>
                            <p className="supportDescription">
                              {support?.description}
                            </p>
                          </div>
                        </div>
                        <span className="supportTime">{timeFromNow}</span>
                        <button
                          className="markAsReadButton"
                          onClick={() => markAsReadHandler(support.id)}
                        >
                          <BiCheck />
                        </button>
                      </div>
                      <span
                        className={`${
                          support?.isRead == '1' ? 'readed' : 'unreaded'
                        }`}
                      >
                        <GoDotFill />
                      </span>
                    </div>
                  )
                })
              ) : (
                <p className="noSupportMessage">No support data available.</p>
              )}
            </>
          )}

          {isActive == 3 && signdata?.data?.role == 1 && (
            <>
              {leaveDetails.length > 0 ? (
                leaveDetails.map((leave, index) => {
                  const timeFromNow = moment.unix(leave?.created_at).fromNow()
                  return (
                    <div
                      className={`leaveBox ${leave.isRead ? 'read' : 'unread'}`}
                      key={index}
                     
                    >
                      <div
                          style={{ display: 'flex' }}
                          onClick={() =>
                            handleNavigation({
                              type: leave.type,
                              leaveId: leave.doc_id,
                              id: leave.id,
                              uid: leave.user?.id,
                            })
                          }
                        >
                      <div className="leaveHeader">
                        <span className="leaveIcon">
                          <FaUser />
                        </span>
                        <div className="userDetails">
                          <p className="leaveTitle">{leave?.user?.name}</p>
                          <p className="leaveDescription">
                            {leave?.description}
                          </p>
                        </div>
                        </div>
                        <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginLeft: 30}}>
                        <span className="leaveTime">{timeFromNow}</span>
                        <span
                          className="markAsReadButton"
                          onClick={() => markAsReadHandler(leave.id)}
                        >
                          <FaCheckCircle />
                        </span>
                        </div>
                      </div>
                      <span
                        className={`${
                          leave?.isRead == '1' ? 'readed' : 'unreaded'
                        }`}
                      >
                        <GoDotFill />
                      </span>
                    </div>
                  )
                })
              ) : (
                <p className="noLeaveMessage">No leave data available.</p>
              )}
            </>
          )}
        </>
      )}
    </div>
  )
}

export default NotificationComponent
