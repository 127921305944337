
import {INVENTORY_LIST_REQUEST,INVENTORY_LIST_REQUEST_ERROR,INVENTORY_LIST_REQUEST_SUCCESS} from "../actionType/type";

export const inventoryRequest = (access_token) => {
    return {
        type :   INVENTORY_LIST_REQUEST,
        params : access_token 
        
    }
}

export const inventoryActionSuccess = (result) => {

    return {
        type: INVENTORY_LIST_REQUEST_SUCCESS,
        payload: result,
        error: null,
    };
}

export const inventoryActionError = (error) => {
    return {
        type: INVENTORY_LIST_REQUEST_ERROR,
        error:error,
        payload:null
    }
}
// export const actionRefreshForm = (msg) => {
//     return {
//         type: FORM_REFRESH,
//         error:null,
//         payload:null,
//         message:msg
//     }
// }
