/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Utils from "../utility/utility";
import { Link } from "react-router-dom";
import LoadingBox from "../components/LoadingBox";
import { postApiCall } from "../service/PostApiCall";
import { DELETE_CLICKUP_ACCOUNT } from "../service/apiUrl";
import { actionRefreshForm } from "../redux/action/manageTrackerAction";
import ReactPaginate from "react-paginate";
import { manageClickupActionRequest } from "../redux/action/manageClickAction";

const ManageClickupAccount = (props) => {
  let filterByEmail = localStorage.getItem("filterEmail")
  console.log("asdas",filterByEmail)
  const [clickup, setClickup] = useState(filterByEmail !="null" ? filterByEmail:"");
  const [update, setUpdate] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);

  const dispatch = useDispatch();
  const signdata = useSelector((state) => state.signinReducers);
  const access_token = signdata?.data?.access_token;

  const manageClikupAccounts = useSelector(
    (state) => state.manageClickupReducers
  );

  useEffect(() => {
    const parms = {
      access_token: access_token,
      search: clickup,
      pageSize: pageSize,
      page: currentPage,
    };
    dispatch(manageClickupActionRequest(parms));
  }, [clickup, update, currentPage, manageClikupAccounts.refreshPage]);

  const deletedata = (objectdata) => {
    if (window.confirm("Are you sure you want to delete it?")) {
      postApiCall(objectdata, access_token, DELETE_CLICKUP_ACCOUNT).then(
        (response) => {
          Utils.toast("success", "Click account deleted successfully!");
          dispatch(actionRefreshForm());
        }
      );
    }
  };
  const email = async (data) => {
    await navigator.clipboard.writeText(data);
    Utils.toast("success", "Username copied");
  };
  const password = async (data) => {
    await navigator.clipboard.writeText(data);
    Utils.toast("success", "Password copied");
  };

  const handlePageChange = (pageNumber) => {
    const page = pageNumber?.selected + 1;
    setCurrentPage(page);
    setUpdate(!update);
  };

  return (
    <>
      {manageClikupAccounts?.isLoading === true ? <LoadingBox /> : null}
      <div className={props?.data?.transtion === true ? "contents" : "content"}>
        <div className="content-top-spacer"></div>
        <div className="color-body">
          <div className="col-md-12">
            <div className="card-input mt-3">
              <div className="col-md-12 mb-3">
                <div className="mt-3">
                  <h3>Clickup Trackers</h3>
                </div>
                <div className="mt-4">
                  <div className="">
                    <div className="mt-3">
                      <table className="table table-sm">
                        {/* <colgroup>
                        <col width="2%" />
                        <col width="20%" />
                        <col width="15%" />
                        <col width="19%" />
                        <col width="19%" />
                        <col width="15%" />
                        <col width="10%" />
                      </colgroup> */}
                        <thead>
                          <tr>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">#</span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">Email</span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">Password</span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">
                                Developers
                              </span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">
                                Updated At
                              </span>
                            </th>
                            <th scope="col" className="site-color">
                              <span className="mt-2 mb-2 d-flex">Manage</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td></td>
                            <td style={{ position: "relative" }}>
                              <input
                                type="text"
                                className="w-100 pl-2 listing-serach min-h-38 mt-2 mb-2"
                                value={clickup}
                                placeholder="Please enter the email address."
                                onChange={(name) => {
                                  localStorage.setItem("filterEmail",name.target.value)
                                  setClickup(name.target.value);
                                  setUpdate(!update);
                                }}
                              />
                              {clickup?.length > 0 && (
                                <span
                                  style={{
                                    position: "absolute",
                                    color: "#949494",
                                    top: "50%",
                                    right: 30,
                                    transform: "translate(50%, -50%)",
                                    cursor: "pointer",
                                  }}
                                  onClick={()=>{
                                    localStorage.removeItem("filterEmail")
                                    setClickup("");
                                    setUpdate(!update);
                                  }}
                                >
                                  &#x2715;
                                </span>
                              )}
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          {manageClikupAccounts?.data?.clickup.length ? (
                            <>
                              {manageClikupAccounts?.data?.clickup?.map(
                                (data, idx) => {
                                  const datestamp = Utils.timestampDate(
                                    data?.updated_at,
                                    "MM/DD/YYYY"
                                  );
                                  const objectdata = {
                                    id: data?.id,
                                    access_token: access_token,
                                  };

                                  return (
                                    <tr key={idx}>
                                      <td>
                                        <span className="mt-4 d-flex mr-4">
                                          {(currentPage - 1) * pageSize +
                                            (idx + 1)}
                                        </span>
                                      </td>
                                      <td>
                                        <div className="d-flex row mt-3 ml-2">
                                          <span className="">
                                            {data?.email}
                                          </span>
                                          <span className="ml-2">
                                            <i
                                              className="fa fa-clipboard site-color curser-point"
                                              aria-hidden="true"
                                              onClick={() => {
                                                email(data?.email);
                                              }}
                                            ></i>
                                          </span>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="row d-flex mt-3 ml-2">
                                          <span className="">
                                            {data?.password}
                                          </span>
                                          <span className="ml-2">
                                            <i
                                              className="fa site-color fa-clipboard curser-point"
                                              aria-hidden="true"
                                              onClick={() => {
                                                password(data?.password);
                                              }}
                                            ></i>
                                          </span>
                                        </div>
                                      </td>
                                      <td>
                                        <span className="mt-3 d-flex">
                                          <ul>
                                            {data?.assigned_developer?.map(
                                              (dev) => {
                                                return (
                                                  <li key={dev.id}>
                                                    {dev.name}
                                                  </li>
                                                );
                                              }
                                            )}
                                          </ul>
                                        </span>
                                      </td>
                                      <td>
                                        <span className="mt-3 d-flex">
                                          {datestamp}
                                        </span>
                                      </td>

                                      <td>
                                        <div className="row mt-3 mb-3">
                                          <Link to={"/add-clickup/" + data?.id}>
                                            <div className="">
                                              <i
                                                className="fa fa-pencil site-color"
                                                aria-hidden="true"
                                              ></i>
                                            </div>
                                          </Link>
                                          <div className="ml-3">
                                            <i
                                              className="site-color fa fa-trash curser-point"
                                              aria-hidden="true"
                                              onClick={() =>
                                                deletedata(objectdata)
                                              }
                                            ></i>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                              <tr>
                                <td colSpan={7} className="text-center w-100">
                                  <ReactPaginate
                                    previousLabel={"Previous"}
                                    nextLabel={"Next"}
                                    pageCount={
                                      manageClikupAccounts?.data?.total_pages
                                    }
                                    onPageChange={handlePageChange}
                                    containerClassName={"pagination"}
                                    activeClassName={"active"}
                                  />
                                </td>
                              </tr>
                            </>
                          ) : (
                            <tr>
                              <td colSpan="7">No record found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageClickupAccount;
