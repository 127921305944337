import AsyncStorage from "@react-native-async-storage/async-storage";

import { applyMiddleware, createStore } from "redux";

import { RootReducer } from "../reducers/rootReducer";
import { persistStore, persistReducer } from "redux-persist";
import creatSagaMiddleware from "redux-saga";
import RootSaga from "../saga/rootSaga";

const sagaMiddleware = creatSagaMiddleware()
const persistConfig = {
    key: "root",
    storage: AsyncStorage,
    whitelist: ["signinReducers" , "dashboardReducers"],
    blacklist: [""]
  }

// Middleware: Redux Persist Persisted Reducer
const persistedReducer = persistReducer(persistConfig, RootReducer);

const store = createStore(persistedReducer,applyMiddleware(sagaMiddleware))
let persistor = persistStore(store)

sagaMiddleware.run(RootSaga)


export { store, persistor };
