import { put, call, takeLatest } from "redux-saga/effects";
import { MANAGE_PROJECT,  DELETE_PROJECT} from "../../service/apiUrl";
import { postApiCall } from "../../service/PostApiCall";
import { manageProjectActionError, 
    manageProjectActionSuccess,
    projectDeleteSuccess,
    projectDeleteError
 } from "../action/manageProjectAction";
import { PROJECT_REQUEST, PROJECT_DELETE_REQUEST } from "../actionType/type";


function* manageProjectSaga(action){
    try {
        const response = yield call(postApiCall,action.params,action.access_token,MANAGE_PROJECT)
        yield put(manageProjectActionSuccess(response));
    } catch (error) {
        yield put(manageProjectActionError(error));
    }
}

export function* watchManageProjectSaga(){
    yield takeLatest(PROJECT_REQUEST,manageProjectSaga)
}

export function* projectDeleteSaga(action) {
    try {
      const response = yield call(postApiCall, action.params,action.access_token, DELETE_PROJECT);
      yield put(projectDeleteSuccess(response));
      action.onEmpDeleteSuccess();
    } catch (error) {
      yield put(projectDeleteError(error));
    }
}
  
export function* watchProjectDeleteSaga() {
    yield takeLatest(PROJECT_DELETE_REQUEST, projectDeleteSaga);
}